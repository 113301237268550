import React from "react";

import GridUnavailability from "../teamViewModule/InfraPerformance/GridUnavailability/GridUnavailability";
import { Route, Routes } from "react-router-dom";
import FuelConsumption from "../teamViewModule/InfraPerformance/FuelConsumption/FuelConsumption";
import TariffGrid from "../teamViewModule/InfraPerformance/TariffGrid/TariffGrid";
import SolarProduction from "../teamViewModule/InfraPerformance/SolarProduction/SolarProduction";
import GensetRunningHours from "../teamViewModule/InfraPerformance/GensetRunningHours/GensetRunningHours";
import SiteLoad from "../teamViewModule/InfraPerformance/SiteLoad/SiteLoad";
import PowerSourcesAnomalies from "../teamViewModule/InfraPerformance/PowerSourcesAnomalies/PowerSourcesAnomalies";
import BatteryChargingPeakHours from "../teamViewModule/InfraPerformance/BatteryChargingPeakHours/BatteryChargingPeakHours";
import FaultyRectifierModules from "../teamViewModule/InfraPerformance/FaultyRectifierModules/FaultyRectifierModules";
import BatteryFaultySites from "../teamViewModule/InfraPerformance/BatteryFaultySites/BatteryFaultySites";
import PowerSources from "../teamViewModule/InfraPerformance/PowerSources/PowerSources";
import BatteryBackup from "../teamViewModule/InfraPerformance/BatteryBackup/BatteryBackup";
import Summary from "../executiveDashboard/Summary/Summary";
import ExecutiveDashboardMain from "../executiveDashboard/MainScreen";
import LiveView from "../LiveView/LiveView";
import Login from "../LoginSignup/Login";
import Loadsheddding from "../executiveDashboard/Summary/Loadsheddding";
import GridConsumption from "../executiveDashboard/Summary/GridConsumption";
import SummaryFuelConsumption from "../executiveDashboard/Summary/SummaryFuelConsumption";
import CO2Emissions from "../executiveDashboard/Summary/CO2Emissions";
import Savings from "../executiveDashboard/Summary/Savings";
import Welcome from "../welcome/Welcome";
import AmTransformer from "../AssetManagement/AssetsManager/AmTransformer";
import AmBattery from "../AssetManagement/AssetsManager/AmBattery";
import AmGenerator from "../AssetManagement/AssetsManager/AmGenerator";
import AmRectifier from "../AssetManagement/AssetsManager/AmRectifier";
import AmSmartMeter from "../AssetManagement/AssetsManager/AmSmartMeter";
import AmAutotransformerSwitch from "../AssetManagement/AssetsManager/AmAutotransformerSwitch";
import AmRMSandCSU from "../AssetManagement/AssetsManager/AmRMSandCSU";
import AmSolar from "../AssetManagement/AssetsManager/AmSolar";
import MapView from "../MapView/MapView";
import SideNav from "../../Components/SideNav/SideNav";
import SiteFueling from "../SiteFueling/SiteFueling";

import { useLocation } from 'react-router-dom'
 
import Infrastructure from "../SitePerformance/Infrastructure";
import SitesInsights from "../SitePerformance/SpInsights/SitesInsights";
 import SitesFuel from "../SitePerformance/SpFueling/SitesFuel";
import SitesPerformance from "../SitePerformance/SpPerformance/SitesPerformance";
import SitesSnapshotView from "../SitePerformance/SitesSnapshotView";
import SitesInterventions from "../SitePerformance/SitesInterventions";
import SitesImpact from "../SitePerformance/SitesImpact";
import SitesAlamrs from "../SitePerformance/SitesAlamrs";
import SitesReports from "../SitePerformance/SitesReports";
import SitesAnomalies from "../SitePerformance/SitesAnomalies";
import SitesHeartbeatView from "../SitePerformance/SitesHeartbeatView";
import SitesPlayground from "../SitePerformance/SitesPlayground";
import SitesConfigurations from "../SitePerformance/SitesConfigurations";
import SitesOperations from "../SitePerformance/SitesOperations";
import SitesCompliance from "../SitePerformance/SitesCompliance";
import SitesControl from "../SitePerformance/SitesControl";
import SitesLogs from "../SitePerformance/SitesLogs";
import SingleSiteSummary from "../SitePerformance/SingleSiteSummary";
import SitesInfraview from "../SitePerformance/SitesInfraview";
import AmCapacityPlanning from "../AssetManagement/CapacityPlanning/AmCapacityPlanning";
import AmPlayground from "../AssetManagement/Playground/AmPlayground";
import AmDatabase from "../AssetManagement/Database/AmDatabase";
import AmInfraHealthCard from "../AssetManagement/InfraHealthCard/AmInfraHealthCard";
import AmEnergyPlanning from "../AssetManagement/EnergyPlanning/AmEnergyPlanning";
import EpTargetsInput from "../AssetManagement/EnergyPlanning/EpTargetsInput";
import EpTargetsOutput from "../AssetManagement/EnergyPlanning/EpTargetsOutput";
import TargetsOutputBattery from "../AssetManagement/EnergyPlanning/TargetsOutput/TargetsOutputBattery";
import TargetsOutputFuel from "../AssetManagement/EnergyPlanning/TargetsOutput/TargetsOutputFuel";
import TargetsOutputSolar from "../AssetManagement/EnergyPlanning/TargetsOutput/TargetsOutputSolar";
import TargetsOutputGrid from "../AssetManagement/EnergyPlanning/TargetsOutput/TargetsOutputGrid";
import TargetsOutputGenset from "../AssetManagement/EnergyPlanning/TargetsOutput/TargetsOutputGenset";
import AmSummary from "../AssetManagement/Summary/AmSummary";
import AmDbSiteInfra from "../AssetManagement/Database/AmDbSiteInfra";
import AmDbTechParameters from "../AssetManagement/Database/AmDbTechParameters";
import AmDbSiteLoad from "../AssetManagement/Database/AmDbSiteLoad";
import AmDbSolar from "../AssetManagement/Database/AmDbSolar";
import AmDbTf from "../AssetManagement/Database/AmDbTf";
import AmDbDg from "../AssetManagement/Database/AmDbDg";
import AmDbRectifier from "../AssetManagement/Database/AmDbRectifier";
import AmDbClassification from "../AssetManagement/Database/AmDbClassification";
import AmDbBattery from "../AssetManagement/Database/AmDbBattery";
import AmDbSizing from "../AssetManagement/Database/AmDbSizing";
import AmDbTCH from "../AssetManagement/Database/AmDbTCH";
import AmDbNAR from "../AssetManagement/Database/AmDbNAR";
import AmDbLS from "../AssetManagement/Database/AmDbLS";
import AmDbFuel from "../AssetManagement/Database/AmDbFuel";
import AmDbGrid from "../AssetManagement/Database/AmDbGrid";
import AmDbAddEdit from "../AssetManagement/Database/AmDbAddEdit";
import AmDbAllTogether from "../AssetManagement/Database/AmDbAllTogether";
import SfDashboard from "../SiteFueling/SfDashboard";
import SpDashboard from "../SitePerformance/SpFueling/SpDashboard";
import Targets from "../SitePerformance/SpTargets/Targets";
import SpTargetsSummary from "../SitePerformance/SpTargets/SpTargetsSummary";
import SitesInsightsSolar from "../SitePerformance/SpInsights/SitesInsightsSolar";
import SitesInsightsGrid from "../SitePerformance/SpInsights/SitesInsightsGrid";
import SitesInsightsBattery from "../SitePerformance/SpInsights/SitesInsightsBattery";
import SitesInsightsGenset from "../SitePerformance/SpInsights/SitesInsightsGenset";
import SitesPerformanceSolar from "../SitePerformance/SpPerformance/SitesPerformanceSolar";
import SitesPerformanceBattery from "../SitePerformance/SpPerformance/SitesPerformanceBattery";
import SitesPerformanceGrid from "../SitePerformance/SpPerformance/SitesPerformanceGrid";
import SitesPerformanceGenset from "../SitePerformance/SpPerformance/SitesPerformanceGenset";
// import SpTargetsCalc from "../SitePerformance/SpTargets/SpTargetsCalc";
import SpTargetsSolar from "../SitePerformance/SpTargets/SpTargetsSolar";
import SpTargetsBattery from "../SitePerformance/SpTargets/SpTargetsBattery"; 
import SpTargetsGrid from "../SitePerformance/SpTargets/SpTargetsGrid";
import SpTargetsWind from "../SitePerformance/SpTargets/SpTargetsWind";
import SpTargetsGenset from "../SitePerformance/SpTargets/SpTargetsGenset";
import SitePulse from "../SitePerformance/SitePulse/SitePulse";
import SitesOnboarding from "../SitesOnboarding/SitesOnboarding";
import SobGeneralInfo from "../SitesOnboarding/AddExistingSite/SobGeneralInfo";

import OnboardingSearch from "../SitesOnboarding/OnboardingSearch";
import SobSitesInfra from "../SitesOnboarding/AddExistingSite/SobSitesInfra";
import SobTechParameters from "../SitesOnboarding/AddExistingSite/SobTechParameters";
import SobSiteLoad from "../SitesOnboarding/AddExistingSite/SobSiteLoad";
import SobSolar from "../SitesOnboarding/AddExistingSite/SobSolar";
import SobGrid from "../SitesOnboarding/AddExistingSite/SobGrid";
import SobGenset from "../SitesOnboarding/AddExistingSite/SobGenset";
import SobRectifier from "../SitesOnboarding/AddExistingSite/SobRectifier";
import SobBattery from "../SitesOnboarding/AddExistingSite/SobBattery";
import SobGatewayConfigs from "../SitesOnboarding/AddExistingSite/SobGatewayConfigs";
import TeamsViewSummary from "../teamViewModule/TeamsViewSummary/TeamsViewSummary";
import TnpPerformanceAnalysis from "../teamViewModule/PerformanceAnalysis/TnpPerformanceAnalysis";
import TnpSummary from "../teamViewModule/NetworkPerformance/TnpSummary";
import TnpEnergyConsumption from "../teamViewModule/NetworkPerformance/TnpEnergyConsumption";
import TnpGrid from "../teamViewModule/NetworkPerformance/TnpGrid";
import TnpSolar from "../teamViewModule/NetworkPerformance/TnpSolar";
import TnpFuelConsumption from "../teamViewModule/NetworkPerformance/TnpFuelConsumption";
import TnpNar from "../teamViewModule/NetworkPerformance/TnpNar";
import TnpCO2Emissions from "../teamViewModule/NetworkPerformance/TnpCO2Emissions";
import TnpPar from "../teamViewModule/NetworkPerformance/TnpPar";
import TnpOpex from "../teamViewModule/NetworkPerformance/TnpOpex";
import TnpTch from "../teamViewModule/NetworkPerformance/TnpTch";
import PerformanceComparison from "../PerformanceComparison/PerformanceComparison";
import TeamViewPlayGround from "../teamViewModule/TeamViewPlayGround/TeamViewPlayGround"; 
import DataStories from "../DataStories/DataStories";   
import DsCommercialSavingsLinks from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsLinks"; 
import DsInfrastructurePerformanceLinks from "../DataStories/DataStoriesMenu/DsInfrastructurePerformance/DsInfrastructurePerformanceLinks";
import DsNetworkPerformanceLinks from "../DataStories/DataStoriesMenu/DsNetworkPerformance/DsNetworkPerformanceLinks"; 
import CsGridPeakHrsPolicy from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsPages/CsGridPeakHrsPolicy";
import CsGridPeakHrsBeforePolicy from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsPages/CsGridPeakHrsBeforePolicy";
import CsGridPeakHrsDuringPolicy from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsPages/CsGridPeakHrsDuringPolicy";
import CsGridPeakHrsAfterPolicy from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsPages/CsGridPeakHrsAfterPolicy";
import DsGensetRunHours from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsPages/DsGensetRunHours";
import DsSolarPotentialMaximization from "../DataStories/DataStoriesMenu/DsCommercialSavings/DsCommercialSavingsPages/DsSolarPotentialMaximization";
import TnpNetworkInsights from "../teamViewModule/NetworkInsights/TnpNetworkInsights";
import TnpGridRCA from "../teamViewModule/NetworkInsights/TnpGridRCA";
import TnpFuelRCA from "../teamViewModule/NetworkInsights/TnpFuelRCA";
import TnpSolarRCA from "../teamViewModule/NetworkInsights/TnpSolarRCA";
import TniNetworkInsightsMap from "../teamViewModule/NetworkInsights/TniNetworkInsightsMap";
import TniNetworkInsightsTable from "../teamViewModule/NetworkInsights/TniNetworkInsightsTable";
import TniGridRCATable from "../teamViewModule/NetworkInsights/TniGridRCATable";
import TniFuelRCATable from "../teamViewModule/NetworkInsights/TniFuelRCATable";
import TniSolarRCATable from "../teamViewModule/NetworkInsights/TniSolarRCATable";
import TniGridRCAMap from "../teamViewModule/NetworkInsights/TniGridRCAMap";
import TnpSolarRCAMap from "../teamViewModule/NetworkInsights/TnpSolarRCAMap";
import TniFuelRCAMap from "../teamViewModule/NetworkInsights/TniFuelRCAMap";
import Reports from "../Reports/Reports";
import CreateCustomReport from "../Reports/CreateCustomReport";
import ManagementReports from "../Reports/ReportTemplates/ManagementReports";
import ReportsEnergyPerfCons from "../Reports/ReportTemplates/ReportsEnergyPerfCons";
import NetworkAssetsDiscovery from "../teamViewModule/NetworkAssetsDiscovery/NetworkAssetsDiscovery";
import SiteAssetsDiscovery from "../teamViewModule/SiteAssetsDiscovery/SiteAssetsDiscovery";
import NetworkPulse from "../teamViewModule/NetworkPulse/NetworkPulse";
import NpSitesReachabilityProfile from "../teamViewModule/NetworkPulse/NpSitesReachabilityProfile/NpSitesReachabilityProfile";
import NpPingSNMPDifference from "../teamViewModule/NetworkPulse/Ping&SNMPDifference/NpPingSNMPDifference";
import NpIpUnreachability from "../teamViewModule/NetworkPulse/NpIpUnreachability/NpIpUnreachability";
import NpSnmpDown from "../teamViewModule/NetworkPulse/NpSnmpDown/NpSnmpDown";
import NpAverageResponseTime from "../teamViewModule/NetworkPulse/AverageResponseTime/NpAverageResponseTime";
import NpProjectWiseGrouping from "../teamViewModule/NetworkPulse/NpProjectWiseGrouping/NpProjectWiseGrouping";
 
    
 

function TeamViewRoute() {

  const location = useLocation();
  console.log(location.pathname);
  
  let leftNav =  <SideNav />;
  let has_side_nav = 'has_side_nav';
     
    if (location.pathname === '/' || location.pathname === '/Login' || location.pathname === '/Welcome') {
      leftNav = ''
      has_side_nav = ''
    }

  return (
    <div className={has_side_nav}>
      {
       leftNav
      }
      <div className="right_content">
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/" element={ <Welcome/> } /> */}
          <Route path="Welcome" element={<Welcome />} />
          <Route path="GridUnavailability" element={<GridUnavailability />} />
          <Route path="FuelConsumption" element={<FuelConsumption />} />
          <Route path="TariffGrid" element={<TariffGrid />} />
          <Route path="SolarProduction" element={<SolarProduction />} />
          <Route path="GensetRunningHours" element={<GensetRunningHours />} />
          <Route path="SiteLoad" element={<SiteLoad />} />
          <Route path="PowerSourcesAnomalies" element={<PowerSourcesAnomalies />} />
          <Route path="BatteryChargingPeakHours" element={<BatteryChargingPeakHours />} />
          <Route path="FaultyRectifierModules" element={<FaultyRectifierModules />} />
          <Route path="BatteryFaultySites" element={<BatteryFaultySites />} />
          <Route path="PowerSources" element={<PowerSources />} />

          <Route path="BatteryBackup" element={<BatteryBackup />} />

          <Route path="ExecutiveDashboard" element={<ExecutiveDashboardMain />} />
          <Route path="Summary" element={<Summary />} />

          <Route path="LiveView" element={<LiveView />} />

          <Route path="Login" element={<Login />} />

          <Route path="Loadsheddding" element={<Loadsheddding />} />
          <Route path="GridConsumption" element={<GridConsumption />} />
          <Route path="SummaryFuelConsumption" element={<SummaryFuelConsumption />} />
          <Route path="CO2Emissions" element={<CO2Emissions />} />
          <Route path="Savings" element={<Savings />} />

          {/*Asset Management screen includes  */}
          <Route path="AmTransformer" element={<AmTransformer />} />
          <Route path="AmBattery" element={<AmBattery />} />
          <Route path="AmGenerator" element={<AmGenerator />} />
          <Route path="AmRectifier" element={<AmRectifier />} />
          <Route path="AmSmartMeter" element={<AmSmartMeter />} />
          <Route path="AmAutotransformerSwitch" element={<AmAutotransformerSwitch />} />
          <Route path="AmRMSandCSU" element={<AmRMSandCSU />} />
          <Route path="AmSolar" element={<AmSolar />} />
          <Route path="MapView" element={<MapView />} />
          <Route path="SiteFueling" element={<SiteFueling />} />
          <Route path="SfDashboard" element={<SfDashboard />} />

          <Route path="SpDashboard" element={<SpDashboard />} />
          
          
          {/* <Route path="SnapshotView" element={<SnapshotView />} /> */}
          <Route path="SitesSnapshotView" element={<SitesSnapshotView />} />
          <Route path="Infrastructure" element={<Infrastructure />} />
          <Route path="Targets" element={<Targets />} />
          <Route path="SpTargetsSummary" element={<SpTargetsSummary />} />

          {/* <Route path="SpTargetsCalc" element={<SpTargetsCalc />} /> */}
          <Route path="SpTargetsSolar" element={<SpTargetsSolar />} />
          <Route path="SpTargetsBattery" element={<SpTargetsBattery />} /> 
          <Route path="SpTargetsGrid" element={<SpTargetsGrid />} /> 
          <Route path="SpTargetsWind" element={<SpTargetsWind />} /> 

          <Route path="SpTargetsGenset" element={<SpTargetsGenset />} /> 


          
          <Route path="sitesPerformance" element={<SitesPerformance />} />
          <Route path="SitesPerformanceSolar" element={<SitesPerformanceSolar />} />
          <Route path="SitesPerformanceBattery" element={<SitesPerformanceBattery />} />
          <Route path="SitesPerformanceGrid" element={<SitesPerformanceGrid />} />
          <Route path="SitesPerformanceGenset" element={<SitesPerformanceGenset />} />
        
          

          <Route path="SitesInfraview" element={<SitesInfraview />} />
          
          <Route path="SitesFuel" element={<SitesFuel />} />
          <Route path="SitesInsights" element={<SitesInsights />} />
          <Route path="SitesInsightsSolar" element={<SitesInsightsSolar />} />
          <Route path="SitesInsightsGrid" element={<SitesInsightsGrid />} />
          <Route path="SitesInsightsGenset" element={<SitesInsightsGenset />} />
          <Route path="SitesInsightsBattery" element={<SitesInsightsBattery />} />
          <Route path="SitePulse" element={<SitePulse />} />
          

          <Route path="SitesInterventions" element={<SitesInterventions />} />
          <Route path="SitesImpact" element={<SitesImpact />} />
          <Route path="SitesAlamrs" element={<SitesAlamrs />} />
          <Route path="SitesReports" element={<SitesReports />} />
          <Route path="SitesAnomalies" element={<SitesAnomalies />} />
          <Route path="SitesHeartbeatView" element={<SitesHeartbeatView />} />
          <Route path="SitesPlayground" element={<SitesPlayground />} />
          <Route path="SitesConfigurations" element={<SitesConfigurations />} />
          <Route path="SitesOperations" element={<SitesOperations />} />
          <Route path="SitesCompliance" element={<SitesCompliance />} />
          <Route path="SitesControl" element={<SitesControl />} />
          <Route path="SitesLogs" element={<SitesLogs />} />
          
          

          <Route path="SingleSiteSummary" element={<SingleSiteSummary />} />
          
          <Route path="AmCapacityPlanning" element={<AmCapacityPlanning />} />
          <Route path="AmPlayground" element={<AmPlayground />} />
          <Route path="AmDatabase" element={<AmDatabase />} />
          <Route path="AmInfraHealthCard" element={<AmInfraHealthCard />} />
          <Route path="AmEnergyPlanning" element={<AmEnergyPlanning />} />
          
          <Route path="EpTargetsInput" element={<EpTargetsInput />} />
          <Route path="EpTargetsOutput" element={<EpTargetsOutput />} />
          
          <Route path="TargetsOutputBattery" element={<TargetsOutputBattery />} />
          <Route path="TargetsOutputFuel" element={<TargetsOutputFuel />} />
          <Route path="TargetsOutputSolar" element={<TargetsOutputSolar />} />
          <Route path="TargetsOutputGrid" element={<TargetsOutputGrid />} />
          <Route path="TargetsOutputGenset" element={<TargetsOutputGenset />} />
          <Route path="AmSummary" element={<AmSummary />} />

{/* asset management database sub pages routs */}
          <Route path="AmDbSiteInfra" element={<AmDbSiteInfra />} />
          <Route path="AmDbTechParameters" element={<AmDbTechParameters />} />
          <Route path="AmDbSiteLoad" element={<AmDbSiteLoad />} />
          <Route path="AmDbSolar" element={<AmDbSolar />} />
          <Route path="AmDbTf" element={<AmDbTf />} />
          <Route path="AmDbDg" element={<AmDbDg />} />
          <Route path="AmDbRectifier" element={<AmDbRectifier />} />
          <Route path="AmDbClassification" element={<AmDbClassification />} />
          <Route path="AmDbBattery" element={<AmDbBattery />} />
          <Route path="AmDbSizing" element={<AmDbSizing />} />
          <Route path="AmDbTCH" element={<AmDbTCH />} />
          <Route path="AmDbNAR" element={<AmDbNAR />} />
          <Route path="AmDbLS" element={<AmDbLS />} />
          <Route path="AmDbFuel" element={<AmDbFuel />} />
          <Route path="AmDbGrid" element={<AmDbGrid />} />
          <Route path="AmDbAddEdit" element={<AmDbAddEdit />} />
          <Route path="AmDbAllTogether" element={<AmDbAllTogether />} />
 
          <Route path="SitesOnboarding" element={<SitesOnboarding />} />
          <Route path="OnboardingSearch" element={<OnboardingSearch />} />
          
          

          <Route path="SobGeneralInfo" element={<SobGeneralInfo />} />
          <Route path="SobSitesInfra" element={<SobSitesInfra />} />
          <Route path="SobTechParameters" element={<SobTechParameters />} />
          <Route path="SobSiteLoad" element={<SobSiteLoad />} />
          <Route path="SobSolar" element={<SobSolar />} />
          <Route path="SobGrid" element={<SobGrid />} />
          <Route path="SobGenset" element={<SobGenset />} />
          <Route path="SobRectifier" element={<SobRectifier />} />
          <Route path="SobBattery" element={<SobBattery />} />
          <Route path="SobGatewayConfigs" element={<SobGatewayConfigs />} />
          
          <Route path="TeamsViewSummary" element={<TeamsViewSummary />} />
          <Route path="TnpPerformanceAnalysis" element={<TnpPerformanceAnalysis />} />
          <Route path="TnpSummary" element={<TnpSummary />} />
          <Route path="TnpEnergyConsumption" element={<TnpEnergyConsumption />} />
          <Route path="TnpGrid" element={<TnpGrid />} />
          <Route path="TnpSolar" element={<TnpSolar />} />
          <Route path="TnpFuelConsumption" element={<TnpFuelConsumption />} />
          <Route path="TnpNar" element={<TnpNar />} />
          <Route path="TnpCO2Emissions" element={<TnpCO2Emissions />} />
          <Route path="TnpPar" element={<TnpPar />} />
          <Route path="TnpOpex" element={<TnpOpex />} />
          <Route path="TnpTch" element={<TnpTch />} />
          
          <Route path="PerformanceComparison" element={<PerformanceComparison />} />
        
          <Route path="TeamViewPlayGround" element={<TeamViewPlayGround />} /> 
          <Route path="TnpNetworkInsights" element={<TnpNetworkInsights />} />

          <Route path="TnpGridRCA" element={<TnpGridRCA />} />
          <Route path="TnpFuelRCA" element={<TnpFuelRCA />} />
          <Route path="TnpSolarRCA" element={<TnpSolarRCA />} />

          <Route path="NetworkAssetsDiscovery" element={<NetworkAssetsDiscovery />} />

          <Route path="SiteAssetsDiscovery" element={<SiteAssetsDiscovery />} />

          <Route path="NetworkPulse" element={<NetworkPulse />} />
          <Route path="NpSitesReachabilityProfile" element={<NpSitesReachabilityProfile />} />
          <Route path="NpSitesReachabilityProfile" element={<NpSitesReachabilityProfile />} />
          <Route path="NpPingSNMPDifference" element={<NpPingSNMPDifference />} />

          <Route path="NpIpUnreachability" element={<NpIpUnreachability />} />
          <Route path="NpSnmpDown" element={<NpSnmpDown />} />

          <Route path="NpAverageResponseTime" element={<NpAverageResponseTime />} />

          <Route path="NpProjectWiseGrouping" element={<NpProjectWiseGrouping />} />

          

          

          

          

           
          



          

          

          




        
          <Route path="DataStories" element={<DataStories />} />
          <Route path="DsCommercialSavingsLinks" element={<DsCommercialSavingsLinks />} />
          <Route path="DsInfrastructurePerformanceLinks" element={<DsInfrastructurePerformanceLinks />} />
          <Route path="DsNetworkPerformanceLinks" element={<DsNetworkPerformanceLinks />} />
 
          <Route path="CsGridPeakHrsPolicy" element={<CsGridPeakHrsPolicy />} />
          <Route path="CsGridPeakHrsBeforePolicy" element={<CsGridPeakHrsBeforePolicy />} />
          <Route path="CsGridPeakHrsDuringPolicy" element={<CsGridPeakHrsDuringPolicy />} />
          <Route path="CsGridPeakHrsAfterPolicy" element={<CsGridPeakHrsAfterPolicy />} />
          <Route path="DsGensetRunHours" element={<DsGensetRunHours />} />
          <Route path="DsSolarPotentialMaximization" element={<DsSolarPotentialMaximization />} />
          <Route path="TniNetworkInsightsMap" element={<TniNetworkInsightsMap />} />
          <Route path="TniNetworkInsightsTable" element={<TniNetworkInsightsTable />} />
          <Route path="TniGridRCATable" element={<TniGridRCATable />} />
          <Route path="TniFuelRCATable" element={<TniFuelRCATable />} />
          <Route path="TniSolarRCATable" element={<TniSolarRCATable />} />
          <Route path="TniGridRCAMap" element={<TniGridRCAMap />} />

          <Route path="TnpSolarRCAMap" element={<TnpSolarRCAMap />} />
          <Route path="TniFuelRCAMap" element={<TniFuelRCAMap />} />

          <Route path="Reports" element={<Reports />} />
          <Route path="CreateCustomReport" element={<CreateCustomReport />} />
          <Route path="ManagementReports" element={<ManagementReports />} />
          <Route path="ReportsEnergyPerfCons" element={<ReportsEnergyPerfCons />} />

          

          

          

          

          

          

          

          

          

          

          



          
          

          
          

          

          

          

          
          
 
          
          

          
 

          
 

          
          
          
          
          
          
          
          

          

          
          
          
          
          
          
          
          
          

          
          
        </Routes>
      </div>
    </div>
  );
}

export default TeamViewRoute;
