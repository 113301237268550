 
import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;
const AnyReactComponent = (props) => (
  <div className='marker_group' style={{
    width: props.width, 
    height: props.height,
  }}>
    {props.text}
  </div>
);

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 30.8953022,
      lng: 68.6836974
    },
    zoom: 5
  };

  return (
    // Important! Always set the container height explicitly
    <div className='mapchart_box' style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={30.8953022}
          lng={68.6836974}
          width='44px'
          height='44px'
          text=""
        />
         <AnyReactComponent
          lat={33.538410}
          lng={71.400744}
          width='30px'
          height='30px'
          text=""
        />
         <AnyReactComponent
          lat={28.579071}
          lng={66.781041}
          width='30px'
          height='30px'
          text=""
        />
      </GoogleMapReact>
    </div>
  );
}