import React from 'react';
// import "../../AssetManagement/AssetManagement.css";

import "./TnpNetworkInsights.css"
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import TnpTabsHeader from '../../../Components/TopTabsHeader/TnpTabsHeader';

import GridRCASummaryHeatMap from '../../../Components/Charts/GridRCASummaryHeatMap';
import FuelRCASummaryHeatMap from '../../../Components/Charts/FuelRCASummaryHeatMap';
import SolarRCASummaryHeatMap from '../../../Components/Charts/SolarRCASummaryHeatMap';
import { moremenu } from './MoreMenuLinks';
import { Link } from 'react-router-dom';



function TnpNetworkInsights() {

    const pageNo = 0;
    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Network Insights', link: '/TnpNetworkInsights' },
        { label: 'Summary', link: '' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpNetworkInsights',
        otherUrl: '/TeamsViewSummary',
    }

    return (
        <div className="wrapper tniNetwork_main">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown />} />
            <div className="tmp_totalSitesFilters">
                <ApplyFilterHeader notYTDBtns notDatepicker />
            </div>

            <div className='tnpNetworkTabs'>
                <div className='stf_header pt-2 pb-1'>
                    <TnpTabsHeader pageNo={pageNo} moremenu={moremenu} />
                    <div className="ampButtonsGroup ps-2">
                        <ul className="ampButtonsBox">
                            <li>
                                <Link to={"/TnpNetworkInsights"} className="ampButton active">Data</Link>
                            </li>
                            <li>
                                <Link to={"/TniNetworkInsightsTable"} className="ampButton">Table</Link >
                            </li>
                            <li>
                                <Link to={"/TniNetworkInsightsMap"} className="ampButton">Map</Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <div className='tnpNetworkInsights'>
                <div className='contentWrap'>
                    <div className='row py-2'>
                        <div className='col-12 col-lg-4'>
                            <div className='box_card p-0'>
                                <div className='tnpNetIn_summary'>
                                    <h4 className='label20 color_white bg_purple2 tnpNetIn_heading'>
                                        Grid RCA Summary
                                    </h4>
                                    <div className='tnpNetIn_inner'>
                                        <div className='tnpNetIn_inner_heading'>
                                            <span className='label20 weight5'>Total Sites</span>
                                            <h2>12056</h2>
                                        </div>
                                        <div className='tnpNetIn_content'>
                                            <div className='tnpNetIn_infraLevel pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-3">
                                                    <span className="px-2 bg_white">Infrastructure Level</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Good Sites</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Bad Sites</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Data Anomaly</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_ctgDist pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-3">
                                                    <span className="px-2 bg_white">Category Distribution</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_uses'>
                                                <div className="tnpNetIn_uses_heading">
                                                    <small className="label22 weight5">Excessive Use</small>
                                                    <h2>
                                                        <span>130</span>
                                                        <strong>kWh</strong>
                                                    </h2>
                                                </div>
                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">15</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">40</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field </small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">35</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tnpNetIn_chart'>
                                            <div className='tnpNetIn_chart_heading'>
                                                <h2>Region Wise Breakdown</h2>
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li>
                                                            <span className="ampButton active">Infrastructure</span>
                                                        </li>
                                                        <li>
                                                            <span className="ampButton">Category</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <GridRCASummaryHeatMap />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='box_card p-0'>
                                <div className='tnpFuelRCA_summary'>
                                    <h4 className='label20 color_white bg_orange tnpNetIn_heading'>
                                        Fuel RCA Summary
                                    </h4>
                                    <div className='tnpNetIn_inner'>
                                        <div className='tnpNetIn_inner_heading'>
                                            <span className='label20 weight5'>Total Sites</span>
                                            <h2>12056</h2>
                                        </div>
                                        <div className='tnpNetIn_content'>
                                            <div className='tnpNetIn_infraLevel pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-3">
                                                    <span className="px-2 bg_white">Infrastructure Level</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Good Sites</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Bad Sites</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Data Anomaly</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_ctgDist pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-3">
                                                    <span className="px-2 bg_white">Category Distribution</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_uses'>
                                                <div className="tnpNetIn_uses_heading">
                                                    <small className="label22 weight5">Excessive Use</small>
                                                    <h2>
                                                        <span>40</span>
                                                        <strong>Ltrs</strong>
                                                    </h2>
                                                </div>
                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">25</span>
                                                            <strong className="label20 weight9">kLtrs</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">5</span>
                                                            <strong className="label20 weight9">kLtrs</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field </small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">10</span>
                                                            <strong className="label20 weight9">kLtrs</strong>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tnpNetIn_chart'>
                                            <div className='tnpNetIn_chart_heading'>
                                                <h2>Region Wise Breakdown</h2>
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li>
                                                            <span className="ampButton active">Infrastructure</span>
                                                        </li>
                                                        <li>
                                                            <span className="ampButton">Category</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <FuelRCASummaryHeatMap />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='box_card p-0'>
                                <div className='tnpSolarRCA_summary'>
                                    <h4 className='label20 color_white bg_yellow tnpNetIn_heading'>
                                        Solar RCA Summary
                                    </h4>
                                    <div className='tnpNetIn_inner'>
                                        <div className='tnpNetIn_inner_heading'>
                                            <span className='label20 weight5'>Total Sites</span>
                                            <h2>12056</h2>
                                        </div>
                                        <div className='tnpNetIn_content'>
                                            <div className='tnpNetIn_infraLevel pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-3">
                                                    <span className="px-2 bg_white">Infrastructure Level</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Good Sites</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Bad Sites</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Data Anomaly</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_ctgDist pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-3">
                                                    <span className="px-2 bg_white">Category Distribution</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_uses'>
                                                <div className="tnpNetIn_uses_heading">
                                                    <small className="label22 weight5">Potential Loss</small>
                                                    <h2>
                                                        <span>130</span>
                                                        <strong>kWh</strong>
                                                    </h2>
                                                </div>
                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">15</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">40</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">35</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='tnpNetIn_chart'>
                                            <div className='tnpNetIn_chart_heading'>
                                                <h2>Region Wise Breakdown</h2>
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li>
                                                            <span className="ampButton active">Infrastructure</span>
                                                        </li>
                                                        <li>
                                                            <span className="ampButton">Category</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <SolarRCASummaryHeatMap />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TnpNetworkInsights;