import React, { useState } from 'react';
import AmpButtonsGroup from '../AmpButtonsGroup/AmpButtonsGroup';
import RegionWiseColumnChart from './RegionWiseColumnChart';
 

const InfrastructureRegionWiseCol = () => {
    const dataFaulty = [22, 11, 18, 13, 13, 13.5, 4, 12, 16, 13, 11.5, 6,];
    const dataFunctional = [4, 16, 14, 14, 11.5, 6, 14, 14, 18, 13, 13, 13.5];
 
    const [chartData, setChartData] = useState(dataFaulty);
    const colsData_gensetOverruns = {
        title: {
            text: 'Infrastructure Region Wise',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
               
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        grid: {
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'Infrastructure Wide',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#88745B',
                ],
                // areaStyle: {normal: {}},
                data: chartData
            },

        ]
    };
    const btnArray = [
        { id: 1, name: 'Faulty', active: true },
        { id: 2, name: 'Functional', active: false },
    ];
    const [array, setArray] = useState(btnArray);
    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
        console.log('array', id)
        if (id === 1) {
            setChartData(dataFaulty);
        }
        if (id === 2) {
            setChartData(dataFunctional);
        }
        
    };

    return (
        <>
            <div className="amp_btn_margin">
                <AmpButtonsGroup array={array} handleItemClick={handleItemClick} />
            </div>
            <RegionWiseColumnChart options={colsData_gensetOverruns} />
        </>
    )
}

export default InfrastructureRegionWiseCol;