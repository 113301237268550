 
import React, { useState } from 'react';
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
import "../AssetManagement.css";
 import AmHeaderDropdown from '../AmHeaderDropdown';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import AmTabsHeader from '../../../Components/TopTabsHeader/AmTabsHeader';
import { amDbMoreMenu } from './AmDbMoreMenu';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Popover,
    List,
    Pagination,
    ListItem,
    Checkbox,
} from '@mui/material';

import { Button, Form } from 'react-bootstrap';
import AmDbAlldownloadInfo from '../../../screensUi/AmDbAlldownloadInfo';

function AmDbClassification() {
    const pageNoDb = 8;
    const breadcrumbs = [
        { label: 'Classification', link: '' },
        // { label: 'Database', link: '/AmDatabase' },
    ];
  
    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/AmDbClassification',
        otherUrl: '/AmSummary',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">  <g clip-path="url(#clip0_4924_1097)"><path d="M22.9037 13.7827H1.77701C1.22155 13.7827 0.771271 14.233 0.771271 14.7885V23.5079C0.771271 24.0634 1.22155 24.5137 1.77701 24.5137H22.9037C23.4591 24.5137 23.9094 24.0634 23.9094 23.5079V14.7885C23.9094 14.233 23.4591 13.7827 22.9037 13.7827Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2.31381 13.7826V7.78011C2.31544 7.51236 2.42323 7.2562 2.61352 7.06784C2.80381 6.87948 3.06106 6.7743 3.3288 6.7754H11.9547C12.1119 6.77516 12.2671 6.81145 12.4079 6.8814C12.5487 6.95136 12.6713 7.05307 12.7661 7.17852L13.9857 9.08715H20.5816C20.8495 9.08633 21.1068 9.19191 21.297 9.38069C21.4871 9.56946 21.5945 9.826 21.5956 10.0939V13.7826" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9.32202 16.8003H15.3585" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  <path d="M20.2967 6.00214V4.6951C20.2951 4.42735 20.1873 4.17119 19.997 3.98283C19.8067 3.79447 19.5495 3.68929 19.2817 3.69039H13.9857L12.766 1.77764C12.6712 1.65219 12.5486 1.55048 12.4078 1.48053C12.267 1.41057 12.1119 1.37428 11.9546 1.37452H4.62757C4.49482 1.37398 4.36326 1.39959 4.24041 1.44989C4.11756 1.50019 4.00582 1.5742 3.91156 1.66769C3.81731 1.76118 3.7424 1.87231 3.6911 1.99475C3.6398 2.11719 3.61312 2.24853 3.61258 2.38129V3.45387" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  </g>  <defs> <clipPath id="clip0_4924_1097"> <rect width="24.6807" height="24.6807" fill="white" transform="translate(0 0.605469)"/> </clipPath>  </defs> svg>`
     }
     // table
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Ini

    const columns = [
        { field: 'FutureEnergyStrategyProject', headerName: 'Future Energy Strategy Project', flex: 1 },
        { field: 'FutureEnergyStaggeryProjectImplemented', headerName: 'Future Energy Staggery Project_Implemented', flex: 1 },
        { field: 'BlackListedSites', headerName: 'Black Listed Sites', flex: 1 },
        { field: 'TelcoPartsTheft', headerName: 'Telco Parts Theft', flex: 1 },
        { field: 'Problamatic', headerName: 'Problamatic', flex: 1 },
        { field: 'BatteryTheftWarranty', headerName: 'Battery Theft/Warranty', flex: 1 },
        { field: 'V1ProjectStatus', headerName: 'V1 Project Status', flex: 1 },
        
        
    ];

    const [rows, setRows] = useState([
        { id: 1, FutureEnergyStrategyProject: 'Thunder', FutureEnergyStaggeryProjectImplemented: 'Thunder', BlackListedSites: 'Thunder', TelcoPartsTheft: 'Thunder', Problamatic: 'Thunder', BatteryTheftWarranty: 'Thunder', V1ProjectStatus: 'Thunder' }, 
    ]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleFilterIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen(!filterOpen);
    };



    const handleSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        const newOrder = isAscending ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        const sortedRows = [...rows].sort((a, b) => {
            const aValue = a[property];
            const bValue = b[property];
            if (newOrder === 'asc') {
                return aValue < bValue ? -1 : 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        });

        setRows(sortedRows);
    };


    const handleFilterChange = (event) => {
        const value = event.target.value;

        if (selectedFilters.includes(value)) {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
        } else {
            setSelectedFilters([...selectedFilters, value]);
        }
    };

    const applyFilters = () => {
        // Apply filters based on selectedFilters
        // You can implement your custom filtering logic here

        // For demonstration purposes, we'll just log the selected filters
        console.log('Selected Filters:', selectedFilters);

        // Close the filter dropdown
        setFilterOpen(false);
    };


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        console.log("currentPage:", newPage);
    };

    // pagination js

    // Calculate the total number of pages
    const totalPages = Math.ceil(rows.length / rowsPerPage);

    // Slice the data based on the current page and rows per page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = rows.slice(startIndex, endIndex);

    console.log("setRowsPerPage:", setRowsPerPage);
    console.log("endIndex:", endIndex);
    console.log("slicedData---:", slicedData);


    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader  breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<AmHeaderDropdown parentPath='/AmDatabase'/>} />
            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div>
                <div className='col-12 col-lg-3'>
                    <AmDbAlldownloadInfo/>
                </div>
            </div> 
            <AmTabsHeader pageNo={pageNoDb} moremenu={amDbMoreMenu} />


            <div className='am_database contentWrap pt-2'>
                <div className='amDbTopHeading'>
                    <h4 className='label13 weight7'>Classification</h4>
                </div>
                <div className='asstManagement_table'>
                    <div className='stf_tableWrap amDb_table_hAuto'>
                        <TableContainer>
                            <Table aria-label="Basic table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.field}>
                                                <div className='customTbl_headCell'>
                                                    <TableSortLabel
                                                        active={orderBy === column.field}
                                                        direction={orderBy === column.field ? order : 'asc'}
                                                        onClick={() => handleSort(column.field)}
                                                    >
                                                        {column.headerName}
                                                    </TableSortLabel>
                                                    <Tooltip title="Filter">
                                                        <label className='filter_header_btn' onClick={handleFilterIconClick}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
                                                                <path d="M7.66615 1.88513C7.70623 1.83087 7.73063 1.76635 7.73663 1.6988C7.74263 1.63125 7.72999 1.56333 7.70012 1.50267C7.67024 1.442 7.62433 1.39098 7.56751 1.35533C7.51069 1.31967 7.44522 1.30078 7.37843 1.30078H0.897744C0.830952 1.30078 0.765478 1.31967 0.708661 1.35533C0.651843 1.39098 0.605927 1.442 0.576056 1.50267C0.546186 1.56333 0.533541 1.63125 0.539539 1.6988C0.545538 1.76635 0.569942 1.83087 0.610018 1.88513L3.41823 5.71662V9.33556C3.41823 9.40339 3.43683 9.46987 3.47194 9.52757C3.50706 9.58527 3.5573 9.63189 3.61704 9.66222C3.67679 9.69256 3.74366 9.7054 3.81019 9.69931C3.87671 9.69321 3.94025 9.66843 3.99368 9.62774L4.713 9.07991C4.75767 9.04589 4.79392 9.00178 4.81889 8.95107C4.84386 8.90036 4.85686 8.84444 4.85686 8.78774V5.71626L7.66615 1.88513Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </label>
                                                    </Tooltip>

                                                    <Popover className='stf_filter_dropdown' open={filterOpen} anchorEl={anchorEl} onClose={() => setFilterOpen(false)}>
                                                        <div className='stf_filter_header'>
                                                            <h3 className='stf_filter_title'>Filter By Value <span>T<small>T</small></span></h3>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Filter Values"
                                                                aria-describedby="passwordHelpBlock"
                                                            />
                                                        </div>
                                                        <List>
                                                            <ListItem>
                                                                <label>
                                                                    <Checkbox
                                                                        value="FilterValue1"
                                                                        checked={selectedFilters.includes('FilterValue1')}
                                                                        onChange={handleFilterChange}
                                                                    />
                                                                    Filter Option 1
                                                                </label>
                                                            </ListItem>
                                                            <ListItem>
                                                                <label>
                                                                    <Checkbox
                                                                        value="FilterValue2"
                                                                        checked={selectedFilters.includes('FilterValue2')}
                                                                        onChange={handleFilterChange}
                                                                    />
                                                                    Filter Option 2
                                                                </label>
                                                            </ListItem>
                                                            {/* Add more filter options as needed */}
                                                        </List>
                                                        <div className='stf_filter_footer'>
                                                            <Button variant="outline-secondary" onClick={applyFilters}>Cancel</Button>
                                                            <Button variant="dark" onClick={applyFilters}>Ok</Button>
                                                        </div>
                                                    </Popover>
                                                </div>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {slicedData.map((row) => (
                                        <TableRow key={row.id}>
                                            {columns.map((column) => (
                                                <TableCell key={column.field}>{row[column.field]}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className='am_pagination'>
                            <div className='am_pagination_left'>
                                {/* <div className='am_pagination_icon'>
                                    <i>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.7036 8.12598C12.5656 8.12598 12.4536 8.23798 12.4546 8.37598C12.4546 8.51398 12.5666 8.62598 12.7046 8.62598C12.8426 8.62598 12.9546 8.51398 12.9546 8.37598C12.9546 8.23798 12.8426 8.12598 12.7036 8.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7046 21.126V21.126C7.73359 21.126 3.70459 17.097 3.70459 12.126V12.126C3.70459 7.15498 7.73359 3.12598 12.7046 3.12598V3.12598C17.6756 3.12598 21.7046 7.15498 21.7046 12.126V12.126C21.7046 17.097 17.6756 21.126 12.7046 21.126Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.7046 12.126V17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </i>
                                    <i>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.3711 17.126V3.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M21.3711 17.126C21.3711 19.335 19.5801 21.126 17.3711 21.126H7.37109C5.16209 21.126 3.37109 19.335 3.37109 17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M17.3711 12.126L12.3701 17.127L7.37012 12.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </i>
                                </div> */}
                            </div>
                            <div className='am_pagination_right '>
                                <div className='am_pagination_rowPg d-flex align-items-center'>
                                    <strong className='label13 weight7 pe-2'>Rows per page</strong>
                                    <div className="applyFilter_box">
                                        <select name="customSearch">
                                            <option value="">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                        </select>
                                    </div>
                                </div>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AmDbClassification;