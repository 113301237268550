
import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';
const NetworkReachabilityChart = () => { 
    const options = {
        chart: {
          type: 'heatmap',
          toolbar: {
            show: false,
          },
          fill: {
            colors: ['#32323E']
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#343434']
          }
        },
        colors: ['#E5292B'],
        xaxis: {
          categories: ['10%', '20%', '30%', '40%', '50%', '60%','70%', '80%', '90%', '100% Ping'],
        },
        stroke: {
            width: 0,
          },
      };
      // Define your chart data
      const series = [
        {
            name: '10%',
            data: [240, 240, 890, 190, 240, 196,240, 27, 345, 190],
          
        },
        {
          name: '20%',
          data: [240, 67, 34, 240, 178, 240, 156, 240, 99, 240],
        },
        {
          name: '30%',
          data: [ 240, 240, 96, 758, 25, 240, 58, 240, 240, 240],
        },
        {
          name: '40%',
          data: [240, 240, 240, 145, 240, 135, 365, 240, 189, 174],
        },
        {
            name: '50%',
            data: [45, 258, 240, 112, 21, 240, 189, 240, 240, 240],
        },
        {
            name: '60%',
            data: [240, 240, 240, 140, 240, 240,240, 240, 180, 240],
        },
        {
            name: '70%',
            data: [67, 95, 135, 240, 85, 240, 240, 56, 75, 96],
        },
        {
            name: '80%',
            data: [240, 346, 240, 78, 11, 240, 88, 189, 90, 240],
        },
        {
            name: '90%',
            data: [12, 256, 56, 240, 89, 240, 98, 240, 89, 254],
        },
        {
            name: '100%',
            data: [120, 100, 110, 98, 240, 120,100, 110, 98, 240],
        },
      ];


    return (
      <div className='heatmap_out'>
          <h3 className='chartTitleHeading'>Network Reachability</h3>
          <ApexCharts options={options} series={series} type="heatmap" height={350} />
      </div>
    );
  };
  
  export default NetworkReachabilityChart;
  