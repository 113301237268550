import React from "react";
import SpHeader from "../../../../../Components/mainHeader/SpHeader";
import peakHoursImg from '../../../../../assets/images/ds_offPeakChart.svg';
import CommercialSavingPagination from "../CommercialSavingPagination"; 

function CsGridPeakHrsPolicy() {
    const breadcrumbs = [
        { label: 'Data Stories', link: '/DataStories' },
        { label: 'Commercial Savings', link: '/DsCommercialSavingsLinks' },
        { label: 'Grid Peak Hours Battery Charging', link: '' },
    ];
    return (
        <>
            <div className="gridPeakHrs_main">
                <div className="cs_gp_header">
                    <SpHeader breadcrumbs={breadcrumbs} />
                </div>

                <div className="cs_gp_content_main">
                    <div className="contentWrap">
                        <div className="cs_gp_content_inner">
                            <div className="ampButtonsGroup pt-2">
                                <ul className="ampButtonsBox">
                                    <li><span className="ampButton ">Total</span></li>
                                    <li><span className="ampButton active">Average</span></li>
                                </ul>
                            </div>
                            <div className="row pt-3 cp_gp_policy">
                                <div className="col-12 col-lg-6">
                                    <div className="cs_gp_content_info">
                                        <h2>Grid Peak Hours Battery Charging</h2>
                                        <div className="row cs_gp_info_list">
                                            <div className="col-12 col-lg-6 border_right">
                                                <div className="cs_gp_info_data">
                                                    <span>Total Sites</span>
                                                    <strong className="color_black">1571</strong>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="cs_gp_info_data">
                                                    <span>Total Sites</span>
                                                    <h3>
                                                        <strong className="color_black">41</strong>
                                                        <sub className="color_black">PKR</sub>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row cs_gp_info_list">
                                            <div className="col-12 col-lg-6 border_right">
                                                <div className="cs_gp_info_data">
                                                    <span>Daily Average Savings Per Site</span>
                                                    <h3>
                                                        <strong>56</strong>
                                                        <sub>PKR</sub>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="cs_gp_info_data">
                                                    <span>Daily Average Savings All Sites</span>
                                                    <h3>
                                                        <strong>88,485</strong>
                                                        <sub>PKR</sub>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row cs_gp_info_list">
                                            <div className="col-12 col-lg-6 border_right">
                                                <div className="cs_gp_info_data">
                                                    <span>Projected Monthly Savings</span>
                                                    <h3>
                                                        <strong>2.65M</strong>
                                                        <sub>PKR</sub>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="cs_gp_info_data">
                                                    <span>Projected Yearly Savings</span>
                                                    <h3>
                                                        <strong>32.3M</strong>
                                                        <sub>PKR</sub>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="cs_gp_content_right">
                                        <div className="ds_peakHrs_info">
                                            <h2>Policy</h2>
                                            <div className="ds_peakHrs_info_list">
                                                <ol>
                                                    <li>Only <span className="color_purple_dark">Grid</span> based battery charging.</li>
                                                    <li>Minimal <span className="color_blue_navy">Battery</span> charging during peak hours.</li>
                                                    <li>Normal grid utilization for site load.</li>
                                                </ol>
                                            </div>
                                        </div>
                                        <div className="ds_peakHrs_chrt">
                                            <img src={peakHoursImg} alt="#"></img>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <CommercialSavingPagination isNext='/CsGridPeakHrsBeforePolicy' isPrev='/DsSolarPotentialMaximization' currentId='1' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CsGridPeakHrsPolicy