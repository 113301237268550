import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import "./HeaderMain.css"
import MinimizeSummaryButton from "../MinimizeSummaryBtn";
import { Link } from "react-router-dom";
function ExecutiveDashboardHeader(props) {

    const [isSummary, setSummary] = useState(props.exdb);

    const handleClickFilterButton = event => {
      // 👇️ toggle isActive state on click
      setSummary(current => !current);
    };

    return (
        <div className="header_main exdb_summary_header">
            <div className="contentWrap">
                <div className="headerMain_inner">
                    <div className="headerMain_logo">
                    <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
                    </div>
                    <div className="headerMain_title">
                        <span>{props.pageTitle}</span>
                    </div>
                    <div className="headerMain_right">
                         <MinimizeSummaryButton href={isSummary ? '/ExecutiveDashboard' : '/Summary'} className={isSummary ? 'btn_minimize' : ''} onClick={handleClickFilterButton}> {isSummary ? 'Summary' : 'Minimise'}  </MinimizeSummaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ExecutiveDashboardHeader;