
import React from 'react'; 

import ReactECharts from 'echarts-for-react';
import ApplyFilterHeader from '../../Components/ApplyFilterHeader'; 
import TeamsViewHeaderDropdown from '../teamViewModule/TeamsViewHeaderDropdown';
import SpHeader from '../../Components/mainHeader/SpHeader';




function PerformanceComparison() {
    // const pageNo = 0;
    // let activeIndex = 3;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Performance Comparison', link: '' }, 

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/PerformanceComparison',
        otherUrl: '/TeamsViewSummary',
    }

    //  Energy Consumption GWh chart
    
    const EnergyConsumptionGWh = {
        title: {
            text: 'Avg. TCH %age',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '0px',
            left: 'center',
            show: true,
            data: ['Fuel', 'Grid', 'Solar'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '20px',
            right: '0px',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2022 Actual', '2023 YTD', '2023 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Fuel',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#F55836',
                ],
                data: [60, 45, 200]
            },
            {
                name: 'Grid',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#7243D0',
                ],

                data: [240, 45, 100]

            },
            {
                name: 'Solar',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#F1B400',
                ],
                // areaStyle: {normal: {}},
                data: [50, 20, 32],

            },
        ],
    };

    // Fuel Consumption (MLtrs)
    const FuelConsumptionMLtrs = {
        title: {
            text: 'Avg. TCH %age',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '0px',
            left: 'center',
            show: true,
            data: ['Owned Sites', 'Shared Sites', 'OMO Share'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '20px',
            right: '0px',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2022 Actual', '2023 YTD', '2023 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Owned Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#19AAF8',
                ],

                data: [172.5, 45, 110.6]

            },
            {
                name: 'Shared Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#0058FF',
                ],

                data: [0, 0, 110.6]

            },
            {
                name: 'OMO Share',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#41C776',
                ],
                // areaStyle: {normal: {}},
                data: [172.5, 50, 110.6],

            },
        ],
    };

    // Grid Consumption (GWh)
    
    const GridConsumptionGWh = {
        title: {
            text: 'Avg. TCH %age',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '0px',
            left: 'center',
            show: true,
            data: ['Owned Sites', 'Shared Sites', 'OMO Share'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '20px',
            right: '0px',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2022 Actual', '2023 YTD', '2023 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Owned Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#19AAF8',
                ],

                data: [172.5, 45, 110.6]

            },
            {
                name: 'Shared Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#0058FF',
                ],

                data: [0, 20, 110.6]

            },
            {
                name: 'OMO Share',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#41C776',
                ],
                // areaStyle: {normal: {}},
                data: [172.5, 45, 110.6],

            },
        ],
    };
    
     
    // Carbon Emissions KTons
    const CarbonEmissionsKTons = {
        title: {
            text: 'Avg. TCH %age',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '0px',
            left: 'center',
            show: false,
            data: ['Owned Sites', 'Shared Sites', 'OMO Share'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '20px',
            right: '0px',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2022 Actual', '2023 YTD', '2023 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Owned Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#D8E0E4'
                },
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#0058FF',
                ],

                data: [111.6, 109.8, 332.6]

            },
            
        ],
    };

  


    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/PerformanceComparison' />} />

            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>
            </div>


            <div className='contentWrap'>
                {/* <div className="exe_summary_header pt-3">
                    <TnpRadioTabs activeIndex={activeIndex} />
                    <div className="border-bottom-0 pb-0">
                        <div className="amp_btn_margin float-end">
                            <div className="ampButtonsGroup">
                                <ul className="ampButtonsBox">
                                    <li><span className="ampButton active">Data</span></li>
                                    <li><span className="ampButton">Map</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='performance_comparison'>
                    {/* row 1 */}
                    <div className='row pt-3'> 
                        <div className='col-12 col-lg-6 pb-3'>
                            <div className='box_card'>
                                <div className='TnpPerformanceComp'>
                                    <div className="tnp_perAnalys_top">
                                        <h3>Energy Consumption (GWh)</h3>
                                        <div className='tnpPerformanceCompInner'>
                                            <div className="nar_chartBoxes_row">
                                                <ul>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-235.8GWh</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>68.24%</div>

                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-222.8GWh</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>67.0%</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='nar_chartTop'>
                                                <ReactECharts option={EnergyConsumptionGWh} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 pb-3'>
                            <div className='box_card'>
                                <div className='TnpPerformanceComp'>
                                    <div className="tnp_perAnalys_top">
                                        <h3>Fuel Consumption (MLtrs)</h3>
                                        <div className='tnpPerformanceCompInner'>
                                            <div className="nar_chartBoxes_row">
                                                <ul>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">0MLtrs</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>0%</div>

                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-7.2MLtrs</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>53.6%</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='nar_chartTop'>
                                                <ReactECharts option={FuelConsumptionMLtrs} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 pb-3'>
                            <div className='box_card'>
                                <div className='TnpPerformanceComp'>
                                    <div className="tnp_perAnalys_top">
                                        <h3>Grid Consumption (GWh)</h3>
                                        <div className='tnpPerformanceCompInner'>
                                            <div className="nar_chartBoxes_row">
                                                <ul>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-231.2GWh</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>85.0%</div>

                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-133.1GWh</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>76.6%</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='nar_chartTop'>
                                                <ReactECharts option={GridConsumptionGWh} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 pb-3'>
                            <div className='box_card'>
                                <div className='TnpPerformanceComp'>
                                    <div className="tnp_perAnalys_top">
                                        <h3>Carbon Emissions (KTons)</h3>
                                        <div className='tnpPerformanceCompInner'>
                                            <div className="nar_chartBoxes_row">
                                                <ul>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-81.1KTons</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>72.7%</div>

                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="nar_chartVal_type">-55.1KTons</div>
                                                            <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                            </svg></span>64.4%</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='nar_chartTop'>
                                                <ReactECharts option={CarbonEmissionsKTons} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}
export default PerformanceComparison;