import React from "react";
import ReactECharts from 'echarts-for-react';
function SitePerformanceSolarPowerGenerationChart(){
     // 

    // Function to generate dynamic data between min and max values
    function generateDynamicData(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Function to get the number of days in a specific month
    function getDaysInMonth(year, month) {
        return new Date(year, month + 1, 0).getDate();
    }

    // Generate array of date objects with dynamically created data for "Actual," "Target," and "Prediction"
    const dataArray = [];
    const startMonth = 2; // March (0-based index)
    const endMonth = 6; // July (0-based index)
    const currentYear = new Date().getFullYear();
    const minValue = 30000;
    const maxValue = 40000;

    for (let month = startMonth; month <= endMonth; month++) {
        const monthName = new Date(currentYear, month, 1).toLocaleString('en-US', { month: 'long' });

        const numberOfDays = getDaysInMonth(currentYear, month);
        const daysOfMonth = Array.from({ length: numberOfDays }, (_, i) => {
            const dayNumber = i + 1;
            const formattedDay = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`;
            const date = `${formattedDay} ${monthName}`;
            const actualData = generateDynamicData(minValue, maxValue);
            const targetData = generateDynamicData(minValue, maxValue);
            const predictionData = generateDynamicData(minValue, maxValue);
            return { date, actual: actualData, target: targetData, prediction: predictionData };
        });

        dataArray.push(...daysOfMonth);
    }

    const solarPowerGeneration_data = {
        title: {
            text: '',
            show: false,
            top: '0px',
            left: '0',
            textStyle: {
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            top: '0px',
            right: '0px',
            show: true,
            data: ['Actual', 'Target', 'Prediction'],
            fill: 'orange',
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },
        toolbox: {
            //   feature: {
            //     dataZoom: {
            //       // yAxisIndex: 'none'
            //     },

            //     restore: {},
            //     saveAsImage: {},
            //     dataView: { readOnly: false },
            //   }
        },
        grid: {
            top: '70px',
            left: '20px',
            right: '40px',
            bottom: '60px',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: dataArray.map(item => item.date),
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0',
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '',
                position: 'left',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },

        ],
        series: [
            {
                name: 'Actual',
                data: dataArray.map(item => item.target),
                type: 'bar',
                showSymbol: false,

                color: '#F1B400',

                itemStyle: {
                    color: '#F1B400',
                    symbol: 'circle',
                    symbolSize: 8,
                    symbolOffset: [0, '100%'],
                },
                lineStyle: {
                    type: 'dashed',
                },
            },
            {
                name: 'Target',
                data: dataArray.map(item => item.actual),
                type: 'line',
                showSymbol: false,

                color: '#B35A3E',

                itemStyle: {
                    color: '#B35A3E',
                    symbol: 'diamond',
                    symbolSize: 8,
                    symbolOffset: [0, '-100%'],
                },
                lineStyle: {
                    type: 'dashed',
                },
            },
            {
                name: 'Prediction',
                data: dataArray.map(item => item.prediction),
                type: 'line',
                showSymbol: false,
                color: '#32864B',
                itemStyle: {
                    color: '#32864B',
                    symbol: 'diamond',
                    symbolSize: 8,
                    symbolOffset: [0, '-100%'],
                },
                lineStyle: {
                    type: 'dashed',
                },
            },


        ],
        dataZoom: [
            {
                type: 'slider',
                xAxisIndex: 0,
                filterMode: 'none'
            },
            // {
            //   type: 'slider',
            //   yAxisIndex: 0,
            //   filterMode: 'none'
            // },
            {
                type: 'inside',
                xAxisIndex: 0,
                filterMode: 'none'
            },
            // {
            //   type: 'inside',
            //   yAxisIndex: 0,
            //   filterMode: 'none'
            // }
        ],

    };

    return(
        <>
         <ReactECharts option={solarPowerGeneration_data} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
        </>
    )
}

export default SitePerformanceSolarPowerGenerationChart