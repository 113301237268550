
import React from 'react'; 
import Offcanvas from 'react-bootstrap/Offcanvas';  
import SortableList from './DragDrop';


function TableSettings(props) {
    
    return (
        <div className='sss_siteDetail_comp'>
            <Offcanvas className="sss_siteDetailNav" show={props.showOffcanvas} onHide={() => props.toggleOffcanvas(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Customize Table</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='amDb_tableSetting'>
                        <div className='amDb_ts_content'>
                            {/* <ul>
                                <li className='active'>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Site ID
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    District
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    City
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    EOL Status
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Site Address
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Cluster
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Belt
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Geo Tag
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Region
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Sub Region
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Lat
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='active'>
                                    <div className='amDb_ts_content_row'>
                                        <i></i> 
                                        <div className='amDb_ts_checkbox'>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" value="" /> 
                                                    Long
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </li>    
                            </ul> */}
                            <SortableList/>
                        </div>
                        <div className='amDb_ts_footer'>
                            <div className="mvc_visit_btn"> 
                                <button className="applyFilter_button bg_grey_light2 color_black me-3" style={{border: '0'}}>Cancel</button>
                                <button className="applyFilter_button">Done</button>  
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default TableSettings;
