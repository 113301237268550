import React, { useState } from 'react';

function FilterSelectColumnToDisplay() {
    const [columnsSelection, setColumnsSelection] = useState([
        { id: 1, text: 'Battery Brand', selected: true },
        { id: 2, text: 'Battery Rating AH', selected: false },
        { id: 3, text: 'Battery Technology', selected: false },
        { id: 4, text: 'EOL Status', selected: false },
        { id: 5, text: 'Solar Capacity', selected: false },
        { id: 6, text: 'Genset Brand', selected: false },

    ]);

    const handleCheckboxChange = (id) => {
        const updatedColumnsSelection = columnsSelection.map((item) => {
            if (item.id === id) {
                return { ...item, selected: !item.selected };
            }
            return item;
        });
        setColumnsSelection(updatedColumnsSelection);
    };

    const handleDelete = (id) => {
        const updatedColumnsSelection = columnsSelection.map((item) => {
            if (item.id === id) {
                return { ...item, selected: false };
            }
            return item;
        });
        setColumnsSelection(updatedColumnsSelection);
    };

    return ( 
        <div className="tf_column_display tfFiltersHeight row p-2">
            <div className="col-12 col-lg-6">
                <div className='tf_select_columns box_card tf_selectBox'>
                    <div className='tf_select_heading'>
                        <h4 className='label16 weight6 color_black2 text-center'>Select Colum to display</h4>
                    </div>
                    <ul>
                        {columnsSelection.map((item) => (
                            <li key={item.id} className={item.selected ? 'active' : ''}>
                                <div className='amDb_ts_checkbox width100 ps-3'>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input className='form-check-input'
                                                type="checkbox"
                                                checked={item.selected}
                                                onChange={() => handleCheckboxChange(item.id)}
                                            />
                                            {item.text}
                                        </label>
                                    </div>
                                </div>

                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="col-12 col-lg-6 bg_grey_dark2 tf_selected">
                <div className='tf_select_columns'>
                    <div className='tf_select_heading'>
                        <h4 className='label16 weight6 color_black2 text-center'>Selected Column</h4>
                    </div>
                    <ul>
                        {columnsSelection
                            .filter((item) => item.selected)
                            .map((item) => (
                                <li key={item.id}>
                                    <div className='if_selected_list label14'>
                                        {item.text}
                                        <button onClick={() => handleDelete(item.id)} className='if_selected_dltBtn'>
                                            <i>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.68829 0.410582C1.36285 0.0851447 0.835217 0.0851446 0.50978 0.410581C0.184343 0.736018 0.184343 1.26366 0.50978 1.58909L4.92049 5.9998L0.509703 10.4106C0.184266 10.736 0.184266 11.2637 0.509703 11.5891C0.83514 11.9145 1.36278 11.9145 1.68821 11.5891L6.099 7.17831L10.5098 11.5891C10.8352 11.9145 11.3629 11.9145 11.6883 11.5891C12.0137 11.2637 12.0137 10.736 11.6883 10.4106L7.27751 5.9998L11.6882 1.58909C12.0137 1.26366 12.0137 0.73602 11.6882 0.410583C11.3628 0.0851457 10.8351 0.0851461 10.5097 0.410583L6.099 4.82129L1.68829 0.410582Z" fill="white" />
                                                </svg>
                                            </i>
                                        </button>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div> 
    );
};


export default FilterSelectColumnToDisplay;