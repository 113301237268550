import React from 'react'
import { Link } from 'react-router-dom'

const CommercialSavingPagination = (props) => {
   const activeIndex = props.currentId;
   console.log('activeIndex',activeIndex);
    return (
        <div className="cs_pagination">
            <ul>
                <li>
                    <Link to={props.isPrev} className="cs_arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 178 178" fill="none">
                            <path d="M140.916 89L37.083 89M37.083 89L88.9997 140.917M37.083 89L88.9997 37.0833" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Link>
                </li>
                <li>
                    <div className="cs_pagination_dots">
                        <Link className={activeIndex === '1'? 'active' : ''} to={'/CsGridPeakHrsPolicy'}></Link>
                        <Link className={activeIndex === '2'? 'active' : ''} to={'/CsGridPeakHrsBeforePolicy'}></Link>
                        <Link className={activeIndex === '3'? 'active' : ''} to={'/CsGridPeakHrsDuringPolicy'}></Link>
                        <Link className={activeIndex === '4'? 'active' : ''} to={'/CsGridPeakHrsAfterPolicy'}></Link>
                        <Link className={activeIndex === '5'? 'active' : ''} to={'/DsGensetRunHours'}></Link>
                        <Link className={activeIndex === '6'? 'active' : ''} to={'/DsSolarPotentialMaximization'}></Link>
                    </div>
                </li>
                <li>
                    <Link  to={props.isNext} className="cs_arrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="170" height="170" viewBox="0 0 178 178" fill="none">
                            <path d="M37.0836 89L140.917 89M140.917 89L89.0003 37.0833M140.917 89L89.0003 140.917" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default CommercialSavingPagination