import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import SimpleMapPage from "../Components/Map/MapChart";


function SingleSiteDetailRightNav(props) {
    return (
        <div className='sss_siteDetail_comp'>
            <Offcanvas className="sss_siteDetailNav" show={props.showOffcanvas} onHide={() => props.toggleOffcanvas(false)} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Site Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='sss_rn_content'>
                     
                        <div className="box_card_inner">
                            <div className="mvc_card_header">
                                <div className="mvc_card_detail_row d-flex justify-content-between align-items-center">
                                    <div className="mvc_card_detail_cell">
                                        <div className="raised_text text-start ">
                                            <i className="raised_circle bg_green"></i>
                                            Site Details</div>
                                    </div>
                                    <div className="mvc_card_detail_cell">
                                        <strong>ABCH15</strong>
                                    </div>
                                    <div className="mvc_card_detail_cell">
                                        <span className="label_btn">Good Site</span>
                                    </div>
                                </div>
                                <div className="mvc_latlong_row d-flex justify-content-between align-items-center">
                                    <div className="mvc_latlong_cell">
                                        <span>Lat:</span>
                                        <strong className='ps-1'>34.2756</strong>
                                    </div>
                                    <div className="mvc_latlong_cell">
                                        <span>Lat:</span>
                                        <strong className='ps-1'>34.2756</strong>
                                    </div>
                                    <div className="mvc_latlong_cell">
                                        <span className='pe-3'>Status:</span>
                                
                                        <div className={`sts_status_row sts_status_small_row`}>
                                            <div className="sts_status_box sts_connected" title='Connected'>
                                                <span>Connected</span>
                                                <i></i>
                                            </div>
                                            <div className="sts_status_box sts_solar" title='Solar'>
                                                <span>Solar</span>
                                                <i></i>
                                            </div>
                                            <div className="sts_status_box sts_battery" title='Battery'>
                                                <span>Battery</span>
                                                <i></i>
                                            </div>

                                            {/*                                             
                                            <div className="sts_status_box sts_main" title='Mains'>
                                                <span>Mains</span>
                                                <i></i>
                                            </div>

                                            <div className="sts_status_box sts_genset" title='Genset'>
                                                <span>Genset</span>
                                                <i></i>
                                            </div>
                                             <div className="sts_status_box sts_rectifier" title='Rectifier'>
                                                <span>Rectifier</span>
                                                <i></i>
                                            </div>  
                                           <div className="sts_status_box sts_outage" title='Outage'>
                                                <span>Outage</span>
                                                <i></i>
                                            </div>  
                                           <div className="sts_status_box sts_wind" title='Wind'>
                                                <span>Wind</span>
                                                <i></i>
                                            </div>   */}
                                            <div className="sts_status_box sts_csu" title='CSU'>
                                                <span>CSU</span>
                                                <i></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mvc_performance pt-2 pb-2">
                                    <strong>Performance</strong>
                                    <ul>
                                        <li>
                                            <div className="raised_text text-start"><i className="raised_circle bg_green"></i>Energy</div>
                                        </li>
                                        <li>
                                            <div className="raised_text text-start"><i className="raised_circle bg_green"></i>CO<sub>2</sub></div>
                                        </li>
                                        <li>
                                            <div className="raised_text text-start"><i className="raised_circle bg_grey_light"></i>NAR</div>
                                        </li>
                                        <li>
                                            <div className="raised_text text-start"><i className="raised_circle bg_green"></i>FC</div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            {/* content */}
                            <div className='sss_mtv_mapContent'>
                               <SimpleMapPage />
                            </div>
                            <div className="mvc_site_list">
                                <ul>
                                    <li>
                                        <Link>
                                            <span>Site ID</span>
                                            <b>ABCH15</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>District</span>
                                            <b>Poonch</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Site address</span>
                                            <b>Gajjan Sari tehsil Balooch District Sudhnooti</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Cluster</span>
                                            <b>-</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Belt</span>
                                            <b>-</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Geo tag</span>
                                            <b>-</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Region</span>
                                            <b>North</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Sub region</span>
                                            <b>N 11</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Lat</span>
                                            <b>33.6548</b>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link>
                                            <span>Long</span>
                                            <b>73.891565</b>
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div className="mvc_visit_btn">
                                <Link to={'/SitesSnapshotView'}>
                                    <button className="applyFilter_button" type="submit">Visit Site</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default SingleSiteDetailRightNav;
