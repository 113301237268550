import React from "react";
import "./EnvironmentalBenefitsList.css";

function EnvironmentalBenefitsList(props) {
    let heading = '';
    
    if(props.title !== ''){
        heading =  <h3 className="chartTitleHeading">{props.title}</h3>;
    }
    
    return (
        <div className="ebListBox">
            

           {heading}
           
            <ul>
                {
                    props.data.map((item, index) => {
                        return (
                            <li key={item.id}>
                                 <div className='ebListIcon' key={index} dangerouslySetInnerHTML={{ __html: item.icon }} ></div>
                                <p>{item.title}</p>
                                <h3><span>{item.value}</span> <sub>{item.type}</sub></h3>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}
export default EnvironmentalBenefitsList;
