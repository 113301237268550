import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "bootstrap/dist/css/bootstrap.min.css";

const initialItems = [
  { id: "item-1", content: "Site ID", checked: false },
  { id: "item-2", content: "District", checked: false },
  { id: "item-3", content: "City", checked: true },
  { id: "item-4", content: "EOL Status", checked: false },
  { id: "item-5", content: "Site Address", checked: false },
  { id: "item-6", content: "Cluster", checked: true },
  { id: "item-7", content: "Belt", checked: false },
  { id: "item-8", content: "Geo Tag", checked: false },
  { id: "item-9", content: "Region", checked: false },
  { id: "item-10", content: "Sub Region", checked: false },
  { id: "item-11", content: "Lat", checked: false },
  { id: "item-12", content: "Long", checked: false }
];
 
function SortableList() {
  const [items, setItems] = useState(initialItems);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);

    setItems(reorderedItems);
  };

  const handleCheckboxChange = (itemId) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setItems(updatedItems);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ listStyle: "none", padding: 0 }}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}  

                      className={item.checked ? 'active': ''}
                    >
                      {/* <input
                        type="checkbox"
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(item.id)}
                        style={{ marginRight: "8px" }}
                      />
                      {item.content} */}
                      <div className='amDb_ts_content_row'>
                        <i></i>
                        <div className='amDb_ts_checkbox'>
                          <div className="form-check">
                            <label className="form-check-label">
                              <input className="form-check-input" type="checkbox" value="" checked={item.checked}
                                onChange={() => handleCheckboxChange(item.id)} />
                                  {item.content}
                            </label>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default SortableList;
