import React from 'react';
import SpHeader from "../../Components/mainHeader/SpHeader";
import ApplyFilterHeader from "../../Components/ApplyFilterHeader";
import SiteHeaderDropdown from "./SiteHeaderDropdown";
import PowerFlowNew from '../../Components/PowerFlow/PowerFlowNew';
import styles from '../../pages/SitePerformance/SitePerformance.module.css';

import ReactECharts from 'echarts-for-react';
import PowerSourceUtilization33 from '../../Components/Charts/PowerSourceUtilization33';
import CirclePrograssChart from '../../screensUi/CirclePrograssChart';
import PowerSourceDutyCycle from '../../screensUi/PowerSourceDutyCycle';

import sunnyIcon from '../../assets/images/sunny_128.png';
import cloudyIcon from '../../assets/images/cloudy_128.png';
import showerIcon from '../../assets/images/showers_128.png';
import SsViewBatteryProfileChart from '../../screensUi/SsViewBatteryProfileChart';



function SitesSnapshotView() {
  const breadcrumbs = [
    { label: 'Sites', link: '' },
    { label: 'Snapshot View', link: '/SitesSnapshotView' },
  ];

  const summaryObj = {
    minimize: 'Minimise',
    otherName: "Summary",
    minUrl: '/SitesSnapshotView',
    otherUrl: '/SingleSiteSummary',
  }

  //   Power Sources Utilisation
  const powerSourcesUtilisation = {
    title: {
      text: '',
      textStyle: {
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      bottom: 10,
      left: 'center',
      //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
      type: 'scroll',
      orient: 'horizontal',
      itemWidth: 8,
      selectedMode: true,
      icon: "circle",
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: '30px',
      right: '10px',
      bottom: '0px',
      containLabel: true
    },
    xAxis: [

      {
        type: 'category',
        boundaryGap: true,
        //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
        show: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],

    series: [
      {
        type: 'pie',
        radius: '52%',
        center: ['50%', '36%'],
        selectedMode: 'single',
        color: [
          '#7243D0',
          '#32864B',
          '#0058FF',
          '#F1B400',
        ],
        data: [
          {
            value: 2000,
            name: 'Grid',
            label: {
              formatter: ['40%'].join('\n'),
            }
          },
          {
            value: 1500,
            name: 'Genset',
            label: {
              formatter: ['30%'].join('\n'),
            }
          },
          {
            value: 900,
            name: 'Battery',
            label: {
              formatter: ['18%'].join('\n'),
            }
          },
          {
            value: 600,
            name: 'Solar',
            label: {
              formatter: ['12%'].join('\n'),
            }
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };


  // Fuel Consumption chart function 

  const fuelConsumptionChart = {
    title: {
      text: 'Liters',
      show: true,
      left: '0',
      top: '5px',
      textStyle: {
        color: '#747c8a',
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      right: '0',
      top: '4px',
      show: false,
      data: ['2022', '2023'],
      fill: 'orange',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      top: '50px',
      left: '0px',
      right: '10px',
      bottom: '10px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['04:00', '05:00', '06:00', '07:00', '08:00'],
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    series: [
      {
        name: 'Fuel Consumption',
        type: 'bar',
        stack: 'Fuel Consumption',
        smooth: true,
        color: ['#800080'],
        data: [1, 4.05, 4.05, 4.05, 2.49],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },

    ]
  };

  // Power Source Duty  cycles (Hrs) chart

  const powerSourceDutyCyclesHrs = {
    title: {
      text: 'Power Source Duty cycles (Hrs)',
      show: true,
      top: '0px',
      left: 'center',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },


    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '25px',
      left: 'center',
      show: true,
      data: ['DG', 'DG + Solar', 'Solar', 'Solar + battery', 'Undetermined'],
      fill: 'orange',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },

    grid: {
      top: '60px',
      left: '0px',
      right: '10px',
      bottom: '30px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['2023-09-05'],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    series: [
      // {
      //     name: 'DG',
      //     type: 'bar',
      //     stack: 'Hours',
      //     smooth: true,
      //     color: ['#403f3d'],
      //     data: [20]
      // },
      {
        name: 'DG',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#403f3d'],
        data: [1.08],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'DG + Solar',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#268c2d'],
        data: [3.00],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Solar',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#02e10c'],
        data: [5.00],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Solar + battery',
        type: 'bar',
        stack: 'Hours', // Use the same stack name for both series
        smooth: true,
        color: ['#b5fb5d'],
        data: [1.42],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Undetermined',
        type: 'bar',
        stack: 'Hours', // Use the same stack name for both series
        smooth: true,
        color: ['#bbe3fd'],
        data: [0.25],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
    ]

  };

  // Power Source Duty cycles (KWH)

  const powerSourceDutyCyclesKwh = {
    title: {
      text: 'Power Source Duty cycles (KWH)',
      show: true,
      top: '0px',
      left: 'center',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },


    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '25px',
      left: 'center',
      show: true,
      data: ['Main', 'Solar', 'Genset', 'Battery', 'Main -> Site', 'Main -> Batt.', 'Solar -> Site', 'Solar -> Batt.', 'DG -> Site', 'DG -> Batt.'],
      fill: 'orange',
      type: 'scroll',
      orient: 'horizontal',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
      // itemWidth: ,
    },

    grid: {
      top: '60px',
      left: '0px',
      right: '10px',
      bottom: '30px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['2023-09-05'],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    series: [
      {
        name: 'Main',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#4472c4'],
        data: [0.0, 0],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Main -> Site',
        type: 'bar',
        stack: 'Main',
        smooth: true,
        color: ['#5470c6'],
        data: [0],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Main -> Batt.',
        type: 'bar',
        stack: 'Main',
        smooth: true,
        color: ['#91cc75'],
        data: [0],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Solar',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#16fc04'],
        data: [21.17],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Solar -> Site',
        type: 'bar',
        stack: 'Main',
        smooth: true,
        color: ['#fac858'],
        data: [16.73],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Solar -> Batt.',
        type: 'bar',
        stack: 'Main',
        smooth: true,
        color: ['#ee6666'],
        data: [4.44],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Genset',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#0d0d0d'],
        data: [33.15],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Battery',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#ffff00'],
        data: [0.99],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },

      {
        name: 'DG -> Site',
        type: 'bar',
        stack: 'Main',
        smooth: true,
        color: ['#73c0de'],
        data: [17.08],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'DG -> Batt.',
        type: 'bar',
        stack: 'Main',
        smooth: true,
        color: ['#3ba272'],
        data: [16.07],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
    ]

  };



  // battery Charge And Discharge OverDay

  const batteryChargeAndDischargeOverDay = {
    title: {
      text: 'Battery charge & discharge over day',
      show: true,
      top: '0px',
      left: 'center',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },


    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '25px',
      left: 'center',
      show: true,
      data: ['Charging Hrs', 'Discharging Hrs'],
      fill: 'orange',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },

    grid: {
      top: '60px',
      left: '0px',
      right: '10px',
      bottom: '30px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['Charging/Discharging'],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    series: [
      {
        name: 'Charging Hrs',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#5470c6'],
        data: [9.25],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Discharging Hrs',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#91cc75'],
        data: [1.42],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },

    ]

  };

  // Battery charging contribution chart

  const batteryChargingContribution = {
    title: {
      text: 'Battery charging contribution',
      show: true,
      top: '0px',
      left: 'center',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },


    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '25px',
      left: 'center',
      show: true,
      data: ['Grid contrib.', 'DG contrib.', 'Solar contrib.'],
      fill: 'orange',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },

    grid: {
      top: '60px',
      left: '0px',
      right: '10px',
      bottom: '30px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['Charging Source'],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    series: [
      {
        name: 'Grid contrib.',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#4472c4'],
        data: [0.0],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'DG contrib.',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#0d0d0d'],
        data: [75.7],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
      {
        name: 'Solar contrib.',
        type: 'bar',
        stack: 'Hours',
        smooth: true,
        color: ['#16fc04'],
        data: [24.3],
        label: {
          show: true,
          position: 'inside',
          fontSize: 12,
        },
      },
    ]

  };

  const svEnergyUsage = {
    title: {
      text: 'DC Load (A)',
      show: false,
      top: '0px',
      left: '0',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },

    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '0px',
      left: 'center',
      show: true,
      data: ['DC Load(A)', 'Battery Current(A)', 'Solar Output Current(A)', 'AC Phase 1 Current(A)', 'AC Phase 2 Current(A)', 'AC Phase 3 Current(A)'],
      fill: 'orange',
      type: 'scroll',
      orient: 'horizontal',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },

    grid: {
      top: '60px',
      left: '0px',
      right: '40px',
      bottom: '60px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0',
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Left Y-Axis',
        position: 'left',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
        axisLabel: {
          formatter: '{value} A'
        }
      },
      {
        type: 'value',
        name: 'AC Phas1 (A)',
        position: 'right',
        alignTicks: true,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0B41D',
          }
        },
        axisLabel: {
          formatter: '{value} A',
          color: '#F0B41D'
        }
      },
      {
        type: 'value',
        name: 'Solar Out (A)',
        position: 'right',
        alignTicks: true,
        offset: 80,
        data: [0, 20, 40, 60, 80, 100],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#666666',
          }
        },
        axisLabel: {
          formatter: '{value} A',
          color: '#F0B41D'
        }
      },

    ],
    series: [
      {
        name: 'DC Load(A)',
        data: [70, 70, 70, 70, 70, 70, 70, 50, 67, 68, 69, 70, 49, 50, 70, 48, 52, 70, 72, 70, 70, 70, 70, 70],
        type: 'line',
        showSymbol: false,
        yAxisIndex: 0,
        color: '#F13534',
        areaStyle: {
          color: 'rgba(241, 53, 52, 0.5)',
        },
        itemStyle: {
          color: '#F13534',
          symbol: 'circle',
          symbolSize: 8,
          symbolOffset: [0, '100%'],
        },
      },
      {
        name: 'Battery Current(A)',
        data: [-72, -93, -60, -43, -79, -90, -56, 72, -93, -60, 43, 79, 90, 56, -72, -93, -60, -43, -79, -90, -56, -72, -93, -53,],
        type: 'line',
        showSymbol: false,
        yAxisIndex: 1,
        color: '#E5CF1A',
        areaStyle: {
          color: 'rgba(229, 207, 26, 0.5)',
        },
        itemStyle: {
          color: '#E5CF1A',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'Solar Output Current(A)',
        data: [50, 50, 40, 30, 30, 36, 34, 50, 67, 110, 115, 106, 100, 105, 108, 105, 75, 90, 42, 60, 70, 90, 60, 40],
        type: 'line',
        showSymbol: false,
        yAxisIndex: 2,
        color: '#01A340',
        areaStyle: {
          color: 'rgba(229, 207, 26, 0.8)',
        },
        itemStyle: {
          color: '#01A340',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'AC Phase 1 Current(A)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: false,
        color: '#0000ff',
        areaStyle: {
          color: 'rgba(229, 207, 26, 0.8)',
        },
        itemStyle: {
          color: '#0000ff',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'AC Phase 2 Current(A)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: false,
        color: '#800080',
        areaStyle: {
          color: 'rgba(229, 207, 26, 0.8)',
        },
        itemStyle: {
          color: '#800080',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'AC Phase 3 Current(A)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: false,
        color: '#ff00ff',
        areaStyle: {
          color: 'rgba(229, 207, 26, 0.8)',
        },
        itemStyle: {
          color: '#ff00ff',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        xAxisIndex: 0,
        filterMode: 'none'
      },
      // {
      //   type: 'slider',
      //   yAxisIndex: 0,
      //   filterMode: 'none'
      // },
      {
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none'
      },
      // {
      //   type: 'inside',
      //   yAxisIndex: 0,
      //   filterMode: 'none'
      // }
    ],

  };


  // ac Power Source Availabality 

  const acPowerSourceAvailabality = {
    title: {
      text: 'DC Load (A)',
      show: false,
      top: '0px',
      left: '0',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },

    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '25px',
      left: 'center',
      show: true,
      data: ['Mains', 'DG'],
      fill: 'orange',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },

    grid: {
      top: '60px',
      left: '10px',
      right: '0px',
      bottom: '60px',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0',
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Availability',
        position: 'left',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
        axisLabel: {
          formatter: '{value}'
        }
      },
    ],
    series: [
      {
        name: 'DG',
        data: [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: false,
        color: '#E5CF1A',
        areaStyle: {
          color: '#E5CF1A',
        },
        itemStyle: {
          color: '#E5CF1A',
          symbol: 'circle',
          symbolSize: 8,
          symbolOffset: [0, '100%'],
        },
      },
      {
        name: 'Mains',
        data: [0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: false,
        color: '#0059FF',
        areaStyle: {
          color: '#0059FF',
        },
        itemStyle: {
          color: '#0059FF',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },

    ],
    dataZoom: [
      {
        type: 'slider',
        xAxisIndex: 0,
        filterMode: 'none'
      },
      // {
      //   type: 'slider',
      //   yAxisIndex: 0,
      //   filterMode: 'none'
      // },
      {
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none'
      },
      // {
      //   type: 'inside',
      //   yAxisIndex: 0,
      //   filterMode: 'none'
      // }
    ],

  };




  // weaher chart

  const solarProfilingWeatherData = {
    title: {
      text: 'DC Load (A)',
      show: false,
      top: '0px',
      left: '0',
      textStyle: {
        fontSize: 14,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },

    tooltip: {
      trigger: 'axis'
    },
    legend: {
      top: '0px',
      right: '150px',
      show: true,
      data: ['Target Solar Profile', 'Actual Solar Profile', 'Actual Site Load', 'Site Weather'],
      fill: 'orange',
      type: 'scroll',
      orient: 'horizontal',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          // yAxisIndex: 'none'
        },

        restore: {},
        saveAsImage: {},
        dataView: { readOnly: false },
      }
    },
    grid: {
      top: '60px',
      left: '20px',
      right: '40px',
      bottom: '60px',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: [
          '0 hr',
          '1 hr',
          '2 hr',
          '3 hr',
          '4 hr',
          '5 hr',
          '6 hr',
          '7 hr',
          '8 hr',
          '9 hr',
          '10 hr',
          '11 hr',
          '12 hr',
          '13 hr',
          '14 hr',
          '15 hr',
          '16 hr',
          '17 hr',
          '18 hr',
          '19 hr',
          '20 hr',
          '21 hr',
          '22 hr',
          '23 hr',
        ],
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0',
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Profile',
        position: 'left',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
        axisLabel: {
          formatter: '{value}'
        }
      },

    ],
    series: [
      {
        name: 'Target Solar Profile',
        data: [0, 0, 0, 0, 0, 0.5, 2.1, 3.1, 4.01, 4.5, 7.9, 8, 7.8, 6, 4.1, 2.1, 0.2, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: true,

        color: '#0058FF',
        areaStyle: {
          color: 'rgba(0, 88, 255, 0.5)',
        },
        itemStyle: {
          color: '#0058FF',
          symbol: 'circle',
          symbolSize: 8,
          symbolOffset: [0, '100%'],
        },
      },
      {
        name: 'Actual Solar Profile',
        data: [0, 0, 0, 0, 0, 0.5, 2.1, 3.1, 2.6, 2.4, 2, 2.2, 2, 2.6, 2.5, 4.1, 2.2, 2.6, 2.4, 2, 2.2, 0, 0, 0, 0],
        type: 'line',
        showSymbol: true,

        color: '#01A340',
        areaStyle: {
          color: 'rgba(1, 163, 64, 0.5)',
        },
        itemStyle: {
          color: '#01A340',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'Actual Site Load',
        data: [0, 0, 0, 2.6, 2.4, 2, 2.2, 2, 2.6, 2.5, 4.1, 2.1, 3.2, 6, 4.1, 2.1, 0.2, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: true,

        color: '#D2E265',
        areaStyle: {
          color: 'rgba(210, 226, 101, 0.8)',
        },
        itemStyle: {
          color: '#D2E265',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'Site Weather',
        data: [0, 0, 0, 0, 0.6, 2, 2.5, 2.6, 2.4, 2, 2.2, 2, 2.6, 2.5, 4.1, 2.1, 0.2, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line',
        showSymbol: true,
        color: '#E5CF1A',
        areaStyle: {
          color: 'rgba(229, 207, 26, 0.8)',
        },
        itemStyle: {
          color: '#E5CF1A',
          symbol: 'diamond',
          symbolSize: 8,
          symbolOffset: [0, '-100%'],
        },
      },
      {
        name: 'Site Weather',
        data: [
          { value: [1, 12, '16.3c', sunnyIcon], symbol: 'image://' + sunnyIcon },
          { value: [2, 12, '16.3c', cloudyIcon], symbol: 'image://' + cloudyIcon },
          { value: [3, 12, '16.3c', showerIcon], symbol: 'image://' + showerIcon },
          { value: [4, 12, '16.3c', sunnyIcon], symbol: 'image://' + sunnyIcon },
          { value: [5, 12, '16.3c', cloudyIcon], symbol: 'image://' + cloudyIcon },
          { value: [6, 12, '16.3c', showerIcon], symbol: 'image://' + showerIcon },
        ],
        type: 'scatter',
        label: {
          show: true,
          fontSize: 10,
          position: 'top',
          formatter: function (params) {
            return params.data.value[2];
          },

        },
        symbolOffset: [0, 30],
        symbolSize: 18, // Adjust the size of the symbols
      },
    ],

  };


  // 

  const energySourcesUsageShowData = {
    title: {
      text: 'Solar Contribution between 5am to 7am',
      show: true,
      left: 'center',
      width: "100%",
      textStyle: {
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '500',
        color: '#000',
        fontFamily: 'Roboto, sans-serif',
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: function (params) {
        let tooltip = `<strong className='weight7 mb-1 d-block'>${params[0].name}</strong> `;
        params.forEach((param) => {
          if (param.seriesName === "Transformer" && param.value === 0) {
            //  tooltip += `${param.marker} Custom Value<br />`;
          } else if (param.seriesName === "Rectifier" && param.value === 0) {
            // tooltip += `${param.marker} Custom Value<br />`;
          } else {
            tooltip += `${param.marker} ${param.seriesName}:    <strong className='weight7 ps-2'>${param.value}</strong> <br />`;
          }
        });
        return tooltip;
      },
    },
    legend: {

      left: 'center',
      top: '25px',
      show: true,
      data: ['Target Solar Profile', 'Actual Site Load', 'Total Solar Prod', 'Solar -> Load', 'Solar -> Battery'],
      itemHeight: 10,
      itemWidth: 10,
      type: 'scroll',
      orient: 'horizontal',
      textStyle: {
        fontSize: 13,
        color: '#000',
        fontWeight: '600'
      },

    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: '10px',
      top: '80px',
      right: '0px',
      bottom: '5%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: '0%',
        data: ['Solar Contribution between 5am to 7am'],
        axisLabel: {
          fontSize: 0,
          // color: 'blue',  
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      },
    ],

    yAxis: [
      {
        type: 'value',
        // show: true,
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],

    series: [

      {
        name: 'Target Solar Profile',
        type: 'bar',
        stack: '0',
        smooth: true,
        color: [
          '#68D2F1',
        ],

        data: [0.00],

        label: {
          show: true,
          color: "#FFF",
          emphasis: {
            label: {
              color: "#FFF",
            },
          },
          // formatter: function (params) {
          //     return params.value === 0 ? "" : params.value; // Hide labels with value 0
          // },
        },
        barGap: '0%',
      },
      {
        name: 'Actual Site Load',
        type: 'bar',
        stack: '1',
        smooth: true,
        color: [
          '#FAA640',
        ],

        data: [26.02],

        label: {
          show: true,
          color: "#FFF",
          emphasis: {
            label: {
              color: "#FFF",
            },
          },
          formatter: function (params) {
            return params.value === 0 ? "" : params.value; // Hide labels with value 0
          },
        },
        barGap: '0%',
      },
      {
        name: 'Total Solar Prod',
        type: 'bar',
        stack: '2',
        smooth: true,
        color: [
          '#00E308',
        ],

        data: [21.84],

        label: {
          show: true,
          color: "#FFF",
          emphasis: {
            label: {
              color: "#FFF",
            },
          },
          formatter: function (params) {
            return params.value === 0 ? "" : params.value; // Hide labels with value 0
          },
        },
        barGap: '0%',
      },
      {
        name: 'Solar -> Load',
        type: 'bar',
        stack: '3',
        smooth: true,
        color: [
          '#01A340',
        ],

        data: [17.05],

        label: {
          show: true,
          color: "#FFF",
          emphasis: {
            label: {
              color: "#FFF",
            },
          },
          formatter: function (params) {
            return params.value === 0 ? "" : params.value; // Hide labels with value 0
          },
        },
        barGap: '0%',
      },
      {
        name: 'Solar -> Battery',
        type: 'bar',
        stack: '3',
        smooth: true,
        color: [
          '#8BC486',
        ],

        data: [4.55],

        label: {
          show: true,
          color: "#FFF",
          emphasis: {
            label: {
              color: "#FFF",
            },
          },
          formatter: function (params) {
            return params.value === 0 ? "" : params.value; // Hide labels with value 0
          },
        },
        barGap: '0%',
      },

    ],

  };



  return (
    <div className="sitePerformance_wrapper">
      <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<SiteHeaderDropdown />} />
      <ApplyFilterHeader />

      <div className="sitesInfra_content contentWrap pt-2">
        <div className={styles.sts_topHeader}>
          <div className={styles.sts_goodSite}>
            <span>Good Site</span>
          </div>
          <h4 className={styles.title}> Status </h4>

          <div className={`sts_status_row ${styles.status_border}`}>
            <div className="sts_status_box sts_connected">
              <span>Connected</span>
              <i></i>
            </div>
            <div className="sts_status_box sts_solar">
              <span>Solar</span>
              <i></i>
            </div>
            <div className="sts_status_box sts_battery">
              <span>Battery</span>
              <i></i>
            </div>
            {/* <div className="sts_status_box sts_main">
                                <span>Mains</span>
                                <i></i>
                            </div> */}

            {/* <div className="sts_status_box sts_genset">
                                <span>Genset</span>
                                <i></i>
                            </div> */}
            {/* <div className="sts_status_box sts_rectifier">
                                <span>Rectifier</span>
                                <i></i>
                            </div> */}
            {/* <div className="sts_status_box sts_outage">
                                <span>Outage</span>
                                <i></i>
                            </div> */}
            {/* <div className="sts_status_box sts_wind">
                                <span>Wind</span>
                                <i></i>
                            </div> */}
            <div className="sts_status_box sts_csu">
              <span>CSU</span>
              <i></i>
            </div>
          </div>
          <h4 className={styles.title}> Performance </h4>
          <div className={styles.labels}>
            <span>Energy <i></i></span>
            <span>CO2  <i></i></span>
            <span>NAR <i></i></span>
            <span>FC <i></i></span>
            <span>LS <i></i></span>
          </div>

          <div className={styles.sts_date_status}>Last Updated: October 05,2020</div>
        </div>

        <div className="row">
          <div className="col-12 pt-3">
            <div className="box_card mb-4">
              <PowerFlowNew />
            </div>

            {/* <div className="box_card mb-4">
              <div className='w-100'>

                <div className="node1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1229 234" fill="none">
                    <path d="M4 205.542V34C4 17.4314 17.4315 4 34 4H1195C1211.57 4 1225 17.4315 1225 34V234" stroke="#EDEDED" stroke-width="6" />
                  </svg>
                </div> 
              </div>
            </div> */}

          </div>
        </div>


        <div className='row pb-3'>
          <div className='col-12 col-lg-8'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5'>Energy Usage</h4>
                </div>
                <ReactECharts option={svEnergyUsage} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5'>Power Sources Utilisation</h4>
                </div>
                <ReactECharts option={powerSourcesUtilisation} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
              </div>
            </div>
          </div>
        </div>

        <div className='row pb-3'>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5'>AC Power Source Availabality</h4>
                </div>
                <ReactECharts option={acPowerSourceAvailabality} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5'>Fuel Consumption</h4>
                </div>
                <ReactECharts option={fuelConsumptionChart} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
              </div>
            </div>
          </div>
        </div>

        <div className='row pb-3'>
          <div className='col-12 col-lg-4'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <CirclePrograssChart />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-8'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <PowerSourceDutyCycle />
              </div>
            </div>
          </div>
        </div>

        <div className='row pb-3'>
          <div className='col-lg-12'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <PowerSourceUtilization33 />
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <div className='row pb-3'>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading '>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'> Solar Profiling</h4>
                </div>
                <ReactECharts option={solarProfilingWeatherData} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'> Solar Contribution between 5am to 7am</h4>
                </div>
                <ReactECharts option={energySourcesUsageShowData} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
              </div>
            </div>
          </div>
        </div>

        <div className='row pb-3'>
          <div className='col-12'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'> Battery Profile</h4>
                </div>
                {/* <ReactECharts option={powerSourceDutyCyclesKwh} opts={{ renderer: 'svg' }} style={{ height: 350 }} /> */}
                <SsViewBatteryProfileChart />
              </div>
            </div>
          </div>
        </div>
        <div className='row pb-3'>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'>Battery charging contribution</h4>

                </div>
                <ReactECharts option={batteryChargingContribution} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'>Battery charge & discharge over day</h4>
                </div>
                <ReactECharts option={batteryChargeAndDischargeOverDay} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
              </div>
            </div>
          </div>
        </div>
        <div className='row pb-3'>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading '>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'> Power Source Duty  cycles (Hrs)</h4>
                </div>
                <ReactECharts option={powerSourceDutyCyclesHrs} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='box_card'>
              <div className='ssView_charts'>
                <div className='ssView_chart_heading'>
                  <h4 className='label16 weight5 ssView_cchartH_border pb-2'> Power Source Duty cycles (KWH)</h4>
                </div>
                <ReactECharts option={powerSourceDutyCyclesKwh} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default SitesSnapshotView;