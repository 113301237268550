import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import TotalAverageRadio from "../../../../Components/TotalAverageRadio/TotalAverageRadio";
import PeakHoursChargingCol from "../../../../Components/PeakHoursChargingCol";
import PhcRegionWiseCol from "../../../../Components/PhcRegionWiseCol";
import ReliabilityGradingChartPie from "../../../../Components/Charts/ReliabilityGradingChartPie";
import ReliabilityGradingRegionWiseHeatMap from "../../../../Components/Charts/ReliabilityGradingRegionWiseHeatMap";
import EnvironmentalBenefitsList from "../../../../Components/EnvironmentalBenefitsList/EnvironmentalBenefitsList";
import SpHeader from "../../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function BatteryChargingPeakHours() {
    const pageNo = 10;

    // start  Genset Run Hours
    const piechartOptions = {
        title: {
            text: 'Genset Run Hours',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: 10,
            left: 'center',
            //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle"
        },

        grid: {
            left: '30px',
            right: '10px',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                type: 'pie',
                radius: '60%',
                center: ['50%', '50%'],
                selectedMode: 'single',
                color: [
                    '#99B3AB',
                    '#41BA70',
                    '#68D2F1',
                    '#5967FC',
                    '#F56867',
                ],
                data: [
                    {
                        value: 9,
                        name: '1 Hour',
                        label: {
                            formatter: ['9'].join('\n'),
                        }
                    },
                    {
                        value: 13,
                        name: '2 Hours',
                        label: {
                            formatter: ['13'].join('\n'),
                        }
                    },
                    {
                        value: 15,
                        name: '3 Hours',
                        label: {
                            formatter: ['15'].join('\n'),
                        }
                    },
                    {
                        value: 73,
                        name: '4 Hours',
                        label: {
                            formatter: ['73'].join('\n'),
                        }
                    },
                    {
                        value: 227,
                        name: '5 Hours',
                        label: {
                            formatter: ['227'].join('\n'),
                        }
                    },

                ],
                emphasis: {

                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    // end  Genset Run Hours

    const environmentalBenefitsList = [
        {
            "id": 1,
            "title": "Equivalent Trees Planted",
            "value": "3083",
            "type": "Plants",
            "icon": `<svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="22.5" r="22.5" fill="#0058FF"/>
            <path d="M34.3311 33.7094H11.9802L11.9948 33.2272C12.0629 30.9976 12.7323 29.4651 13.9839 28.6728C15.521 27.7 17.4585 28.1622 18.3037 28.4415C19.7814 25.7031 22.9068 26.0121 24.5847 26.6399C25.4227 26.2166 26.2763 26.1334 27.1262 26.3898C28.921 26.9334 30.1412 28.8594 30.5192 29.5283C32.1563 29.7417 33.3087 30.306 33.9457 31.2065C34.6716 32.2333 34.4276 33.3049 34.4165 33.3497L34.3311 33.7094ZM12.957 32.7733H33.5295C33.519 32.5026 33.4458 32.1145 33.1716 31.7332C32.6691 31.0357 31.6323 30.5848 30.1735 30.4298L29.9193 30.4028L29.8042 30.1738C29.7923 30.1505 28.6028 27.8135 26.8527 27.2854C26.1825 27.082 25.526 27.1739 24.8476 27.562L24.6474 27.6763L24.434 27.5872C24.2694 27.5191 20.3945 25.9428 18.9685 29.2151L18.7821 29.6435L18.3528 29.4574C18.3295 29.4477 16.0423 28.4754 14.4819 29.466C13.5952 30.0289 13.0838 31.1403 12.9571 32.7733L12.957 32.7733Z" fill="white"/>
            <path d="M25.551 19.1411C24.4944 19.1411 23.4228 18.9323 22.4011 18.4915L22.3727 18.4782L21.9352 18.2597L22.1729 17.8323C23.9143 14.2051 28.4639 12.5855 32.3062 14.2421L32.7244 14.4226L32.5599 14.8478C31.2629 17.5497 28.466 19.1411 25.5512 19.1411L25.551 19.1411ZM23.2413 17.8149C26.3569 18.9017 29.8674 17.665 31.4671 14.9174C28.3624 13.8261 24.844 15.0628 23.2413 17.8149Z" fill="white"/>
            <path d="M19.6556 21.1778C16.7813 21.1778 14.2801 19.1366 13.9106 16.3544L13.8087 15.5896L14.591 15.4964C17.7509 15.1219 20.6594 17.2633 21.0593 20.2767V20.2772L21.1603 21.0417L20.3791 21.1349C20.1364 21.1636 19.8945 21.1779 19.6554 21.1779L19.6556 21.1778ZM14.8665 16.4092C15.2798 18.7727 17.5679 20.4473 20.1042 20.2219C19.6912 17.8593 17.4064 16.1889 14.8665 16.4092Z" fill="white"/>
            <path d="M20.9751 19.8881L20.4704 19.3349C19.4582 18.2243 18.9595 16.8053 19.0665 15.3383C19.1743 13.8566 19.8853 12.5075 21.068 11.5393L21.6482 11.063L22.1525 11.6171C23.1647 12.7265 23.6635 14.1456 23.557 15.6127C23.4492 17.0944 22.7382 18.4439 21.5555 19.4122L20.9751 19.8881ZM21.5543 12.3535C20.6363 13.1498 20.0858 14.2288 19.9999 15.4064C19.9153 16.5685 20.2943 17.6964 21.0683 18.5978C21.9867 17.8015 22.5371 16.722 22.6227 15.5444C22.7072 14.3828 22.3287 13.255 21.5543 12.3535Z" fill="white"/>
            <path d="M23.6721 26.7917L22.7879 26.4845C23.5967 24.1529 21.2884 22.5593 21.2652 22.5433L21.223 22.5118C21.1806 22.4756 20.1889 21.6125 20.284 20.3463C20.3575 19.3665 21.0474 18.4632 22.3344 17.6622C27.436 14.4872 29.8416 15.279 29.9399 15.3146L29.6349 16.1996C29.6144 16.1918 27.4789 15.5629 22.8293 18.4567C21.8139 19.0889 21.2717 19.7458 21.2182 20.4093C21.1601 21.1325 21.7179 21.693 21.8179 21.7872C22.8366 22.4902 24.4985 24.4102 23.6724 26.7915L23.6721 26.7917Z" fill="white"/>
            <path d="M20.5556 19.5977C20.2149 16.168 20.8811 15.2743 21.0178 15.1299L21.6972 15.7745L21.3576 15.4522L21.7174 15.7512C21.7123 15.7585 21.1903 16.5114 21.4875 19.5054L20.5556 19.5977Z" fill="white"/>
            <path d="M15.4652 17.5574L16.014 16.7987L20.8696 20.3111L20.3208 21.0698L15.4652 17.5574Z" fill="white"/>
            </svg>`
        },
    ]

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Battery Charging Peak Hours', link: '/BatteryChargingPeakHours' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/BatteryChargingPeakHours',
        otherUrl: '/TeamsViewSummary',
    }

    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />


            <div className="totalAverage_header">
                <div className="contentWrap">
                    <div className="totalAverage_header_row">
                        <TotalAverageRadio />
                    </div>
                </div>
            </div>
            <div className="contentWrap">
                <div className="chart_containter pt-0">
                    <div className="row">
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <PeakHoursChargingCol />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <PhcRegionWiseCol />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">
                        <div className="pb-3 col-12 col-lg-3">
                            <div className="box_card">
                                <div className="box_card_inner solarPotential_lossBox">
                                    <h3 className="chartTitleHeading">Solar Potential Loss (kWh)</h3>
                                    <div className="fuelAnomalies_list">
                                        <ul>
                                            <li>
                                                <div className="fuelAnomalies_row">
                                                    <div className="fuelAnomalies_img">
                                                        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="34" cy="34" r="34" fill="#7243D0" />
                                                            <g clipPath="url(#clip0_3178_20965)">
                                                                <path d="M12.2771 53.833H55.7216" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M26.2644 53.8331L28.3327 10.3887H43.4439L45.5122 53.8331" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M43.2966 10.3887L27.666 24.408" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M35.8884 31.1669L27.651 24.7031" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M28.4801 10.3887L44.1107 24.408" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M35.8883 31.1669L44.1258 24.7031" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M54.7432 41.0327C54.8379 41.1749 54.8921 41.3401 54.9003 41.5108C54.9085 41.6814 54.8702 41.8511 54.7896 42.0017C54.7089 42.1523 54.589 42.2782 54.4424 42.366C54.2959 42.4538 54.1283 42.5002 53.9574 42.5003H17.8192C17.6484 42.5002 17.4808 42.4538 17.3342 42.366C17.1877 42.2782 17.0677 42.1523 16.9871 42.0017C16.9064 41.8511 16.8682 41.6814 16.8763 41.5108C16.8845 41.3401 16.9388 41.1749 17.0334 41.0327L23.331 31.5882C23.4171 31.4587 23.534 31.3525 23.671 31.279C23.8081 31.2056 23.9612 31.1671 24.1168 31.167H47.6599C47.8154 31.1671 47.9685 31.2056 48.1056 31.279C48.2427 31.3525 48.3595 31.4587 48.4457 31.5882L54.7432 41.0327Z" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M27.3903 31.167L45.3328 42.5003L51.5662 36.267" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M44.3866 31.1685L26.4459 42.4999L20.2107 36.2647" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M22.666 10.3887H28.3327" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M43.4438 10.3887H49.1105" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M22.666 19.8335H27.8831" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M43.8934 19.8335H49.1105" stroke="white" strokeWidth="1.71921" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_3178_20965">
                                                                    <rect width="45.3333" height="45.3333" fill="white" transform="translate(11.3328 9.44434)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </div>
                                                    <div className="fuelAnomalies_text">
                                                        <p>Total kWh</p>
                                                        <h3><span>210000</span></h3>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <EnvironmentalBenefitsList title="" data={environmentalBenefitsList} />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-3">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <ReliabilityGradingChartPie options={piechartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-6">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <ReliabilityGradingRegionWiseHeatMap />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default BatteryChargingPeakHours; 