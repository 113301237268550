
import React from 'react';
import "../reports.css"
import SpHeader from '../../../Components/mainHeader/SpHeader'; 
import ReportsTempTabs from './ReportsTempTabs';


function ManagementReports() {
    const breadcrumbs = [
        { label: 'Report Templates', link: '/Reports' },
        { label: 'Management Reports', link: '/ManagementReports' },
        // { label: 'Capacity Planning', link: '/AmCapacityPlanning' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TeamViewPlayGround',
        otherUrl: '/TeamsViewSummary',
    }

    return (
        <div className="assetManagement_wrapper reportsPlayGround_main">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} />

            <div className='management_reports pt-3'>
                <div className='contentWrap'>
                    <div className='reports_targets'>

                        <ReportsTempTabs /> 
                        
                    </div>  
                </div>
            </div>

        </div>
    )
}
export default ManagementReports;