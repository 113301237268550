

import React, { useState } from 'react';
import NpTabsHeader from '../../../../Components/TopTabsHeader/NpTabsHeader';

import { Link } from 'react-router-dom';
import SpHeader from '../../../../Components/mainHeader/SpHeader';
import ApplyFilterHeader from '../../../../Components/ApplyFilterHeader';
import TeamsViewHeaderDropdown from '../../TeamsViewHeaderDropdown';
import SiteDownIpUnreachableTable3 from '../NpTables/SiteDownIpUnreachableTable3.js';
import SiteDownIpUnreachableTable2 from '../NpTables/SiteDownIpUnreachableTable2.js';
import SiteDownIpUnreachableTable1 from '../NpTables/SiteDownIpUnreachableTable1.js';

import { 
    Pagination, 
} from '@mui/material';
 

// import styles from '../SitePerformance.module.css';  


function NpIpUnreachability() {
    const pageNo = 2;

    // let activeIndex = 1;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Network Pulse', link: '/NetworkPulse' },
        // { label: 'Summary', link: '' },

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpSummary',
        otherUrl: '/TeamsViewSummary',
    }

    // 
     // table
     const [currentPage, setCurrentPage] = useState(1); // Current page
    //  const [rowsPerPage, setRowsPerPage] = useState(20); // Ini
  
 
    //  const [rows, setRows] = useState([ 
         
    //  ]);  
 
     const handlePageChange = (event, newPage) => {
         setCurrentPage(newPage); 
     };
 
     // pagination js
     // Calculate the total number of pages
     const totalPages = 5; 
     
    //  const startIndex = (currentPage - 1) * rowsPerPage;
    //  const endIndex = startIndex + rowsPerPage;
    //  const slicedData = rows.slice(startIndex, endIndex);
  

    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/NetworkPulse' />} />
            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>
            </div>

            <div className='d-flex align-items-center pb-3 pt-3'>
                <NpTabsHeader pageNo={pageNo} />
                <div className="ampButtonsGroup pe-4">
                    <ul className="ampButtonsBox">
                        <li>
                            <Link to="/NetworkPulse" className="ampButton">Data</Link>
                        </li>
                        <li>
                            <Link to="/NpIpUnreachability" className="ampButton active">Table</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='npTables npSiteDownIpUnreachable'>
                <div className='contentWrap'>
                    <div className='npTables_inner'>
                        <div className='row'>
                            <div className='col-12 col-lg-4'>
                                <h4 className='npTableHeading'>Site Down / IP Unreachable (Last 24-Hrs)</h4>
                                <SiteDownIpUnreachableTable1 />
                            </div>
                            <div className='col-12 col-lg-4'>
                                <h4 className='npTableHeading'>Site Down / IP Unreachable (Last 3-Days)</h4>
                                <SiteDownIpUnreachableTable2 />
                            </div>
                            <div className='col-12 col-lg-4'>
                                <h4 className='npTableHeading'>Site Down / IP Unreachable (Last 7-Days)</h4>
                                <SiteDownIpUnreachableTable3 />
                            </div>
                        </div>

                        {/* pagination */}
                        <div className='am_pagination'>
                            <div className='am_pagination_left'>
                                <div className='am_pagination_icon'>
                                    <i>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.7036 8.12598C12.5656 8.12598 12.4536 8.23798 12.4546 8.37598C12.4546 8.51398 12.5666 8.62598 12.7046 8.62598C12.8426 8.62598 12.9546 8.51398 12.9546 8.37598C12.9546 8.23798 12.8426 8.12598 12.7036 8.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7046 21.126V21.126C7.73359 21.126 3.70459 17.097 3.70459 12.126V12.126C3.70459 7.15498 7.73359 3.12598 12.7046 3.12598V3.12598C17.6756 3.12598 21.7046 7.15498 21.7046 12.126V12.126C21.7046 17.097 17.6756 21.126 12.7046 21.126Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.7046 12.126V17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </i>
                                    <i>
                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.3711 17.126V3.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M21.3711 17.126C21.3711 19.335 19.5801 21.126 17.3711 21.126H7.37109C5.16209 21.126 3.37109 19.335 3.37109 17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M17.3711 12.126L12.3701 17.127L7.37012 12.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </i>
                                </div>
                            </div>
                            <div className='am_pagination_right '>

                                <div className='am_pagination_rowPg d-flex align-items-center'>
                                    <strong className='label13 weight7 pe-2'>Rows per page</strong>

                                    <div className="applyFilter_box">
                                        <select name="customSearch">
                                            <option value="">25</option>
                                            <option value="26">26</option>
                                            <option value="27">27</option>
                                        </select>
                                    </div>
                                </div>

                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default NpIpUnreachability;