
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import PowerSourcesContent from "../../../../Components/Charts/PowerSourcesContent";
import SpHeader from "../../../../Components/mainHeader/SpHeader";

import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";
function PowerSources() {
    const pageNo = 2;
    // 

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Power Sources', link: '/PowerSources' },
    ];
    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/PowerSources',
        otherUrl: '/TeamsViewSummary',
    }

    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />


            {/* powerSourcesContent */}
            <PowerSourcesContent />

        </div>
    );
};

export default PowerSources; 