
import React from "react"; 
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SiteHeaderDropdown from "../SiteHeaderDropdown";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";  
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import {sptargetsMenu} from "./SpTargetsMenu"; 


 
 


function SpTargetsSolar() {

    const pageNo = 2;
    const breadcrumbs = [
        { label: 'Sites', link: '' },
        { label: 'Targets', link: '/Targets' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SpTargetsSolar',
        otherUrl: '/SingleSiteSummary',
    }



    return (
        <>
            <div className="sitePerformance_wrapper">
                <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<SiteHeaderDropdown parentPath='/Targets' />} />
                <div className="pb-2">
                    <ApplyFilterHeader />
                </div>
                <div className='row align-items-center pe-4 pb-2'>
                    <div className='col-12 col-lg-9'>
                        <AmTabsHeader pageNo={pageNo} moremenu={sptargetsMenu} />
                    </div>
                </div>

                <div className="contentWrap pt-3 mb-3">
                     {/* Targets Solar........ */}
                   Targets Solar ...................
                      
                </div>
            </div>
        </>
    );
}

export default SpTargetsSolar;