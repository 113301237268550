import React from 'react';
import ReactECharts from 'echarts-for-react';

function OuterCircleChart() {

      // outerCircleChart

    const outerCircleChart = {
        title: {
            text: 'Sites',
            left: 'center',
            top: 'center',
            textStyle: {
              fontSize: 18,
              fontWeight: '600',
              color: '#000'
            }
          }, 
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}'
          },
        legend: {
            bottom: '0px',
            left: 'center',
            show: true,
            data: ['C1', 'C2', 'C3', 'C4', 'N1', 'N2', 'N3', 'S1', 'S2', 'S3'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            fill: 'orange',
        },

        grid: {
            top: '60px',
            left: '0px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: [],
                // splitLine: {
                //     show: false,
                //     lineStyle: {
                //         color: 'red'
                //     }
                // },
            }
        ],
        yAxis: [
            {
                type: 'value',
                // splitLine: {
                //     show: false,
                //     lineStyle: {
                //         color: '#F0F0F0'
                //     }
                // },
            }
        ],
        series: [
            {
                name: 'Sites',
                type: 'pie',
                selectedMode: 'single',
                radius: ['35%', '20%'],
                label: {
                    position: 'inner',
                    fontSize: 12
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 764, name: '764', itemStyle: { color: '#5271C3' } },
                    { value: 846, name: '846', itemStyle: { color: '#91CC77' } },
                    { value: 1064, name: '1064', itemStyle: { color: '#F7C95A' } },
                    { value: 684, name: '684', itemStyle: { color: '#EC6864' } },
                    { value: 936, name: '936', itemStyle: { color: '#70C1DE' } },
                    { value: 741, name: '741', itemStyle: { color: '#3EA673' } },
                    { value: 698, name: '698', itemStyle: { color: '#FA8051' } },
                    { value: 814, name: '814', itemStyle: { color: '#9C5DB3' } },
                    { value: 776, name: '776', itemStyle: { color: '#E67ED1' } },
                    { value: 300, name: '300', itemStyle: { color: '#5271C3' } },
                ]
            },
            {
                name: 'Access From',
                type: 'pie',
                radius: ['45%', '70%'],
                labelLine: {
                    length: 10,
                    show: false
                },
                label: {
                    formatter: '{b|{b}: }\n {c|{c}}', 
                    // backgroundColor: '',
                    // borderColor: '',
                    // borderWidth: 1,
                    // borderRadius: 4,

                    rich: {
                        a: {
                            color: 'red',
                            lineHeight: 22,
                            align: 'center'
                        }, 
                        b: {
                            // color: 'black',
                            fontSize: 12,
                            fontWeight: 'bold', 
                            lineHeight: 20,
                            padding: [10, -5, -10, 0] 
                        },
                        c: {
                            color: 'black',
                            fontSize: 10,
                            lineHeight: 40,
                            fontWeight: '400', 
                            padding: [0, -10, 10, 0]
                          },
                         
                    }
                },
                data: [
                    { value: 764, name: 'C1', itemStyle: { color: '#5271C3' },  label: { color: '#5271C3' } },
                    { value: 846, name: 'C2', itemStyle: { color: '#91CC77' }, label: { color: '#91CC77' } },
                    { value: 1064, name: 'C3', itemStyle: { color: '#F7C95A' }, label: { color: '#F7C95A' } },
                    { value: 684, name: 'C4', itemStyle: { color: '#EC6864' }, label: { color: '#EC6864' } },
                    { value: 936, name: 'N1', itemStyle: { color: '#70C1DE' }, label: { color: '#70C1DE' } },
                    { value: 741, name: 'N2', itemStyle: { color: '#3EA673' }, label: { color: '#3EA673' } },
                    { value: 698, name: 'N3', itemStyle: { color: '#FA8051' }, label: { color: '#FA8051' } },
                    { value: 814, name: 'S1', itemStyle: { color: '#9C5DB3' }, label: { color: '#9C5DB3' } },
                    { value: 776, name: 'S2', itemStyle: { color: '#E67ED1' }, label: { color: '#E67ED1' } },
                    { value: 300, name: 'S3', itemStyle: { color: '#5271C3' }, label: { color: '#5271C3' } },
                ]
            }
        ]

    };
    return (
        <>
            <ReactECharts option={outerCircleChart} opts={{ renderer: 'svg' }} style={{ height: 438 }} />
        </>
    );
};
export default OuterCircleChart;