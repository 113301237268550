
import React  from 'react';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import NetworkReachabilityChart from '../../../Components/Charts/NetworkReachabilityChart';
import RegionWiseNetworkReachability from '../../../Components/Charts/RegionWiseNetworkReachability';

import ReactECharts from 'echarts-for-react';


import { Link } from 'react-router-dom';


// import styles from '../SitePerformance.module.css';  


function NetworkPulse() {
    // const pageNo = 0;
    // let activeIndex = 1;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Network Pulse', link: '/NetworkPulse' },
        // { label: 'Summary', link: '' },

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpSummary',
        otherUrl: '/TeamsViewSummary',
    }

    // 

    // 
    const RegionWiseSiteFailure = {
        title: {
            text: 'Sites',
            show: true,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '400',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        // tooltip: {
        //     trigger: 'axis'
        // },

        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Ping', 'SNMP', 'IP'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'S3', 'N2  Region'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Ping',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#1F78B4',
                ],

                data: [6500, 7000, 4500, 3000, 5000, 6500, 6000, 5500, 5000, 5000,],

                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },
            {
                name: 'SNMP',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#32864B',
                ],

                data: [2000, 2000, 3000, 4000, 4000, 5000, 5000, 5000, 5000, 5000],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
            {
                name: 'IP',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#8038B9',
                ],

                data: [5000, 2000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },

        ],

    };




    //  
    const NpSiteReachability = {
        title: {
            text: 'kWh',
            show: true,
            top: '30px',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'left',
            top: '0px',
            show: true,
            data: ['Ping', 'SNMP',],
            fill: 'orange', 
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            right: '20px',
            bottom: '3%',
            top: '70px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ], 

        series: [
            {
              name: 'Ping',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              color: [
                '#00B2FF',
             ],
              data: [50, 52, 58, 50, 55, 50, 55, 60, 50, 45, 55, 50]
            },
            {
              name: 'SNMP',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              color: [
                '#C93898',
             ],
              data: [40, 42, 48, 50, 45, 50, 45, 35, 40, 35, 45, 35]
            },  
          ] 
    };


    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown />} />
            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>
            </div>

            <div className='networkPuls'>
                <div className='contentWrap'>
                    <div className='networkPuls_inner'>
                        <div className="ampButtonsGroup ps-2">
                            <ul className="ampButtonsBox">
                                <li>
                                    <Link to="/NetworkPulse" className="ampButton active">Data</Link>
                                </li>
                                <li>
                                    <Link to="/NpSitesReachabilityProfile" className="ampButton">Table</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='nad_data pb-3'>
                            <div className='row'>
                                <div className='col-12 col-lg-3'>
                                    <div className='box_card'>
                                        <div className='nsd_data_collection'>
                                            <span>Ping Success Sites</span>
                                            <h4 className='color_green4'>9000</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-3'>
                                    <div className='box_card'>
                                        <div className='nsd_data_collection'>
                                            <span>Ping Fail Sites</span>
                                            <h4 className='color_red2'>3000</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-3'>
                                    <div className='box_card'>
                                        <div className='nsd_data_collection'>
                                            <span>SNMP Success Sites</span>
                                            <h4 className='color_green4'>9000</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-3'>
                                    <div className='box_card'>
                                        <div className='nsd_data_collection'>
                                            <span>SNMP Fail Sites</span>
                                            <h4 className='color_red2'>3000</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* row 2 */}

                        <div className='row pb-3'>
                            <div className='col-12 col-lg-12'>
                                <div className='box_card'>
                                <h4 className='label18 weight5 pb-2'>Site Reachability</h4>
                                    <ReactECharts option={NpSiteReachability} opts={{ renderer: 'svg' }} style={{ height: 351 }} />
                                </div>
                            </div>
                        </div>
                        {/* row 3 */}

                        <div className='row pb-3'>
                            <div className='col-12 col-lg-6'>
                                <div className='box_card'>
                                    <NetworkReachabilityChart />
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <div className='box_card'>
                                    <RegionWiseNetworkReachability />
                                </div>
                            </div>
                        </div>

                        {/* row 4 */}
                        <div className='row pb-3'>
                            <div className='col-12 col-lg-12'>
                                <div className='box_card'>
                                    <h4 className='label18 weight5 pb-2'>Region Wise Site Failure</h4>
                                    <ReactECharts option={RegionWiseSiteFailure} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default NetworkPulse;