import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import TotalAverageRadio from "../../../../Components/TotalAverageRadio/TotalAverageRadio";
import ReliabilityGradingChartPie from "../../../../Components/Charts/ReliabilityGradingChartPie";
import InfrastructureWideCol from "../../../../Components/Charts/InfrastructureWideCol";
import InfrastructureRegionWiseCol from "../../../../Components/Charts/InfrastructureRegionWiseCol";
import SpHeader from "../../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function FaultyRectifierModules() {
    const pageNo = 4;
    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Faulty Rectifier Modules', link: '/FaultyRectifierModules' },
    ];
    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/FaultyRectifierModules',
        otherUrl: '/TeamsViewSummary',
    }
    // start  Genset Run Hours
    const piechartOptions = {
        title: {
            text: 'Run Hours Distribution',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: 10,
            left: 'center',
            //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle"
        },

        grid: {
            left: '30px',
            right: '10px',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                type: 'pie',
                radius: '60%',
                center: ['50%', '50%'],
                selectedMode: 'single',
                color: [
                    '#99B3AB',
                    '#1F78B4',
                    '#F56867',
                    '#5967FC',
                    '#F56867',
                ],
                data: [
                    {
                        value: 9,
                        name: '0 - 5 Hrs',
                        label: {
                            formatter: ['9'].join('\n'),
                        }
                    },

                    {
                        value: 250,
                        name: '5 - 15 Hrs',
                        label: {
                            formatter: ['250​'].join('\n'),
                        }
                    },
                    {
                        value: 3382,
                        name: '15+ Hrs',
                        label: {
                            formatter: ['3382'].join('\n'),
                        }
                    },


                ],
                emphasis: {

                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    // end  Genset Run Hours


    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />

            <div className="totalAverage_header">
                <div className="contentWrap">
                    <div className="totalAverage_header_row">
                        <TotalAverageRadio />
                    </div>
                </div>
            </div>
            <div className="contentWrap">
                <div className="chart_containter pt-0">
                    <div className="row">
                        <div className="pb-3 col-12 col-lg-8">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <InfrastructureWideCol />
                                </div>
                            </div>
                        </div>

                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">
                        <div className="pb-3 col-12 col-lg-8">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <InfrastructureRegionWiseCol />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <ReliabilityGradingChartPie options={piechartOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default FaultyRectifierModules; 