import React, { useState } from 'react';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import TnpRadioTabs from './TnpRadioTabs';

import ReactECharts from 'echarts-for-react';
 import TnpSummaryMapView from './TnpSummaryMapView';


function TnpSummary() {
    // const pageNo = 0;
    let activeIndex = 1;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Network Performance', link: '' },
        { label: 'Summary', link: '' },

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpSummary',
        otherUrl: '/TeamsViewSummary',
    }

    // Energy Consumption(GWh) chart 
    const EnergyConsumptionGWh = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: true,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                axisLabel: { interval: 0, rotate: 30 },
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'GWh',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#0072AF',
                ],
                data: [6, 10, 4, 5, 5, 16, 5, 3, 20, 22, 22, 8],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },
            {
                name: 'Target',
                type: 'line',
                stack: '1',
                smooth: true,
                color: [
                    '#19AAF8',
                ],
                data: [3, 2, 1, 9, 3, 3, 8, 5, 2, 1, 5, 4],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },

        ],

    };
    const ecGWHData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '30px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    offset: [0, -10],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 11.2,
                        itemStyle: {
                            color: '#19AAF8',
                        },

                    },
                    {
                        value: 10.5,
                        itemStyle: {
                            color: '#0072AF',
                        },
                    },
                ],
            },
        ],
    };
    // Grid Consumption GWh chart
    const GridConsumptionGWh = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: true,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                axisLabel: { interval: 0, rotate: 30 },
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'GWh',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#7243D0',
                ],
                data: [6, 10, 4, 5, 5, 16, 5, 3, 20, 22, 22, 8],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },
            {
                name: 'Target',
                type: 'line',
                stack: '1',
                smooth: true,
                color: [
                    '#B899F5',
                ],
                data: [3, 2, 1, 9, 3, 3, 8, 5, 2, 1, 5, 4],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },

        ],

    };
    const gcGWHData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '30px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    offset: [0, -10],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 11.2,
                        itemStyle: {
                            color: '#B899F5',
                        },

                    },
                    {
                        value: 10.5,
                        itemStyle: {
                            color: '#7243D0',
                        },
                    },
                ],
            },
        ],
    };
    // Solar Generatio MWh chart
    const SolarGenerationMWh = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: true,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                axisLabel: { interval: 0, rotate: 30 },
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'Hrs',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#F1B400',
                ],
                data: [6, 10, 4, 5, 5, 16, 5, 3, 20, 22, 22, 8],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },
            {
                name: 'Target',
                type: 'line',
                stack: '1',
                smooth: true,
                color: [
                    '#FED55F',
                ],
                data: [3, 2, 1, 9, 3, 3, 8, 5, 2, 1, 5, 4],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },

        ],

    };
    const scGWHData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '30px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    offset: [0, -10],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 11.2,
                        itemStyle: {
                            color: '#FED55F',
                        },

                    },
                    {
                        value: 10.5,
                        itemStyle: {
                            color: '#F1B400',
                        },
                    },
                ],
            },
        ],
    };
    // 
    // Fuel Consumption MLtrs
    const FuelConsumptionMLtrs = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: true,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                axisLabel: { interval: 0, rotate: 30 },
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'MLts',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#F55836',
                ],
                data: [6, 10, 4, 5, 5, 16, 5, 3, 20, 22, 22, 8],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },
            {
                name: 'Target',
                type: 'line',
                stack: '1',
                smooth: true,
                color: [
                    '#F49782',
                ],
                data: [3, 2, 1, 9, 3, 3, 8, 5, 2, 1, 5, 4],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },

        ],

    };
    const FcMltrsData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '30px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    offset: [0, -10],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 11.2,
                        itemStyle: {
                            color: '#F49782',
                        },

                    },
                    {
                        value: 10.5,
                        itemStyle: {
                            color: '#F55836',
                        },
                    },
                ],
            },
        ],
    };
    // Network Availability Ratio chart
    const NetworkAvailabilityRatio = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: true,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                // axisLabel: { interval: 0, rotate: 30 },
                // data: ['1', '5', '10', '15', '20', '25', '30',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: '%',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#B35A3E',
                ],
                data: [26, 22, 19, 25, 22, 16, 25, 23, 17, 21, 19, 22,26, 22, 19, 25, 22, 16, 25, 23, 17, 21, 19, 22,22,26, 22, 19, 25, 22,],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },
         
            {
                name: 'Target',
                type: 'line',
                stack: '1',
                smooth: true,
                color: [
                    '#D2A091',
                ],
                data: [20, 22, 21, 20, 23, 21, 22,20, 22, 21, 20, 23, 21, 22,20, 22, 21, 20, 23, 21, 22,20, 22, 21, 20, 23, 21, 22],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },

        ],

    };
    const naRatioData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '30px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    offset: [0, -10],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 11.2,
                        itemStyle: {
                            color: '#D2A091',
                        },

                    },
                    {
                        value: 10.5,
                        itemStyle: {
                            color: '#B35A3E',
                        },
                    },
                ],
            },
        ],
    };
    // 
    // CO₂ Emissions kTons chart
    const CO2EmissionskTons = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: true,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                axisLabel: { interval: 0, rotate: 30 },
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'kTons',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#5D5B57',
                ],
                data: [6, 10, 4, 5, 5, 16, 5, 3, 20, 22, 22, 8],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },
            {
                name: 'Target',
                type: 'line',
                stack: '1',
                smooth: true,
                color: [
                    '#AEAAA3',
                ],
                data: [3, 2, 1, 9, 3, 3, 8, 5, 2, 1, 5, 4],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },
                barGap: '0%',

            },

        ],

    };
    const Co2EkTonsData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '30px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    offset: [0, -10],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 11.2,
                        itemStyle: {
                            color: '#AEAAA3',
                        },

                    },
                    {
                        value: 10.5,
                        itemStyle: {
                            color: '#5D5B57',
                        },
                    },
                ],
            },
        ],
    };


    const [dataMapStatus, setDataMapStatus] = useState(1);

    const handleDataMapClick = (newStatus) => {
        setDataMapStatus(newStatus);
    };


    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown />} />
            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>

            </div>
            <div className='contentWrap'>

                <div className="exe_summary_header tnp_np_header pt-3">
                    <TnpRadioTabs activeIndex={activeIndex} />
                    <div className="border-bottom-0 pb-0">
                        <div className="amp_btn_margin float-end">
                            <div className="ampButtonsGroup">
                                <ul className="ampButtonsBox">
                                    <li><span
                                        className={`ampButton ${dataMapStatus === 1 ? 'active' : ''}`}
                                        onClick={() => handleDataMapClick(1)}
                                    >Data</span></li>
                                    <li><span 
                                        className={`ampButton ${dataMapStatus === 2 ? 'active' : ''}`}
                                        onClick={() => handleDataMapClick(2)}
                                    >Map</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {dataMapStatus === 1 &&
                    <div className='tnpSummary'>
                        <div className='row'>
                            <div className='col-12 col-lg-4 pb-3'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row">
                                            <h3 className="chartTitleHeading pb-0">Energy Consumption(GWh)</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">Monthly</span></li>
                                                        <li><span className="ampButton">MTD</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-9'>
                                            <ReactECharts option={EnergyConsumptionGWh} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                        <div className='col-12 col-lg-3'>
                                            <div className='tnpChartValue'>
                                                <ul>
                                                    <li>
                                                        <span>3<sub>Hrs</sub></span>
                                                    </li>
                                                    <li>
                                                        <div className='TnpPerChartValue'>
                                                            <i><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                                                <path d="M4.46686 4.94021C4.2262 5.27368 3.72968 5.27368 3.48903 4.94021L0.751502 1.14699C0.463719 0.748228 0.748654 0.191205 1.24042 0.191205H6.71547C7.20723 0.191205 7.49216 0.748227 7.20438 1.14699L4.46686 4.94021Z" fill="#00B53D" />
                                                            </svg></i>
                                                            <strong>2.4<sub>%</sub></strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ReactECharts option={ecGWHData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 pb-3'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row">
                                            <h3 className="chartTitleHeading pb-0">Grid Consumption (GWh)</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">Monthly</span></li>
                                                        <li><span className="ampButton">MTD</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-9'>
                                            <ReactECharts option={GridConsumptionGWh} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                        <div className='col-12 col-lg-3'>
                                            <div className='tnpChartValue'>
                                                <ul>
                                                    <li>
                                                        <span>3<sub>Hrs</sub></span>
                                                    </li>
                                                    <li>
                                                        <div className='TnpPerChartValue'>
                                                            <i><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                                                <path d="M4.46686 4.94021C4.2262 5.27368 3.72968 5.27368 3.48903 4.94021L0.751502 1.14699C0.463719 0.748228 0.748654 0.191205 1.24042 0.191205H6.71547C7.20723 0.191205 7.49216 0.748227 7.20438 1.14699L4.46686 4.94021Z" fill="#00B53D" />
                                                            </svg></i>
                                                            <strong>2.4<sub>%</sub></strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ReactECharts option={gcGWHData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 pb-3'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row">
                                            <h3 className="chartTitleHeading pb-0">Solar Generation (MWh)</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">Monthly</span></li>
                                                        <li><span className="ampButton">MTD</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-9'>
                                            <ReactECharts option={SolarGenerationMWh} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                        <div className='col-12 col-lg-3'>
                                            <div className='tnpChartValue'>
                                                <ul>
                                                    <li>
                                                        <span>3<sub>Hrs</sub></span>
                                                    </li>
                                                    <li>
                                                        <div className='TnpPerChartValue'>
                                                            <i><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                                                <path d="M4.46686 4.94021C4.2262 5.27368 3.72968 5.27368 3.48903 4.94021L0.751502 1.14699C0.463719 0.748228 0.748654 0.191205 1.24042 0.191205H6.71547C7.20723 0.191205 7.49216 0.748227 7.20438 1.14699L4.46686 4.94021Z" fill="#00B53D" />
                                                            </svg></i>

                                                            <strong>2.4<sub>%</sub></strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ReactECharts option={scGWHData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 pb-3'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row">
                                            <h3 className="chartTitleHeading pb-0">Fuel Consumption (MLtrs)</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">Monthly</span></li>
                                                        <li><span className="ampButton">MTD</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-9'>
                                            <ReactECharts option={FuelConsumptionMLtrs} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                        <div className='col-12 col-lg-3'>
                                            <div className='tnpChartValue'>
                                                <ul>
                                                    <li>
                                                        <span>3<sub>Hrs</sub></span>
                                                    </li>
                                                    <li>
                                                        <div className='TnpPerChartValue'>
                                                            <i><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                                                <path d="M4.46686 4.94021C4.2262 5.27368 3.72968 5.27368 3.48903 4.94021L0.751502 1.14699C0.463719 0.748228 0.748654 0.191205 1.24042 0.191205H6.71547C7.20723 0.191205 7.49216 0.748227 7.20438 1.14699L4.46686 4.94021Z" fill="#00B53D" />
                                                            </svg></i>

                                                            <strong>2.4<sub>%</sub></strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ReactECharts option={FcMltrsData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 pb-3'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row">
                                            <h3 className="chartTitleHeading pb-0">Network Availability Ratio (%)</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton">Monthly</span></li>
                                                        <li><span className="ampButton active">MTD</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-9'>
                                            <ReactECharts option={NetworkAvailabilityRatio} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                        <div className='col-12 col-lg-3'>
                                            <div className='tnpChartValue'>
                                                <ul>
                                                    <li>
                                                        <span>3<sub>Hrs</sub></span>
                                                    </li>
                                                    <li>
                                                        <div className='TnpPerChartValue'>
                                                            <i><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                                                <path d="M4.46686 4.94021C4.2262 5.27368 3.72968 5.27368 3.48903 4.94021L0.751502 1.14699C0.463719 0.748228 0.748654 0.191205 1.24042 0.191205H6.71547C7.20723 0.191205 7.49216 0.748227 7.20438 1.14699L4.46686 4.94021Z" fill="#00B53D" />
                                                            </svg></i>

                                                            <strong>2.4<sub>%</sub></strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ReactECharts option={naRatioData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 pb-3'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row">
                                            <h3 className="chartTitleHeading pb-0">CO<sub>2</sub> EmissionskTons</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">Monthly</span></li>
                                                        <li><span className="ampButton">MTD</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-9'>
                                            <ReactECharts option={CO2EmissionskTons} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                        <div className='col-12 col-lg-3'>
                                            <div className='tnpChartValue'>
                                                <ul>
                                                    <li>
                                                        <span>3<sub>Hrs</sub></span>
                                                    </li>
                                                    <li>
                                                        <div className='TnpPerChartValue'>
                                                            <i><svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                                                <path d="M4.46686 4.94021C4.2262 5.27368 3.72968 5.27368 3.48903 4.94021L0.751502 1.14699C0.463719 0.748228 0.748654 0.191205 1.24042 0.191205H6.71547C7.20723 0.191205 7.49216 0.748227 7.20438 1.14699L4.46686 4.94021Z" fill="#00B53D" />
                                                            </svg></i>

                                                            <strong>2.4<sub>%</sub></strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ReactECharts option={Co2EkTonsData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* to show the map view section */}
                {dataMapStatus === 2 && <TnpSummaryMapView />}
            </div>
        </div>
    )
}
export default TnpSummary;