import React from "react";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
import ReactECharts from 'echarts-for-react';

import SpHeader from "../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../TeamsViewHeaderDropdown";

function TnpPerformanceAnalysis() {

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Performance Analysis', link: '/TnpPerformanceAnalysis' },
    ];


    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpPerformanceAnalysis',
        otherUrl: '/TeamsViewSummary',
    }

    // 
    const ecConsumptionData = {
        title: {
            text: 'NAR Performance',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '15px',
            top: '20px',
            right: '44px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Previous', 'Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 91.8,
                        itemStyle: {
                            color: '#B35A3E',
                        },
                    },
                    {
                        value: 91.4,
                        itemStyle: {
                            color: '#B35A3E',
                        },
                    },
                    {
                        value: 94.8,
                        itemStyle: {
                            color: '#CC9B8B',
                        },
                    },
                ],
            },
        ],

    };


    // 
    const fuelPerformanceData = {
        title: {
            text: 'Fuel Performance (Avg/Site/Month)',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '15px',
            top: '20px',
            right: '44px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Previous', 'Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        // Customize the label format here
                        return params.value + ' Ltrs';
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 420,
                        itemStyle: {
                            color: '#F55836',
                        },
                    },
                    {
                        value: 202,
                        itemStyle: {
                            color: '#F55836',
                        },
                    },
                    {
                        value: 232,
                        itemStyle: {
                            color: '#F49782',
                        },
                    },
                ],
            },
        ],

    };



    // 
    const gridPerformanceData = {
        title: {
            text: 'Fuel Performance (Avg/Site/Month)',
            show: false,
            left: 'center',
            width: "100%",
            color: '#000',
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '15px',
            top: '20px',
            right: '44px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Previous', 'Actual', 'Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Previous',
                type: 'bar',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        // Customize the label format here
                        return params.value + ' MWh';
                    },
                },
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },

                data: [
                    {
                        value: 3,
                        itemStyle: {
                            color: '#7243D0',
                        },
                    },
                    {
                        value: 2,
                        itemStyle: {
                            color: '#7243D0',
                        },
                    },
                    {
                        value: 2,
                        itemStyle: {
                            color: '#D6CAEC',
                        },
                    },
                ],
            },
        ],

    };

  // 
  const oPEXPerformanceData = {
    title: {
        text: 'OPEX Performance (Avg/Site/Month)',
        show: false,
        left: 'center',
        width: "100%",
        color: '#000',
        textStyle: {
            textAlign: 'center',
            fontSize: 16,
            fontWeight: '500',
            fontFamily: 'Roboto, sans-serif',
        },
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        left: 'center',
        top: '50px',
        show: false,
        data: ['Previous', 'Actual', 'Target'],
        fill: 'orange',
        itemHeight: 14,
        itemWidth: 14,
        textStyle: {
            fontSize: 12,
            color: '#000',
            fontWeight: '700'
        },
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
        left: '15px',
        top: '20px',
        right: '44px',
        bottom: '5%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            data: ['Previous', 'Actual', 'Target'],
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#F0F0F0'
                }
            },
        },
    ],

    yAxis: [
        {
            type: 'value',
            show: false,
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#F0F0F0'
                }
            },
        }
    ],

    series: [
        {
            name: 'Previous',
            type: 'bar',
            smooth: true,
            label: {
                show: true,
                position: 'outside',
                color: "#000",
                offset: [0, -2],
                emphasis: {
                    label: {
                        color: "#000",
                    },
                },
                formatter: function (params) {
                    // Customize the label format here
                    return params.value + 'k PKR';
                },
            },
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(216, 224, 228, 1)'
            },

            data: [
                {
                    value: 182,
                    itemStyle: {
                        color: '#326657',
                    },
                },
                {
                    value: 102,
                    itemStyle: {
                        color: '#326657',
                    },
                },
                {
                    value:90,
                    itemStyle: {
                        color: '#7CB5A4',
                    },
                },
            ],
        },
    ],

};
    

    // 

    const rwb_chart1 = {
        title: {
            text: 'Region Wise Breakdown',
            show: true,
            subtext: 'Sites',
            left: '-5px',
            width: "100%",
            color: '#000',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
            subtextStyle: {
                color: '#000',
                fontSize: 12,
                fontWeight: '500',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                axisLabel: {
                    textStyle: {
                        fontSize: 11,
                        color: '#000',
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Sites',
                type: 'bar',
                smooth: true,
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: false,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },
                color: '#B35A3E',
                data: [20, 20, 20, 20, 25, 25, 36, 36, 39, 39]
            },
        ],

    };

    // 2bd column bar chart

    const rwb_chart2 = {
        title: {
            text: 'Region Wise Breakdown',
            show: true,
            subtext: 'Sites',
            left: '-5px',
            width: "100%",
            color: '#000',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
            subtextStyle: {
                color: '#000',
                fontSize: 12,
                fontWeight: '500',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                axisLabel: {
                    textStyle: {
                        fontSize: 11,
                        color: '#000',
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Sites',
                type: 'bar',
                smooth: true,
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: false,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },
                color: '#F55836',
                data: [20, 20, 20, 20, 25, 25, 36, 36, 39, 39]
            },
        ],

    };


    // 3rd column bar chart

    const rwb_chart3 = {
        title: {
            text: 'Region Wise Breakdown',
            show: true,
            subtext: 'Sites',
            left: '-5px',
            width: "100%",
            color: '#000',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
            subtextStyle: {
                color: '#000',
                fontSize: 12,
                fontWeight: '500',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                axisLabel: {
                    textStyle: {
                        fontSize: 11,
                        color: '#000',
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Sites',
                type: 'bar',
                smooth: true,
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: false,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },
                color: '#7243D0',
                data: [20, 20, 20, 20, 25, 25, 36, 36, 39, 39]
            },
        ],

    };

    // 4th column bar chart

    const rwb_chart4 = {
        title: {
            text: 'Region Wise Breakdown',
            show: true,
            subtext: 'Sites',
            left: '-5px',
            width: "100%",
            color: '#000',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
            subtextStyle: {
                color: '#000',
                fontSize: 12,
                fontWeight: '500',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                axisLabel: {
                    textStyle: {
                        fontSize: 11,
                        color: '#000',
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Sites',
                type: 'bar',
                smooth: true,
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: false,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },
                color: '#326657',
                data: [20, 20, 20, 20, 25, 25, 36, 36, 39, 39]
            },
        ],

    };



    return (
        <div className="wrapper">
            {/* <HeaderMain pageTitle='Teams View - Grid Unavailability'/> */}
            {/* <SpHeader breadcrumbs={breadcrumbs} /> */}
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/TnpPerformanceAnalysis' />} />
            <div className="tmp_totalSitesRow  d-flex ps-3">
                <div className="tmp_totalSitesBox d-flex">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters w-100">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>
            </div>

            <div className="contentWrap ">
                <div className="tnp_perAnalys_row pt-3 row">
                    <div className="col-12 col-lg-6 col-xl-3 mb-3">
                        <div className="tnp_perAnalys_box">
                            <div className="tnp_perAnalys_top">
                                <h3>NAR Performance <small>(Avg/Site/Month)</small></h3>
                                <div className="nar_chartBoxes_row">
                                    <ul>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                </svg></span> 0.4%</div>
                                                <div className="nar_chartVal_type">-0.4</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                </svg></span> 3.7%</div>
                                                <div className="nar_chartVal_type">-3.4</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='nar_chartTop'>
                                    <ReactECharts option={ecConsumptionData} opts={{ renderer: 'svg' }} style={{ height: 200 }} />
                                </div>
                            </div>
                            <div className="tnp_analys_compares">
                                <div className="tnp_analys_header">
                                    <ul className="d-flex">
                                        <li>
                                            <div className="tnp_analys_headerBox bg_green3">
                                                <strong>Good Sites</strong>
                                                <h3>
                                                    <span>2256</span>
                                                    <span>27%</span>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="tnp_analys_headerBox bg_red">
                                                <strong>Bad Sites</strong>
                                                <h3>
                                                    <span>7925</span>
                                                    <span>73%</span>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tnp_analys_content">
                                    <div className="tnp_analys_compareChart">
                                        <div className="nar_chartBoxes_row">
                                            <ul>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 0.4%</div>
                                                        <div className="nar_chartVal_type">-0.4</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 3.7%</div>
                                                        <div className="nar_chartVal_type">-3.4</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='nar_chartTop'>
                                            <ReactECharts option={ecConsumptionData} opts={{ renderer: 'svg' }} style={{ height: 160 }} />
                                        </div>
                                    </div>
                                    <div className="tnp_analys_ccolChart">
                                        <ReactECharts option={rwb_chart1} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3 mb-3">
                        <div className="tnp_perAnalys_box">
                            <div className="tnp_perAnalys_top">
                                <h3>Fuel Performance <small>(Avg/Site/Month)</small></h3>
                                <div className="nar_chartBoxes_row">
                                    <ul>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent color_green3"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.19175 3.23206C2.05355 3.42355 1.76842 3.42355 1.63022 3.23206L0.0581803 1.05377C-0.107081 0.824777 0.0565448 0.504903 0.338943 0.504903H3.48303C3.76543 0.504903 3.92905 0.824777 3.76379 1.05377L2.19175 3.23206Z" fill="#17A700" />
                                                </svg></span> 107.9%</div>
                                                <div className="nar_chartVal_type">-218 Ltrs</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent color_green3"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.19175 3.23206C2.05355 3.42355 1.76842 3.42355 1.63022 3.23206L0.0581803 1.05377C-0.107081 0.824777 0.0565448 0.504903 0.338943 0.504903H3.48303C3.76543 0.504903 3.92905 0.824777 3.76379 1.05377L2.19175 3.23206Z" fill="#17A700" />
                                                </svg></span> 15.0%</div>
                                                <div className="nar_chartVal_type">-30 Ltrs</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='nar_chartTop'>
                                    <ReactECharts option={fuelPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 200 }} />
                                </div>
                            </div>
                            <div className="tnp_analys_compares activeRed">
                                <div className="tnp_analys_header">
                                    <ul className="d-flex">
                                        <li>
                                            <div className="tnp_analys_headerBox bg_green3">
                                                <strong>Good Sites</strong>
                                                <h3>
                                                    <span>9321</span>
                                                    <span>81%</span>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="tnp_analys_headerBox bg_red">
                                                <strong>Bad Sites</strong>
                                                <h3>
                                                    <span>1879</span>
                                                    <span>19%</span>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tnp_analys_content">
                                    <div className="tnp_analys_compareChart">
                                        <div className="nar_chartBoxes_row">
                                            <ul>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 0.4%</div>
                                                        <div className="nar_chartVal_type">-0.4</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 3.7%</div>
                                                        <div className="nar_chartVal_type">-3.4</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='nar_chartTop'>
                                            <ReactECharts option={fuelPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 160 }} />
                                        </div>
                                    </div>
                                    <div className="tnp_analys_ccolChart">
                                        <ReactECharts option={rwb_chart2} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3 mb-3">
                        <div className="tnp_perAnalys_box">
                            <div className="tnp_perAnalys_top">
                                <h3>Grid Performance <small>(Avg/Site/Month)</small></h3>
                                <div className="nar_chartBoxes_row">
                                    <ul>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent color_green3"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="5" height="4" viewBox="0 0 5 4" fill="none">
                                                    <path d="M1.85903 3.23206C1.99723 3.42355 2.28236 3.42355 2.42056 3.23206L3.9926 1.05377C4.15786 0.824776 3.99424 0.504902 3.71184 0.504902L0.567751 0.504902C0.285352 0.504902 0.121726 0.824777 0.286988 1.05377L1.85903 3.23206Z" fill="#17A700" />
                                                </svg></span> 60.0%</div>
                                                <div className="nar_chartVal_type">-0.4 MWh</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                </svg></span> 15.7%</div>
                                                <div className="nar_chartVal_type">299.8 KWh</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='nar_chartTop'>
                                    <ReactECharts option={gridPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 200 }} />
                                </div>
                            </div>
                            <div className="tnp_analys_compares">
                                <div className="tnp_analys_header">
                                    <ul className="d-flex">
                                        <li>
                                            <div className="tnp_analys_headerBox bg_green3">
                                                <strong>Good Sites</strong>
                                                <h3>
                                                    <span>9321</span>
                                                    <span>81%</span>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="tnp_analys_headerBox bg_red">
                                                <strong>Bad Sites</strong>
                                                <h3>
                                                    <span>1879</span>
                                                    <span>19%</span>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tnp_analys_content">
                                    <div className="tnp_analys_compareChart">
                                        <div className="nar_chartBoxes_row">
                                            <ul>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 0.4%</div>
                                                        <div className="nar_chartVal_type">-0.4</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 3.7%</div>
                                                        <div className="nar_chartVal_type">-3.4</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='nar_chartTop'>
                                            <ReactECharts option={gridPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 160 }} />
                                        </div>
                                    </div>
                                    <div className="tnp_analys_ccolChart">
                                        <ReactECharts option={rwb_chart3} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-3 mb-3">
                        <div className="tnp_perAnalys_box">
                            <div className="tnp_perAnalys_top">
                                <h3>OPEX Performance  <small>(Avg/Site/Month)</small></h3>
                                <div className="nar_chartBoxes_row">
                                    <ul>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                </svg></span> 66.2%</div>
                                                <div className="nar_chartVal_type">-73.8k PKR</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="nar_chartBox">
                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                </svg></span> 2.8%</div>
                                                <div className="nar_chartVal_type">2.7k PKR</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='nar_chartTop'>
                                    <ReactECharts option={oPEXPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 200 }} />
                                </div>
                            </div>
                            <div className="tnp_analys_compares">
                                <div className="tnp_analys_header">
                                    <ul className="d-flex">
                                        <li>
                                            <div className="tnp_analys_headerBox bg_green3">
                                                <strong>Good Sites</strong>
                                                <h3>
                                                    <span>9321</span>
                                                    <span>81%</span>
                                                </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="tnp_analys_headerBox bg_red">
                                                <strong>Bad Sites</strong>
                                                <h3>
                                                    <span>1879</span>
                                                    <span>19%</span>
                                                </h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tnp_analys_content">
                                    <div className="tnp_analys_compareChart">
                                        <div className="nar_chartBoxes_row">
                                            <ul>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 0.4%</div>
                                                        <div className="nar_chartVal_type">-0.4</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="nar_chartBox">
                                                        <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                            <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                        </svg></span> 3.7%</div>
                                                        <div className="nar_chartVal_type">-3.4</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='nar_chartTop'>
                                            <ReactECharts option={oPEXPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 160 }} />
                                        </div>
                                    </div>
                                    <div className="tnp_analys_ccolChart">
                                        <ReactECharts option={rwb_chart4} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TnpPerformanceAnalysis; 