import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AddFuelDetails(props) {
    return (
        <Modal
            {...props}
            size="lg"
            centered
            className='cstm_b_modal addFuelDetails_pop'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Add Fuel Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='ps_title_border'><span>Person Details</span></h4>
                <div className='addFuelDetails_form'>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Fueler Name *">
                                <Form.Control type="text" placeholder="Fueler Name *" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Fueler Contact Number">
                                <Form.Control type="text" placeholder="Fueler Contact Number" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Guard Name">
                                <Form.Control type="text" placeholder="Guard Name" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Guard Contact Number">
                                <Form.Control type="text" placeholder="Guard Contact Number" />
                            </FloatingLabel>
                        </div>
                        
                    </div>
                    <h4 className='ps_title_border'><span>Site Details</span></h4>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Site ID *">
                                <Form.Control type="text" placeholder="Site ID *" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Region">
                                <Form.Control type="text" placeholder="Region" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Recommendation">
                                <Form.Control type="text" placeholder="Recommendation" />
                            </FloatingLabel>
                        </div>
                    </div>
                    <h4 className='ps_title_border'><span>Fueling Details</span></h4>
                    <div className='row'>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="FSR Number *">
                                <Form.Control type="text" placeholder="FSR Number *" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Fuel Opening Stock *">
                                <Form.Control type="text" placeholder="Fuel Opening Stock *" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Fuel Poured Quality (Ltr) *">
                                <Form.Control type="text" placeholder="Fuel Poured Quality (Ltr) *" />
                            </FloatingLabel>
                        </div>

                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Visit Type *">
                                <Form.Control type="text" placeholder="Visit Type *" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Reason">
                                <Form.Control type="text" placeholder="Reason" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <FloatingLabel  controlId="floatingInput"  label="Grid Status">
                                <Form.Control type="text" placeholder="Grid Status" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-2'>
                            <FloatingLabel  controlId="floatingInput"  label="DG-1 Meter Readong *">
                                <Form.Control type="text" placeholder="DG-1 Meter Readong *" />
                            </FloatingLabel>
                        </div>
                        <div className='col-md-4 mb-2'>
                            <FloatingLabel  controlId="floatingInput"  label="DG-2 Meter Reading">
                                <Form.Control type="text" placeholder="DG-2 Meter Reading" />
                            </FloatingLabel>
                        </div>
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer className='pt-0'>
                <Button variant='secondary' onClick={props.onHide}>Cancel</Button>
                <Button variant='dark'>Add Details</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default AddFuelDetails