
import React from 'react';
import NpTabsHeader from '../../../../Components/TopTabsHeader/NpTabsHeader';
import { Link } from 'react-router-dom';
import SpHeader from '../../../../Components/mainHeader/SpHeader';
import ApplyFilterHeader from '../../../../Components/ApplyFilterHeader';
import TeamsViewHeaderDropdown from '../../TeamsViewHeaderDropdown';
import SiteReachabilityProfileTable from '../NpTables/SiteReachabilityProfileTable';
import DailySiteReachabilityTable from '../NpTables/DailySiteReachabilityTable';

// import styles from '../SitePerformance.module.css';  


function NpSitesReachabilityProfile() {
    const pageNo = 0;

    // let activeIndex = 1;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Network Pulse', link: '/NetworkPulse' },
        // { label: 'Summary', link: '' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpSummary',
        otherUrl: '/TeamsViewSummary',
    }

    // 


    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/NetworkPulse' />} />
            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>
            </div>

            <div className='d-flex align-items-center pb-3 pt-3'>
                <NpTabsHeader pageNo={pageNo} />
                <div className="ampButtonsGroup pe-4">
                    <ul className="ampButtonsBox">
                        <li>
                            <Link to="/NetworkPulse" className="ampButton">Data</Link>
                        </li>
                        <li>
                            <Link to="/NpSitesReachabilityProfile" className="ampButton active">Table</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='npTables'>
                <div className='contentWrap'>
                    <div className='npTables_inner'>
                        <div className='row'>
                            <div className='col-12 col-lg-12 pb-3'>
                                <h4 className='npTableHeading'>Daily Site Reachability (Last 7- Days)</h4>
                                <DailySiteReachabilityTable />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 col-lg-12'>
                                <h4 className='npTableHeading'>Site Reachability Profile</h4>
                                <SiteReachabilityProfileTable />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
export default NpSitesReachabilityProfile;