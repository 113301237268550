
import React, { useState } from "react";
// import "../../AssetManagement/AssetManagement.css";

import "./TnpNetworkInsights.css"
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import TnpTabsHeader from '../../../Components/TopTabsHeader/TnpTabsHeader';

import { moremenu } from './MoreMenuLinks';
import { Link } from 'react-router-dom';

// table

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Popover,
    List,
    Pagination,
    ListItem,
    Checkbox,
} from '@mui/material';
import { Button, Form } from 'react-bootstrap';



function TniNetworkInsightsTable() {

    const pageNo = 0;

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Network Insights', link: '/TnpNetworkInsights' },
        { label: 'Summary', link: '' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpNetworkInsights',
        otherUrl: '/TeamsViewSummary',
    }

    // table

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Ini

    const columns = [
        { field: 'SiteID', headerName: 'Site ID', flex: 1 },
        { field: 'ProjectID', headerName: 'Project ID', flex: 1 },
        { field: 'External1', headerName: 'External', flex: 1 },
        { field: 'Infra1', headerName: 'Infra', flex: 1 },
        { field: 'Field1', headerName: 'Field', flex: 1 },
        { field: 'External2', headerName: 'External', flex: 1 },
        { field: 'Infra2', headerName: 'Infra', flex: 1 },
        { field: 'Field2', headerName: 'Field', flex: 1 },
        { field: 'External3', headerName: 'External', flex: 1 },
        { field: 'Infra3', headerName: 'Infra', flex: 1 },
        { field: 'Field3', headerName: 'Field', flex: 1 },

    ];

    const [rows, setRows] = useState([
        { id: 1, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        { id: 2, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        { id: 3, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        { id: 4, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        { id: 5, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        { id: 6, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        { id: 7, SiteID: 'QMN101', ProjectID: 'TB-2022', External1: 'LR is Reduced', Infra1: 'Site Load Increased', Field1: 'CC Increased', External2: 'Extended LS', Infra2: '--', Field2: 'Unaccounted  DG Run', External3: '--', Infra3: '--', Field3: '--' },
        
    ]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleFilterIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen(!filterOpen);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value;

        if (selectedFilters.includes(value)) {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
        } else {
            setSelectedFilters([...selectedFilters, value]);
        }
    };

    const applyFilters = () => {
        // Apply filters based on selectedFilters
        // You can implement your custom filtering logic here

        // For demonstration purposes, we'll just log the selected filters
        console.log('Selected Filters:', selectedFilters);

        // Close the filter dropdown
        setFilterOpen(false);
    };


    const handleSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        const newOrder = isAscending ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        const sortedRows = [...rows].sort((a, b) => {
            const aValue = a[property];
            const bValue = b[property];
            if (newOrder === 'asc') {
                return aValue < bValue ? -1 : 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        });

        setRows(sortedRows);
    };

    const totalPages = Math.ceil(rows.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = rows.slice(startIndex, endIndex);
 


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        console.log("currentPage:", newPage);
    };

    console.log("setRowsPerPage:", setRowsPerPage);
    console.log("endIndex:", endIndex);
    console.log("slicedData---:", slicedData);

     

    return (
        <div className="wrapper tniNetwork_main">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/TnpNetworkInsights' />} />
            <div className="tmp_totalSitesFilters">
                <ApplyFilterHeader notYTDBtns notDatepicker />
            </div>

            <div className='tnpNetworkTabs'>
                <div className='stf_header pt-2 pb-1'>
                    <TnpTabsHeader pageNo={pageNo} moremenu={moremenu} />
                    <div className="ampButtonsGroup ps-2">
                        <ul className="ampButtonsBox">
                            <li>
                                <Link to={"/TnpNetworkInsights"} className="ampButton">Data</Link>
                            </li>
                            <li>
                                <Link to={"/TniNetworkInsightsTable"} className="ampButton active">Table</Link >
                            </li>
                            <li>
                                <Link to={"/TniNetworkInsightsMap"} className="ampButton">Map</Link>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div className='tniNetworkInsightsTable pb-3 tniNetworkInsightsTable'>
                <div className='stf_tableWrap contentWrap'>

                    <div className="table_top_info mt-2">
                        <ul>
                            <li className="width25">
                                <span></span>
                            </li>
                            <li className="width25">
                                <span>Grid RCA</span>
                            </li>
                            <li className="width25">
                                <span>Fuel RCA</span>
                            </li>
                            <li className="width25">
                                <span>Solar RCA</span>
                            </li>
                        </ul>
                    </div>

                    <TableContainer>
                        <Table aria-label="Basic table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.field}>
                                            <div className='customTbl_headCell'>
                                                <TableSortLabel
                                                    active={orderBy === column.field}
                                                    direction={orderBy === column.field ? order : 'asc'}
                                                    onClick={() => handleSort(column.field)}
                                                >
                                                    {column.headerName}
                                                </TableSortLabel>

                                                <Tooltip title="Filter">
                                                    <label className='filter_header_btn' onClick={handleFilterIconClick}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
                                                            <path d="M7.66615 1.88513C7.70623 1.83087 7.73063 1.76635 7.73663 1.6988C7.74263 1.63125 7.72999 1.56333 7.70012 1.50267C7.67024 1.442 7.62433 1.39098 7.56751 1.35533C7.51069 1.31967 7.44522 1.30078 7.37843 1.30078H0.897744C0.830952 1.30078 0.765478 1.31967 0.708661 1.35533C0.651843 1.39098 0.605927 1.442 0.576056 1.50267C0.546186 1.56333 0.533541 1.63125 0.539539 1.6988C0.545538 1.76635 0.569942 1.83087 0.610018 1.88513L3.41823 5.71662V9.33556C3.41823 9.40339 3.43683 9.46987 3.47194 9.52757C3.50706 9.58527 3.5573 9.63189 3.61704 9.66222C3.67679 9.69256 3.74366 9.7054 3.81019 9.69931C3.87671 9.69321 3.94025 9.66843 3.99368 9.62774L4.713 9.07991C4.75767 9.04589 4.79392 9.00178 4.81889 8.95107C4.84386 8.90036 4.85686 8.84444 4.85686 8.78774V5.71626L7.66615 1.88513Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </label>
                                                </Tooltip>

                                                <Popover className='stf_filter_dropdown' open={filterOpen} anchorEl={anchorEl} onClose={() => setFilterOpen(false)}>
                                                    <div className='stf_filter_header'>
                                                        <h3 className='stf_filter_title'>Filter By Value <span>T<small>T</small></span></h3>

                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Filter Values"
                                                            aria-describedby="passwordHelpBlock"
                                                        />
                                                    </div>
                                                    <List>
                                                        <ListItem>
                                                            <label>
                                                                <Checkbox
                                                                    value="FilterValue1"
                                                                    checked={selectedFilters.includes('FilterValue1')}
                                                                    onChange={handleFilterChange}
                                                                />
                                                                Filter Option 1
                                                            </label>
                                                        </ListItem>
                                                        <ListItem>
                                                            <label>
                                                                <Checkbox
                                                                    value="FilterValue2"
                                                                    checked={selectedFilters.includes('FilterValue2')}
                                                                    onChange={handleFilterChange}
                                                                />
                                                                Filter Option 2
                                                            </label>
                                                        </ListItem>
                                                        {/* Add more filter options as needed */}
                                                    </List>
                                                    <div className='stf_filter_footer'>
                                                        <Button variant="outline-secondary" onClick={applyFilters}>Cancel</Button>
                                                        <Button variant="dark" onClick={applyFilters}>Ok</Button>
                                                    </div>

                                                </Popover>
                                            </div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {slicedData.map((row) => (
                                    <TableRow key={row.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.field}>{row[column.field]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>

                    <div className='am_pagination'>
                        <div className='am_pagination_left'>
                            <div className='am_pagination_icon'>
                                <i>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.7036 8.12598C12.5656 8.12598 12.4536 8.23798 12.4546 8.37598C12.4546 8.51398 12.5666 8.62598 12.7046 8.62598C12.8426 8.62598 12.9546 8.51398 12.9546 8.37598C12.9546 8.23798 12.8426 8.12598 12.7036 8.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7046 21.126V21.126C7.73359 21.126 3.70459 17.097 3.70459 12.126V12.126C3.70459 7.15498 7.73359 3.12598 12.7046 3.12598V3.12598C17.6756 3.12598 21.7046 7.15498 21.7046 12.126V12.126C21.7046 17.097 17.6756 21.126 12.7046 21.126Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M12.7046 12.126V17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </i>
                                <i>
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3711 17.126V3.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M21.3711 17.126C21.3711 19.335 19.5801 21.126 17.3711 21.126H7.37109C5.16209 21.126 3.37109 19.335 3.37109 17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M17.3711 12.126L12.3701 17.127L7.37012 12.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </i>
                            </div>
                        </div>
                        <div className='am_pagination_right '>

                            <div className='am_pagination_rowPg d-flex align-items-center'>
                                <strong className='label13 weight7 pe-2'>Rows per page</strong>

                                <div className="applyFilter_box">
                                    <select name="customSearch">
                                        <option value="">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                    </select>
                                </div>
                            </div>

                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                shape="rounded"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TniNetworkInsightsTable;