
import React from "react";
import SpHeader from "../../../../../Components/mainHeader/SpHeader";
import MyAllDownloadInfo from "../../../../../Components/ytdToFrom/MyAllDownloadInfo";
import CommercialSavingPagination from "../CommercialSavingPagination";
import ReactECharts from 'echarts-for-react';

function DsSolarPotentialMaximization() {
    const breadcrumbs = [
        { label: 'Data Stories', link: '/DataStories' },
        { label: 'Commercial Savings', link: '/DsCommercialSavingsLinks' },
        { label: 'Grid Peak Hours Battery Charging', link: '' },
    ];

    //

    const DsSolarPotentialMaximization = {
        title: {
            text: 'Energy Performance Comparison',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '4px',
            left: 'center',
            show: true,
            data: ['Grid Utilization', 'Battery Charging'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '0px',
            right: '0px',
            bottom: '16px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Before Policy is Applied', 'After Policy is Applied'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            // stack 1
            {
                name: 'Grid Utilization',
                type: 'bar',
                stack: 'stack1',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#A472B4',
                ],

                data: [46.6, 46.6]

            },
            {
                name: 'Battery Charging',
                type: 'bar',
                stack: 'stack1',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#00B2FF',
                ],
                // areaStyle: {normal: {}},
                data: [10.4, 10.4],

            },
            // stack 2
            {
                name: 'Grid Utilization',
                type: 'bar',
                stack: 'stack2',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#A472B4',
                ],

                data: [46.6, 46.6]

            },
            {
                name: 'Battery Charging',
                type: 'bar',
                stack: 'stack2',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#00B2FF',
                ],
                // areaStyle: {normal: {}},
                data: [10.4, 10.4],

            },
            // stack 3
            {
                name: 'Grid Utilization',
                type: 'bar',
                stack: 'stack3',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#A472B4',
                ],

                data: [46.0, 46.0]

            },
            {
                name: 'Battery Charging',
                type: 'bar',
                stack: 'stack3',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#00B2FF',
                ],
                // areaStyle: {normal: {}},
                data: [10.4, 10.4],

            },
        ],
    };


    return (
        <div className="gridPeakHrs_main">
            <div className="cs_gp_header">
                <SpHeader breadcrumbs={breadcrumbs} />
            </div>
            <div className="cs_gp_hrs_inner">
                <div className="contentWrap">
                    <div className="cs_gp_heading">
                        <h2> Grid Peak Hours Battery Charging </h2>
                        <div className="cs_gp_amButtons">
                            <div className="ampButtonsGroup pe-3">
                                <ul className="ampButtonsBox">
                                    <li><span className="ampButton active">kWh</span></li>
                                    <li><span className="ampButton">USD</span></li>
                                    <li><span className="ampButton">PKR</span></li>
                                </ul>
                            </div>
                            <div className="ampButtonsGroup">
                                <ul className="ampButtonsBox">
                                    <li><span className="ampButton ">Total</span></li>
                                    <li><span className="ampButton active">Average</span></li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div className="cs_gp_hrs_content">
                        <div className="box_card">
                            <div className="row cs_gp_hrs_content_inner">
                                <div className="col-12 col-lg-7">
                                    <div className="cs_gp_hrs_content_info">
                                        <h4> policy based savings </h4>
                                        <p>An effective <strong className="color_green_pigment">2.8%</strong> reduction in grid utilization.</p>
                                        <p>During Peak hours an effective <strong className="color_green_pigment">5.6%</strong> reduction in grid utilization.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="cs_gp_hrs_statusRow">
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Responded Sites</small>
                                                <h2>1571</h2>
                                            </div>
                                        </div>
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Per Day</small>
                                                <h2 className="color_green4">
                                                    <strong>44.9</strong>
                                                    <sub>kWh</sub>
                                                </h2>
                                            </div>

                                            <div className="cs_gp_hrs_statusRow_below">
                                                <div className="cs_gp_hrs_status_belowCell">
                                                    <div className="cs_gp_hrs_status_text_below">
                                                        <small>Grid</small>
                                                        <h2 className="color_purple_dark">
                                                            <strong>0.62kWh</strong> 
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="cs_gp_hrs_status_belowCell">
                                                    <div className="cs_gp_hrs_status_text_below">
                                                        <small>Battery Charging</small>
                                                        <h2 className="color_blue_navy">
                                                            <strong>0.82kWh</strong> 
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Per Day</small>
                                                <h2 className="color_green4">
                                                    <strong>9.07</strong>
                                                    <sub>kWh</sub>
                                                </h2>
                                            </div>

                                            <div className="cs_gp_hrs_statusRow_below">
                                                <div className="cs_gp_hrs_status_belowCell">
                                                    <div className="cs_gp_hrs_status_text_below">
                                                        <small>Grid</small>
                                                        <h2 className="color_purple_dark">
                                                            <strong>1.11kWh</strong> 
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="cs_gp_hrs_status_belowCell">
                                                    <div className="cs_gp_hrs_status_text_below">
                                                        <small>Battery Charging</small>
                                                        <h2 className="color_blue_navy">
                                                            <strong>1.37kWh</strong> 
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="cs_gp_hrs_sRow_btn_grp">
                                        {/* <Link className="cs_sRow_btn">Grid Utilization</Link>
                                        <Link className="cs_sRow_btn bg_deep_skyBlue">Battery Charging</Link> */}
                                        <MyAllDownloadInfo notYTDBtns notDatepicker />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <div className="ds_solarPotentialMax">
                                        <div className='tnpPerformanceCompInner'>
                                            <div className="nar_chartBoxes_row">
                                                <ul>
                                                    <li>
                                                        <div className="nar_chartBox">
                                                            <div className="ds_sp_chartBox">
                                                                <div className="ds_sp_chartBox_list">
                                                                    <div className="ds_sp_chartDayHrs">Day:</div>
                                                                    <div className="nar_chartVal_type">-5.7<sub>kWh</sub></div>
                                                                    <div className="nar_chartVal_percent">
                                                                        <span className="nar_chartVal_arrow">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                            </svg>
                                                                        </span>8.7%
                                                                    </div>
                                                                </div>
                                                                <div className="ds_sp_chartBox_list">
                                                                    <div className="ds_sp_chartDayHrs">Off Peak:</div>
                                                                    <div className="nar_chartVal_type">-3.6<sub>kWh</sub></div>
                                                                    <div className="nar_chartVal_percent">
                                                                        <span className="nar_chartVal_arrow">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                            </svg>
                                                                        </span>5.7%
                                                                    </div>
                                                                </div>

                                                                <div className="ds_sp_chartBox_list">
                                                                    <div className="ds_sp_chartDayHrs">Peak:</div>
                                                                    <div className="nar_chartVal_type">-2.1<sub>kWh</sub></div>
                                                                    <div className="nar_chartVal_percent">
                                                                        <span className="nar_chartVal_arrow">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                                <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                            </svg>
                                                                        </span>3.0%
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='nar_chartTop'>
                                                <ReactECharts option={DsSolarPotentialMaximization} opts={{ renderer: 'svg' }} style={{ height: 174 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <CommercialSavingPagination isNext='/CsGridPeakHrsPolicy' isPrev='/DsGensetRunHours' currentId='6'/>

                </div>
            </div>
        </div>
    )
}

export default DsSolarPotentialMaximization