import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import InfrastructureWideLineChart from "../../../../Components/Charts/InfrastructureWideLineChart";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import RegionWiseColumnChart from "../../../../Components/Charts/RegionWiseColumnChart";
import ReliabilityGradingChartPie from "../../../../Components/Charts/ReliabilityGradingChartPie";
import ReliabilityGradingRegionWiseHeatMap from "../../../../Components/Charts/ReliabilityGradingRegionWiseHeatMap";
import FuelAnomalies from "../../../../Components/FuelAnomalies/FuelAnomalies";
import SpHeader from "../../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function FuelConsumption() {
    //this is selected index path of top header tab.
    const pageNo = 1;

    //start line chart options
    const lineoption = {
        title: {
            text: 'Infrastructure Wide (Litres/Day)',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0px',
            top: '2px',
            show: true,
            data: ['Target', 'Actual', 'Last Year'],
            // fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Target',
                type: 'line',
                color: [
                    '#326657',
                ],
                stack: 'Actual',
                smooth: true,
                // areaStyle: {normal: {}},
                data: [13, 12, 12, 12.2, 11, 11.5, 13, 13, 13, 13, 13, 13]
            },
            {
                name: 'Actual',
                type: 'line',
                color: [
                    '#F55836',
                ],
                stack: 'Actual',
                smooth: true,
                // areaStyle: {normal: {}},
                data: [13, 12, 12, 12.2, 11, 11.5, 13, 13, 13, 13, 13, 13]
            },
            {
                name: 'Last Year',
                type: 'line',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#FF9882',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 14, 13, 13, 13.5, 0, 0, 0, 0, 0, 0]
            },

        ]
    };
    //end line chart options


    // start  Genset Run Hours
    const piechartOptions = {
        title: {
            text: 'Genset Run Hours',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: 10,
            left: 'center',
            //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle"
        },

        grid: {
            left: '30px',
            right: '10px',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                type: 'pie',
                radius: '60%',
                center: ['50%', '50%'],
                selectedMode: 'single',
                color: [
                    '#99B3AB',
                    '#41BA70',
                    '#FAA640',
                    '#5967FC',
                    '#F56867',
                ],
                data: [
                    {
                        value: 9,
                        name: '0 - 2 Hours',
                        label: {
                            formatter: ['9'].join('\n'),
                        }
                    },
                    {
                        value: 13,
                        name: '2 - 4 Hours',
                        label: {
                            formatter: ['13'].join('\n'),
                        }
                    },
                    {
                        value: 15,
                        name: '2 - 4 Hours',
                        label: {
                            formatter: ['15'].join('\n'),
                        }
                    },
                    {
                        value: 73,
                        name: '6-10 Hours',
                        label: {
                            formatter: ['73'].join('\n'),
                        }
                    },
                    {
                        value: 227,
                        name: '10+ Hours',
                        label: {
                            formatter: ['227'].join('\n'),
                        }
                    },

                ],
                emphasis: {

                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    // end  Genset Run Hours

    // Region Wise (Hours/Day column chart start
    const columnchartOptions = {
        title: {
            text: 'Region Wise (Hours/Day)',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: '(Hours/Day)',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#F55836',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13]
            },

        ]
    };

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Fuel Consumption', link: '/FuelConsumption' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/FuelConsumption',
        otherUrl: '/TeamsViewSummary',
    }



    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />



            <div className="contentWrap">
                <div className="chart_containter">
                    <div className="row pb-3">
                        <div className="col-5">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <InfrastructureWideLineChart options={lineoption} />
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <ReliabilityGradingChartPie options={piechartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">
                        <div className="col-3">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <h3 className="chartTitleHeading">Fuel Anomalies</h3>
                                    <FuelAnomalies />
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <RegionWiseColumnChart options={columnchartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <ReliabilityGradingRegionWiseHeatMap />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default FuelConsumption; 