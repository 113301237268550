
import React from "react"; 
import Logo from "../../../../assets/images/logo_white.svg"; 
import { Link } from "react-router-dom";
function DsInfrastructurePerformanceLinks() {

    return (
        <div className="welcome_main onboarding_ops_wrap">
            <div className="header_main welcome_header">
                <div className="contentWrap">
                    <div className="headerMain_inner">
                        <div className="headerMain_logo">
                            <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
                        </div>
                        <div className="headerMain_title"><span>Site On Boarding</span></div>
                    </div>
                </div>
            </div> 
        </div>
    )
}
export default DsInfrastructurePerformanceLinks