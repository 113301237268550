import React from "react";
import Logo from "../../assets/images/logo.svg";
import "./HeaderMain.css"
import ExportDropdown from "../ExportDropdown";
import YtdToFromBox from "../ytdToFrom/YtdToFromBox";
import { Link } from "react-router-dom";
function HeaderMain(props) {
    return (
        <div className="header_main">
            <div className="contentWrap">
                <div className="headerMain_inner">
                    <div className="headerMain_logo">
                    <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
                    </div>
                    <div className="headerMain_title">
                        <span>{props.pageTitle}</span>
                    </div>
                    <div className="headerMain_right">
                        <YtdToFromBox />
                        <ExportDropdown />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeaderMain;