

import React, { useState } from 'react';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import TnpRadioTabs from './TnpRadioTabs';

import ReactECharts from 'echarts-for-react';
import TnpMapView from './TnpMapView';


function TnpCO2Emissions() {
    // const pageNo = 0;
    let activeIndex = 7;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Network Performance', link: '' },
        { label: 'CO₂ Emissions', link: '' },

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpCO2Emissions',
        otherUrl: '/TeamsViewSummary',
    }

    //   Region Wise Breakdown chart
    const RegionWiseBreakdown = {
        title: {
            text: 'Power Sources Utilization',
            show: true,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },

        legend: {
            left: '0px',
            top: '24px',
            show: true,
            data: ['Good Sites', 'Bad Sites'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '90px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'Sites',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],
        series: [
            {
                name: 'Good Sites',
                type: 'bar',
                stack: 'Hours',
                color: ['#21AB4A'],
                data: [13, 12, 16, 14, 15, 17, 16, 12, 14, 15,],
            },
            {
                name: 'Bad Sites',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#D00',
                ],
                data: [14, 19, 14, 11, 10, 15, 12, 9, 10, 12],
            },
        ],
    };

    const TnpOpexPerformanceData = {
        title: {
            text: 'Avg. TCH %age',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '0px',
            left: 'center',
            show: false,
            data: ['Owned Sites', 'Shared Sites', 'OMO Share'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '20px',
            right: '0px',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2022 Actual', '2023 YTD', '2023 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Owned Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#fff",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#5D5B57',
                ],

                data: [111.6, 0, 0]

            },
            {
                name: 'Shared Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#fff",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#5D5B57',
                ],

                data: [0, 109.8, 0]

            },
            {
                name: 'OMO Share',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#fff",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#5D5B57',
                ],
                // areaStyle: {normal: {}},
                data: [0, 0, 332.6],

            },
        ],
    };

    //
    // CO₂ Emissions kTons chart
    const CO2EmissionskTons = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: '20px',
            top: '4px',
            show: true,
            data: ['Forecast', 'Actual', 'Grid', 'Genset'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 16,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '106px',
            right: '0px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'Forecast',
                type: 'bar',
                stack: 'Forecast',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#CAC8C3',
                ],

                data: [22.2, 20.2, 22.6, 22.0, 22.8, 22.0, 22.8, 22.8, 22.0, 22.9, 22.4, 23.1,]

            },
            {
                name: 'Actual',
                type: 'bar',
                stack: 'Actual',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#5D5B57',
                ],
                data: [22.2, 21.9, 20.7, 23.2, 22.7, 22.6, 22.8, 22.3, 22.4,]
            },
            {
                name: 'Grid',
                type: 'bar',
                barWidth: 5,
                stack: 'YTD',
                smooth: true,
                label: {
                    show: false
                },
                color: [
                    '#7243D0',
                ],
                // areaStyle: {normal: {}},
                data: [6, 6, 6, 7, 6, 7, 7, 7, 6,]
            },
            {
                name: 'Genset',
                type: 'bar',
                barWidth: 5,
                stack: 'YTD',
                smooth: true,
                label: {
                    show: false
                },
                color: [
                    '#32864B',
                ],
                // areaStyle: {normal: {}},
                data: [16, 16, 16, 16, 16, 16, 16, 16, 16,]

            },
        ],
        graphic: [
            {
                type: 'group',
                left: '68%',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: 'center',
                        top: 'middle',
                        shape: {
                            width: 100,
                            height: 50,
                        },
                        style: {
                            fill: '#5D5B57',
                            stroke: '#5D5B57',
                            lineWidth: 1,
                        },

                    },
                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '-28px',
                        bottom: "-40px",
                        shape: {
                            width: 1,
                            height: 15,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '28px',
                        bottom: "-39px",
                        shape: {
                            width: 1,
                            height: 13,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '24px',
                        bottom: -40,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '-0.6\n(-3%)',
                            fill: '#fff',
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: '0%', // Adjust the horizontal position
                            top: '0%', // Adjust the vertical position
                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },

                    },
                ],
            },
        ],
    };
    const CO2EmissionskTonsData = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: false,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '10px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: ['Target', '2022 YTD'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'GWh',
                position: 'left',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#5D5B57',
                ],
                data: [0, 198.9],
                label: {
                    show: true,
                    position: 'inside',
                    color: "#fff",
                    emphasis: {
                        show: 'false',
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
            {
                name: 'Target',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#CAC8C3',
                ],
                data: [267.2, 66.7],
                label: {
                    show: true,
                    position: 'inside',
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },

        ],
        graphic: [
            {
                type: 'group',
                left: 'center',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: 'center',
                        top: 'middle',
                        shape: {
                            width: 100,
                            height: 50,
                        },
                        style: {
                            fill: '#5D5B57',
                            stroke: '#5D5B57',
                            lineWidth: 1,
                        },

                    },
                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '-28px',
                        bottom: "-40px",
                        shape: {
                            width: 1,
                            height: 15,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '28px',
                        bottom: "-39px",
                        shape: {
                            width: 1,
                            height: 13,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '24px',
                        bottom: -40,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '-1.57\n(-1%)',
                            fill: '#fff',
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: '0%', // Adjust the horizontal position
                            top: '0%', // Adjust the vertical position
                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },

                    },
                ],
            },
        ],
    };

    const [dataMapStatus, setDataMapStatus] = useState(1);

    const handleDataMapClick = (newStatus) => {
        setDataMapStatus(newStatus);
    };

    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/TnpSummary' />} />

            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>

            </div>

            <div className='contentWrap'>
                <div className="exe_summary_header tnp_np_header pt-3">
                    <TnpRadioTabs activeIndex={activeIndex} />
                    <div className="border-bottom-0 pb-0">
                        <div className="amp_btn_margin float-end">
                            <div className="ampButtonsGroup">
                                <ul className="ampButtonsBox">
                                    <li><span
                                        className={`ampButton ${dataMapStatus === 1 ? 'active' : ''}`}
                                        onClick={() => handleDataMapClick(1)}
                                    >Data</span></li>
                                    <li><span  
                                        className={`ampButton ${dataMapStatus === 2 ? 'active' : ''}`}
                                        onClick={() => handleDataMapClick(2)}
                                    >Map</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                {/* data view content showing below */}
                {dataMapStatus === 1 &&
                    <div className='tnpCo2PerComp'>
                        {/* row 1 */}
                        <div className='row pb-3'>
                            <div className='col-12 col-lg-4'>
                                <div className='box_card'>
                                    <div className='tnpSiteBox'>
                                        <div className="faulty_transformers">
                                            <div className="am_faulty_transformers">
                                                <span>Total Sites</span>
                                                <h2>12056</h2>
                                            </div>
                                            <div className="feeders_status_row">
                                                <div className="feeders_status_cell">
                                                    <small>Good Sites</small>
                                                    <h4 className='color-green'>10000</h4>
                                                </div>
                                                <div className="feeders_status_cell">
                                                    <small>Bad Sites</small>
                                                    <h4 className='color-red'>2556</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid_siteBoxChart'>
                                        <ReactECharts option={RegionWiseBreakdown} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-8'>
                                <div className='box_card'>
                                    <div className='TnpPerformanceComp'>
                                        <div className="tnp_perAnalys_top">
                                            <h3>CO<sub>2</sub> Performance Comparison</h3>
                                            <div className='tnpPerformanceCompInner'>
                                                <div className="nar_chartBoxes_row">
                                                    <ul>
                                                        <li>
                                                            <div className="nar_chartBox">
                                                                <div className="nar_chartVal_type">-81.1KTons</div>
                                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                </svg></span>72.7%</div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="nar_chartBox">
                                                                <div className="nar_chartVal_type">-55.1KTons</div>
                                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                </svg></span>64.4%</div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='nar_chartTop'>
                                                    <ReactECharts option={TnpOpexPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 348 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* row 2 */}
                        <div className='row pb-3'>
                            <div className='col-12 col-lg-12'>
                                <div className='box_card'>
                                    <div className='row'>
                                        <div className="psu_headlines_row border-bottom-0 mb-0">
                                            <h3 className="chartTitleHeading  weight6 pb-0">CO<sub>2</sub> Emissions (kTons)</h3>
                                            <div className="psu_headlines_right d-flex align-items-center">
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">Average</span></li>
                                                        <li><span className="ampButton">Total</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-10'>
                                            <ReactECharts option={CO2EmissionskTons} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                        <div className='col-12 col-lg-2'>
                                            <ReactECharts option={CO2EmissionskTonsData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* to show the map view section */}
                {dataMapStatus === 2 && <TnpMapView />}

            </div>



        </div>
    )
}
export default TnpCO2Emissions;