import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function QueryBuilder() {
    const [queryGroups, setQueryGroups] = useState([
        {
            groupOperator: "And",
            conditions: [
                {
                    id: '1',
                    field: "Product",
                    operation: "Starts with",
                    value: "Value",
                    conditionOperator: "And",
                },
              
            ],
        },
        // {
        //     groupOperator: "Or",
        //     conditions: [
        //         {
        //             field: "Property",
        //             operation: "Operator",
        //             value: "Value",
        //             conditionOperator: "Or",
        //         },
        //     ],
        // },
    ]);

    const fields = ["Product", "Property", "Category", "Price"];
    const operations = ["Starts with", "Equals", "Contains", "Greater than", "Less than"];
    const groupOperators = ["And", "Or"];
    const conditionOperators = ["And", "Or"];


    const onDragEnd = (result) => {
        if (!result.destination) return;
    
        const { source, destination, draggableId } = result;
        const updatedGroups = [...queryGroups];
    
        if (draggableId.startsWith("group-")) {
            const movedGroup = updatedGroups.splice(source.index, 1)[0];
            updatedGroups.splice(destination.index, 0, movedGroup);
        } else if (draggableId.startsWith("condition-")) {
            const [sourceGroupIndex, sourceConditionIndex] = source.droppableId.split("-");
            const [destGroupIndex, destConditionIndex] = destination.droppableId.split("-");
            const sourceGroup = updatedGroups[sourceGroupIndex];
            const destGroup = updatedGroups[destGroupIndex];
    
            if (sourceGroup && destGroup) {
                const movedCondition = sourceGroup.conditions.splice(sourceConditionIndex, 1)[0];
                destGroup.conditions.splice(destConditionIndex, 0, movedCondition);
            }
        }
    
        setQueryGroups(updatedGroups);
    };
    

    const handleAddGroup = () => {
        const newGroup = {
            groupOperator: "And",
            conditions: [
                {
                    field: fields[0],
                    operation: operations[0],
                    value: "",
                    conditionOperator: "And",
                },
            ],
        };

        setQueryGroups([...queryGroups, newGroup]);
    };

    const handleAddCondition = (groupIndex, conditionIndex) => {
        const newCondition = {
            field: fields[0],
            operation: operations[0],
            value: "",
            conditionOperator: "And",
        };

        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].conditions.splice(conditionIndex, 0, newCondition);

        setQueryGroups(updatedGroups);
    };

    const handleDeleteGroup = (groupIndex) => {
        const updatedGroups = [...queryGroups];
        updatedGroups.splice(groupIndex, 1);

        setQueryGroups(updatedGroups);
    };

    const handleDeleteCondition = (groupIndex, conditionIndex) => {
        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].conditions.splice(conditionIndex, 1);

        setQueryGroups(updatedGroups);
    };

    const handleGroupOperatorChange = (groupIndex, selectedOperator) => {
        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].groupOperator = selectedOperator;

        setQueryGroups(updatedGroups);
    };

    const handleFieldChange = (groupIndex, conditionIndex, selectedField) => {
        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].conditions[conditionIndex].field = selectedField;

        setQueryGroups(updatedGroups);
    };

    const handleOperationChange = (groupIndex, conditionIndex, selectedOperation) => {
        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].conditions[conditionIndex].operation = selectedOperation;

        setQueryGroups(updatedGroups);
    };

    const handleValueChange = (groupIndex, conditionIndex, value) => {
        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].conditions[conditionIndex].value = value;

        setQueryGroups(updatedGroups);
    };

    const handleConditionOperatorChange = (groupIndex, conditionIndex, selectedConditionOperator) => {
        const updatedGroups = [...queryGroups];
        updatedGroups[groupIndex].conditions[conditionIndex].conditionOperator = selectedConditionOperator;

        setQueryGroups(updatedGroups);
    };

    return (
        <div className='table_filter box_card tfFiltersHeight'>
            <div className='tf_inner'>
                <div className='tf_heading'>
                    <strong className='label17 weight6'>Query</strong>
                </div>
                <DragDropContext onDragEnd={onDragEnd} className='tf_parent_filter'>
                    <div className="smart-scroll-viewer-content-container" role="presentation" smart-id="scrollViewerContentContainer">
                        <Droppable droppableId="groups" type="GROUP">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {queryGroups.map((group, groupIndex) => (
                                        <Draggable draggableId={`group-${groupIndex}`} index={groupIndex} key={groupIndex}>
                                            {(provided) => (
                                                <div className="smart-filter-group-row"  {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                    <div className="smart-filter-group" node-id={groupIndex}>
                                                        <div className="smart-filter-group-operator tf_dropdownBtn" role="button" aria-expanded="false" aria-haspopup="menu">
                                                            <select className="am_cp_btn"
                                                                value={group.groupOperator}
                                                                onChange={(e) => handleGroupOperatorChange(groupIndex, e.target.value)}
                                                            >
                                                                {groupOperators.map((operator, index) => (
                                                                    <option key={index} value={operator}>
                                                                        {operator}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="smart-filter-group-condition-container" role="group">
                                                            <Droppable droppableId={`conditions-${groupIndex}`} type="CONDITION">
                                                                {(provided) => (
                                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                                        {group.conditions.map((condition, conditionIndex) => (
                                                                            <Draggable
                                                                                draggableId={`condition-${groupIndex}-${conditionIndex}`}
                                                                                index={conditionIndex}
                                                                                key={conditionIndex}
                                                                            >
                                                                                {(provided) => (
                                                                                    <div className="tf_table pb-2"  {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>

                                                                                        {/* +add dropdown */}
                                                                                        <div className="filter-builder-item smart-condition-operator pb-2 tf_dropdownBtn" placeholder="">
                                                                                            <select className="am_cp_btn"
                                                                                                value={condition.conditionOperator}
                                                                                                onChange={(e) =>
                                                                                                    handleConditionOperatorChange(groupIndex, conditionIndex, e.target.value)
                                                                                                }
                                                                                            >
                                                                                                {conditionOperators.map((operator, index) => (
                                                                                                    <option key={index} value={operator}>
                                                                                                        {operator}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="smart-filter-group-condition tf_child_filter tf_row_inner" node-id={`${groupIndex}.${conditionIndex}`}>
                                                                                            <div className='tf_menu_icon'>
                                                                                                <i>
                                                                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M4.62891 12.0703H21.1289M4.62891 15.8203H21.1289M4.62891 19.5703H21.1289M6.50391 4.57031H19.2539C20.2894 4.57031 21.1289 5.40978 21.1289 6.44531C21.1289 7.48085 20.2894 8.32031 19.2539 8.32031H6.50391C5.46837 8.32031 4.62891 7.48085 4.62891 6.44531C4.62891 5.40978 5.46837 4.57031 6.50391 4.57031Z" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                </i>
                                                                                            </div>
                                                                                            <div className="filter-builder-item smart-filter-field-name" placeholder="">
                                                                                                <div className="applyFilter_box">
                                                                                                    <select
                                                                                                        value={condition.field}
                                                                                                        onChange={(e) => handleFieldChange(groupIndex, conditionIndex, e.target.value)}
                                                                                                    >
                                                                                                        {fields.map((field, index) => (
                                                                                                            <option key={index} value={field}>
                                                                                                                {field}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="filter-builder-item smart-filter-operation" placeholder="">
                                                                                                <div className="applyFilter_box">
                                                                                                    <select
                                                                                                        value={condition.operation}
                                                                                                        onChange={(e) => handleOperationChange(groupIndex, conditionIndex, e.target.value)}
                                                                                                    >
                                                                                                        {operations.map((operation, index) => (
                                                                                                            <option key={index} value={operation}>
                                                                                                                {operation}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="filter-builder-item smart-filter-value" placeholder="">
                                                                                                <div className="applyFilter_box">
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        value={condition.value}
                                                                                                        onChange={(e) => handleValueChange(groupIndex, conditionIndex, e.target.value)}
                                                                                                    />
                                                                                                </div>

                                                                                                {/* <div className="applyFilter_box">
                                                                                                        <select
                                                                                                            value={condition.operation}
                                                                                                            onChange={(e) => handleOperationChange(groupIndex, conditionIndex, e.target.value)}
                                                                                                        >
                                                                                                            {operations.map((operation, index) => (
                                                                                                                <option key={index} value={operation}>
                                                                                                                    {operation}
                                                                                                                </option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </div> */}

                                                                                            </div>
                                                                                            <div
                                                                                                className="smart-filter-delete-btn"
                                                                                                role="button"
                                                                                                aria-label="Close"
                                                                                                onClick={() => handleDeleteCondition(groupIndex, conditionIndex)}
                                                                                            >
                                                                                                <i>
                                                                                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M18.1436 21.0703H7.64355C7.24573 21.0703 6.8642 20.9123 6.58289 20.631C6.30159 20.3497 6.14355 19.9681 6.14355 19.5703V6.07031H19.6436V19.5703C19.6436 19.9681 19.4855 20.3497 19.2042 20.631C18.9229 20.9123 18.5414 21.0703 18.1436 21.0703Z" stroke="#F13534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M10.6436 16.5703V10.5703" stroke="#F13534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M15.1436 16.5703V10.5703" stroke="#F13534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M3.14355 6.07031H22.6436" stroke="#F13534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                        <path d="M15.1436 3.07031H10.6436C10.2457 3.07031 9.8642 3.22835 9.58289 3.50965C9.30159 3.79096 9.14355 4.17249 9.14355 4.57031V6.07031H16.6436V4.57031C16.6436 4.17249 16.4855 3.79096 16.2042 3.50965C15.9229 3.22835 15.5414 3.07031 15.1436 3.07031Z" stroke="#F13534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                </i>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                            <div className="smart-filter-add-condition-btn" role="button" aria-label="Add condition" limit-selection="">
                                                                <button onClick={() => handleAddCondition(groupIndex, group.conditions.length)} className="tf_addOr_btn label16 weight6 color_black bg_grey_light2">
                                                                    {/* Add Condition */}
                                                                    + Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="smart-filter-add-btn text_right"
                                                            role="button"
                                                            aria-expanded="false"
                                                            aria-haspopup="menu"
                                                            aria-label="Add group"
                                                            onClick={() => handleDeleteGroup(groupIndex)}
                                                        >
                                                            <button className="btn btn-secondary">Delete Group</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                        <div className="smart-filter-add-btn mt-3" >
                            {/* Add Group */}
                            <span className="label30 weight5 tf_plus_dropdownBtn" role="button"
                                aria-expanded="false"
                                aria-haspopup="menu"
                                aria-label="Add group"
                                onClick={handleAddGroup}> +</span>
                        </div>
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
}

export default QueryBuilder;
