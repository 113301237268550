import React from "react";
import { Link } from "react-router-dom";
function SiteSummaryBtn(props) {
 

  return (
    <Link className={`sites_summaryBtn ${props?.icon? 'summaryBtn_hasIcon' : 'summaryBtn_notIcon'} ${props?.className}`} to={props?.href} type={props?.type} onClick={props?.onClick}>
      {
        props?.icon?  <i className='summaryIcon' dangerouslySetInnerHTML={{ __html: props.icon }} ></i> : ''
      }
      <span>{props?.children}</span>
      <small className="summaryEdge symmaryEdgeLeft"></small>
      <small className="summaryEdge symmaryEdgeRight"></small>
    </Link>
  );
}

export default SiteSummaryBtn;