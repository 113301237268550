import React, { useState } from "react";
import TableSettings from "../Modals/TableSettings";
 
function AmDbAlldownloadInfo() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const toggleOffcanvas = () => {
      setShowOffcanvas(!showOffcanvas);
    };
    return (
        <>
            <div className='myAllInfoBox d-flex amDbAlldownloadInfo'>
                <div className='myAllinfoCell'>
                    <button className='label13 weight4 amDbAlldownloadInfo_btn'>
                        <i>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.7036 8.12598C12.5656 8.12598 12.4536 8.23798 12.4546 8.37598C12.4546 8.51398 12.5666 8.62598 12.7046 8.62598C12.8426 8.62598 12.9546 8.51398 12.9546 8.37598C12.9546 8.23798 12.8426 8.12598 12.7036 8.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7046 21.126V21.126C7.73359 21.126 3.70459 17.097 3.70459 12.126V12.126C3.70459 7.15498 7.73359 3.12598 12.7046 3.12598V3.12598C17.6756 3.12598 21.7046 7.15498 21.7046 12.126V12.126C21.7046 17.097 17.6756 21.126 12.7046 21.126Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.7046 12.126V17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </i>
                    </button>
                </div>
                <div className='myAllinfoCell'>
                    <button onClick={toggleOffcanvas} className='label13 weight4 amDbAlldownloadInfo_btn'>
                        <i> 
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12.3599" cy="12.1274" r="3.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.74987 12.1265C5.74987 12.4235 5.77687 12.7205 5.81287 13.0085L4.22487 14.2505C3.87287 14.5265 3.77687 15.0195 4.00087 15.4065L5.41287 17.8495C5.63587 18.2365 6.10987 18.3995 6.52487 18.2335L7.94687 17.6625C8.22787 17.5495 8.54087 17.5945 8.79287 17.7615C9.01287 17.9075 9.24087 18.0415 9.47687 18.1615C9.74687 18.2985 9.94287 18.5435 9.98587 18.8435L10.2029 20.3565C10.2659 20.7985 10.6449 21.1265 11.0909 21.1265H13.9079C14.3539 21.1265 14.7329 20.7985 14.7959 20.3565L15.0129 18.8445C15.0559 18.5445 15.2539 18.2975 15.5249 18.1615C15.7599 18.0435 15.9869 17.9105 16.2059 17.7655C16.4599 17.5975 16.7739 17.5495 17.0559 17.6635L18.4749 18.2335C18.8889 18.3995 19.3629 18.2365 19.5869 17.8495L20.9989 15.4065C21.2229 15.0195 21.1269 14.5255 20.7749 14.2505L19.1869 13.0085C19.2229 12.7205 19.2499 12.4235 19.2499 12.1265C19.2499 11.8295 19.2229 11.5325 19.1869 11.2445L20.7749 10.0025C21.1269 9.72646 21.2229 9.23346 20.9989 8.84646L19.5869 6.40346C19.3639 6.01646 18.8899 5.85346 18.4749 6.01946L17.0559 6.58946C16.7739 6.70246 16.4599 6.65546 16.2059 6.48746C15.9869 6.34246 15.7599 6.20946 15.5249 6.09146C15.2539 5.95546 15.0559 5.70846 15.0129 5.40846L14.7969 3.89646C14.7339 3.45446 14.3549 3.12646 13.9089 3.12646H11.0919C10.6459 3.12646 10.2669 3.45446 10.2039 3.89646L9.98587 5.41046C9.94287 5.70946 9.74587 5.95546 9.47687 6.09246C9.24087 6.21246 9.01287 6.34746 8.79287 6.49246C8.53987 6.65846 8.22687 6.70346 7.94587 6.59046L6.52487 6.01946C6.10987 5.85346 5.63587 6.01646 5.41287 6.40346L4.00087 8.84646C3.77687 9.23346 3.87287 9.72746 4.22487 10.0025L5.81287 11.2445C5.77687 11.5325 5.74987 11.8295 5.74987 12.1265V12.1265Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> 
                        </i>
                    </button>
                </div>
                <div className='myAllinfoCell'>
                    <button className='label13 weight4 amDbAlldownloadInfo_btn'>
                        <i>
                            <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.3711 17.126V3.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M21.3711 17.126C21.3711 19.335 19.5801 21.126 17.3711 21.126H7.37109C5.16209 21.126 3.37109 19.335 3.37109 17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.3711 12.126L12.3701 17.127L7.37012 12.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </i>
                    </button>
                </div> 
            </div>
             {/* single site side panel nav component */}
             <TableSettings showOffcanvas={showOffcanvas} toggleOffcanvas={toggleOffcanvas}/>
        </>
    );
}

export default AmDbAlldownloadInfo;