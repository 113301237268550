import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const siteDropdownArry = [
    { id: 1, label: 'Snapshot view', url: '/SitesSnapshotView' },
    // { id: 2, label: 'Infra view', url: '/SitesInfraview' },
    { id: 3, label: 'Infrastructure', url: '/Infrastructure' },
    { id: 4, label: 'Targets', url: '/Targets' },
    { id: 5, label: 'Performance', url: '/SitesPerformance' },
    { id: 6, label: 'Fuel', url: '/SitesFuel' },
    { id: 7, label: 'Insights', url: '/SitesInsights' },
    { id: 8, label: 'Site Pulse', url: '/SitePulse' },
   
    // { id: 8, label: 'Interventions', url: '/SitesInterventions' },
    // { id: 9, label: 'Impact', url: '/SitesImpact' },
    // { id: 10, label: 'Alamrs', url: '/SitesAlamrs' },
    // { id: 11, label: 'Reports', url: '/SitesReports' },
    // { id: 12, label: 'Anomalies', url: '/SitesAnomalies' },
    // { id: 13, label: 'Heartbeat View', url: '/SitesHeartbeatView' },
    // { id: 14, label: 'Playground', url: '/SitesPlayground' },
    // { id: 15, label: 'Configurations', url: '/SitesConfigurations' },
    // { id: 16, label: 'Operations', url: '/SitesOperations' },
    // { id: 17, label: 'Compliance', url: '/SitesCompliance' },
    // { id: 18, label: 'Control', url: '/SitesControl' },
    // { id: 19, label: 'Logs', url: '/SitesLogs' },


];

const siteDropdownDataArry = siteDropdownArry;
function SiteHeaderDropdown(props) {

     
    const navigate = useNavigate();
    const location = useLocation();
    const [defaultValue, setDefaultValue] = useState(null);

    if(props?.parentPath){
        location.pathname = props.parentPath;
    }
    useEffect(() => {
        const currentPath = location.pathname;
        const matchingOption = siteDropdownDataArry.find((option) => option.url === currentPath);
        if (matchingOption) {
            setDefaultValue(matchingOption);
        }
    }, [location.pathname]);

    const handleOptionSelect = (event, selectedOption) => {
        if (selectedOption) {
            const selectedUrl = selectedOption.url;
            navigate(selectedUrl);
        }
    };

    return (
        <div className="sitesHeader_dropdown">
            <Autocomplete
                disablePortal
                options={siteDropdownDataArry}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} placeholder="Sites select" />}
                onChange={handleOptionSelect}
                value={defaultValue}
                isOptionEqualToValue={(option, value) => option.url === value.url}
            />
        </div>
    )
}

 
export default SiteHeaderDropdown;