import React from "react";
import ReactECharts from 'echarts-for-react';

function SssConsumption() {
     //profile chart options data

     const data = [];
     const dataCount = 500;
     const startTime = +new Date();
     const categories = ['Mar 31'];
     const types = [
         { name: 'Mains', color: '#B798F5' },
         { name: 'DG', color: '#FEDE81' },
         { name: 'Solar', color: '#86C493' },
         { name: 'Battery', color: '#86B0FF' },
         { name: 'Wind', color: '#D2E265' },
         { name: 'Outage', color: '#9E1E1E' },
         { name: 'Down Time', color: '#F56867' },
         { name: 'Solar - DG', color: '#85AB4A' },
         { name: 'DG - Battery', color: '#855981' },
         { name: 'Mains - Battery', color: '#6069F5' },
         { name: 'Solar - Battery', color: '#468793' },
         { name: 'Solar - Mains', color: '#60758D' },
         { name: 'Undetermind', color: '#BDC4D1' },
     ];


 
 
     // Generate mock data
     categories.forEach(function (category, index) {
         let baseTime = startTime;
         for (let i = 0; i < dataCount; i++) {
             const typeItem = types[Math.round(Math.random() * (types.length - 1))];
             const duration = Math.round(Math.random() * 100000);
             data.push({
                 name: typeItem.name,
                 value: [index, baseTime, (baseTime += duration), duration],
                 itemStyle: {
                     normal: {
                         color: typeItem.color
                     }
                 }
             });
             baseTime += Math.round(Math.random() * 2000);
         }
     });
 
     const renderItem = (params, api) => {
     
         const categoryIndex = api.value(0);
         const start = api.coord([api.value(1), categoryIndex]);
         const end = api.coord([api.value(2), categoryIndex]);
         const height = api.size([0, 1])[1] * 0.6;
         const rectShape = {
             x: start[0],
             y: start[1] - height / 2,
             width: end[0] - start[0],
             height: height
         };
         return (
             rectShape && {
                 type: 'rect',
                 shape: rectShape,
                 style: api.style()
             }
         );
     };
     const option = {
         tooltip: {
             formatter: function (params) {
                 return params.marker + params.name + ': ' + params.value[3] + ' ms';
             }
         },
         title: {
             text: 'Consumption',
             show:true,
             textStyle: {
                 fontSize: 16,
                 fontWeight: '500',
                 fontFamily: 'Roboto, sans-serif',
                 color:'#000'
             },
         },
 
         legend: {
            // left: '200px',
             left: '150px',
             top: '2px',
             show: true,
             data: types.map((type) => type.name),
             fill: 'orange',
             itemHeight: 14,
             itemWidth: 14,
             type: 'scroll', // Set the type to 'scroll'
             scrollDataIndex: 0,
         },
 
 
         dataZoom: [
             {
                 type: 'slider',
                 xAxisIndex: 0, // Use this to enable horizontal 
                 filterMode: 'weakFilter',
                 showDataShadow: false,
                 showDetail: false
             },
             {
                 type: 'slider',
                 yAxisIndex: 0, // Use this to enable vertical slider
                 filterMode: 'weakFilter',
                 showDataShadow: false,
 
             },
             {
                 type: 'inside',
                 filterMode: 'weakFilter'
             }
         ],
 
         // grid: {
         //   height: 300
         // },
         grid: {
             top: '40px',
             left: '0px',
             right: '60px',
             bottom: '60px',
             containLabel: true
         },
         xAxis: {
             type: 'time',
             axisLabel: {
                 formatter: function (val) {
                     return new Date(val).toLocaleString([], {
                         hour: 'numeric',
                         minute: '2-digit',
                         hour12: true
                     });
 
                 }
             }
         },
         yAxis: {
             data: categories,
             width: 60,
             show: false,
         },
 
         series: types.map((type) => ({
             type: 'custom',
             name: type.name,
             renderItem: renderItem,
             itemStyle: {
                 // opacity: 0.8
             },
             color: [
                 type.color,
             ],
             encode: {
                 x: [1, 2],
                 y: 0
             },
             data: data.filter((item) => item.name === type.name),
         })),
     };
    return (
        <>
           
            <ReactECharts option={option} opts={{ renderer: 'svg' }} style={{ height: 200 }} />
        </>
    )
}

export default SssConsumption;