import React from 'react';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import MyAllDownloadInfo from '../../../Components/ytdToFrom/MyAllDownloadInfo';
import ReactECharts from 'echarts-for-react';

function AmSummary() {
    const breadcrumbs = [
        { label: 'Summary', link: '' },
    ];

    const getUrlFromLocalStorage = localStorage.getItem('summaryParentUrl');
    let parenturl = '/AmTransformer';

    if (getUrlFromLocalStorage !== '' && getUrlFromLocalStorage !== undefined) {
        parenturl = getUrlFromLocalStorage;
    }

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: parenturl,
        otherUrl: '/AmSummary',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">  <g clip-path="url(#clip0_4924_1097)"><path d="M22.9037 13.7827H1.77701C1.22155 13.7827 0.771271 14.233 0.771271 14.7885V23.5079C0.771271 24.0634 1.22155 24.5137 1.77701 24.5137H22.9037C23.4591 24.5137 23.9094 24.0634 23.9094 23.5079V14.7885C23.9094 14.233 23.4591 13.7827 22.9037 13.7827Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2.31381 13.7826V7.78011C2.31544 7.51236 2.42323 7.2562 2.61352 7.06784C2.80381 6.87948 3.06106 6.7743 3.3288 6.7754H11.9547C12.1119 6.77516 12.2671 6.81145 12.4079 6.8814C12.5487 6.95136 12.6713 7.05307 12.7661 7.17852L13.9857 9.08715H20.5816C20.8495 9.08633 21.1068 9.19191 21.297 9.38069C21.4871 9.56946 21.5945 9.826 21.5956 10.0939V13.7826" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9.32202 16.8003H15.3585" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  <path d="M20.2967 6.00214V4.6951C20.2951 4.42735 20.1873 4.17119 19.997 3.98283C19.8067 3.79447 19.5495 3.68929 19.2817 3.69039H13.9857L12.766 1.77764C12.6712 1.65219 12.5486 1.55048 12.4078 1.48053C12.267 1.41057 12.1119 1.37428 11.9546 1.37452H4.62757C4.49482 1.37398 4.36326 1.39959 4.24041 1.44989C4.11756 1.50019 4.00582 1.5742 3.91156 1.66769C3.81731 1.76118 3.7424 1.87231 3.6911 1.99475C3.6398 2.11719 3.61312 2.24853 3.61258 2.38129V3.45387" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  </g>  <defs> <clipPath id="clip0_4924_1097"> <rect width="24.6807" height="24.6807" fill="white" transform="translate(0 0.605469)"/> </clipPath>  </defs> svg>`
    }


    //
    // 
    const equipmentSizing_data = {
        title: {
            text: 'Equipment Sizing',
            show: true,
            left: '3',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
         // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            bottom: '0px',
            show: true,
            data: ['Over Sized', 'Right Sized', 'Under Sized', 'Asset Conflict', 'No Data', 'Off Load'],
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },

        },
        
        grid: {
            left: '10px',
            top: '50px',
            right: '0px',
            bottom: '70px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Transformer', 'Genset', 'Rectifier', 'Solar', 'Battery'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Over Sized',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#0E7EFE',
                ],

                data: [2, 7, 6, 2, 3]

            },
            {
                name: 'Right Sized',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#32864B',
                ],

                data: [2, 7, 2, 5, 4]

            },
            {
                name: 'Under Sized',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
               
                color: [
                    '#E31A1C',
                ],
                data: [5, 2, 5, 5, 1]
            },
            {
                name: 'Asset Conflict',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
               
                color: [
                    '#A972CC',
                ],
                data: [7, 2, 3.5, 3.5, 6]
            },

            {
                name: 'No Data',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
               
                color: [
                    '#905722',
                ],
                data: [2, 5, 1, 6, 5]
            },

            {
                name: 'Off Load',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
               
                color: [
                    '#303437',
                ],
                data: [5, 0, 5, 2, 4]
            },


        ],

    };

   //   fuelDistribution_data
   const fuelDistribution_data = {
    title: {
        text:'Fuel Distribution',
        show: true,
        left: '3',
        width: "100%",
        textStyle: {
            textAlign: 'center',
            fontSize: 16,
            fontWeight: '500',
            color: '#000',
            fontFamily: 'Roboto, sans-serif',
        },
    },
    tooltip: {
       trigger: 'axis'
    },
    legend: {
       bottom: 10,
       left: '0',
       //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
       type: 'scroll',
       orient: 'horizontal',
      
       selectedMode: true,
       icon: "circle",
       itemHeight: 10,
       itemWidth: 10,
       textStyle: {
           fontSize: 13,
           color: '#000',
           fontWeight: '600'
       },
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
       left: '30px',
       right: '10px',
       bottom: '0%',
       containLabel: true
    },
    xAxis: [

       {
          type: 'category',
          boundaryGap: true,
          //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
          show: false,
          splitLine: {
             show: true,
             lineStyle: {
                color: '#F0F0F0'
             }
          },
       }
    ],
    yAxis: [
       {
          type: 'value',
          splitLine: {
             show: true,
             lineStyle: {
                color: '#F0F0F0'
             }
          },
       }
    ],

    series: [
       {
          type: 'pie',
          radius: '66%',
          center: ['50%', '48%'],
          selectedMode: 'single',
          color: [
             '#0D7DFE',
             '#00D4DF',
             '#54D03C',
             '#F8C85A',
             '#383738',
          ],
          data: [
             {
                value: 227,
                name: 'NAR > 98%',
                label: {
                   formatter: ['227'].join('\n'),
                }
             },
             {
                value: 73,
                name: '96% < NAR < 98%',
                label: {
                   formatter: ['73'].join('\n'),
                }
             },
             {
                value: 15,
                name: '91% < NAR < 95%',
                label: {
                   formatter: ['15'].join('\n'),
                }
             },
             {
                value: 13,
                name: 'NAR < 90%',
                label: {
                   formatter: ['13'].join('\n'),
                }
             },
             {
                value: 9,
                name: 'No Data',
                label: {
                   formatter: ['9'].join('\n'),
                }
             },
          ],
          emphasis: {
             itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
             }
          }
       }
    ]
 };

 
   //   fuelDistribution_data
   const nARDistribution_data = {
    title: {
        text:'NAR Distribution',
        show: true,
        left: '3',
        width: "100%",
        textStyle: {
            textAlign: 'center',
            fontSize: 16,
            fontWeight: '500',
            color: '#000',
            fontFamily: 'Roboto, sans-serif',
        },
    },
    tooltip: {
       trigger: 'axis'
    },
    legend: {
       bottom: 10,
       left: '0',
       //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
       type: 'scroll',
       orient: 'horizontal',
        selectedMode: true,
       icon: "circle",
       itemHeight: 10,
       itemWidth: 10,
       textStyle: {
           fontSize: 13,
           color: '#000',
           fontWeight: '600'
       },
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
       left: '30px',
       right: '10px',
       bottom: '0%',
       containLabel: true
    },
    xAxis: [

       {
          type: 'category',
          boundaryGap: true,
          //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
          show: false,
          splitLine: {
             show: true,
             lineStyle: {
                color: '#F0F0F0'
             }
          },
       }
    ],
    yAxis: [
       {
          type: 'value',
          splitLine: {
             show: true,
             lineStyle: {
                color: '#F0F0F0'
             }
          },
       }
    ],

    series: [
       {
          type: 'pie',
          radius: '66%',
          center: ['50%', '48%'],
          selectedMode: 'single',
          color: [
             '#0D7DFE',
             '#00D4DF',
             '#54D03C',
             '#F8C85A',
             '#383738',
          ],
          data: [
             {
                value: 227,
                name: '0 Fuel',
                label: {
                   formatter: ['227'].join('\n'),
                }
             },
             {
                value: 73,
                name: '1 < Fuel < 600',
                label: {
                   formatter: ['73'].join('\n'),
                }
             },
             {
                value: 15,
                name: '601 < Fuel < 1200',
                label: {
                   formatter: ['15'].join('\n'),
                }
             },
             {
                value: 13,
                name: 'Fuel > 1200',
                label: {
                   formatter: ['13'].join('\n'),
                }
             },
             {
                value: 9,
                name: 'No Data',
                label: {
                   formatter: ['9'].join('\n'),
                }
             },
          ],
          emphasis: {
             itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
             }
          }
       }
    ]
 };

    return (
        <div className="assetManagement_wrapper ams_wrapMain">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={false} isSummaryButton />
            {/* <ApplyFilterHeader />  */}
            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div>
                <div className='col-12 col-lg-3'>
                    <MyAllDownloadInfo />
                </div>
            </div>

            <div className='contentWrap'>
                <div className='row'>
                    <div className='col-12 pb-3'>
                        <div className="box_card has_info_icon h-100">
                            {/* <div className="box_info_flexible">
                                <h3 className='chartTitleHeading  pb-0 pt-0'>
                                Equipment Sizing
                                </h3>
                               
                                <div className='box_info'>
                                    <i className='box_info_icon'></i>
                                </div>
                            </div> */}
                            <div className="ams_equipmentSizing_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                        <ReactECharts option={equipmentSizing_data} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6 pb-3'>
                        <div className="box_card has_info_icon h-100">
                            {/* <div className="box_info_flexible">
                                <h3 className='chartTitleHeading  pb-0 pt-0'>
                                Equipment Sizing
                                </h3>
                               
                                <div className='box_info'>
                                    <i className='box_info_icon'></i>
                                </div>
                            </div> */}
                            <div className="ams_equipmentSizing_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                        <ReactECharts option={fuelDistribution_data} opts={{ renderer: 'svg' }} style={{ height:380 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 pb-3'>
                        <div className="box_card has_info_icon h-100">
                            <div className="ams_equipmentSizing_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                        <ReactECharts option={nARDistribution_data} opts={{ renderer: 'svg' }} style={{ height: 380 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default AmSummary;