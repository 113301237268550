import React from 'react';

const energyPlanningMenu = [
    {
      "id": 1,
      "title": "Targets Input",
      "path": 'AmEnergyPlanning',
      "value": "",
      "valueType": "",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M13 19H6C4.34315 19 3 17.6569 3 16V8C3 6.34315 4.34315 5 6 5H13" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M9 10L11 12L9 14" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 19H18C19.6569 19 21 17.6569 21 16V8C21 6.34315 19.6569 5 18 5H16" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 2V22" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    },
    {
      "id": 2,
      "title": "Targets Output",
      "path": 'EpTargetsOutput',
      "value": "",
      "valueType": "",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M11 5L18 5C19.6569 5 21 6.34315 21 8L21 16C21 17.6569 19.6569 19 18 19L11 19" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 14L13 12L15 10" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 5L6 5C4.34315 5 3 6.34315 3 8L3 16C3 17.6569 4.34315 19 6 19L8 19" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 22L8 2" stroke="#131325" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    },

 
 
  ];
function EnergyPlanningMenu() {
    return(
        <> 
        </>
    )
}

export { energyPlanningMenu, EnergyPlanningMenu }; 