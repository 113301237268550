import React from 'react';
import "./AmpButtonsGroup.css";
const AmpButtonsGroup = ({ array, handleItemClick }) => {
    return (
        <div className="ampButtonsGroup">
            <ul className="ampButtonsBox">
                {array.map((item) => ( 
                    <li
                        key={item.id}
                     >
                        <span  className={`ampButton ${item.active ? 'active' : ''}`}
                        onClick={() => handleItemClick(item.id)}>
                            {item.name}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default AmpButtonsGroup;