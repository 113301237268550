
import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SobTabsHeader from "../SobTabsHeader/SobTabsHeader";
import { sob_moremenu } from "../SobTabsHeader/SobMoreMenuLinks";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Link } from "react-router-dom";




function SobTechParameters() {
    const pageNo = 2;
    const breadcrumbs = [
        { label: 'Site On Boarding', link: '' },
        { label: 'Add Existing Site', link: '/SitesOnboarding' },
        { label: 'AMKM - 03', link: '' },
    ];

    // const summaryObj = {
    //     minimize: 'Minimise',
    //     otherName: "Summary",
    //     minUrl: '/SitePulse',
    //     otherUrl: '/SingleSiteSummary',
    // }


    return (
        <div className="sobSteps_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} />

            <SobTabsHeader pageNo={pageNo} sob_moremenu={sob_moremenu} />

            <div className="sobSteps_content_main">

                <div className="sobGeneralInfo pb-1">
                    <div className="sobSteps_content contentWrap">
                        <div className="row pt-3">
                            <div className="col-12 col-lg-8 box_card">
                                <div className="sobGeneralInfo_inner">
                                    <div className="sob_heading">
                                        <h2> Tech Parameters</h2>
                                    </div>
                                    <div className="row">
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>E-Band<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>i1000<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select label="Select an option">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>i200<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>i400<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>VR10<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>VR4<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ML 6693<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ML 6694<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ML 6654<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ML 6692<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXCTN 6150<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXCTN 6180H<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXR10 M6000-2S6<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXCTN 9000-2E4<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXCTN 9000-3E<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXCTN 9000-5E<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>NX41-21 Shelf<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXONE 9700 S1<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXONE 9700 S3<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ZXONE 9700 S6<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="row ps-1">
                                    <div className="col-12 col-lg-12">
                                        <div className="sobGeneralInfo_inner bg_gray_dark5">
                                            <div className="sob_heading">
                                                <h2> Tech Parameters </h2>
                                            </div>
                                            <div className="sob_summary_data">
                                                <table className="table st_summary_list mb-2">
                                                    <tbody>
                                                        <tr>
                                                            <th>E-Band</th>
                                                            <th>GST</th>
                                                        </tr>
                                                        <tr>
                                                            <td>i1000</td>
                                                            <td>Deposit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>i200</td>
                                                            <td>Ballon / RV</td>
                                                        </tr>
                                                        <tr>
                                                            <td>i400</td>
                                                            <td>Term</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VR10</td>
                                                            <td>Payment Structure</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VR4</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ML 6693</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ML 6694</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ML 6654</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ML 6692</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ZXCTN 6150</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ZXCTN 6180H</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ZXR10 M6000-2S6</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ZXCTN 9000-2E4</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ZXCTN 9000-3E</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tf_next_reset_btn pt-3 contentWrap">
                        <Link to={'/OnboardingSearch'} className="btn btn-secondary me-2">Cancel</Link>
                        <Link to={'/SobSitesInfra'} className="btn btn-secondary me-2">Back</Link>
                        <Link to={'/SobSiteLoad'} className="btn btn-dark">Next</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SobTechParameters;