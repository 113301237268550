import React, { useState } from 'react';
import "./Login.css";
import Logo from "../../assets/images/login_logo.svg";
import { Link } from "react-router-dom";
 import { useNavigate } from 'react-router-dom';

const Login = ({ history }) => {
    const [username, setUsername] = useState('info@thunder.com');
    const [password, setPassword] = useState('123456');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Perform validation
        if (!username || !password) {
            setError('Please enter a username and password.');
        } else if (!validateEmail(username)) {
            setError('Please enter a valid email address for the username.');
        } else {
            // Valid login, perform further actions (e.g., API call)
            setError('');
            console.log('Valid login:', username, password);
            // Create the request body
            // const requestBody = {
            //     email: username,
            //     password: password
            // };

          

             navigate('/Welcome'); 
           
        }
    };



    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className="login_body">
            <div className="login_main">
                <div className="login_left">
                </div>
                <div className="login_right">
                    <div className="login_section">
                        <div className="login_section_inner">
                            <div className="login_logo">
                                <figure><img src={Logo} alt="#"></img></figure>
                            </div>
                            <div className="login_form_main">
                                <h5>Sign in to <br></br> Thunder System</h5>
                                <form onSubmit={handleSubmit}>
                                    <div className="login_form">
                                        <div className="login_field">
                                            <input
                                                type="text"
                                                placeholder=" "
                                                id="username"
                                                value={username}
                                                onChange={handleUsernameChange}
                                                className={error && !validateEmail(username) ? 'error' : 'check_mark' && validateEmail(username) ? 'check_mark' : ''}
                                            ></input>
                                            <label className="floating_label_login" htmlFor="EMAIL">EMAIL</label>
                                        </div>
                                        <div className="login_field">
                                            <input
                                                placeholder=" "
                                                type="password"
                                                id="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                className={error && password === '' ? 'error' : ''}
                                            />
                                            <label className="floating_label_login" htmlFor="PASSWORD">PASSWORD</label>
                                        </div>
                                        {error && <div className="error_text">{error}</div>}
                                        <div className="forget">
                                            <Link className="loginLink">Forgot Password?</Link>
                                        </div>
                                        <div className="login_btn_main">
                                            <input className="login_btn" type="submit" value="Sign in"></input>
                                        </div>
                                        <div className="sign_up">
                                            <p>Not a member?</p>
                                            <p><Link className="loginLink">Sign up now</Link></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;