import React from "react";
import ReactECharts from 'echarts-for-react';

function PowerSourceUtilizationCustom22() {
    // Custom JSON data
    const data = [
        { name: 'JS Heap', value: [0, 123456789, 123458000, 1211], itemStyle: { normal: { color: '#7b9ce1' } } },
        { name: 'Documents', value: [1, 123457789, 123460000, 2211], itemStyle: { normal: { color: '#bd6d6c' } } },
        { name: 'Nodes', value: [2, 123459789, 123462000, 3211], itemStyle: { normal: { color: '#75d874' } } },
        { name: 'Listeners', value: [0, 123461789, 123465000, 4011], itemStyle: { normal: { color: '#e0bc78' } } },
        { name: 'GPU Memory', value: [1, 123463789, 123467000, 3400], itemStyle: { normal: { color: '#dc77dc' } } },
        { name: 'GPU', value: [2, 123465789, 123469000, 2800], itemStyle: { normal: { color: '#72b362' } } },
        
    ];

    function renderItem(params, api) {
       
        var categoryIndex = api.value(0);
        var start = api.coord([api.value(1), categoryIndex]);
        var end = api.coord([api.value(2), categoryIndex]);
        var height = api.size([0, 1])[1] * 0.6;
      
        const rectShape = {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height,
        };
      
        return (
          rectShape && {
            type: 'rect',
            shape: rectShape,
            style: api.style(),
          }
        );
    }

    var option = {
        tooltip: {
            formatter: function (params) {
                return params.marker + params.name + ': ' + params.value[3] + ' ms';
            },
        },
        title: {
            text: 'Profile',
            left: 'center',
        },
        dataZoom: [
            {
                type: 'slider',
                filterMode: 'weakFilter',
                showDataShadow: false,
                top: 400,
                labelFormatter: '',
            },
            {
                type: 'inside',
                filterMode: 'weakFilter',
            },
        ],
        grid: {
            height: 300,
        },
        xAxis: {
            min: 123456789,
            scale: true,
            axisLabel: {
                formatter: function (val) {
                    return Math.max(0, val - 123456789) + ' ms';
                },
            },
        },
        yAxis: {
            data: ['categoryA', 'categoryB', 'categoryC'],
        },
        series: [
            {
                type: 'custom',
                renderItem: renderItem,
                itemStyle: {
                    opacity: 0.8,
                },
                encode: {
                    x: [1, 2],
                    y: 0,
                },
                data: data,
            },
        ],
    };


    return (
        <>
            <div className="psu_headlines_row">
                <h3 className="chartTitleHeading pb-0">Power Source Utilization</h3>
                <div className="psu_headlines_right">
                    <div className="psu_selectbox">
                        <select>
                            <option value='1'>7 days</option>
                            <option value='2'>Last week</option>
                            <option value='3'>Last Mont</option>
                        </select>
                    </div>
                </div>
            </div>
            <ReactECharts option={option} opts={{ renderer: 'svg' }} style={{ height: 380 }} />
        </>
    )
}

export default PowerSourceUtilizationCustom22;