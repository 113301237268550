
import React, { useState } from 'react';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import TnpRadioTabs from './TnpRadioTabs';
import ReactECharts from 'echarts-for-react';
import { Link } from 'react-router-dom';
import TnpNarParMapView from './TnpNarParMapView';


function TnpNar() {
    // const pageNo = 0;
    let activeIndex = 6;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Network Performance', link: '' },
        { label: 'Nar', link: '' },

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpNar',
        otherUrl: '/TeamsViewSummary',
    }

    //   Region Wise Breakdown chart
    const RegionWiseBreakdown = {
        title: {
            text: 'Power Sources Utilization',
            show: true,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },

        legend: {
            left: '0px',
            top: '24px',
            show: true,
            data: ['Good Sites', 'Bad Sites'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '90px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'Sites',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],
        series: [
            {
                name: 'Good Sites',
                type: 'bar',
                stack: 'Hours',
                color: ['#21AB4A'],
                data: [13, 12, 16, 14, 15, 17, 16, 12, 14, 15,],
            },
            {
                name: 'Bad Sites',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#D00',
                ],
                data: [14, 19, 14, 11, 10, 15, 12, 9, 10, 12],
            },
        ],
    };

    const TnpNarPerformanceData = {
        title: {
            text: 'Avg. TCH %age',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: '0px',
            left: 'center',
            show: false,
            data: ['Owned Sites', 'Shared Sites', 'OMO Share'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '20px',
            right: '0px',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2022 Actual', '2023 YTD', '2023 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Owned Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#fff",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#B35A3E',
                ],

                data: [91.8, 0, 0]

            },
            {
                name: 'Shared Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#fff",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#B35A3E',
                ],

                data: [0, 91.4, 0]

            },
            {
                name: 'OMO Share',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#fff",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                color: [
                    '#CC9B8B',
                ],
                // areaStyle: {normal: {}},
                data: [0, 0, 94.8],

            },
        ],
    };

    //
    // NAR percentage  chart
    const NarPercentage = {
        title: {
            text: 'NAR, PAR & TCH (%)',
            show: true,
            textStyle: {
                fontSize: 20,
                fontWeight: '500',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: '0px',
            top: '30px',
            show: true,
            data: ['NAR Forecast', 'NAR Actual', 'PAR Forecast', 'PAR Actual', 'TCH Forecast', 'TCH Actual'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 16,
                color: '#000',
                fontWeight: '700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '100px',
            right: '0px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                show: true,
                name: '%',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'NAR Forecast',
                type: 'bar',
                stack: 'NAR Forecast',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#F8F8F8'
                },
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#CC9B8B',
                ],

                data: [52.2, 54, 50, 50, 52.8, 52.0, 48, 48, 50, 49, 48, 52,]

            },
            {
                name: 'NAR Actual',
                type: 'bar',
                stack: 'NAR Actual',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#F8F8F8'
                },
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#B35A3E',
                ],
                data: [52.2, 54, 50, 50, 52.8, 52.0, 48, 48, 50, 49, 48, 52,]
            },
            {
                name: 'PAR Forecast',
                type: 'bar',
                stack: 'PAR Forecast',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#F8F8F8'
                },
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#D5B5D6',
                ],
                data: [52.2, 54, 50, 50, 52.8, 52.0, 48, 48, 50, 49, 48, 52,]
            },
            {
                name: 'PAR Actual',
                type: 'bar',
                stack: 'PAR Actual',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#F8F8F8'
                },
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#A472B4',
                ],
                data: [52.2, 54, 50, 50, 52.8, 52.0, 48, 48, 50, 49, 48, 52,]
            },
            {
                name: 'TCH Forecast',
                type: 'bar',
                stack: 'TCH Forecast',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#F8F8F8'
                },
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#ADC1DA',
                ],
                data: [52.2, 54, 50, 50, 52.8, 52.0, 48, 48, 50, 49, 48, 52,]
            },
            {
                name: 'TCH Actual',
                type: 'bar',
                stack: 'TCH Actual',
                smooth: true,
                showBackground: true,
                backgroundStyle: {
                    color: '#F8F8F8'
                },
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#647EA3',
                ],
                data: [52.2, 54, 50, 50, 52.8, 52.0, 48, 48, 50, 49, 48, 52,]
            },
        ],
    };


    const NarPerData = {
        title: {
            text: 'Energy Consumption(GWh)',
            show: false,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0px',
            top: '0px',
            show: false,
            data: ['Actual', 'Target'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '10px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: ['Target', '2022 YTD'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'GWh',
                position: 'left',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'NAR',
                type: 'bar',
                stack: 'stack1',
                smooth: true,
                color: [
                    '#CC9B8B',
                ],
                data: [160, 160],
                label: {
                    show: false,
                    position: 'inside',
                    color: "#fff",
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
            {
                name: 'PAR',
                type: 'bar',
                stack: 'stack2',
                smooth: true,
                color: [
                    '#D5B5D6',
                ],
                data: [160, 160],
                label: {
                    show: false,
                    position: 'inside',
                    color: "#000",
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
            {
                name: 'TCH',
                type: 'bar',
                stack: 'stack3',
                smooth: true,
                color: [
                    '#ADC1DA',
                ],
                data: [160, 160],
                label: {
                    show: false,
                    position: 'inside',
                    color: "#000",
                    emphasis: {
                        show: false,
                        label: {
                            color: "#000",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
        ],
        graphic: [
            {
                type: 'group',
                left: 'center',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: '0',
                        top: 'middle',
                        shape: {
                            width: 50,
                            height: 50,
                        },
                        style: {
                            fill: '#B35A3E',
                            stroke: '#B35A3E',
                            lineWidth: 1,
                        },

                    },
                    // {
                    //     type: 'rect',
                    //     z: 100,
                    //     left: '50',
                    //     top: 'middle',
                    //     shape: {
                    //         width: 50,
                    //         height: 50,
                    //     },
                    //     style: {
                    //         fill: '#A472B4',
                    //         stroke: '#A472B4',
                    //         lineWidth: 1,
                    //     },

                    // },
                    // {
                    //     type: 'rect',
                    //     z: 100,
                    //     left: '100',
                    //     top: 'middle',
                    //     shape: {
                    //         width: 50,
                    //         height: 50,
                    //     },
                    //     style: {
                    //         fill: '#647EA3',
                    //         stroke: '#647EA3',
                    //         lineWidth: 1,
                    //     },

                    // },

                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '26px',
                        bottom: "-40px",
                        shape: {
                            width: 1,
                            height: 15,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '126px',
                        bottom: "-39px",
                        shape: {
                            width: 1,
                            height: 13,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '122px',
                        bottom: -40,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    // {
                    //     type: 'text',
                    //     z: 101,
                    //     style: {
                    //         text: '-1.57\n(-1%)',
                    //         fill: '#fff',
                    //         fontSize: 11,
                    //         fontWeight: '400',
                    //         fontFamily: 'Roboto, sans-serif',
                    //         left: 100,  // Note: Removed quotes
                    //         top: 10,    // Note: Removed quotes
                    //         position: 'absolute',  // Add position property
                    //         textAlign: 'center',
                    //         textVerticalAlign: 'middle',
                    //     },

                    // },
                    {
                        type: 'text',
                        z: 101,
                        left: '12',
                        style: {
                            text: '-1.57\n(-1%)',
                            fill: '#fff',
                            fontSize: 11,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',

                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },

                    },
                ],
            },
            {
                type: 'group',
                left: '98',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: '0',
                        top: 'middle',
                        shape: {
                            width: 50,
                            height: 50,
                        },
                        style: {
                            fill: '#A472B4',
                            stroke: '#A472B4',
                            lineWidth: 1,
                        },
                    },
                    {
                        type: 'text',
                        z: 101,
                        left: '10', // Adjust as needed based on your layout
                        top: 'middle', // Adjust as needed based on your layout
                        style: {
                            text: '-1.57\n(-1%)',
                            fill: '#fff',
                            fontSize: 11,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',

                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },
                    },
                ],
            },
            {
                type: 'group',
                left: '148',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: '0',
                        top: 'middle',
                        shape: {
                            width: 50,
                            height: 50,
                        },
                        style: {
                            fill: '#647EA3',
                            stroke: '#647EA3',
                            lineWidth: 1,
                        },
                    },
                    {
                        type: 'text',
                        z: 101,
                        left: '10', // Adjust as needed based on your layout
                        top: 'middle', // Adjust as needed based on your layout
                        style: {
                            text: '-1.57\n(-1%)',
                            fill: '#fff',
                            fontSize: 11,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',

                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },
                    },
                ],
            }

        ],
    };


    const [dataMapStatus, setDataMapStatus] = useState(1);

    const handleDataMapClick = (newStatus) => {
        setDataMapStatus(newStatus);
    };



    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/TnpSummary' />} />

            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>

            </div>

            <div className='contentWrap'>
                <div className="exe_summary_header  tnp_np_header pt-3">
                    <TnpRadioTabs activeIndex={activeIndex} />
                    <div className="border-bottom-0 pb-0">
                        <div className="amp_btn_margin float-end">
                            <div className="ampButtonsGroup">
                                <ul className="ampButtonsBox">
                                    <li><span
                                        className={`ampButton ${dataMapStatus === 1 ? 'active' : ''}`}
                                        onClick={() => handleDataMapClick(1)}
                                    >Data</span></li>
                                    <li><span 
                                        className={`ampButton ${dataMapStatus === 2 ? 'active' : ''}`}
                                        onClick={() => handleDataMapClick(2)}
                                    >Map</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* data view content showing below */}
                {dataMapStatus === 1 &&
                    <div className='tnpNar'>
                        {/* row 1 */}
                        <div className='row pb-3'>
                            <div className='col-12 col-lg-4'>
                                <div className='box_card'>
                                    <div className='tnpSiteBox'>
                                        <div className="faulty_transformers">
                                            <div className="am_faulty_transformers">
                                                <span>Total Sites</span>
                                                <h2>12056</h2>
                                            </div>
                                            <div className="feeders_status_row">
                                                <div className="feeders_status_cell narParSites">
                                                    <small>Good Sites</small>
                                                    <div className='feaders_value_Box'>
                                                        <ul>
                                                            <li>
                                                                <div className='feaders_value_Box_inner'>
                                                                    <span>NAR</span>
                                                                    <h4 className='color-green'>3000</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='feaders_value_Box_inner'>
                                                                    <span>PAR</span>
                                                                    <h4 className='color-green'>5000</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='feaders_value_Box_inner'>
                                                                    <span>TCH</span>
                                                                    <h4 className='color-green'>2000</h4>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="feeders_status_cell narParSites">
                                                    <small>Bad Sites</small>
                                                    <div className='feaders_value_Box'>
                                                        <ul>
                                                            <li>
                                                                <div className='feaders_value_Box_inner'>
                                                                    <span>NAR</span>
                                                                    <h4 className='color-red'>1250</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='feaders_value_Box_inner'>
                                                                    <span>PAR</span>
                                                                    <h4 className='color-red'>500</h4>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='feaders_value_Box_inner'>
                                                                    <span>TCH</span>
                                                                    <h4 className='color-red'>806</h4>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='grid_siteBoxChart'>
                                        <div className="border-bottom-0 pb-0">
                                            <div className="amp_btn_margin float-end">
                                                <div className="ampButtonsGroup">
                                                    <ul className="ampButtonsBox">
                                                        <li><span className="ampButton active">NAR</span></li>
                                                        <li><span className="ampButton ">PAR</span></li>
                                                        <li><span className="ampButton ">TCH</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <ReactECharts option={RegionWiseBreakdown} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-lg-8'>
                                <div className='box_card'>
                                    <div className='TnpPerformanceComp'>
                                        <div className="tnp_perAnalys_top">
                                            <div className='tnpNarParChartHeading'>
                                                <h3>NAR Performance Comparison</h3>
                                                <div className="amp_btn_margin">
                                                    <div className="ampButtonsGroup">
                                                        <ul className="ampButtonsBox">
                                                            <li><Link to={'/TnpNar'} className="ampButton active">NAR</Link>
                                                            </li>
                                                            <li><Link to={'/TnpPar'} className="ampButton">PAR</Link>
                                                            </li>
                                                            <li><Link to={'/TnpTch'} className="ampButton">TCH</Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpPerformanceCompInner'>
                                                <div className="nar_chartBoxes_row">
                                                    <ul>
                                                        <li>
                                                            <div className="nar_chartBox">
                                                                <div className="nar_chartVal_type">-0.4</div>
                                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                </svg></span>0.4%</div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="nar_chartBox">
                                                                <div className="nar_chartVal_type">-3.4</div>
                                                                <div className="nar_chartVal_percent"><span className="nar_chartVal_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                                                                    <path d="M2.35191 3.23206C2.21371 3.42355 1.92858 3.42355 1.79038 3.23206L0.218337 1.05377C0.0530753 0.824777 0.216701 0.504903 0.4991 0.504903H3.64319C3.92558 0.504903 4.08921 0.824777 3.92395 1.05377L2.35191 3.23206Z" fill="#B50000" />
                                                                </svg></span>3.7%</div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='nar_chartTop'>
                                                    <ReactECharts option={TnpNarPerformanceData} opts={{ renderer: 'svg' }} style={{ height: 362 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* row 2 */}
                        <div className='row pb-3'>
                            <div className='col-12 col-lg-12'>
                                <div className='box_card'>
                                    <div className='tnp_narpar_3boxe_row'>
                                        <div className='tnp_narpar_3boxe_col1'>
                                            <ReactECharts option={NarPercentage} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                        </div>
                                        <div className='tnp_narpar_3boxe_col2'>
                                            <div className='tnp_narpar_3boxes_chart'>
                                                <ReactECharts option={NarPerData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* to show the map view section */}
                {dataMapStatus === 2 && <TnpNarParMapView />}
            </div>
        </div>
    )
}
export default TnpNar;