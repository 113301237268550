
import React from "react";
import Logo from "../../../../assets/images/logo_white.svg";
import { Link } from "react-router-dom";
function DsCommercialSavingsLinks() {

    const CommercialSavingLinks = [
        {
            id: 0,
            title: "Grid Peak Hours Utilization (Overall)",
            description: "Peak Hours Grid Utilization",
            linkText: "View Data Story",
            url: "/CsGridPeakHrsPolicy"
        },
        {
            id: 1,
            title: "Grid Peak Hours Utilization (Overall)",
            description: "Peak Hours Grid Utilization",
            linkText: "View Data Story",
            url: "/CsGridPeakHrsBeforePolicy"
        },
        {
            id: 2,
            title: "Grid Peak Hours Utilization (Overall)",
            description: "Peak Hours Grid Utilization",
            linkText: "View Data Story",
            url: "/CsGridPeakHrsDuringPolicy"
        },
        {
            id: 3,
            title: "Grid Peak Hours Utilization (Overall)",
            description: "Peak Hours Grid Utilization",
            linkText: "View Data Story",
            url: "/CsGridPeakHrsAfterPolicy"
        },
        {
            id: 4,
            title: "Genset Run Hours Optimization (Fuel Savings)",
            description: "Peak Hours Grid Utilization",
            linkText: "View Data Story",
            url: "/DsGensetRunHours"
        },
        {
            id: 5,
            title: "Solar Potential Maximization",
            description: "Peak Hours Grid Utilization",
            linkText: "View Data Story",
            url: "/DsSolarPotentialMaximization"
        }
    ]

    return (
        <div className="welcome_main onboarding_ops_wrap">
            <div className="header_main welcome_header">
                <div className="contentWrap">
                    <div className="headerMain_inner">
                        <div className="headerMain_logo">
                            <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
                        </div>
                        <div className="headerMain_title"><span>Site On Boarding</span></div>
                    </div>
                </div>
            </div>

            <div className="dsComSavingLinks_main">
                <div className="contentWrap">
                    <div className="dsComSavingLinks_inner">
                        <div className="row">
                            {
                                CommercialSavingLinks.map((item) => {
                                    return (
                                        <div key={item.id} className="col-12 col-lg-3 pb-3">
                                            <div className="comSavingLinksBox">
                                                <div className="comSavingContent">
                                                    <h4>{item.title}</h4>
                                                    <p>{item.description}</p>
                                                    <Link to={item.url} className="comSavingContentBtn">{item.linkText}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            } 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default DsCommercialSavingsLinks