import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SiteHeaderDropdown from "../SiteHeaderDropdown";
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import { performanceMenu } from "./SpPerformanceMenu";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";

function SitesPerformanceGenset() {
    const pageNo = 3;
    const breadcrumbs = [
        { label: 'Sites', link: '' },
        { label: 'Performance', link: '/SitesPerformance' },
        { label: 'Genset', link: '/SitesPerformanceGenset' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SitesPerformanceGenset',
        otherUrl: '/SingleSiteSummary',
    }

 
    return (
        <div className="sitePerformance_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb='false'  isSummaryButton isSiteDropdown={<SiteHeaderDropdown parentPath='/SitesPerformance' />} /> 
            <div className="pb-2">
                <ApplyFilterHeader />
            </div>
            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'>
                <AmTabsHeader pageNo={pageNo} moremenu={performanceMenu} />    
                </div>
            </div>
            <div className="sitesInfra_content contentWrap">
            Performance Genset content goes here...
            </div>
        </div>
    )
}
export default SitesPerformanceGenset;