import React, { useState } from 'react';
import AmpButtonsGroup from '../AmpButtonsGroup/AmpButtonsGroup';
import ReliabilityGradingChartPie from './ReliabilityGradingChartPie';


const WarrantyClaimsPie = () => {
    const dataRaised =[
        {
            value: 250,
            name: 'Claimed',
            label: {
                formatter: ['250'].join('\n'),
            }
        },
        {
            value: 3382,
            name: 'Pending',
            label: {
                formatter: ['3382'].join('\n'),
            }
        },
    ];
    const dataAging = [
        {
            value: 550,
            name: 'Claimed',
            label: {
                formatter: ['550'].join('\n'),
            }
        },
        {
            value: 1202,
            name: 'Pending',
            label: {
                formatter: ['1202'].join('\n'),
            }
        },
    ];

    const [chartData, setChartData] = useState(dataRaised);

    const piechartOptions = {
        title: {
            text: 'Reliability Grading',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: 10,
            left: 'center',
            //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle"
        },

        grid: {
            left: '30px',
            right: '10px',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                type: 'pie',
                radius: '60%',
                center: ['50%', '50%'],
                selectedMode: 'single',
                color: [
                    '#41BA70',
                    '#99B3AB',
                    
                ],
                data:chartData,
                emphasis: {

                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    const btnArray = [
        { id: 1, name: 'Raised', active: true },
        { id: 2, name: 'Aging', active: false },
    ];
    const [array, setArray] = useState(btnArray);
    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
        console.log('array', id)
        if (id === 1) {
            setChartData(dataRaised);
        }
        if (id === 2) {
            setChartData(dataAging);
        }

    };

    return (
        <>
            <div className="amp_btn_margin">
                <AmpButtonsGroup array={array} handleItemClick={handleItemClick} />
            </div>
            <ReliabilityGradingChartPie options={piechartOptions} />
        </>
    )
}

export default WarrantyClaimsPie;