import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Popover,
    List,
    Pagination,
    ListItem,
    Checkbox,
} from '@mui/material';
import { Button, Form } from 'react-bootstrap';
import AddFuelDetails from '../../Modals/AddFuelDetails';
import SpHeader from '../../Components/mainHeader/SpHeader';
import ApplyFilterHeader from '../../Components/ApplyFilterHeader';
import AmTabsHeader from '../../Components/TopTabsHeader/AmTabsHeader';
import MyAllDownloadInfo from '../../Components/ytdToFrom/MyAllDownloadInfo';



const SiteFueling = () => {
    
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(10); // Ini

    const [rows, setRows] = useState([
        { id: 1, Date: '12 August 2023', VisitType: 'Emergency', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'Standly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 2, Date: '14 August 2023', VisitType: 'Urgent', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'btandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 3, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 4, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 5, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 6, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 7, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 8, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 9, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 10, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 11, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 12, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 21, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 13, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 14, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 15, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 16, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 17, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 18, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 19, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 20, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },


        { id: 33, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 22, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 23, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 24, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 25, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 26, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },


        { id: 27, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 28, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 29, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 30, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 31, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 32, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '300' },


        { id: 34, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 35, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 36, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 37, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 38, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '0312345678' },
        { id: 39, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '300' },
        { id: 40, Date: '13 August 2023', VisitType: 'Normal', FuelPoured: 'Doe', FuelingReason: 'Prolong CP Shutdown', GridStatus: 'ztandly', FuelOpeningStock: '0', FuelerContactNumber: '300' },

    ]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleFilterIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen(!filterOpen);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value;

        if (selectedFilters.includes(value)) {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
        } else {
            setSelectedFilters([...selectedFilters, value]);
        }
    };

    const applyFilters = () => {
        // Apply filters based on selectedFilters
        // You can implement your custom filtering logic here

        // For demonstration purposes, we'll just log the selected filters
        console.log('Selected Filters:', selectedFilters);

        // Close the filter dropdown
        setFilterOpen(false);
    };


    const handleSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        const newOrder = isAscending ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        const sortedRows = [...rows].sort((a, b) => {
            const aValue = a[property];
            const bValue = b[property];
            if (newOrder === 'asc') {
                return aValue < bValue ? -1 : 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        });

        setRows(sortedRows);
    };

    const columns = [
        { field: 'Date', headerName: 'Date', flex: 1 },
        { field: 'VisitType', headerName: 'Visit Type', flex: 1 },
        { field: 'FuelPoured', headerName: 'Fuel Poured (Ltrs)', flex: 1 },
        { field: 'FuelingReason', headerName: 'Fueling Reason', flex: 1 },
        { field: 'GridStatus', headerName: 'Grid Status', flex: 1 },
        { field: 'FuelOpeningStock', headerName: 'Fuel Opening Stock  (Ltrs)', flex: 1 },
        { field: 'FuelerContactNumber', headerName: 'Fueler Contact Number', flex: 1 },
    ];

    // pagination js 
    
    // Calculate the total number of pages
    const totalPages = Math.ceil(rows.length / rowsPerPage);

    // Slice the data based on the current page and rows per page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = rows.slice(startIndex, endIndex);

    console.log("setRowsPerPage:", setRowsPerPage);
    console.log("endIndex:", endIndex);
    console.log("slicedData---:", slicedData);


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        console.log("currentPage:", newPage);
    };


    const [modalShow, setModalShow] = React.useState(false);

    const breadcrumbs = [
        { label: 'Fueling', link: '' },

    ];

    const moremenu = [
        {
            "id": 1,
            "title": "Fuel Pouring",
            "path": 'SiteFueling',
            "value": "",
            "valueType": "",
            "dataType": "normal",
            "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M14.9018 13.934C14.337 14.6551 13.6137 15.2387 12.7873 15.64C11.9609 16.0414 11.0531 16.25 10.1332 16.25H2.51716C2.1166 16.25 1.73244 16.092 1.4492 15.8107C1.16596 15.5294 1.00684 15.1478 1.00684 14.75V9.5C1.00684 9.10218 1.16596 8.72064 1.4492 8.43934C1.73244 8.15804 2.1166 8 2.51716 8H8.78398C9.12696 8.0001 9.45968 8.11613 9.72743 8.329L12.4853 10.516C12.6735 10.6655 12.895 10.7682 13.1313 10.8153C13.3676 10.8624 13.6118 10.8527 13.8436 10.787L18.3755 9.5L14.9018 13.934Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.04785 5V8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.02734 5H10.0686" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.376 9.5L20.6415 10.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.9061 18.594C22.9061 19.1658 22.6774 19.7142 22.2703 20.1185C21.8632 20.5229 21.311 20.75 20.7353 20.75C20.1595 20.75 19.6074 20.5229 19.2003 20.1185C18.7932 19.7142 18.5645 19.1658 18.5645 18.594C18.5645 17.4 20.0113 15 20.7353 15C21.4592 15 22.9061 17.4 22.9061 18.594Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
        },
        {
            "id": 2,
            "title": "Dashboard",
            "path": 'SfDashboard',
            "value": "",
            "valueType": "",
            "dataType": "normal",
            "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clip-path="url(#clip0_203_2818)">
              <path d="M9.48524 11.3158C9.48572 11.55 9.39322 11.7747 9.22808 11.9407C9.06293 12.1067 8.83864 12.2004 8.60449 12.2011H1.55849C1.44251 12.2009 1.32772 12.1778 1.22068 12.1331C1.11363 12.0885 1.01643 12.0232 0.934635 11.941C0.852838 11.8588 0.788048 11.7613 0.743967 11.654C0.699887 11.5467 0.677382 11.4318 0.677739 11.3158V1.56886C0.677501 1.45284 0.700117 1.33791 0.744296 1.23064C0.788475 1.12336 0.853351 1.02583 0.93522 0.94363C1.01709 0.861426 1.11435 0.796151 1.22144 0.751534C1.32854 0.706917 1.44337 0.683831 1.55939 0.683594L8.60539 0.695337C8.83939 0.696294 9.06344 0.790042 9.2284 0.95601C9.39335 1.12198 9.48572 1.34661 9.48524 1.5806V11.3158Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.9989 5.91211C20.9972 6.14411 20.9035 6.36595 20.7384 6.5289C20.5732 6.69184 20.3502 6.78256 20.1182 6.78112H13.0722C12.9572 6.78195 12.8433 6.76013 12.7368 6.71689C12.6304 6.67366 12.5335 6.60987 12.4517 6.52916C12.3699 6.44845 12.3048 6.35242 12.2601 6.24654C12.2155 6.14066 12.1921 6.02702 12.1914 5.91211V1.56437C12.1928 1.33261 12.2862 1.1109 12.451 0.947957C12.6158 0.785012 12.8386 0.694159 13.0703 0.69536L20.1163 0.683617C20.3486 0.681933 20.572 0.772516 20.7375 0.935462C20.903 1.09841 20.997 1.32039 20.9989 1.55262V5.91211Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.48523 20.1379C9.48452 20.2531 9.46113 20.367 9.4164 20.4732C9.37166 20.5793 9.30645 20.6756 9.2245 20.7566C9.14255 20.8375 9.04545 20.9015 8.93876 20.9449C8.83207 20.9884 8.71787 21.0103 8.60268 21.0096L1.55668 20.9979C1.32468 20.9988 1.10179 20.9077 0.936984 20.7444C0.772175 20.5811 0.678929 20.3591 0.677734 20.1271V15.7829C0.678326 15.6679 0.701583 15.5541 0.746176 15.4481C0.79077 15.3421 0.855825 15.2459 0.937622 15.165C1.01942 15.0841 1.11636 15.0202 1.22289 14.9768C1.32942 14.9334 1.44346 14.9114 1.55848 14.9121H8.60448C8.83672 14.9109 9.05994 15.002 9.22508 15.1653C9.39023 15.3285 9.4838 15.5507 9.48523 15.7829V20.1379Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.0731 21.0077C12.957 21.0075 12.8421 20.9844 12.7349 20.9397C12.6278 20.895 12.5305 20.8297 12.4486 20.7474C12.3667 20.6651 12.3018 20.5674 12.2577 20.4601C12.2136 20.3527 12.1911 20.2377 12.1914 20.1216V10.3764C12.1911 10.2604 12.2136 10.1454 12.2576 10.0381C12.3017 9.93079 12.3665 9.8332 12.4483 9.75091C12.53 9.66862 12.6272 9.60325 12.7343 9.55852C12.8413 9.51379 12.9561 9.49059 13.0722 9.49023H20.1182C20.2342 9.49059 20.349 9.51379 20.456 9.55852C20.5631 9.60325 20.6603 9.66862 20.7421 9.75091C20.8239 9.8332 20.8886 9.93079 20.9327 10.0381C20.9768 10.1454 20.9993 10.2604 20.9989 10.3764V20.1098C20.9994 20.3439 20.9071 20.5686 20.7421 20.7347C20.5772 20.9008 20.3531 20.9948 20.1191 20.996L13.0731 21.0077Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_203_2818">
                <rect width="21.68" height="21.68" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
        },
        {
            "id": 3,
            "title": "Add Fuel Details",
            // "path": '',
            // "hhandleClick" : setModalShow(true),
            hhandleClick: () => {
                setModalShow(true)
                console.log('Item 2 was clicked');
            },
            "value": "",
            "valueType": "",
            "dataType": "normal",
            "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_206_5384)">
              <path d="M0.75 12H23.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 0.75V23.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_206_5384">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
        },
    ]

    const pageNo = 0;

    return (
        <div className='pg_siteFueling_wraper pb-2'>
            {/* <HeaderMain pageTitle='Site Fueling' /> */}

            <SpHeader breadcrumbs={breadcrumbs} />
            <div className='row align-items-center pe-4'>
                <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div>
                <div className='col-12 col-lg-3'>
                    <MyAllDownloadInfo />
                </div>
            </div>


            <div className='stf_header pt-2 pb-1'>
                <AmTabsHeader pageNo={pageNo} moremenu={moremenu} />
            </div>
            {/* <div className='stf_header contentWrap pt-2 pb-2'>
                <div className='d-flex align-items-center justify-content-start flex-wrap'>
                    <Button className='d-flex align-items-center me-3' variant="dark">Dashboard</Button>

                    <Button onClick={() => setModalShow(true)} className='d-flex align-items-center stf_addbtn' variant="dark">
                        Add Fuel Details</Button>
                </div>
            </div> */}
            <div className='stf_tableWrap contentWrap'>
                <TableContainer>
                    <Table aria-label="Basic table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.field}>
                                        <div className='customTbl_headCell'>
                                            <TableSortLabel
                                                active={orderBy === column.field}
                                                direction={orderBy === column.field ? order : 'asc'}
                                                onClick={() => handleSort(column.field)}
                                            >
                                                {column.headerName}
                                            </TableSortLabel>

                                            <Tooltip title="Filter">
                                                <label className='filter_header_btn' onClick={handleFilterIconClick}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
                                                        <path d="M7.66615 1.88513C7.70623 1.83087 7.73063 1.76635 7.73663 1.6988C7.74263 1.63125 7.72999 1.56333 7.70012 1.50267C7.67024 1.442 7.62433 1.39098 7.56751 1.35533C7.51069 1.31967 7.44522 1.30078 7.37843 1.30078H0.897744C0.830952 1.30078 0.765478 1.31967 0.708661 1.35533C0.651843 1.39098 0.605927 1.442 0.576056 1.50267C0.546186 1.56333 0.533541 1.63125 0.539539 1.6988C0.545538 1.76635 0.569942 1.83087 0.610018 1.88513L3.41823 5.71662V9.33556C3.41823 9.40339 3.43683 9.46987 3.47194 9.52757C3.50706 9.58527 3.5573 9.63189 3.61704 9.66222C3.67679 9.69256 3.74366 9.7054 3.81019 9.69931C3.87671 9.69321 3.94025 9.66843 3.99368 9.62774L4.713 9.07991C4.75767 9.04589 4.79392 9.00178 4.81889 8.95107C4.84386 8.90036 4.85686 8.84444 4.85686 8.78774V5.71626L7.66615 1.88513Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </label>
                                            </Tooltip>

                                            <Popover className='stf_filter_dropdown' open={filterOpen} anchorEl={anchorEl} onClose={() => setFilterOpen(false)}>
                                                <div className='stf_filter_header'>
                                                    <h3 className='stf_filter_title'>Filter By Value <span>T<small>T</small></span></h3>

                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Filter Values"
                                                        aria-describedby="passwordHelpBlock"
                                                    />
                                                </div>
                                                <List>
                                                    <ListItem>
                                                        <label>
                                                            <Checkbox
                                                                value="FilterValue1"
                                                                checked={selectedFilters.includes('FilterValue1')}
                                                                onChange={handleFilterChange}
                                                            />
                                                            Filter Option 1
                                                        </label>
                                                    </ListItem>
                                                    <ListItem>
                                                        <label>
                                                            <Checkbox
                                                                value="FilterValue2"
                                                                checked={selectedFilters.includes('FilterValue2')}
                                                                onChange={handleFilterChange}
                                                            />
                                                            Filter Option 2
                                                        </label>
                                                    </ListItem>
                                                    {/* Add more filter options as needed */}
                                                </List>
                                                <div className='stf_filter_footer'>
                                                    <Button variant="outline-secondary" onClick={applyFilters}>Cancel</Button>
                                                    <Button variant="dark" onClick={applyFilters}>Ok</Button>
                                                </div>

                                            </Popover>
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slicedData.map((row) => (
                                <TableRow key={row.id}>
                                    {columns.map((column) => (
                                        <TableCell key={column.field}>{row[column.field]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </TableContainer>
                
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                />

            </div>
            <AddFuelDetails
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    );
};

export default SiteFueling;
