import React from "react";

function ExeNetworkInfrastructureTable() {

    const sitesData = [
        {
            id: 1,
            title: 'Total Sites',
            value: '6167',
            isOnline: false,
            isOffline: false,
        },
        {
            id: 2,
            title: 'TP Owned',
            value: '1855',
            isOnline: false,
            isOffline: false,
        },
        {
            id: 3,
            title: 'TP Guest',
            value: '5370',
            isOnline: false,
            isOffline: false,
        },
        {
            id: 4,
            title: 'Online',
            value: '6199',
            isOnline: true,
            isOffline: false,
        },
        {
            id: 5,
            title: 'Offline',
            value: '3412',
            isOnline: false,
            isOffline: true,
        },

    ]

    const gridConnectedData = [
        {
            id: 1,
            title: "Grid Connected",
            value: 10,
        },
        {
            id: 2,
            title: "Genset",
            value: 729,
        },
        {
            id: 3,
            title: "Solar",
            value: 190,
        },
        {
            id: 4,
            title: "Wind",
            value: 1,
        },
        {
            id: 5,
            title: "Battery Li",
            value: 113,
        },
        {
            id: 6,
            title: "Battery AGM",
            value: 11,
        }
    ]

    const typesData = [
        {
            id: 1,
            type: "2G",
            value: 1283,
        },
        {
            id: 2,
            type: "3G",
            value: 1207,
        },
        {
            id: 3,
            type: "4G",
            value: 1220,
        },
        {
            id: 4,
            type: "FN",
            value: 9410,
        }
    ]

    const offGridData = [
        {
            id: 1,
            title: "Off Grid",
            value: 6167,
        },
        {
            id: 2,
            title: "Good Grid",
            value: 1855,
        },
        {
            id: 3,
            title: "Bad Grid",
            value: 5370,
        },
        {
            id: 4,
            title: "Genset Only",
            value: 6199,
        },
        {
            id: 5,
            title: "Non-DG",
            value: 3412,
        }
    ]

    return (
        <div className="ts_networks_row">
            <div className="ts_networks_cell">
                <div className="ts_network_box">
                    <ul>

                        {
                            sitesData.map((data) => {
                                return (
                                    <li key={data.id}>
                                        <p className={data.isOnline ? 'ts_has_status' : '' || data.isOffline ? 'ts_has_status' : ''}>
                                            {data.isOnline ? <i className="ts_status ts_online"></i> : ''}
                                            {data.isOffline ? <i className="ts_status ts_offline"></i> : ''}
                                            {data.title}</p>
                                        <strong>{data.value}</strong>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="ts_networks_cell">
                <div className="ts_network_box ts_network_box_dark">
                    <ul>
                        {
                            gridConnectedData.map((data) => {
                                return (
                                    <li key={data.id}>
                                        <p>{data.title}</p>
                                        <strong>{data.value}</strong>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="ts_networks_cell">
                <div className="ts_network_box ">
                    <ul>
                        {
                            typesData.map((data) => {
                                return (
                                    <li key={data.id}>
                                        <h4><i>{data.type}</i> {data.value}</h4>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <div className="ts_networks_cell">
                <div className="ts_network_box ts_network_box_dark">
                    <ul>
                        {
                            offGridData.map((data) => {
                                return (
                                    <li key={data.id}>
                                        <p>{data.title}</p>
                                        <strong>{data.value}</strong>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ExeNetworkInfrastructureTable;