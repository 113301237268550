import React from "react";
import PowerFlow from "../../Components/PowerFlow/PowerFlow";
 
import PowerSourceUtilization from "../../Components/Charts/PowerSourceUtilizationCustom";
import PowerSourceUtilizationCustom22 from "../../Components/Charts/PowerSourceUtilizationCustom22";

function LiveView() {
    // const columnchartOptions = {
    //     title: {
    //         text: 'Region Wise (Hours/Day)',
    //         textStyle: {
    //             fontSize: 16,
    //             fontWeight: '500',
    //             fontFamily: 'Roboto, sans-serif',
    //         },
    //     },
    //     tooltip: {
    //         trigger: 'axis'
    //     },
    //     legend: {
    //         right: '0',
    //         top: '4px',
    //         show: true,
            
    //         fill: 'orange',
    //         itemHeight: 14,
    //         itemWidth: 14
    //     },
       
    //     grid: {
    //         left: '20px',
    //         right: '10px',
    //         bottom: '60px',
    //         containLabel: true
    //     },
    //     xAxis: [
    //         {
 
    //             type: 'value',
    //             data: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    //             axisLabel: {
    //                 interval: 0,  
    //                 formatter: (value) => {
                        
    //                     return value;
    //                 },
    //             },
    //             splitLine: {
    //                 show: true,
    //                 lineStyle: {
    //                     color: '#F0F0F0'
    //                 }
    //             },
    //         }
    //     ],
    //     yAxis: [
    //         {
    //             type: 'category',
    //             data: ['Mar 31', 'Tue', 'Apr 1', 'Apr 2', 'Apr 3', 'Apr 4', 'Apr 5', 'Apr 6'],
    //             splitLine: {
    //                 show: true,
    //                 lineStyle: {
    //                     color: '#F0F0F0'
    //                 }
    //             },
    //         }
    //     ],
    //     dataZoom: [
    //         {
    //             type: 'slider',
    //             xAxisIndex: 0,  
    //             filterMode: 'weakFilter',
    //             showDataShadow: false,

    //         },
    //         {
    //             type: 'slider',
    //             yAxisIndex: 0,  
    //             filterMode: 'weakFilter',
    //             showDataShadow: false,

    //         },
    //     ],
    //     series: [


    //         {
    //             name: 'Mains',
    //             type: 'bar',
    //             stack: 'total',
    //             smooth: true,
    //             color: [
    //                 '#B798F5',
    //             ],

    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [320, 302, 301, 334, 390, 330, 320,]
    //         },
    //         {
    //             name: 'DG',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#FEDE81',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [120, 132, 101, 134, 90, 230, 210]
    //         },
    //         {
    //             name: 'Solar',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#86C493',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [220, 182, 191, 234, 290, 330, 310]
    //         },
    //         {
    //             name: 'Battery',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#86B0FF',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [150, 212, 201, 154, 190, 330, 410]
    //         },
    //         {
    //             name: 'Wind',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#D2E265',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Outage',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#9E1E1E',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Down Time',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#F56867',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Solar - DG',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#85AB4A',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'DG - Battery',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#855981',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Mains - Battery',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#6069F5',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Solar - Battery',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#468793',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Solar - Mains',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#60758D',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },
    //         {
    //             name: 'Undetermind',
    //             type: 'bar',
    //             stack: 'total',
    //             color: [
    //                 '#BDC4D1',
    //             ],
    //             emphasis: {
    //                 focus: 'series'
    //             },
    //             data: [820, 832, 901, 934, 1290, 1330, 1320]
    //         },

    //     ]
    // };

 

    return (
        <div className="liveViewWrapMain container-fluid pt-3">

            <div className="row">
                <div className="col-12 col-xl-7 col-xxl-8">
                    Left Col
                </div>
                <div className="col-12 col-xl-5 col-xxl-4">
                    <div className="box_card">
                        <PowerFlow />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 pt-3">
                    <div className="box_card">
                       <PowerSourceUtilization/>
                    </div>
                </div>

                <div className="col-12 pt-3">
                    <div className="box_card">
                       <PowerSourceUtilizationCustom22/>
                    </div>
                </div>

                
                {/* <div className="col-12 pt-3">
                    <div className="box_card">
                        <ReactECharts option={columnchartOptions} opts={{ renderer: 'svg' }} style={{ height: 380 }} />
                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default LiveView;