import React from "react";



function MyAllDownloadInfo(props) {
 
    return (
        <>
            <div className='myAllInfoBox d-flex'>
                {
                    props?.notYTDBtns ? '' : <div className="d-flex">
                        <div className='myAllinfoCell'>
                            <button className='monthYearBtn active'>1M</button>
                        </div>
                        <div className='myAllinfoCell ps-1'>
                            <small className='monthYearBtn'>6M</small>
                        </div>
                        <div className='myAllinfoCell px-1'>
                            <small className='monthYearBtn'>1Y</small>
                        </div>
                        <div className='myAllinfoCell pe-1'>
                            <small className='monthYearBtn'>All</small>
                        </div>
                    </div>
                }

                {
                    props?.notDatepicker ? '' : <div className='myAllinfoCell px-2'>
                        <small className='label13 weight4 cursor-pointer'>
                            <i>
                                <svg width="20" height="20" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7513 1.33301V3.99967" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.41927 1.33301V3.99967" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2.08594 6.00033H14.0859" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7526 2.66699H3.41927C2.6826 2.66699 2.08594 3.26366 2.08594 4.00033V12.667C2.08594 13.4037 2.6826 14.0003 3.41927 14.0003H12.7526C13.4893 14.0003 14.0859 13.4037 14.0859 12.667V4.00033C14.0859 3.26366 13.4893 2.66699 12.7526 2.66699Z" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.76236 8.486C4.67036 8.486 4.59569 8.56067 4.59636 8.65267C4.59636 8.74467 4.67102 8.81934 4.76302 8.81934C4.85502 8.81934 4.92969 8.74467 4.92969 8.65267C4.92969 8.56067 4.85502 8.486 4.76236 8.486" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.09439 8.486C8.00239 8.486 7.92772 8.56067 7.92839 8.65267C7.92839 8.74467 8.00305 8.81934 8.09505 8.81934C8.18705 8.81934 8.26172 8.74467 8.26172 8.65267C8.26172 8.56067 8.18705 8.486 8.09439 8.486" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M11.4264 8.486C11.3344 8.486 11.2598 8.56067 11.2604 8.65267C11.2604 8.74467 11.3351 8.81934 11.4271 8.81934C11.5191 8.81934 11.5938 8.74467 11.5938 8.65267C11.5938 8.56067 11.5191 8.486 11.4264 8.486" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.76236 11.153C4.67036 11.153 4.59569 11.2277 4.59636 11.3197C4.59636 11.4117 4.67102 11.4863 4.76302 11.4863C4.85502 11.4863 4.92969 11.4117 4.92969 11.3197C4.92969 11.2277 4.85502 11.153 4.76236 11.153" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.09439 11.153C8.00239 11.153 7.92772 11.2277 7.92839 11.3197C7.92839 11.4117 8.00305 11.4863 8.09505 11.4863C8.18705 11.4863 8.26172 11.4117 8.26172 11.3197C8.26172 11.2277 8.18705 11.153 8.09439 11.153" stroke="#33383F" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </i>
                        </small>
                    </div>
                }

                {
                    props?.notInfo ? '' : <div className='myAllinfoCell pe-2'>
                        <small className='label13 weight4 cursor-pointer'>
                            <i>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.7036 8.12598C12.5656 8.12598 12.4536 8.23798 12.4546 8.37598C12.4546 8.51398 12.5666 8.62598 12.7046 8.62598C12.8426 8.62598 12.9546 8.51398 12.9546 8.37598C12.9546 8.23798 12.8426 8.12598 12.7036 8.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7046 21.126V21.126C7.73359 21.126 3.70459 17.097 3.70459 12.126V12.126C3.70459 7.15498 7.73359 3.12598 12.7046 3.12598V3.12598C17.6756 3.12598 21.7046 7.15498 21.7046 12.126V12.126C21.7046 17.097 17.6756 21.126 12.7046 21.126Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12.7046 12.126V17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </i>
                        </small>
                    </div>
                }

                {
                    props?.notDownload ? '' : <div className='myAllinfoCell'>
                        <small className='label13 weight4 cursor-pointer'>
                            <i>
                                <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.3711 17.126V3.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M21.3711 17.126C21.3711 19.335 19.5801 21.126 17.3711 21.126H7.37109C5.16209 21.126 3.37109 19.335 3.37109 17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M17.3711 12.126L12.3701 17.127L7.37012 12.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </i>
                        </small>
                    </div>
                }


            </div>
        </>
    );
}

export default MyAllDownloadInfo;