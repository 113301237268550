import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SobTabsHeader from "../SobTabsHeader/SobTabsHeader";
import { sob_moremenu } from "../SobTabsHeader/SobMoreMenuLinks";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";


function SobGatewayConfigs() {
    const [modalShow, setModalShow] = React.useState(false);
    const pageNo = 9;
    const breadcrumbs = [
        { label: 'Site On Boarding', link: '' },
        { label: 'Add Existing Site', link: '/SitesOnboarding' },
        { label: 'AMKM - 03', link: '' },
    ];

    return (
        <div className="sobSteps_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} />
            <SobTabsHeader pageNo={pageNo} sob_moremenu={sob_moremenu} />
            <div className="sobSteps_content_main">
                <div className="sobGeneralInfo pb-1">
                    <div className="sobSteps_content contentWrap">
                        <div className="row pt-3">
                            <div className="col-12 col-lg-8 box_card">
                                <div className="sobGeneralInfo_inner pb-0">
                                    <div className="sob_heading">
                                        <h2>Gateway Configs</h2>
                                    </div>
                                    <div className="row">
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>

                                            <div className="sobInfo_box">
                                                <h4> IP address (text entry field)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select label="Select an option">
                                                            {/* <MenuItem value="None">
                                                            <em>None</em>
                                                        </MenuItem> */}
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>SNMP community string (text entry field)<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>MAC address (text entry field)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>MIB (drop down selection)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>SW version (drop down selection)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="row ps-1">
                                    <div className="col-12 col-lg-12">
                                        <div className="sobGeneralInfo_inner bg_gray_dark5">
                                            <div className="sob_heading">
                                                <h2>Gateway Configs</h2>
                                            </div>
                                            <div className="sob_summary_data">
                                                <table className="table st_summary_list mb-2">
                                                    <tbody>
                                                        <tr>
                                                            <th>IP address (text entry field)</th>
                                                            <th>GST</th>
                                                        </tr>
                                                        <tr>
                                                            <td>SNMP community string (text entry field)</td>
                                                            <td>Deposit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>MAC address (text entry field)</td>
                                                            <td>Ballon / RV</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SW version (drop down selection)</td>
                                                            <td>Term</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf_next_reset_btn pt-3 contentWrap">
                        <Link to={'/OnboardingSearch'} className="btn btn-secondary me-2">Cancel</Link>
                        <Link to={'/SobBattery'} className="btn btn-secondary me-2">Back</Link>
                        <Link className="btn btn-dark" onClick={() => setModalShow(true)}>Save</Link>
                        <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )


    function MyVerticallyCenteredModal(props) {
        return (
            <Modal className="sobSaveModel"
                {...props}

                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modal heading
                    </Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="sobSaveModel_content">
                        <div className="row">
                            <Link className="sobSaveModel_close" onClick={props.onHide}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                                    <path d="M10.1867 8.09887C9.61017 7.52238 8.6755 7.52238 8.09901 8.09887C7.52252 8.67536 7.52252 9.61003 8.09901 10.1865L15.9123 17.9998L8.09887 25.8132C7.52238 26.3896 7.52238 27.3243 8.09887 27.9008C8.67536 28.4773 9.61003 28.4773 10.1865 27.9008L17.9999 20.0874L25.8133 27.9008C26.3898 28.4773 27.3245 28.4773 27.901 27.9008C28.4774 27.3243 28.4774 26.3897 27.901 25.8132L20.0876 17.9998L27.9008 10.1865C28.4773 9.61003 28.4773 8.67536 27.9008 8.09887C27.3243 7.52238 26.3896 7.52238 25.8132 8.09887L17.9999 15.9121L10.1867 8.09887Z" fill="#6F767E" />
                                </svg>
                            </Link>
                        </div>
                        <div className="d-flex w-100 justify-content-center align-items-center pt-4">
                            <span className="sobSaveModel_tick"><svg xmlns="http://www.w3.org/2000/svg" width="144" height="145" viewBox="0 0 144 145" fill="none">
                                <path d="M72 137.731C107.824 137.731 136.866 108.69 136.866 72.8657C136.866 37.0414 107.824 8 72 8C36.1757 8 7.13428 37.0414 7.13428 72.8657C7.13428 108.69 36.1757 137.731 72 137.731Z" stroke="#2AC810" stroke-width="14.25" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M100.909 53.957L69.5027 95.8387C68.8044 96.7674 67.9148 97.5355 66.8941 98.0909C65.8734 98.6463 64.7454 98.9762 63.5863 99.0582C62.4271 99.1402 61.2639 98.9724 60.1752 98.5662C59.0865 98.16 58.0976 97.5248 57.2755 96.7035L41.0591 80.4871" stroke="#2AC810" stroke-width="14.25" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></span>
                        </div>
                        <h3>Site onboarding success!</h3>

                        <div className="sobSaveModel_table">
                            <div className="sob_site_box_head">
                                <h4>Site Details</h4>
                            </div>
                            <div className="sob_site_box_detail">
                                <div className="sob_site_box_text row pb-4">
                                    <h4 className="col-6">AMKM - 03</h4>
                                    <strong className="col-6">Abbottabad</strong>
                                </div>
                                <ul>
                                    <li>
                                        <div className="sob_site_box_text row pb-2">
                                            <span className="col-6">Site Type</span>
                                            <strong className="col-6">BTS + HUB</strong>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="sob_site_box_text row pb-2">
                                            <span className="col-6">Site Category</span>
                                            <strong className="col-6">Host Sharing</strong>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div className="sobSaveModel_done">
                            <Link to={'/Welcome'} onClick={props.onHide} className="btn btn-dark">Done</Link>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }

}
export default SobGatewayConfigs;