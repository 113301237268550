import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import InfrastructureWideLineChart from "../../../../Components/Charts/InfrastructureWideLineChart";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import RegionWiseColumnChart from "../../../../Components/Charts/RegionWiseColumnChart";
import ReliabilityGradingChartPie from "../../../../Components/Charts/ReliabilityGradingChartPie";
import ReliabilityGradingRegionWiseHeatMap from "../../../../Components/Charts/ReliabilityGradingRegionWiseHeatMap";
import ActualYtdHrsBox from "../../../../Components/ActualYtdHrsBox/ActualYtdHrsBox";
import SpHeader from "../../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function GridUnavailability() {
   const pageNo = 0;


   const lineoption = {
      title: {
         text: 'Infrastructure Wide (Hours/Day)',
         textStyle: {
            fontSize: 18,
            fontWeight: '500',
            fontFamily: 'Roboto, sans-serif',
         },
      },
      tooltip: {
         trigger: 'axis'
      },
      legend: {
         left: 'center',
         top: '4px',
         show: true,
         data: ['2022', '2023'],
         fill: 'orange',
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      grid: {
         left: '10px',
         right: '10px',
         bottom: '3%',
         containLabel: true
      },
      xAxis: [
         {
            type: 'category',
            boundaryGap: false,
            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            splitLine: {
               show: true,
               lineStyle: {
                  color: '#F0F0F0'
               }
            },
         }
      ],
      yAxis: [
         {
            type: 'value',
            splitLine: {
               show: true,
               lineStyle: {
                  color: '#F0F0F0'
               }
            },
         }
      ],

      series: [
         {
            name: '2022',
            type: 'line',
            color: [
               '#E3D9F6',
            ],
            stack: 'Actual',
            smooth: true,
            areaStyle: {
               normal: {
                 color: 'rgba(227, 217, 246, 0.2)', 
               },
             },
            data: [13, 12, 12, 12.2, 11, 11.5, 13, 13, 13, 13, 13, 13]
         },
         {
            name: '2023',
            type: 'line',
            stack: 'YTD',
            smooth: true,
            color: [
               '#7243D0',
            ],
            areaStyle: {
               normal: {
                 color: 'rgba(114, 67, 208, 0.7)', 
               },
             },
            data: [14, 14, 14, 13, 13, 13.5, 0, 0, 0, 0, 0, 0]
         },

      ]
   };


   //   pie chart options start
   const piechartOptions = {
      title: {
         text: 'Reliability Grading ',
         textStyle: {
            fontSize: 16,
            fontWeight: '500',
            fontFamily: 'Roboto, sans-serif',
         },
      },
      tooltip: {
         trigger: 'axis'
      },
      legend: {
         bottom: 10,
         left: 'center',
         //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
         type: 'scroll',
         orient: 'horizontal',
         itemWidth: 8,
         selectedMode: true,
         icon: "circle"
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      grid: {
         left: '30px',
         right: '10px',
         bottom: '0%',
         containLabel: true
      },
      xAxis: [

         {
            type: 'category',
            boundaryGap: true,
            //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
            show: false,
            splitLine: {
               show: true,
               lineStyle: {
                  color: '#F0F0F0'
               }
            },
         }
      ],
      yAxis: [
         {
            type: 'value',
            splitLine: {
               show: true,
               lineStyle: {
                  color: '#F0F0F0'
               }
            },
         }
      ], 
      series: [
         {
            type: 'pie',
            radius: '52%',
            center: ['50%', '44%'],
            selectedMode: 'single',
            color: [
               '#01A340',
               '#F13534',
               '#F88800',
               '#32323E',
            ],
            data: [
               {
                  value: 1568,
                  name: 'Good 0 - 8 Hours',
                  label: {
                     formatter: ['1568'].join('\n'),
                  }
               },
               {
                  value: 240,
                  name: 'Bad 15 - 18 Hours',
                  label: {
                     formatter: ['240'].join('\n'),
                  }
               },
               {
                  value: 350,
                  name: 'Average 9 - 14 Hours',
                  label: {
                     formatter: ['350'].join('\n'),
                  }
               },
               {
                  value: 2272,
                  name: 'Off 19 - 24 Hours',
                  label: {
                     formatter: ['2272'].join('\n'),
                  }
               },
            ],
            emphasis: {
               itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
               }
            }
         }
      ]
   };

   // 
   const columnchartOptions = {
      title: {
         text: 'Region Wise (Hours/Day)',
         textStyle: {
            fontSize: 16,
            fontWeight: '500',
            fontFamily: 'Roboto, sans-serif',
         },
      },
      tooltip: {
         trigger: 'axis'
      },
      legend: {
         right: '0',
         top: '4px',
         show: false,
         data: ['2022', '2023'],
         fill: 'orange',
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      grid: {
         left: '0px',
         right: '10px',
         bottom: '3%',
         containLabel: true
      },
      xAxis: [
         {
            type: 'category',
            boundaryGap: true,
            data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
            splitLine: {
               show: true,
               lineStyle: {
                  color: '#F0F0F0'
               }
            },
         }
      ],
      yAxis: [
         {
            type: 'value',
            splitLine: {
               show: true,
               lineStyle: {
                  color: '#F0F0F0'
               }
            },
         }
      ],
      series: [
         {
            name: '(Hours/Day)',
            type: 'bar',
            stack: 'YTD',
            smooth: true,
            color: [
               '#7243D0',
            ],
            // areaStyle: {normal: {}},
            data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13]
         },

      ]
   };

   const breadcrumbs = [
      { label: 'Teams View', link: '' },
      { label: 'Grid Unavailability', link: '/GridUnavailability' },
   ];


   const summaryObj = {
      minimize: 'Minimise',
      otherName: "Summary",
      minUrl: '/GridUnavailability',
      otherUrl: '/TeamsViewSummary',
    }
  

   return (
      <div className="wrapper">
         {/* <HeaderMain pageTitle='Teams View - Grid Unavailability'/> */}
         {/* <SpHeader breadcrumbs={breadcrumbs} /> */}
         <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown />} />
         <ApplyFilterHeader />
         <div className='pt-1'>
            <TopTabsHeaders pageNo={pageNo} />
         </div>
         <OnOffGridSitesHeader /> 
         
         <div className="contentWrap">
            <div className="chart_containter">
               <div className="row pb-3">
                  <div className="col-8">
                     <div className="box_card">
                        <div className="box_card_inner infrastructureChartBox">
                           <ActualYtdHrsBox titleText='2022 Actual vs 2023 YTD' hours='2.0 Hrs' percent='16.3%' />
                           <InfrastructureWideLineChart options={lineoption} />
                        </div>
                     </div>
                  </div>
                  <div className="col-4">
                     <div className="box_card">
                        <div className="box_card_inner infrastructureChartBox">
                           <SimpleMapPage />
                        </div>
                     </div>
                  </div>
               </div>
               {/* 2nd row */}
               <div className="row">
                  <div className="col-3">
                     <div className="box_card">
                        <div className="box_card_inner infrastructureChartBox">
                           <RegionWiseColumnChart options={columnchartOptions} />
                        </div>
                     </div>
                  </div>
                  <div className="col-3">
                     <div className="box_card">
                        <div className="box_card_inner infrastructureChartBox">
                           <ReliabilityGradingChartPie options={piechartOptions} />
                        </div>
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="box_card">
                        <div className="box_card_inner infrastructureChartBox">
                           <ReliabilityGradingRegionWiseHeatMap />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default GridUnavailability; 