import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const siteDropdownArry = [
    { id: 1, label: 'Infra Performance', url: '/GridUnavailability' },
    // { id: 2, label: 'Infra view', url: '/SitesInfraview' },
    { id: 3, label: 'Network Performance ', url: '/TnpSummary' },
    { id: 4, label: 'Performance Comparison', url: '/PerformanceComparison' },
    { id: 5, label: 'Performance Analysis', url: '/TnpPerformanceAnalysis' },
    { id: 6, label: 'Commercial Savings', url: '/GridUnavailability4' },
    { id: 7, label: 'Playground', url: '/TeamViewPlayGround' },
    { id: 8, label: 'Network Insights', url: '/TnpNetworkInsights' }, 
    { id: 9, label: 'Network-Assets Discovery', url: '/NetworkAssetsDiscovery' }, 
    { id: 10, label: 'Site-Assets Discovery', url: '/SiteAssetsDiscovery' }, 
    { id: 11, label: 'Network Pulse', url: '/NetworkPulse' },

     
    
   
];

const siteDropdownDataArry = siteDropdownArry;
function TeamsViewHeaderDropdown(props) {

     
    const navigate = useNavigate();
    const location = useLocation();
    const [defaultValue, setDefaultValue] = useState(null);

    if(props?.parentPath){
        location.pathname = props.parentPath;
    }
    useEffect(() => {
        const currentPath = location.pathname;
        const matchingOption = siteDropdownDataArry.find((option) => option.url === currentPath);
        if (matchingOption) {
            setDefaultValue(matchingOption);
        }
    }, [location.pathname]);

    const handleOptionSelect = (event, selectedOption) => {
        if (selectedOption) {
            const selectedUrl = selectedOption.url;
            navigate(selectedUrl);
        }
    };

    return (
        <div className="sitesHeader_dropdown">
            <Autocomplete
                disablePortal
                options={siteDropdownDataArry}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} placeholder="Sites select" />}
                onChange={handleOptionSelect}
                value={defaultValue}
                isOptionEqualToValue={(option, value) => option.url === value.url}
            />
        </div>
    )
}

 
export default TeamsViewHeaderDropdown;