
import React, { useState } from 'react';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Popover,
    List,
    Pagination,
    ListItem,
    Checkbox,
} from '@mui/material';

import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SingleSiteDetailRightNav from '../../../Modals/SingleSiteDetailRightNav';

import styles from '../../SitePerformance/SitePerformance.module.css';

// import styles from '../SitePerformance.module.css';  


function SiteAssetsDiscovery() {
    // const pageNo = 0;
    // let activeIndex = 1;

    const breadcrumbs = [
        { label: 'Teams View', link: '/TnpSummary' },
        { label: 'Site-Assets Discovery', link: '/SiteAssetsDiscovery' },
        // { label: 'Summary', link: '' },

    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpSummary',
        otherUrl: '/TeamsViewSummary',
    }


    // table
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage] = useState(20); // Ini

    const columns = [
        { field: 'IP', headerName: 'IP', flex: 1 },
        { field: 'Timestamp', headerName: 'Timestamp', flex: 1 },
        { field: 'Category', headerName: 'Category', flex: 1 },
        { field: 'StatusDetails', headerName: 'Status Details', flex: 1 },
        { field: 'SNMPParameters', headerName: 'SNMP Parameters', flex: 1 },
        { field: 'CurrentValue', headerName: 'Current Value', flex: 1 },
        { field: 'PreviousValue', headerName: 'Previous Value', flex: 1 },

    ];

    const [rows, setRows] = useState([
        { id: 1, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'New', StatusDetails: 'Initial record inserted', SNMPParameters: 'csu-manufacturer', CurrentValue: 'ZTE Corporation' },
        { id: 2, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'Added', StatusDetails: 'New value found in curr...', SNMPParameters: 'csu-plt-ver', CurrentValue: 'V2.01.04.00' },
        { id: 3, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'New', StatusDetails: 'New value found in curr...', SNMPParameters: 'csu-manufacturer', CurrentValue: 'V2.3' },
        { id: 4, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'New', StatusDetails: 'New value found in curr...', SNMPParameters: 'csu-manufacturer', CurrentValue: '[“0xD2B8009A”, “0xC2B806AA”, 0x51660C95”]' },
        { id: 5, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'Added', StatusDetails: 'New value found in curr...', SNMPParameters: 'csu-plt-ver', CurrentValue: '[“0xD2B8009A”, “0xC2B806AA”, 0x51660C95”]' },
        { id: 6, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'Added', StatusDetails: 'New value found in curr...', SNMPParameters: 'csu-manufacturer', CurrentValue: '[“0xD2B8009A”, “0xC2B806AA”, 0x51660C95”]' },
        { id: 7, IP: '10.242.130.243', Timestamp: '2024-01-26 02:42:00', Category: 'New', StatusDetails: 'New value found in curr...', SNMPParameters: 'csu-manufacturer', CurrentValue: '[“0xD2B8009A”, “0xC2B806AA”, 0x51660C95”]' },
    ]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleFilterIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen(!filterOpen);
    };



    const handleSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        const newOrder = isAscending ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        const sortedRows = [...rows].sort((a, b) => {
            const aValue = a[property];
            const bValue = b[property];
            if (newOrder === 'asc') {
                return aValue < bValue ? -1 : 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        });

        setRows(sortedRows);
    };


    const handleFilterChange = (event) => {
        const value = event.target.value;

        if (selectedFilters.includes(value)) {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
        } else {
            setSelectedFilters([...selectedFilters, value]);
        }
    };

    const applyFilters = () => {
        // Apply filters based on selectedFilters
        // You can implement your custom filtering logic here

        // For demonstration purposes, we'll just log the selected filters
        console.log('Selected Filters:', selectedFilters);

        // Close the filter dropdown
        setFilterOpen(false);
    };


    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        console.log("currentPage:", newPage);
    };

    // pagination js
    // Calculate the total number of pages
    const totalPages = Math.ceil(rows.length / rowsPerPage);

    // Slice the data based on the current page and rows per page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = rows.slice(startIndex, endIndex);


    // Step 3: Create a state variable for Offcanvas visibility
    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
    };


    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown />} />
            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>

            </div>
            <div className='siteAssetsDiscovery'>
                <div className='contentWrap'>
                    <div className='siteAssetsDiscovery_inner'>
                        <div className='sad_details'>
                            <div className='row'>
                                <div className='col-12 sad_details_li'>
                                    <div className="box_card has_info_icon h-100">
                                        <div className="box_info_flexible">
                                            <h3 className='chartTitleHeading  pb-0 pt-0'>
                                                Site Details
                                            </h3>
                                            <div className='box_info'>
                                                <i className='box_info_icon'></i>
                                            </div>
                                        </div>
                                        <div className="sts_siteDetail_data pt-2">
                                            <div className="row pb-3">
                                                <div className="col-6">
                                                    <p className="label15 weight4">Site IP</p>
                                                </div>
                                                <div className="col-5 ms-auto">
                                                    <p className="label16 weight5">AAB804</p>
                                                </div>
                                            </div>
                                            <div className="row pb-2">
                                                <div className="col-6">
                                                    <p className="label15 weight4">Site Location</p>
                                                </div>
                                                <div className="col-5 ms-auto">
                                                    <p className="label16 weight5">10.242.5.50</p>
                                                </div>
                                            </div>
                                            <div className="row pb-3">
                                                <div className="col-6">
                                                    <p className="label15 weight4">Site Type</p>
                                                </div>
                                                <div className="col-5 ms-auto">
                                                    <p className="label16 weight5">BTS + HUB</p>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-end  pt-2">
                                                <Link onClick={toggleOffcanvas} className={styles.moreBtn}>View more <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                    <path d="M12.6654 8.01497H3.33203" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.33203 11.349L12.6654 8.01562" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.33203 4.68164L12.6654 8.01497" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 sad_details_li'>
                                    <div className='box_card h-100'>
                                        <div className='sad_detail_box'>
                                            <div className='sad_detail_heading'>
                                                <h4>CSU</h4>
                                            </div> 
                                            <div className='sad_detail_content'>
                                                <span>210066944986</span>
                                                <span>ZE-78588</span>
                                            </div>
                                            <p>Last Updated: October 05,2020</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 sad_details_li'>
                                    <div className='box_card h-100'>
                                        <div className='sad_detail_box'>
                                            <div className='sad_detail_heading'>
                                                <h4>PU</h4>
                                            </div> 
                                            <div className='sad_detail_content'> 
                                                <span>N/A</span>
                                            </div>
                                            <p>Last Updated: October 05,2020</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 sad_details_li'>
                                    <div className='box_card h-100'>
                                        <div className='sad_detail_box'>
                                            <div className='sad_detail_heading'>
                                                <h4>SMR</h4>
                                            </div> 
                                            <div className='sad_detail_content'> 
                                                <span>0xD2B8009A</span>
                                                <span>0xC2B806AA</span>
                                                <span>0x51660C95</span>
                                            </div>
                                            <p>Last Updated: October 05,2020</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-12 sad_details_li'>
                                    <div className='box_card h-100'>
                                        <div className='sad_detail_box'>
                                            <div className='sad_detail_heading'>
                                                <h4>BMS</h4>
                                            </div> 
                                            <div className='sad_detail_content'> 
                                                <span>N/A</span> 
                                            </div>
                                            {/* <p>Last Updated: October 05,2020</p> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='asstManagement_table pt-4'>
                            <div className='stf_tableWrap'>
                                <TableContainer>
                                    <Table aria-label="Basic table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell key={column.field}>
                                                        <div className='customTbl_headCell'>
                                                            <TableSortLabel
                                                                active={orderBy === column.field}
                                                                direction={orderBy === column.field ? order : 'asc'}
                                                                onClick={() => handleSort(column.field)}
                                                            >
                                                                {column.headerName}
                                                            </TableSortLabel>

                                                            <Tooltip title="Filter">
                                                                <label className='filter_header_btn' onClick={handleFilterIconClick}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
                                                                        <path d="M7.66615 1.88513C7.70623 1.83087 7.73063 1.76635 7.73663 1.6988C7.74263 1.63125 7.72999 1.56333 7.70012 1.50267C7.67024 1.442 7.62433 1.39098 7.56751 1.35533C7.51069 1.31967 7.44522 1.30078 7.37843 1.30078H0.897744C0.830952 1.30078 0.765478 1.31967 0.708661 1.35533C0.651843 1.39098 0.605927 1.442 0.576056 1.50267C0.546186 1.56333 0.533541 1.63125 0.539539 1.6988C0.545538 1.76635 0.569942 1.83087 0.610018 1.88513L3.41823 5.71662V9.33556C3.41823 9.40339 3.43683 9.46987 3.47194 9.52757C3.50706 9.58527 3.5573 9.63189 3.61704 9.66222C3.67679 9.69256 3.74366 9.7054 3.81019 9.69931C3.87671 9.69321 3.94025 9.66843 3.99368 9.62774L4.713 9.07991C4.75767 9.04589 4.79392 9.00178 4.81889 8.95107C4.84386 8.90036 4.85686 8.84444 4.85686 8.78774V5.71626L7.66615 1.88513Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </label>
                                                            </Tooltip>

                                                            <Popover className='stf_filter_dropdown' open={filterOpen} anchorEl={anchorEl} onClose={() => setFilterOpen(false)}>
                                                                <div className='stf_filter_header'>
                                                                    <h3 className='stf_filter_title'>Filter By Value <span>T<small>T</small></span></h3>

                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Filter Values"
                                                                        aria-describedby="passwordHelpBlock"
                                                                    />
                                                                </div>
                                                                <List>
                                                                    <ListItem>
                                                                        <label>
                                                                            <Checkbox
                                                                                value="FilterValue1"
                                                                                checked={selectedFilters.includes('FilterValue1')}
                                                                                onChange={handleFilterChange}
                                                                            />
                                                                            Filter Option 1
                                                                        </label>
                                                                    </ListItem>
                                                                    <ListItem>
                                                                        <label>
                                                                            <Checkbox
                                                                                value="FilterValue2"
                                                                                checked={selectedFilters.includes('FilterValue2')}
                                                                                onChange={handleFilterChange}
                                                                            />
                                                                            Filter Option 2
                                                                        </label>
                                                                    </ListItem>
                                                                    {/* Add more filter options as needed */}
                                                                </List>
                                                                <div className='stf_filter_footer'>
                                                                    <Button variant="outline-secondary" onClick={applyFilters}>Cancel</Button>
                                                                    <Button variant="dark" onClick={applyFilters}>Ok</Button>
                                                                </div>

                                                            </Popover>
                                                        </div>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {slicedData.map((row) => (
                                                <TableRow key={row.id}>
                                                    {columns.map((column) => (
                                                        <TableCell key={column.field}>{row[column.field]}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>

                                <div className='am_pagination'>
                                    <div className='am_pagination_left'>
                                        <div className='am_pagination_icon'>
                                            <i>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.7036 8.12598C12.5656 8.12598 12.4536 8.23798 12.4546 8.37598C12.4546 8.51398 12.5666 8.62598 12.7046 8.62598C12.8426 8.62598 12.9546 8.51398 12.9546 8.37598C12.9546 8.23798 12.8426 8.12598 12.7036 8.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7046 21.126V21.126C7.73359 21.126 3.70459 17.097 3.70459 12.126V12.126C3.70459 7.15498 7.73359 3.12598 12.7046 3.12598V3.12598C17.6756 3.12598 21.7046 7.15498 21.7046 12.126V12.126C21.7046 17.097 17.6756 21.126 12.7046 21.126Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12.7046 12.126V17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </i>
                                            <i>
                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.3711 17.126V3.12598" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M21.3711 17.126C21.3711 19.335 19.5801 21.126 17.3711 21.126H7.37109C5.16209 21.126 3.37109 19.335 3.37109 17.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M17.3711 12.126L12.3701 17.127L7.37012 12.126" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </i>
                                        </div>
                                    </div>
                                    <div className='am_pagination_right '>

                                        <div className='am_pagination_rowPg d-flex align-items-center'>
                                            <strong className='label13 weight7 pe-2'>Rows per page</strong>

                                            <div className="applyFilter_box">
                                                <select name="customSearch">
                                                    <option value="">25</option>
                                                    <option value="26">26</option>
                                                    <option value="27">27</option>
                                                </select>
                                            </div>
                                        </div>

                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* single site side panel nav component */}
            <SingleSiteDetailRightNav showOffcanvas={showOffcanvas} toggleOffcanvas={toggleOffcanvas} />

        </div>
    )
}
export default SiteAssetsDiscovery;