
import React from "react";
import ReactECharts from 'echarts-for-react';
function SitePerfPowerSrcChart(props) {
    const columnchartOptions = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: '0',
            bottom: '0px',
            show: true,
            data: ['Actual', 'Target'],

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            top: '20px',
            left: '0px',
            right: '0px',
            bottom: '20px',
            containLabel: false
        },
        xAxis: [
            {
                // data: ['0', '100'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
                //type: 'value',
                show: false,
                boundaryGap: [0, 0]
            }
        ],
        yAxis: [
            {
                type: 'category',
                show: false,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'Actual',
                type: 'bar',
                smooth: true,
                color: [
                    props.actualColor,
                ],
                data: [props.actualData],
                barGap: '0%',
                label: {
                    show: true,
                    position: 'inside', // Adjust the position of the data labels (e.g., 'top', 'insideTop', 'inside', etc.)
                },
            },
            {
                name: 'Target',
                type: 'bar',
                smooth: true,
                color: [
                    props.targetColor,
                ],
                data: [props.targetData],
                barGap: '0%',
                label: {
                    show: true,
                    position: 'inside', // Adjust the position of the data labels (e.g., 'top', 'insideTop', 'inside', etc.)
                },
            },

        ]
    };

    return (
        <>
            <ReactECharts option={columnchartOptions} opts={{ renderer: 'svg' }} style={{ height: 120 }} />
        </>
    )
}

export default SitePerfPowerSrcChart;