import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import AmpButtonsGroup from "../Components/AmpButtonsGroup/AmpButtonsGroup";
function SitePerformanceSolarPowerContribution() {
    //   Solar Power Contribution (kWh) chart data


    const dataInfra =  [
        {
            name: 'Site Load',
            type: 'bar',
            stack: 'Hours',
            smooth: true,
            color: ['#7243D0'],
            data: [56, 20]
        },
        {
            name: 'Battery Charging',
            type: 'bar',
            stack: 'Hours',
            smooth: true,
            color: ['#0058FF'],
            data: [24, 56]
        },
        {
            name: 'Un-accounted',
            type: 'bar',
            stack: 'Hours', // Use the same stack name for both series
            smooth: true,
            color: ['#32323E'],
            data: [26, 26]
        },
       
    ];
    const dataRegion =  [
        {
            name: 'Site Load',
            type: 'bar',
            stack: 'Hours',
            smooth: true,
            color: ['#7243D0'],
            data: [25, 56]
        },
        {
            name: 'Battery Charging',
            type: 'bar',
            stack: 'Hours',
            smooth: true,
            color: ['#0058FF'],
            data: [56, 30]
        },
        {
            name: 'Un-accounted',
            type: 'bar',
            stack: 'Hours', // Use the same stack name for both series
            smooth: true,
            color: ['#32323E'],
            data: [36, 32]
        },
       
    ];

    const [chartData, setChartData] = useState(dataInfra);
    const colsData_gensetOverruns = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
                width: '120px'
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            bottom: '4px',
            show: true,
            data: ['Site Load', 'Battery Charging', 'Un-accounted'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle",
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },
        grid: {
            left: '0px',
            right: '10px',
            bottom: '40px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Target', 'Actual'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: chartData,
    };


    const btnArray = [
        { id: 1, name: 'Infra', active: true },
        { id: 2, name: 'Region', active: false },
    ];
    const [array, setArray] = useState(btnArray);
    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
        // console.log('array', id)
        if (id === 1) {
            setChartData(dataInfra);
        }
        if (id === 2) {
            setChartData(dataRegion);
        }

    };

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between align-items-center">
                <h3 className="chartTitleHeading ">Solar Power Generation (kWh)</h3>
                <AmpButtonsGroup array={array} handleItemClick={handleItemClick} />
            </div>

            <ReactECharts option={colsData_gensetOverruns} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
        </>
    )
}

export default SitePerformanceSolarPowerContribution