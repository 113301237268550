import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./PowerFlow.css";
import battery_img from "../../assets/images/powerflow/battery_img.svg";
import pf_grid_img from "../../assets/images/powerflow/pf_grid_img.svg";
import pf_tower_img from "../../assets/images/powerflow/pf_tower_img.svg";
import pf_generator_img from "../../assets/images/powerflow/pf_generator_img.svg";
import pf_solar_img from "../../assets/images/powerflow/pf_solar_img.svg";
import pf_rectifier_img from "../../assets/images/powerflow/pf_rectifier_img.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import infraImg1 from '../../assets/images/sts_mains.svg';
import infraImg2 from '../../assets/images/sts_genset.svg';
import infraImg3 from '../../assets/images/sts_battery.svg';
import infraImg4 from '../../assets/images/sts_solar.svg';
import infraImg5 from '../../assets/images/sts_sites.svg';


function PowerFlowNew() {
    const [wfData, setData] = React.useState([]);
    const [isResponse, setIsResponse] = useState(false);

    // useEffect(() => {
    //     fetch("https://api.thunder.softoo.co/vis/api/dashboard/ssu/powersource?siteId=ZLD019")
    //         .then((response) => response.json())
    //         .then((data) => {
    //             return (
    //                 console.log(data),
    //                 setData(data)
    //             )
    //         }
    //         );

    // }, []);
    useEffect(() => {
        fetchData();

        console.log('fetchDatassssss');
    }, []);


    const fetchData = async () => {
        try {
            const response = await fetch('https://api.thunder.softoo.co/vis/api/dashboard/ssu/powersource?siteId=ITN011');
            const jsonData = await response.json();
            // Process the fetched data

            // Call the jQuery function in the API success response
            console.log('------sss', jsonData)
            //  setData(jsonData)
            setData(jsonData)
            myFunction(jsonData);
            batteryCharged(jsonData);
            setIsResponse(true);

        } catch (error) {
            console.error('Error:', error);
        }
    };



    return (
        <div className="powerFlow_graph_main">
            <div className="box_info_flexible borderNone">
                <h3 className='chartTitleHeading  pb-0 pt-0'>
                    Power Flow
                </h3>
                <div className='box_info'>

                    <OverlayTrigger placement="top" overlay={<Tooltip className='help_tip_white'>Power Flow info help</Tooltip>}>
                        <i className='box_info_icon'></i>
                    </OverlayTrigger>
                </div>
            </div>

            <div className="new_powerFlow_graph">
                <div className="npf_top_load">
                    <div className="npf_load_cell">
                        <h4 className="label18 weight5 d-flex align-items-center mb-2"><i className="icon_label bg_blue"><img src={infraImg1} alt="#"></img></i>Mains</h4>

                        <div className="npf_graph_data">
                            <i className="npf_gd_saprator"></i>
                            <ul>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.power_values.Main.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Load</label>
                                </li>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.avg_values.main_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Avg</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="npf_load_cell">
                        <h4 className="label18 weight5 d-flex align-items-center mb-2"><i className="icon_label bg_green"><img src={infraImg2} alt="#"></img></i>Generator</h4>

                        <div className="npf_graph_data">
                            <i className="npf_gd_saprator bg_green"></i>
                            <ul>
                                <li>
                                    <span>
                                        <strong> {isResponse ? wfData.data.power_values.DG.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Load</label>
                                </li>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.avg_values.dg_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Avg</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="npf_load_cell">
                        <h4 className="label18 weight5 d-flex align-items-center mb-2"><i className="icon_label bg_blue_navy"><img src={infraImg3} alt="#"></img></i>Battery</h4>
                        <div className="npf_graph_data">
                            <i className="npf_gd_saprator bg_blue_navy"></i>
                            <ul>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.power_values.Battery.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Load</label>
                                </li>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.avg_values.battery_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Avg</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="npf_load_cell">
                        <h4 className="label18 weight5 d-flex align-items-center mb-2"><i className="icon_label bg_yellow"><img src={infraImg4} alt="#"></img></i>Solar</h4>

                        <div className="npf_graph_data">
                            <i className="npf_gd_saprator bg_yellow"></i>
                            <ul>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.power_values.Solar.toFixed(1) : ''}</strong> <small>kW</small>
                                    </span>
                                    <label>Load</label>
                                </li>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.avg_values.solar_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Avg</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="npf_load_cell">
                        <h4 className="label18 weight5 d-flex align-items-center mb-2"><i className="icon_label bg_blue3"><img src={infraImg5} alt="#"></img></i>Site Usage</h4>

                        <div className="npf_graph_data">
                            <i className="npf_gd_saprator bg_blue3"></i>
                            <ul>
                                <li>
                                    <span>
                                        <strong> {isResponse ? wfData.data.raw_values.load_curr.toFixed(1) : '0'}</strong> <small>kW</small>
                                    </span>
                                    <label>Load</label>
                                </li>
                                <li>
                                    <span>
                                        <strong>{isResponse ? wfData.data.raw_values.load_total_pow.toFixed(1) : '0'}</strong> <small>kW</small>

                                    </span>
                                    <label>Avg</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="npfChart_flow_content">
                    <div className="npfChart_barsRow">

                        <svg className="svg_grid_to_recti" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1229 234" fill="none">
                            <path d="M4 205.542V34C4 17.4314 17.4315 4 34 4H1195C1211.57 4 1225 17.4315 1225 34V234" stroke="#EDEDED" stroke-width="6" />
                            <path className="pf_path pf_grid_to_recti" d="M4 205.542V34C4 17.4314 17.4315 4 34 4H1195C1211.57 4 1225 17.4315 1225 34V234" stroke="#EDEDED" stroke-width="6" />
                        </svg>

                        {/* generater to rectifier */}

                        <svg className="npfChart_bars_svg svg_gen_to_recti" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 909 196" fill="none">
                            <path d="M4 172.244V34C4 17.4314 17.4315 4 34 4H875C891.569 4 905 17.4315 905 34V196" stroke="#EDEDED" stroke-width="6" />
                            <path className="pf_path pf_gen_to_recti" d="M4 172.244V34C4 17.4314 17.4315 4 34 4H875C891.569 4 905 17.4315 905 34V196" stroke="#EDEDED" stroke-width="6" />
                        </svg>



                        {/* battery to rectifier */}
                        <svg className="npfChart_bars_svg svg_battery_to_recti" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 909 196" fill="none">
                            <path d="M4 172.244V34C4 17.4314 17.4315 4 34 4H875C891.569 4 905 17.4315 905 34V196" stroke="#EDEDED" stroke-width="10" />
                            <path className="pf_path pf_recti_to_battery" d="M4 172.244V34C4 17.4314 17.4315 4 34 4H875C891.569 4 905 17.4315 905 34V196" stroke="#EDEDED" stroke-width="10" />
                        </svg>
                        <svg className="npfChart_bars_svg svg_solar_to_recti" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 253 122" fill="none">
                            <path d="M4 107.4V34C4 17.4315 17.4315 4 34 4H219C235.569 4 249 17.4315 249 34V122" stroke="#EDEDED" stroke-width="6" />
                            <path className="pf_path pf_solor_to_recti" d="M4 107.4V34C4 17.4315 17.4315 4 34 4H219C235.569 4 249 17.4315 249 34V122" stroke="#EDEDED" stroke-width="6" />
                        </svg>

                        <svg className="npfChart_bars_svg svg_recti_to_tower" xmlns="http://www.w3.org/2000/svg" width="238" height="8" viewBox="0 0 238 8" fill="none">
                            <path d="M0.5 4H238" stroke="#EDEDED" stroke-width="7" />
                            <path className="pf_path pf_recti_to_tower" d="M0.5 4H238" stroke="#EDEDED" stroke-width="7" />
                        </svg>
                    </div>
                    <div className="npfChart_row">
                        <div className="npfChart_cell npfCell_grid">

                            <div className="npf_graph_pos pf_pos_grid">
                                <div className="npf_graph_pos_img">
                                    <img src={pf_grid_img} alt="#"></img>
                                </div>
                                <div className="npf_cricle">
                                    <svg viewBox="0 0 201 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pf_circle pf_grid_circle_grey" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                        <path className="pf_circle pf_grid_circle" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                            </div>
                            <label className="npf_label npf_label_main">Importing</label>
                        </div>
                        {/*  */}
                        <div className="npfChart_cell npfCell_gen">
                            <div className="npf_graph_pos pf_pos_gen">
                                <div className="npf_graph_pos_img">
                                    <img src={pf_generator_img} alt="#"></img>
                                </div>
                                <div className="npf_cricle">
                                    <svg viewBox="0 0 201 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pf_circle pf_gen_circle_grey" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                        <path className="pf_circle pf_gen_circle" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                    </svg>



                                </div>
                            </div>
                            <label className="npf_label npf_label_idle">Generator</label>
                        </div>
                        {/*  */}
                        <div className="npfChart_cell npfCell_battery">
                            <div className="npf_graph_pos pf_pos_battery">

                                <div className="npf_graph_pos_img">
                                    <img src={battery_img} alt="#"></img>

                                    <div className="pf_battery_pointer">
                                        <svg width="15" height="35" viewBox="0 0 10 21" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.01082 0.398805C8.91224 0.0851315 8.73299 0.00448257 8.49998 0.156838C8.20423 0.354004 7.96224 0.712486 7.72922 1.07993C7.55894 1.35776 7.4066 1.6535 7.23632 1.93133L7.15566 2.0568C6.96746 2.19123 6.7703 2.3167 6.57313 2.44217C6.83303 1.94926 7.11084 1.49219 7.4514 1.15163L7.44246 1.09786C7.31699 1.15163 7.1915 1.19644 7.06604 1.28606C6.65378 1.59077 6.30427 2.09264 5.98163 2.63933C5.78446 2.96196 0.00390625 12.4439 0.00390625 12.4439L3.27506 11.1444L0.0307914 20.3484L8.52686 7.45198L6.13397 8.40196C6.84198 6.44822 8.22215 2.63934 8.49998 2.07472C8.54479 1.97614 8.59855 1.87755 8.66129 1.78793C8.84949 1.50114 8.94806 1.48323 9.10042 1.7252C9.12731 1.23229 9.12733 0.784174 9.01082 0.398805Z"
                                                fill="#A9A9A9" />
                                            <defs>
                                                <linearGradient id="pf_battery_active_gradient" x1="7.78775"
                                                    y1="1.04435" x2="1.6057" y2="16.7113"
                                                    gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#F57F20" />
                                                    <stop offset="1" stop-color="#FFC50F" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>

                                    <div className="pf_battery_bar">
                                        <ul>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 20 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 19 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 18 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 17 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 16 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 15 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 14 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 13 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 12 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 11 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 10 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 9 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 8 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 7 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 6 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 5 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 4 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 3 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 2 }}></li>
                                            <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 1 }}></li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="npf_cricle">
                                    <svg viewBox="0 0 201 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pf_circle pf_battery_circle_grey" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                        <path className="pf_circle pf_battery_circle" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                    </svg>



                                </div>
                            </div>
                            <label className="npf_label npf_label_battery pf_battery_dischargin">Charging</label>
                        </div>

                        {/*  */}

                        <div className="npfChart_cell npfCell_solar">
                            <div className="npf_graph_pos pf_pos_solar">
                                <div className="npf_graph_pos_img">
                                    <img src={pf_solar_img} alt="#"></img>
                                </div>
                                <div className="npf_cricle">
                                    <svg viewBox="0 0 201 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pf_circle pf_solar_circle_grey" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                        <path className="pf_circle pf_solar_circle" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                            </div>
                            <label className="npf_label npf_label_solar">Generating</label>
                        </div>
                        {/*  */}

                        <div className="npfChart_cell npfCell_rectifier">
                            <div className="npf_graph_pos pf_pos_rectifier">
                                <div className="npf_graph_pos_img">
                                    <img src={pf_rectifier_img} alt="#"></img>
                                </div>
                                <div className="npf_cricle">
                                    <svg viewBox="0 0 201 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pf_circle pf_rectifier_circle_grey" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                        <path className="pf_circle pf_rectifier_circle" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                            </div>
                            <label className="npf_label npf_label_rectifier">Idle</label>
                        </div>
                        {/*  */}
                        <div className="npfChart_cell npfCell_tower">
                            <div className="npf_graph_pos pf_pos_tower">
                                <div className="npf_graph_pos_img">
                                    <img src={pf_tower_img} alt="#"></img>
                                </div>
                                <div className="npf_cricle">
                                    <svg viewBox="0 0 201 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className="pf_circle pf_tower_circle_grey" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                        <path className="pf_circle pf_tower_circle" d="M100.374 112.34C154.083 112.34 197.623 87.9449 197.623 57.8518C197.623 27.7586 154.083 3.36328 100.374 3.36328C46.6649 3.36328 3.125 27.7586 3.125 57.8518C3.125 87.9449 46.6649 112.34 100.374 112.34Z" fill="white" stroke="#EDEDED" stroke-width="5" stroke-miterlimit="10" />
                                    </svg>
                                </div>
                            </div>
                            <label className="npf_label npf_label_tower">Active</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function myFunction(wfData) {


    let data = wfData.data;

    // colors variables
    let grey_color = '#EDEDED';
    let tower_color = '#03B9EC';
    let rectifier_color = '#88745B';
    let grid_color = '#6A54A3';
    let solar_color = '#F0B41D';
    let battery_color = '#4162AE';
    // let generator_color = '#32864B';

    // things are connected

    let connected_tower = false;

    let connected_rectifier = false;

    let connected_solar = false;

    let connected_grid = false;

    let connected_battery = false;

    let connected_generator = false;

    // progress variables  
    let generator_on = false;
    let generator_power = false;

    let solar_power = false;

    let grid_power = false;


    let battery_charging = false;
    let battery_disCharging = false;

    if (data.power_values.DG > 0) {
        generator_on = true;
        generator_power = true;
    }

    if (data.power_values.Battery > 0) {
        connected_battery = true;
        connected_rectifier = true;
        connected_tower = true;

    }

    if (data.power_values.Main > 0) {
        connected_grid = true;
        grid_power = true;
        connected_rectifier = true;
        connected_tower = true;
    }

    if (data.power_values.Solar > 0) {
        connected_solar = true;
        solar_power = true;
        connected_rectifier = true;
        connected_tower = true;
    }

    // if (data.charging >= 0) {
    if (data.charging > 0) {
        battery_charging = true;
    }
    if (data.charging < 0) {
        battery_disCharging = true;
    }




    if (connected_tower === true) {
        $('.pf_tower_circle').css({ stroke: tower_color });
    } else {
        $('.pf_tower_circle').css({ stroke: grey_color });
    }
    if (connected_rectifier === true) {
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });
    } else {
        $('.pf_rectifier_circle').css({ stroke: grey_color });
    }

    if (connected_solar === true) {
        $('.pf_solar_circle').css({ stroke: solar_color });
    } else {
        $('.pf_solar_circle').css({ stroke: grey_color });
    }
    if (connected_grid === true) {
        $('.pf_grid_circle').css({ stroke: grid_color });

    } else {
        $('.pf_grid_circle').css({ stroke: grey_color });
    }
    if (connected_battery === true) {
        $('.pf_battery_circle').css({ stroke: battery_color });
        $('.pf_pos_battery_progress').removeClass("pf_hide");
    } else {
        $('.pf_battery_circle').css({ stroke: grey_color });
        $('.pf_pos_battery_progress').addClass("pf_hide");
    }
    if (connected_generator === true) {
        $('.pf_gen_circle').css({ stroke: '#6BC283' });
    } else {
        $('.pf_gen_circle').css({ stroke: grey_color });
    }

    // progres conditions

    if (generator_on === true) {
        $('.pf_gen_circle').addClass('active');
        $('.pf_gen_circle').css({ stroke: '#6BC283' });
        if (generator_power === true) {
            $('.pf_gen_to_recti, .pf_recti_to_tower').addClass("active");

            $('.pf_tower_circle, .pf_rectifier_circle').addClass('active');
            $('.pf_tower_circle').css({ stroke: tower_color });
            $('.pf_rectifier_circle').css({ stroke: rectifier_color });
        } else {
            $('.pf_gen_to_recti').removeClass("active");
        }
    } else {
        $('.pf_gen_to_recti').removeClass("active");

    }

    // grid power
    if (grid_power === true) {
        $('.pf_grid_to_recti, .pf_recti_to_tower').addClass('active');
        $('.pf_grid_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_grid_circle').css({ stroke: grid_color });

        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });


    } else {
        $('.pf_grid_to_recti').removeClass("active");
    }

    // solar power
    if (solar_power === true) {
        $('.pf_solor_to_recti, .pf_recti_to_tower').addClass('active');
        $('.pf_solar_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_solar_circle').css({ stroke: solar_color });
        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });
    } else {
        $('.pf_solor_to_recti').removeClass("active");
    }

    // battery charging power
    if (battery_charging === true) {
        $('.pf_recti_to_battery, .pf_recti_to_tower, .pf_battery_pointer').addClass('active');
        $('.pf_battery_dischargin').text('Charging');
        $('.pf_battery_dischargin').addClass('charging');
        $('.pf_battery_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_battery_circle').css({ stroke: battery_color });
        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });

        $('.pf_battery_bar').addClass('is_charging');


    } else {
        $('.pf_battery_pointer').removeClass("active");
        $('.pf_battery_dischargin').removeClass('charging');

        $('.pf_battery_bar').removeClass('is_charging');
    }

    // battery charging power
    if (battery_disCharging === true) {
        $('.pf_recti_to_battery, .pf_battery_dischargin').addClass('decharging');
        $('.pf_recti_to_tower').addClass('active');
        $('.pf_battery_dischargin').text('Discharging');
        $('.pf_battery_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_battery_circle').css({ stroke: battery_color });

        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });
        $('.pf_battery_bar').addClass('is_discharging');

    } else {
        $('.pf_battery_dischargin').removeClass("decharging");
        $('.pf_battery_bar').removeClass('is_discharging');
    }





}
function batteryCharged(wfData) {
    let data = wfData.data;
    console.log('batteryCharged---', data);

    const positiveNumber = Math.abs(data.charging);

    let battery_life = positiveNumber;

    if (battery_life > 100) {
        battery_life = 100;
    }
    if (battery_life < 5) {
        battery_life = 5;
    }
    let battery_cells_calc = battery_life / 5;
    let battery_cells = battery_cells_calc.toFixed(0);

    // let parent = $('.pf_battery_bar ul');
    let li = $('.pf_battery_bar ul li');

    var reversedIndex = (li.length - battery_cells);


    $(li).eq(reversedIndex).addClass('current');


    $('.pf_battery_bar ul li.current').prevAll().addClass("batteryCell_disabled");
    $('.pf_battery_bar ul li.current').removeClass("batteryCell_disabled");
    $('.pf_battery_bar ul li.current').nextAll().removeClass("batteryCell_disabled");
    $('.pf_battery_bar').attr("data-percent", battery_life);
    $('.pf_battery_bar').attr("data-cells", battery_cells);



    if (battery_life <= 20) {
        $('.pf_battery_bar').addClass("batteryCell_down");
    } else {
        $('.pf_battery_bar').removeClass("batteryCell_down");
    }
    if (battery_life <= 40 && battery_life > 20) {
        $('.pf_battery_bar').addClass("batteryCell_warning");

    } else {
        $('.pf_battery_bar').removeClass("batteryCell_warning");
    }
    if (battery_life > 40) {

    }


    if ($('.pf_battery_bar').hasClass('is_charging')) {
        $('.pf_battery_bar ul li.current').addClass("blinkAble");

        var len = $('.pf_battery_bar ul li.blinkAble').prevAll().length;
        var oldlen = $('.pf_battery_bar ul li.blinkAble').prevAll().length;


        clearInterval();
        setInterval(function () {
            len = len - 1;
            if (len < 0) {
                len = oldlen;
                $(li).removeClass('blink');
            }
            $(li).eq(len).addClass('blink');
        }, 500)
    }
}


export default PowerFlowNew;