import React from "react";
import "./YtdToFromBox.css"
import MyAllDownloadInfo from "./MyAllDownloadInfo";
function YtdToFromBox(props) {
    let notYTDBtns = props?.notYTDBtns;
    let notDatepicker = props?.notDatepicker;
    let notDownload = props?.notDownload;
    let notInfo = props?.notInfo;
    return(
        <MyAllDownloadInfo notYTDBtns={notYTDBtns} notDatepicker={notDatepicker} notDownload={notDownload} notInfo={notInfo} />
    )
    // <div className="ep_top_years_tabs">
    //     <ul>
    //         <li><span className="ep_top_tab_btn active">YTD</span></li>
    //         <li>
    //             <div className="ep_top_datepicker">
    //                 <input type="date" placeholder="From"></input>
    //             </div>
    //         </li>
    //         <li>
    //             <div className="ep_top_datepicker">
    //                 <input type="date" placeholder="To"></input>
    //             </div>
    //         </li>

    //     </ul>
    // </div>
}
export default YtdToFromBox;