import React from "react";
import { Link } from "react-router-dom";
function MinimizeSummaryButton(props) {
  return (
    <Link className={`minimize_summary_button ${props?.className}`} to={props.href} type={props.type} onClick={props?.onClick}>
      <span>{props.children}</span>
    </Link>
  );
}

export default MinimizeSummaryButton;