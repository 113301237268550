import React, { useState } from 'react';
import Logo from "../../assets/images/logo_white.svg";
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

 
function OnboardingSearch() {

    const [selectedValue, setSelectedValue] = useState(null);

    const handleChange = (event, value) => {
        setSelectedValue(value);
    };
 
    return (
        <div className="welcome_main onboarding_srch_wrap">
            <div className="header_main welcome_header">
                <div className="contentWrap">
                    <div className="headerMain_inner">
                        <div className="headerMain_logo">
                            <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
                        </div>
                        <div className="headerMain_title"><span>Site On Boarding</span></div>
                    </div>
                </div>
            </div>
            <div className="welcome_wrap">
                <div className="welcome_content justify-content-center">

                    <div className="sobSrch_main_parent">
                        <div className="sob_search_out">
                            <Autocomplete
                                disablePortal
                                id="sob_search_field"
                                options={['Option 1', 'Option 2', 'Option 3']}
                                sx={{ width: 480 }}
                                value={selectedValue}
                                onChange={handleChange}
                                renderInput={(params) => <TextField {...params} placeholder="Type Site ID" />}
                            />
                        </div>

                      

                        {
                            selectedValue ?
                                <div>
                                    <div className="sob_site_main">
                                        <div className="sob_site_box">
                                            <div className="sob_site_box_head">
                                                <h4>Site Details</h4>
                                            </div>
                                            <div className="sob_site_box_detail">
                                                <div className="sob_site_box_text row pb-4">
                                                    <h4 className="col-6">AMKM - 03</h4>
                                                    <strong className="col-6">Abbottabad</strong>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className="sob_site_box_text row pb-2">
                                                            <span className="col-6">Site Type</span>
                                                            <strong className="col-6">BTS + HUB</strong>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="sob_site_box_text row pb-2">
                                                            <span className="col-6">Site Category</span>
                                                            <strong className="col-6">Host Sharing</strong>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sob_can_next_btn d-flex justify-content-center">
                                        <Link to={'/SitesOnboarding'} className="btn btn-secondary me-3">Cancel</Link>
                                        <Link to={'/SobGeneralInfo'} className="btn btn-dark">Next</Link>
                                    </div>
                                </div>
                                : ''
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}


export default OnboardingSearch;