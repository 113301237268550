import React, { useState } from "react";
import CustomSelect from "./CustomSelect";
import Button from "./Button";
import YtdToFromBox from "./ytdToFrom/YtdToFromBox";

function ApplyFilterHeader(props) {
    const dataRegion = [ "Region 1", "Region 2", "Region 3", "Region 4" ];
    const dataCluster = [ "Cluster 1", "Cluster 2"];
    const dataSiteID  = [ "Site ID 1", "Site ID 2"];
    const dataProject  = [ "Project 1", "Project 2"];
    const dataQuality  = [ "Data Quality 1", "Data Quality 2"];
    const dataCommunication  = [ "Communication Quality 1", "Communication Quality 2"];

    const [filterDiabled, setfilterDiabled] = useState(true);

    const handleClickFilterButton = event => {
    
    };

    const onChangeHandle = event => {
        
         if(event.target.value === ''){
            setfilterDiabled(current => true);
         }else{
            setfilterDiabled(current => false);
         }
       console.log('change value--', event.target.value);
      };

      let notYTDBtns = props?.notYTDBtns;
      let notDatepicker = props?.notDatepicker;
      let notDownload = props?.notDownload;
      let notInfo = props?.notInfo;
      
    
    return(
        <div className="applyFilter_row">
          <div className="contentWrap">
             <div className="applyFilter_inner">
                <ul>
                    <li>
                        <div className="applyFilter_box">
                        <CustomSelect
                        data={dataRegion}
                        title="Region"
                        onSelectChange={onChangeHandle}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="applyFilter_box">
                        <CustomSelect
                        data={dataCluster}
                        title="Cluster"
                        onSelectChange={onChangeHandle}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="applyFilter_box">
                        <CustomSelect
                        data={dataSiteID}
                        title="Site ID"
                        onSelectChange={(event) => console.log(`Got ${event.target.value}`)}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="applyFilter_box">
                        <CustomSelect
                        data={dataProject}
                        title="Project"
                        onSelectChange={(event) => console.log(`Got ${event.target.value}`)}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="applyFilter_box">
                        <CustomSelect
                        data={dataQuality}
                        title="Data Quality"
                        onSelectChange={(event) => console.log(`Got ${event.target.value}`)}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="applyFilter_box">
                        <CustomSelect
                        data={dataCommunication}
                        title="Communication Quality"
                        onSelectChange={(event) => console.log(`Got ${event.target.value}`)}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="applyFilter_box">
                            <Button className={filterDiabled ? 'disabled' : ''} onClick={handleClickFilterButton} type="submit"> Apply Filter </Button>
                        </div>
                    </li>
                </ul>

                {
                    props.isNotYtd? "": <YtdToFromBox notYTDBtns={notYTDBtns} notDatepicker={notDatepicker} notDownload={notDownload} notInfo={notInfo} /> 
                }
                
             </div>
          </div>
        </div>
    )
}
export default ApplyFilterHeader;