import React from 'react';
import "../AssetManagement.css";
import AmHeaderDropdown from '../AmHeaderDropdown';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import { Link } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';


import SimpleMapPage from "../../../Components/Map/MapChart";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MyAllDownloadInfo from '../../../Components/ytdToFrom/MyAllDownloadInfo';


function AmPlayground() {


    const breadcrumbs = [
        { label: 'Playground', link: '' },
        // { label: 'Playground', link: '/AmPlayground' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/AmPlayground',
        otherUrl: '/AmSummary',
        // icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">  <g clip-path="url(#clip0_4924_1097)"><path d="M22.9037 13.7827H1.77701C1.22155 13.7827 0.771271 14.233 0.771271 14.7885V23.5079C0.771271 24.0634 1.22155 24.5137 1.77701 24.5137H22.9037C23.4591 24.5137 23.9094 24.0634 23.9094 23.5079V14.7885C23.9094 14.233 23.4591 13.7827 22.9037 13.7827Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2.31381 13.7826V7.78011C2.31544 7.51236 2.42323 7.2562 2.61352 7.06784C2.80381 6.87948 3.06106 6.7743 3.3288 6.7754H11.9547C12.1119 6.77516 12.2671 6.81145 12.4079 6.8814C12.5487 6.95136 12.6713 7.05307 12.7661 7.17852L13.9857 9.08715H20.5816C20.8495 9.08633 21.1068 9.19191 21.297 9.38069C21.4871 9.56946 21.5945 9.826 21.5956 10.0939V13.7826" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9.32202 16.8003H15.3585" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  <path d="M20.2967 6.00214V4.6951C20.2951 4.42735 20.1873 4.17119 19.997 3.98283C19.8067 3.79447 19.5495 3.68929 19.2817 3.69039H13.9857L12.766 1.77764C12.6712 1.65219 12.5486 1.55048 12.4078 1.48053C12.267 1.41057 12.1119 1.37428 11.9546 1.37452H4.62757C4.49482 1.37398 4.36326 1.39959 4.24041 1.44989C4.11756 1.50019 4.00582 1.5742 3.91156 1.66769C3.81731 1.76118 3.7424 1.87231 3.6911 1.99475C3.6398 2.11719 3.61312 2.24853 3.61258 2.38129V3.45387" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  </g>  <defs> <clipPath id="clip0_4924_1097"> <rect width="24.6807" height="24.6807" fill="white" transform="translate(0 0.605469)"/> </clipPath>  </defs> svg>`
    }


    //
    // 
    const equipmentSizing_data = {
        title: {
            text: 'Sites',
            show: true,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '400',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        // tooltip: {
        //     trigger: 'axis'
        // },
        tooltip: {
            trigger: "axis",
            formatter: function (params) {
                let tooltip = `<strong className='weight7 mb-1 d-block'>${params[0].name}</strong> `;
                params.forEach((param) => {
                    if (param.seriesName === "Transformer" && param.value === 0) {
                        //  tooltip += `${param.marker} Custom Value<br />`;
                    } else if (param.seriesName === "Rectifier" && param.value === 0) {
                        // tooltip += `${param.marker} Custom Value<br />`;
                    } else {
                        tooltip += `${param.marker} ${param.seriesName}:    <strong className='weight7 ps-2'>${param.value}</strong> <br />`;
                    }
                });
                return tooltip;
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Transformer', 'Rectifier', '0 - 30%', '31 - 60%', '61 - 90%', 'Above 90%'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Transformer', 'Rectifier'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Transformer',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#7243D0',
                ],

                data: [11750, 0],

                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },
            {
                name: 'Rectifier',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#88745B',
                ],

                data: [0, 11750],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                    barGap: '0%',
                },
                barGap: '0%',

            },
            {
                name: '0 - 30%',
                type: 'bar',
                stack: '22',
                smooth: true,
                color: [
                    '#0E7EFE',
                ],

                data: [3125, 1250],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },

            },
            {
                name: '31 - 60%',
                type: 'bar',
                stack: '22',
                smooth: true,
                color: [
                    '#5AA5FF',
                ],

                data: [2875, 7500],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },

            },
            {
                name: '61 - 90%',
                type: 'bar',
                stack: '22',
                smooth: true,
                color: [
                    '#FF8483',
                ],

                data: [3000, 1900],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },

            },
            {
                name: 'Above 90%',
                type: 'bar',
                stack: '22',
                smooth: true,
                color: [
                    '#F94D4F',
                ],

                data: [1000, 1100],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                },

            },

        ],

    };


    //
    // 
    const equipmentEol_data = {
        title: {
            text: 'Sites',
            show: true,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '400',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        // tooltip: {
        //     trigger: 'axis'
        // },
        tooltip: {
            trigger: "axis",
            formatter: function (params) {
                let tooltip = `<strong className='weight7 mb-1 d-block'>${params[0].name}</strong> `;
                params.forEach((param) => {
                    if (param.seriesName === "Transformer" && param.value === 0) {
                        //  tooltip += `${param.marker} Custom Value<br />`;
                    } else if (param.seriesName === "Rectifier" && param.value === 0) {
                        // tooltip += `${param.marker} Custom Value<br />`;
                    } else {
                        tooltip += `${param.marker} ${param.seriesName}:    <strong className='weight7 ps-2'>${param.value}</strong> <br />`;
                    }
                });
                return tooltip;
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Transformer', 'Rectifier', 'EOL', 'NON-EOL'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Transformer', 'Rectifier'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [ 
            {
                name: 'Transformer',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#7243D0',
                ],

                data: [10821, 0],

                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },
            {
                name: 'Rectifier',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#88745B',
                ],

                data: [0, 11750],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
            {
                name: 'EOL',
                type: 'bar',
                stack: '22',
                smooth: true,
                color: [
                    '#F94D4F',
                ],

                data: [10821, 7500],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },

            },
            {
                name: 'NON-EOL',
                type: 'bar',
                stack: '22',
                smooth: true,
                color: [
                    '#01E207',
                ],

                data: [0, 4250],
                label: {
                    show: true,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },

            },
        ],

    };


     //
    // 
    const ageing_data = {
        title: {
            text: 'Sites',
            show: true,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '400',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        // tooltip: {
        //     trigger: 'axis'
        // },
        tooltip: {
            trigger: "axis",
            formatter: function (params) {
                let tooltip = `<strong className='weight7 mb-1 d-block'>${params[0].name}</strong> `;
                params.forEach((param) => {
                    if (param.seriesName === "Transformer" && param.value === 0) {
                        //  tooltip += `${param.marker} Custom Value<br />`;
                    } else if (param.seriesName === "Rectifier" && param.value === 0) {
                        // tooltip += `${param.marker} Custom Value<br />`;
                    } else {
                        tooltip += `${param.marker} ${param.seriesName}:    <strong className='weight7 ps-2'>${param.value}</strong> <br />`;
                    }
                });
                return tooltip;
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Transformer', 'Rectifier'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '9+'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Transformer',
                type: 'bar',
               // stack: '1',
                smooth: true,
                color: [
                    '#7243D0',
                ],

                data: [6000, 2000, 3000,3000, 1000, 4000, 6000, 3000, 6000],

                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },
            {
                name: 'Rectifier',
                type: 'bar',
               // stack: '1',
                smooth: true,
                color: [
                    '#88745B',
                ],

                data: [2000, 7000, 1000,5000, 3000, 5000, 7000, 7000, 3000, 4000],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },
          
        ],

    };

    

    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<AmHeaderDropdown />} />

            <div className='row justify-content-end pe-4 pt-2'>
                {/* <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div> */}
                <div className='col-12 col-lg-3'>
                    <MyAllDownloadInfo />
                </div>
            </div>

            <div className='am_playground_container contentWrap pt-3'>
                <div className='am_pg_container_inner box_card mb-3'>
                    <div className='row'>
                        <div className='col-12 col-lg-8'>
                            <div className='am_pg_table'>
                                <div className='am_pg_tHeader'>
                                    <div className='am_pg_tHeading_row pb-2'>
                                        <div className='am_pg_tHeading'>
                                            <small className='label16 weight5'>Playground</small>
                                        </div>
                                        <div className='am_pg_tHeading_content'>
                                            <div className='am_pg_tHeading_sOption'>
                                                <FormControl sx={{ m: 1, minWidth: 150 }} className='m-0'>
                                                    <InputLabel htmlFor="grouped-native-select" className='label12 weight4'>X axis</InputLabel>
                                                    <Select defaultValue="" id="grouped-select" label="Grouping">
                                                        <MenuItem value={1} className='label16 weight6'>Transformer</MenuItem>
                                                        <MenuItem value={2} className='label16 weight6'>Option 2</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='pe-3'>
                                                <small className='label16 weight6'>VS</small>
                                            </div>
                                            <div className='am_pg_tHeading_sOption'>
                                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                                    <InputLabel htmlFor="grouped-select">Y axis</InputLabel>
                                                    <Select defaultValue="" id="grouped-select" label="Grouping">
                                                        <MenuItem value={1} className='label16 weight6'>Rectifier</MenuItem>
                                                        <MenuItem value={2} className='label16 weight6'>Option 2</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='am_pg_tHeading_sOption pe-0'>
                                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                                    <InputLabel htmlFor="grouped-select">Parameter Value</InputLabel>
                                                    <Select defaultValue="" id="grouped-select" label="Grouping">
                                                        <MenuItem value={1} className='label16 weight6'>NAR</MenuItem>
                                                        <MenuItem value={2} className='label16 weight6'>Option 2</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div className='am_pg_tContent'>
                                    <h4 className='am_pg_tSideHeading label18 weight5'>Rectifier</h4>
                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            <small className='label14 weight4'>Over Size</small>
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_blue4'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_green_light'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_red3'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            <small className='label14 weight4'>Right Size</small>
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_green_light'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_green_light'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_red3'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            <small className='label14 weight4'>Under Size</small>
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_red3'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_red3'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_red3'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            <small className='label14 weight4'>Asset Conflict</small>
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_roil_blue'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            <small className='label14 weight4'>No Rectifier</small>
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_dark_brown'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            <small className='label14 weight4'>Off Load</small>
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner bg_tuna_dark'>
                                                        <small className='label14 weight6 color_white'>998 Sites
                                                            <span className='d-block label14 weight6 color_white'>96.3%</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='am_pg_tContent_row'>
                                        <div className='am_pg_tC_row_h'>
                                            {/* <small className='label14 weight4'>Off Load</small> */}
                                        </div>
                                        <div className='am_pg_tRow_data'>
                                            <div className='width100 d-flex align-items-center'>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner am_pg_bottom_text'>
                                                        <small className='label14 weight5'>Over Size</small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner am_pg_bottom_text'>
                                                        <small className='label14 weight4'>Right Size</small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner am_pg_bottom_text'>
                                                        <small className='label14 weight4'>Under Size</small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner am_pg_bottom_text'>
                                                        <small className='label14 weight4'>Asset Conflict</small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner am_pg_bottom_text'>
                                                        <small className='label14 weight4'>Off Grid</small>
                                                    </div>
                                                </div>
                                                <div className='am_pg_tRow_box'>
                                                    <div className='am_pg_tRow_box_inner am_pg_bottom_text'>
                                                        <small className='label14 weight4'>Off Load</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='text-center'>
                                    <h4 className='label18 weight5'>Transformer</h4>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='am_playground_map'>
                                <div className="ampButtonsGroup pb-2">
                                    <ul className="ampButtonsBox">
                                        <li><Link className="ampButton">Listing</Link> </li>
                                        <li> <Link className="ampButton active">Map</Link></li>
                                    </ul>
                                </div>
                                <div className="amPlaygroundChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>

                   
                </div>
                <div className='row'>
                        <div className='col-lg-3 pb-3'>
                            <div className="ams_equipmentSizing_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                    <div className='box_card'>
                                        <div className="box_info_flexible border-bottom-0 pb-0">
                                            <h3 className='chartTitleHeading pb-0 pt-0'>
                                                Equipment Sizing
                                            </h3>
                                        </div>
                                        <ReactECharts option={equipmentSizing_data} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 pb-3'>
                        <div className="ams_equipmentSizing_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                    <div className='box_card'>
                                        <div className="box_info_flexible border-bottom-0 pb-0">
                                            <h3 className='chartTitleHeading pb-0 pt-0'>
                                              Ageing (Years)
                                            </h3>
                                        </div>
                                        <ReactECharts option={ageing_data} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 pb-3'>
                        <div className="ams_equipmentSizing_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                        <div className='box_card'>
                                        <div className="box_info_flexible border-bottom-0 pb-0">
                                            <h3 className='chartTitleHeading pb-0 pt-0'>
                                            Equipment  (EOL / NON-EOL)
                                            </h3>
                                        </div>
                                        <ReactECharts option={equipmentEol_data} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>

        </div>
    )
}
export default AmPlayground;