import React, { useState } from 'react';
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import InfrastructureWideLineChart from "../../../../Components/Charts/InfrastructureWideLineChart";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import RegionWiseColumnChart from "../../../../Components/Charts/RegionWiseColumnChart";
import ReliabilityGradingChartPie from "../../../../Components/Charts/ReliabilityGradingChartPie";
import LoadDistributionRegionWiseHeatMap from "../../../../Components/Charts/LoadDistributionRegionWiseHeatMap";
import AmpButtonsGroup from '../../../../Components/AmpButtonsGroup/AmpButtonsGroup';
import SpHeader from '../../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function SiteLoad() {
    const pageNo = 8;


    const iwData1 = [13, 12, 12, 12.2, 11, 11.5, 13, 13, 13, 13, 13, 13];
    const iwData2 = [14, 14, 14, 13, 13, 13.5, 0, 0, 0, 0, 0, 0];

    const iwData1_1 = [11.5, 8, 2, 13, 19, 13, 13, 13, 12, 12, 12.2, 11];
    const iwData2_2 = [11, 13, 8, 13, 13, 13.5, 3, 5, 10, 5, 7, 9];

    const [chartData1, setChartData1] = useState(iwData1);
    const [chartData2, setChartData2] = useState(iwData2);

    const lineoption = {
        title: {
            text: 'Infrastructure Wide (kW)',
            textStyle: {
                fontSize: 18,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '4px',
            show: true,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: '2022',
                type: 'line',
                color: [
                    '#E3D9F6',
                ],
                stack: 'Actual',
                smooth: true,
                // areaStyle: {normal: {}},
                data: chartData1
            },
            {
                name: '2023',
                type: 'line',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#7243D0',
                ],
                // areaStyle: {normal: {}},
                data: chartData2
            },

        ]
    };

    //   pie chart options start
    const piechartOptions = {
        title: {
            text: 'Load Distribution',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            bottom: 10,
            left: 'center',
            //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle"
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '30px',
            right: '10px',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [

            {
                type: 'category',
                boundaryGap: true,
                //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                type: 'pie',
                radius: '52%',
                center: ['50%', '44%'],
                selectedMode: 'single',
                color: [
                    '#99B3AB',
                    '#41BA70',
                    '#68D2F1',
                ],
                data: [
                    {
                        value: 3382,
                        name: '5 - 10 kW',
                        label: {
                            formatter: ['3382'].join('\n'),
                        }
                    },
                    {
                        value: 250,
                        name: '10 - 250 kW',
                        label: {
                            formatter: ['250'].join('\n'),
                        }
                    },
                    {
                        value: 1,
                        name: '250+ kW',
                        label: {
                            formatter: ['1'].join('\n'),
                        }
                    },

                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    // 


    const columnchartOptions = {
        title: {
            text: 'Region Wise (kW)',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },

        grid: {
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: '(Hours/Day)',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#7243D0',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13]
            },

        ]
    };

    //   


    const btnArray = [
        { id: 1, name: 'kW', active: true },
        { id: 2, name: 'Amps', active: false },
    ];
    const [array, setArray] = useState(btnArray);
    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
        console.log('array', id)
        if (id === 1) {
            setChartData1(iwData1);
            setChartData2(iwData2);
        }
        if (id === 2) {
            setChartData1(iwData1_1);
            setChartData2(iwData2_2);
        }

    };

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Sites Load', link: '/SiteLoad' },
    ];
    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SiteLoad',
        otherUrl: '/TeamsViewSummary',
    }
    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />


            <div className="contentWrap">
                <div className="chart_containter">
                    <div className="row pb-3">
                        <div className="col-8">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <div className="amp_btn_margin">
                                        <AmpButtonsGroup array={array} handleItemClick={handleItemClick} />
                                    </div>
                                    <InfrastructureWideLineChart options={lineoption} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">
                        <div className="col-3">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <RegionWiseColumnChart options={columnchartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <ReliabilityGradingChartPie options={piechartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <LoadDistributionRegionWiseHeatMap />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SiteLoad; 