import React from "react";
import './Welcome.css';
import Logo from "../../assets/images/logo_white.svg";
import { Link } from "react-router-dom";
function Welcome() {
  // Welcome to Thunder menu array
  const welcomeMenu = [
    {
      id: 1,
      enabled: true,
      name: 'Management Dashboard',
      description: 'Our management dashboard empowers informed decision-making for enhanced efficiency and success',
      url: '/Summary',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <g clipPath="url(#clip0_4685_14419)">
              <path d="M11.7052 12.9305C11.7058 13.197 11.6005 13.4529 11.4125 13.6419C11.2245 13.8308 10.9691 13.9374 10.7026 13.9383H2.68137C2.54934 13.938 2.41866 13.9117 2.2968 13.8609C2.17494 13.8101 2.06429 13.7358 1.97117 13.6422C1.87805 13.5486 1.80429 13.4375 1.75411 13.3154C1.70393 13.1933 1.67831 13.0625 1.67872 12.9305V1.83445C1.67845 1.70238 1.70419 1.57154 1.75449 1.44942C1.80478 1.32729 1.87863 1.21627 1.97183 1.12269C2.06503 1.02911 2.17575 0.954796 2.29767 0.904004C2.41959 0.853211 2.55032 0.82693 2.6824 0.82666L10.7036 0.840029C10.97 0.841118 11.2251 0.947842 11.4128 1.13678C11.6006 1.32572 11.7058 1.58144 11.7052 1.84782V12.9305Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M24.8129 6.77884C24.811 7.04295 24.7043 7.29549 24.5163 7.48099C24.3283 7.66649 24.0744 7.76976 23.8102 7.76812H15.789C15.6582 7.76907 15.5285 7.74423 15.4073 7.69501C15.2861 7.6458 15.1758 7.57317 15.0827 7.4813C14.9895 7.38942 14.9154 7.28009 14.8646 7.15955C14.8138 7.03902 14.7872 6.90965 14.7864 6.77884V1.82934C14.788 1.56551 14.8943 1.31311 15.0819 1.12761C15.2696 0.942115 15.5231 0.838687 15.787 0.840055L23.8082 0.826686C24.0726 0.82477 24.3269 0.92789 24.5153 1.11339C24.7037 1.29889 24.8107 1.55159 24.8129 1.81597V6.77884Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.7052 22.9725C11.7044 23.1037 11.6778 23.2333 11.6269 23.3542C11.5759 23.475 11.5017 23.5846 11.4084 23.6768C11.3151 23.7689 11.2046 23.8418 11.0831 23.8912C10.9617 23.9407 10.8317 23.9657 10.7005 23.9649L2.67931 23.9515C2.4152 23.9526 2.16146 23.8488 1.97384 23.6629C1.78622 23.4771 1.68007 23.2243 1.67871 22.9602V18.0148C1.67938 17.8838 1.70586 17.7543 1.75663 17.6336C1.80739 17.5129 1.88145 17.4034 1.97457 17.3113C2.06769 17.2192 2.17804 17.1464 2.29932 17.097C2.42059 17.0476 2.55042 17.0226 2.68136 17.0235H10.7026C10.967 17.0221 11.2211 17.1257 11.4091 17.3116C11.5971 17.4975 11.7036 17.7504 11.7052 18.0148V22.9725Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.7901 23.9651C15.6579 23.9648 15.5271 23.9385 15.4051 23.8877C15.2831 23.8368 15.1723 23.7624 15.0791 23.6687C14.9859 23.575 14.9121 23.4639 14.8619 23.3416C14.8116 23.2194 14.786 23.0885 14.7864 22.9563V11.8623C14.786 11.7303 14.8116 11.5994 14.8618 11.4772C14.9119 11.355 14.9857 11.2439 15.0788 11.1503C15.1719 11.0566 15.2825 10.9822 15.4044 10.9313C15.5263 10.8803 15.657 10.8539 15.789 10.8535H23.8102C23.9423 10.8539 24.073 10.8803 24.1949 10.9313C24.3168 10.9822 24.4274 11.0566 24.5205 11.1503C24.6136 11.2439 24.6874 11.355 24.7375 11.4772C24.7877 11.5994 24.8133 11.7303 24.8129 11.8623V22.9429C24.8134 23.2094 24.7083 23.4652 24.5206 23.6543C24.3328 23.8434 24.0778 23.9504 23.8113 23.9517L15.7901 23.9651Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_4685_14419">
                <rect width="24.6807" height="24.6807" fill="white" transform="translate(0.907471 0.0493164)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 2,
      enabled: true,
      name: 'Teams View',
      description: 'Operation made easy. This will give you analysis, performance and real time monitoring of the network..',
      url: '/GridUnavailability',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
            <g clipPath="url(#clip0_4685_14430)">
              <path d="M5.53416 21.0708C7.02503 21.0708 8.23361 19.8622 8.23361 18.3713C8.23361 16.8805 7.02503 15.6719 5.53416 15.6719C4.0433 15.6719 2.83472 16.8805 2.83472 18.3713C2.83472 19.8622 4.0433 21.0708 5.53416 21.0708Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.39044 24.1561C9.01767 23.4572 8.46196 22.8727 7.78271 22.4651C7.10346 22.0576 6.32622 21.8423 5.53409 21.8423C4.74195 21.8423 3.96471 22.0576 3.28547 22.4651C2.60622 22.8727 2.0505 23.4572 1.67773 24.1561" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.9595 21.0708C22.4503 21.0708 23.6589 19.8622 23.6589 18.3713C23.6589 16.8805 22.4503 15.6719 20.9595 15.6719C19.4686 15.6719 18.26 16.8805 18.26 18.3713C18.26 19.8622 19.4686 21.0708 20.9595 21.0708Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M24.8157 24.1561C24.443 23.4572 23.8873 22.8727 23.208 22.4651C22.5288 22.0576 21.7515 21.8423 20.9594 21.8423C20.1672 21.8423 19.39 22.0576 18.7108 22.4651C18.0315 22.8727 17.4758 23.4572 17.103 24.1561" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.2468 6.41696C14.7377 6.41696 15.9463 5.20838 15.9463 3.71751C15.9463 2.22665 14.7377 1.01807 13.2468 1.01807C11.7559 1.01807 10.5474 2.22665 10.5474 3.71751C10.5474 5.20838 11.7559 6.41696 13.2468 6.41696Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.5879 8.73057C16.1758 8.24668 15.6635 7.85805 15.0864 7.59154C14.5094 7.32503 13.8814 7.18701 13.2457 7.18701C12.6101 7.18701 11.9821 7.32503 11.405 7.59154C10.828 7.85805 10.3157 8.24668 9.90356 8.73057" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.2139 20.5127C12.1834 21.2635 14.3615 21.2569 16.3264 20.4941" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.43546 6.4165C6.59121 7.20721 5.91829 8.16282 5.45838 9.22416C4.99846 10.2855 4.76135 11.43 4.76172 12.5867C4.76172 12.8468 4.77714 13.1008 4.8008 13.3579" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6915 13.3579C21.7142 13.1029 21.7306 12.8438 21.7306 12.5867C21.7312 11.4299 21.4941 10.2854 21.0342 9.22405C20.5743 8.16268 19.9013 7.20709 19.0569 6.4165" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_4685_14430">
                <rect width="24.6807" height="24.6807" fill="white" transform="translate(0.907471 0.246582)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 3,
      enabled: true,
      name: 'Asset Management & Planning',
      description: 'Efficiently track, optimize, and maximize asset performance with our intuitive Asset Manager solution',
      url: '/AmTransformer',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <g clipPath="url(#clip0_4685_14437)">
              <path d="M23.8108 13.5083H2.6842C2.12875 13.5083 1.67847 13.9586 1.67847 14.514V23.2335C1.67847 23.789 2.12875 24.2392 2.6842 24.2392H23.8108C24.3663 24.2392 24.8166 23.789 24.8166 23.2335V14.514C24.8166 13.9586 24.3663 13.5083 23.8108 13.5083Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.22095 13.5082V7.50569C3.22258 7.23795 3.33037 6.98179 3.52066 6.79343C3.71095 6.60506 3.96819 6.49989 4.23594 6.50099H12.8618C13.0191 6.50074 13.1742 6.53703 13.315 6.60699C13.4558 6.67695 13.5784 6.77866 13.6732 6.9041L14.8928 8.81274H21.4887C21.7567 8.81192 22.014 8.9175 22.2041 9.10627C22.3942 9.29505 22.5016 9.55158 22.5027 9.81951V13.5082" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.2292 16.5259H16.2657" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.2041 5.72773V4.42068C21.2025 4.15294 21.0947 3.89678 20.9044 3.70842C20.7141 3.52005 20.4569 3.41488 20.1892 3.41597H14.8931L13.6735 1.50322C13.5787 1.37778 13.456 1.27607 13.3152 1.20611C13.1744 1.13615 13.0193 1.09987 12.8621 1.10011H5.53501C5.40226 1.09956 5.2707 1.12518 5.14785 1.17548C5.025 1.22578 4.91326 1.29979 4.81901 1.39327C4.72475 1.48676 4.64984 1.5979 4.59854 1.72034C4.54724 1.84278 4.52056 1.97412 4.52002 2.10687V3.17945" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_4685_14437">
                <rect width="24.6807" height="24.6807" fill="white" transform="translate(0.907227 0.331055)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 4,
      enabled: true,
      name: 'Sites',
      description: 'Unlock the potential of every location with our Sites management tool, streamlining operations and amplifying growth.',
      url: '/MapView',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <g clipPath="url(#clip0_4685_14443)">
              <path d="M1.7356 24.5225H24.8737" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.13443 18.3525H4.04935C3.84479 18.3525 3.64862 18.4338 3.50398 18.5784C3.35933 18.7231 3.27808 18.9193 3.27808 19.1238V24.5227H7.9057V19.1238C7.9057 18.9193 7.82444 18.7231 7.6798 18.5784C7.53516 18.4338 7.33898 18.3525 7.13443 18.3525Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.8473 10.6396H11.7622C11.5577 10.6396 11.3615 10.7209 11.2169 10.8655C11.0722 11.0102 10.991 11.2064 10.991 11.4109V24.5225H15.6186V11.4109C15.6186 11.2064 15.5373 11.0102 15.3927 10.8655C15.248 10.7209 15.0519 10.6396 14.8473 10.6396Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.56 15.2676H19.4749C19.2703 15.2676 19.0742 15.3488 18.9295 15.4935C18.7849 15.6381 18.7036 15.8343 18.7036 16.0388V24.5228H23.3312V16.0388C23.3312 15.8343 23.25 15.6381 23.1053 15.4935C22.9607 15.3488 22.7645 15.2676 22.56 15.2676Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.27814 12.9537C4.13006 12.9537 4.82068 12.2631 4.82068 11.4112C4.82068 10.5593 4.13006 9.86865 3.27814 9.86865C2.42621 9.86865 1.7356 10.5593 1.7356 11.4112C1.7356 12.2631 2.42621 12.9537 3.27814 12.9537Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.2195 4.46985C11.0715 4.46985 11.7621 3.77923 11.7621 2.92731C11.7621 2.07538 11.0715 1.38477 10.2195 1.38477C9.36762 1.38477 8.677 2.07538 8.677 2.92731C8.677 3.77923 9.36762 4.46985 10.2195 4.46985Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.3311 8.32581C24.183 8.32581 24.8737 7.63519 24.8737 6.78326C24.8737 5.93134 24.183 5.24072 23.3311 5.24072C22.4792 5.24072 21.7886 5.93134 21.7886 6.78326C21.7886 7.63519 22.4792 8.32581 23.3311 8.32581Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.25537 10.2172L9.24292 4.12109" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.8514 6.34764L11.6995 3.3623" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_4685_14443">
                <rect width="24.6807" height="24.6807" fill="white" transform="translate(0.964355 0.613281)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 8,
      enabled: true,
      name: 'Sites Onboarding',
      description: 'Inputting site details effortlessly for smooth onboarding and accurate record-keeping.',
      url: '/SitesOnboarding',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <g clipPath="url(#clip0_4685_14437)">
              <path d="M23.8108 13.5083H2.6842C2.12875 13.5083 1.67847 13.9586 1.67847 14.514V23.2335C1.67847 23.789 2.12875 24.2392 2.6842 24.2392H23.8108C24.3663 24.2392 24.8166 23.789 24.8166 23.2335V14.514C24.8166 13.9586 24.3663 13.5083 23.8108 13.5083Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.22095 13.5082V7.50569C3.22258 7.23795 3.33037 6.98179 3.52066 6.79343C3.71095 6.60506 3.96819 6.49989 4.23594 6.50099H12.8618C13.0191 6.50074 13.1742 6.53703 13.315 6.60699C13.4558 6.67695 13.5784 6.77866 13.6732 6.9041L14.8928 8.81274H21.4887C21.7567 8.81192 22.014 8.9175 22.2041 9.10627C22.3942 9.29505 22.5016 9.55158 22.5027 9.81951V13.5082" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.2292 16.5259H16.2657" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.2041 5.72773V4.42068C21.2025 4.15294 21.0947 3.89678 20.9044 3.70842C20.7141 3.52005 20.4569 3.41488 20.1892 3.41597H14.8931L13.6735 1.50322C13.5787 1.37778 13.456 1.27607 13.3152 1.20611C13.1744 1.13615 13.0193 1.09987 12.8621 1.10011H5.53501C5.40226 1.09956 5.2707 1.12518 5.14785 1.17548C5.025 1.22578 4.91326 1.29979 4.81901 1.39327C4.72475 1.48676 4.64984 1.5979 4.59854 1.72034C4.54724 1.84278 4.52056 1.97412 4.52002 2.10687V3.17945" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_4685_14437">
                <rect width="24.6807" height="24.6807" fill="white" transform="translate(0.907227 0.331055)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 5,
      enabled: true,
      name: 'Fueling',
      description: 'Fuel data is vital for energy analytics platforms, facilitating cost analysis, emissions tracking, and efficient energy management.',
      url: '/SiteFueling',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
          <path d="M14.9018 13.934C14.337 14.6551 13.6137 15.2387 12.7873 15.64C11.9609 16.0414 11.0531 16.25 10.1332 16.25H2.51716C2.1166 16.25 1.73244 16.092 1.4492 15.8107C1.16596 15.5294 1.00684 15.1478 1.00684 14.75V9.5C1.00684 9.10218 1.16596 8.72064 1.4492 8.43934C1.73244 8.15804 2.1166 8 2.51716 8H8.78398C9.12696 8.0001 9.45968 8.11613 9.72743 8.329L12.4853 10.516C12.6735 10.6655 12.895 10.7682 13.1313 10.8153C13.3676 10.8624 13.6118 10.8527 13.8436 10.787L18.3755 9.5L14.9018 13.934Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.0481 5V8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M4.02759 5H10.0689" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.3755 9.5L20.641 10.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.9066 18.594C22.9066 19.1658 22.6779 19.7142 22.2708 20.1185C21.8637 20.5229 21.3115 20.75 20.7358 20.75C20.16 20.75 19.6079 20.5229 19.2008 20.1185C18.7937 19.7142 18.5649 19.1658 18.5649 18.594C18.5649 17.4 20.0118 15 20.7358 15C21.4597 15 22.9066 17.4 22.9066 18.594Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    },
    {
      id: 6,
      enabled: true,
      name: 'Data Stories',
      description: 'Peak hour data unveils strategies for efficient energy management and sustainability.',
      url: '/DataStories',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
      <path d="M12.3411 10.5598C17.7364 10.5598 22.1102 8.71819 22.1102 6.44646C22.1102 4.17473 17.7364 2.33313 12.3411 2.33313C6.9457 2.33313 2.5719 4.17473 2.5719 6.44646C2.5719 8.71819 6.9457 10.5598 12.3411 10.5598Z" stroke="black" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.1091 10.5588C22.1091 12.8314 17.7346 14.6721 12.34 14.6721C6.94533 14.6721 2.5708 12.8314 2.5708 10.5588" stroke="black" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22.1091 15.1863C22.1091 17.4589 17.7346 19.2996 12.34 19.2996C6.94533 19.2996 2.5708 17.4589 2.5708 15.1863" stroke="black" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.5708 6.44543V19.8138C2.5708 22.0864 6.94533 23.9271 12.34 23.9271C17.7346 23.9271 22.1091 22.0864 22.1091 19.8138V6.44543" stroke="black" stroke-width="1.54" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
    },
    {
      id: 7,
      enabled: false,
      name: 'Alarms ',
      description: 'Stay informed and proactive with our Alarms system, ensuring timely response and safeguarding your operations.',
      url: '',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M10 21.75C10.127 22.1827 10.3908 22.5626 10.7518 22.8328C11.1127 23.1031 11.5516 23.2492 12.0025 23.2492C12.4534 23.2492 12.8923 23.1031 13.2532 22.8328C13.6142 22.5626 13.878 22.1827 14.005 21.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 3V0.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 3C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5C19.5 17.546 21 18.75 21 18.75H3C3 18.75 4.5 16.834 4.5 10.5C4.5 8.51088 5.29018 6.60322 6.6967 5.1967C8.10322 3.79018 10.0109 3 12 3V3Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`
    },
    {
      id: 8,
      enabled: true,
      name: 'Reports',
      description: 'Transform data into actionable insights with our Reports feature, driving informed decisions for superior outcomes.',
      url: '/Reports',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15.75 4.5H19.5C19.8978 4.5 20.2794 4.65804 20.5607 4.93934C20.842 5.22064 21 5.60218 21 6V21.75C21 22.1478 20.842 22.5294 20.5607 22.8107C20.2794 23.092 19.8978 23.25 19.5 23.25H4.5C4.10218 23.25 3.72064 23.092 3.43934 22.8107C3.15804 22.5294 3 22.1478 3 21.75V6C3 5.60218 3.15804 5.22064 3.43934 4.93934C3.72064 4.65804 4.10218 4.5 4.5 4.5H8.25C8.25 3.50544 8.64509 2.55161 9.34835 1.84835C10.0516 1.14509 11.0054 0.75 12 0.75C12.9946 0.75 13.9484 1.14509 14.6517 1.84835C15.3549 2.55161 15.75 3.50544 15.75 4.5V4.5Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 3.75C12.0742 3.75 12.1467 3.77199 12.2083 3.8132C12.27 3.8544 12.3181 3.91297 12.3465 3.98149C12.3748 4.05002 12.3823 4.12542 12.3678 4.19816C12.3533 4.2709 12.3176 4.33772 12.2652 4.39016C12.2127 4.44261 12.1459 4.47833 12.0732 4.49279C12.0004 4.50726 11.925 4.49984 11.8565 4.47145C11.788 4.44307 11.7294 4.39501 11.6882 4.33334C11.647 4.27167 11.625 4.19917 11.625 4.125C11.625 4.02554 11.6645 3.93016 11.7348 3.85983C11.8052 3.78951 11.9005 3.75 12 3.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.75 10.5H12" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 10.5H17.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.25 14.25H12" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 14.25H6.75" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.75 18H12" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 18H17.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`
    },

  ]

  // quick links menu array
  const quickLinks = [
    {
      id: 1,
      name: 'Single Site Dashboard',
      url: '/SingleSiteSummary',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9605 20.8193H4.46436C3.39416 20.8193 2.52734 19.9525 2.52734 18.8823V5.32324C2.52734 4.25304 3.39416 3.38623 4.46436 3.38623H19.9605C21.0307 3.38623 21.8975 4.25304 21.8975 5.32324V18.8823C21.8975 19.9525 21.0307 20.8193 19.9605 20.8193Z" stroke="url(#paint0_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.52734 8.22888H21.8975" stroke="url(#paint1_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.21863 5.79777C5.21572 5.79777 5.21379 5.79971 5.21379 5.80261C5.21379 5.80552 5.21572 5.80745 5.21863 5.80745C5.22153 5.80745 5.22347 5.80552 5.22347 5.80261C5.22347 5.79971 5.22153 5.79777 5.21863 5.79777" stroke="url(#paint2_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.68152 5.79777C7.67861 5.79777 7.67668 5.79971 7.67668 5.80261C7.67668 5.80552 7.67958 5.80745 7.68152 5.80745C7.68442 5.80745 7.68636 5.80552 7.68636 5.80261C7.68636 5.79971 7.68442 5.79777 7.68152 5.79777" stroke="url(#paint3_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.1405 5.79777C10.1376 5.79777 10.1357 5.79971 10.1357 5.80261C10.1357 5.80552 10.1376 5.80745 10.1405 5.80745C10.1434 5.80745 10.1453 5.80552 10.1453 5.80261C10.1453 5.79971 10.1434 5.79777 10.1405 5.79777" stroke="url(#paint4_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.2754 14.0398H7.36987C6.83526 14.0398 6.40137 13.6059 6.40137 13.0713V12.1028C6.40137 11.5682 6.83526 11.1343 7.36987 11.1343H10.2754C10.81 11.1343 11.2439 11.5682 11.2439 12.1028V13.0713C11.2439 13.6059 10.81 14.0398 10.2754 14.0398Z" stroke="url(#paint5_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 11.1342H18.0234" stroke="url(#paint6_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 14.0399H17.0549" stroke="url(#paint7_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.0234 20.8193V17.9138C18.0234 17.3792 17.5895 16.9453 17.0549 16.9453H7.36987C6.83526 16.9453 6.40137 17.3792 6.40137 17.9138V20.8193" stroke="url(#paint8_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_4758_365" x1="7.96006" y1="20.4317" x2="18.4957" y2="9.51557" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4758_365" x1="7.96006" y1="8.6916" x2="8.02268" y2="7.5236" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4758_365" x1="5.00601" y1="6.26533" x2="5.59026" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4758_365" x1="7.4689" y1="6.26533" x2="8.05315" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4758_365" x1="9.92789" y1="6.26533" x2="10.5121" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint5_linear_4758_365" x1="7.75955" y1="13.9752" x2="9.35859" y2="11.49" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint6_linear_4758_365" x1="15.236" y1="11.5969" x2="15.5289" y2="10.5041" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint7_linear_4758_365" x1="14.9643" y1="14.5026" x2="15.3356" y2="13.464" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint8_linear_4758_365" x1="9.661" y1="20.7332" x2="11.1461" y2="16.5786" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
            </defs>
          </svg>`
    },
    {
      id: 2,
      name: 'Single Site Snapshot',
      url: '/SitesSnapshotView',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9605 20.8193H4.46436C3.39416 20.8193 2.52734 19.9525 2.52734 18.8823V5.32324C2.52734 4.25304 3.39416 3.38623 4.46436 3.38623H19.9605C21.0307 3.38623 21.8975 4.25304 21.8975 5.32324V18.8823C21.8975 19.9525 21.0307 20.8193 19.9605 20.8193Z" stroke="url(#paint0_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.52734 8.22888H21.8975" stroke="url(#paint1_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.21863 5.79777C5.21572 5.79777 5.21379 5.79971 5.21379 5.80261C5.21379 5.80552 5.21572 5.80745 5.21863 5.80745C5.22153 5.80745 5.22347 5.80552 5.22347 5.80261C5.22347 5.79971 5.22153 5.79777 5.21863 5.79777" stroke="url(#paint2_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.68152 5.79777C7.67861 5.79777 7.67668 5.79971 7.67668 5.80261C7.67668 5.80552 7.67958 5.80745 7.68152 5.80745C7.68442 5.80745 7.68636 5.80552 7.68636 5.80261C7.68636 5.79971 7.68442 5.79777 7.68152 5.79777" stroke="url(#paint3_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.1405 5.79777C10.1376 5.79777 10.1357 5.79971 10.1357 5.80261C10.1357 5.80552 10.1376 5.80745 10.1405 5.80745C10.1434 5.80745 10.1453 5.80552 10.1453 5.80261C10.1453 5.79971 10.1434 5.79777 10.1405 5.79777" stroke="url(#paint4_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.2754 14.0398H7.36987C6.83526 14.0398 6.40137 13.6059 6.40137 13.0713V12.1028C6.40137 11.5682 6.83526 11.1343 7.36987 11.1343H10.2754C10.81 11.1343 11.2439 11.5682 11.2439 12.1028V13.0713C11.2439 13.6059 10.81 14.0398 10.2754 14.0398Z" stroke="url(#paint5_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 11.1342H18.0234" stroke="url(#paint6_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 14.0399H17.0549" stroke="url(#paint7_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.0234 20.8193V17.9138C18.0234 17.3792 17.5895 16.9453 17.0549 16.9453H7.36987C6.83526 16.9453 6.40137 17.3792 6.40137 17.9138V20.8193" stroke="url(#paint8_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_4758_365" x1="7.96006" y1="20.4317" x2="18.4957" y2="9.51557" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4758_365" x1="7.96006" y1="8.6916" x2="8.02268" y2="7.5236" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4758_365" x1="5.00601" y1="6.26533" x2="5.59026" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4758_365" x1="7.4689" y1="6.26533" x2="8.05315" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4758_365" x1="9.92789" y1="6.26533" x2="10.5121" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint5_linear_4758_365" x1="7.75955" y1="13.9752" x2="9.35859" y2="11.49" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint6_linear_4758_365" x1="15.236" y1="11.5969" x2="15.5289" y2="10.5041" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint7_linear_4758_365" x1="14.9643" y1="14.5026" x2="15.3356" y2="13.464" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint8_linear_4758_365" x1="9.661" y1="20.7332" x2="11.1461" y2="16.5786" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
            </defs>
          </svg>`
    },
    {
      id: 3,
      name: 'Single Site Parameters',
      url: '',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9605 20.8193H4.46436C3.39416 20.8193 2.52734 19.9525 2.52734 18.8823V5.32324C2.52734 4.25304 3.39416 3.38623 4.46436 3.38623H19.9605C21.0307 3.38623 21.8975 4.25304 21.8975 5.32324V18.8823C21.8975 19.9525 21.0307 20.8193 19.9605 20.8193Z" stroke="url(#paint0_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.52734 8.22888H21.8975" stroke="url(#paint1_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.21863 5.79777C5.21572 5.79777 5.21379 5.79971 5.21379 5.80261C5.21379 5.80552 5.21572 5.80745 5.21863 5.80745C5.22153 5.80745 5.22347 5.80552 5.22347 5.80261C5.22347 5.79971 5.22153 5.79777 5.21863 5.79777" stroke="url(#paint2_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.68152 5.79777C7.67861 5.79777 7.67668 5.79971 7.67668 5.80261C7.67668 5.80552 7.67958 5.80745 7.68152 5.80745C7.68442 5.80745 7.68636 5.80552 7.68636 5.80261C7.68636 5.79971 7.68442 5.79777 7.68152 5.79777" stroke="url(#paint3_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.1405 5.79777C10.1376 5.79777 10.1357 5.79971 10.1357 5.80261C10.1357 5.80552 10.1376 5.80745 10.1405 5.80745C10.1434 5.80745 10.1453 5.80552 10.1453 5.80261C10.1453 5.79971 10.1434 5.79777 10.1405 5.79777" stroke="url(#paint4_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.2754 14.0398H7.36987C6.83526 14.0398 6.40137 13.6059 6.40137 13.0713V12.1028C6.40137 11.5682 6.83526 11.1343 7.36987 11.1343H10.2754C10.81 11.1343 11.2439 11.5682 11.2439 12.1028V13.0713C11.2439 13.6059 10.81 14.0398 10.2754 14.0398Z" stroke="url(#paint5_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 11.1342H18.0234" stroke="url(#paint6_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 14.0399H17.0549" stroke="url(#paint7_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.0234 20.8193V17.9138C18.0234 17.3792 17.5895 16.9453 17.0549 16.9453H7.36987C6.83526 16.9453 6.40137 17.3792 6.40137 17.9138V20.8193" stroke="url(#paint8_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_4758_365" x1="7.96006" y1="20.4317" x2="18.4957" y2="9.51557" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4758_365" x1="7.96006" y1="8.6916" x2="8.02268" y2="7.5236" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4758_365" x1="5.00601" y1="6.26533" x2="5.59026" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4758_365" x1="7.4689" y1="6.26533" x2="8.05315" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4758_365" x1="9.92789" y1="6.26533" x2="10.5121" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint5_linear_4758_365" x1="7.75955" y1="13.9752" x2="9.35859" y2="11.49" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint6_linear_4758_365" x1="15.236" y1="11.5969" x2="15.5289" y2="10.5041" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint7_linear_4758_365" x1="14.9643" y1="14.5026" x2="15.3356" y2="13.464" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint8_linear_4758_365" x1="9.661" y1="20.7332" x2="11.1461" y2="16.5786" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
            </defs>
          </svg>`
    },
    {
      id: 4,
      name: 'Single Site Alarms',
      url: '',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9605 20.8193H4.46436C3.39416 20.8193 2.52734 19.9525 2.52734 18.8823V5.32324C2.52734 4.25304 3.39416 3.38623 4.46436 3.38623H19.9605C21.0307 3.38623 21.8975 4.25304 21.8975 5.32324V18.8823C21.8975 19.9525 21.0307 20.8193 19.9605 20.8193Z" stroke="url(#paint0_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.52734 8.22888H21.8975" stroke="url(#paint1_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.21863 5.79777C5.21572 5.79777 5.21379 5.79971 5.21379 5.80261C5.21379 5.80552 5.21572 5.80745 5.21863 5.80745C5.22153 5.80745 5.22347 5.80552 5.22347 5.80261C5.22347 5.79971 5.22153 5.79777 5.21863 5.79777" stroke="url(#paint2_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.68152 5.79777C7.67861 5.79777 7.67668 5.79971 7.67668 5.80261C7.67668 5.80552 7.67958 5.80745 7.68152 5.80745C7.68442 5.80745 7.68636 5.80552 7.68636 5.80261C7.68636 5.79971 7.68442 5.79777 7.68152 5.79777" stroke="url(#paint3_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.1405 5.79777C10.1376 5.79777 10.1357 5.79971 10.1357 5.80261C10.1357 5.80552 10.1376 5.80745 10.1405 5.80745C10.1434 5.80745 10.1453 5.80552 10.1453 5.80261C10.1453 5.79971 10.1434 5.79777 10.1405 5.79777" stroke="url(#paint4_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.2754 14.0398H7.36987C6.83526 14.0398 6.40137 13.6059 6.40137 13.0713V12.1028C6.40137 11.5682 6.83526 11.1343 7.36987 11.1343H10.2754C10.81 11.1343 11.2439 11.5682 11.2439 12.1028V13.0713C11.2439 13.6059 10.81 14.0398 10.2754 14.0398Z" stroke="url(#paint5_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 11.1342H18.0234" stroke="url(#paint6_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.1494 14.0399H17.0549" stroke="url(#paint7_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.0234 20.8193V17.9138C18.0234 17.3792 17.5895 16.9453 17.0549 16.9453H7.36987C6.83526 16.9453 6.40137 17.3792 6.40137 17.9138V20.8193" stroke="url(#paint8_linear_4758_365)" stroke-width="1.45276" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_4758_365" x1="7.96006" y1="20.4317" x2="18.4957" y2="9.51557" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4758_365" x1="7.96006" y1="8.6916" x2="8.02268" y2="7.5236" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4758_365" x1="5.00601" y1="6.26533" x2="5.59026" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4758_365" x1="7.4689" y1="6.26533" x2="8.05315" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4758_365" x1="9.92789" y1="6.26533" x2="10.5121" y2="5.72051" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint5_linear_4758_365" x1="7.75955" y1="13.9752" x2="9.35859" y2="11.49" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint6_linear_4758_365" x1="15.236" y1="11.5969" x2="15.5289" y2="10.5041" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint7_linear_4758_365" x1="14.9643" y1="14.5026" x2="15.3356" y2="13.464" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint8_linear_4758_365" x1="9.661" y1="20.7332" x2="11.1461" y2="16.5786" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
            </defs>
          </svg>`
    },
    {
      id: 5,
      name: 'Fuel Consumption',
      url: '',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_4689_5245)">
              <path d="M15.4921 14.2411C14.9273 14.9623 14.2041 15.5458 13.3777 15.9472C12.5513 16.3485 11.6435 16.5572 10.7235 16.5571H3.10749C2.70693 16.5571 2.32277 16.3991 2.03953 16.1178C1.75629 15.8365 1.59717 15.455 1.59717 15.0571V9.80713C1.59717 9.4093 1.75629 9.02777 2.03953 8.74647C2.32277 8.46516 2.70693 8.30713 3.10749 8.30713H9.37432C9.71729 8.30722 10.05 8.42325 10.3178 8.63613L13.0756 10.8231C13.2638 10.9727 13.4853 11.0753 13.7216 11.1224C13.9579 11.1696 14.2021 11.1599 14.4339 11.0941L18.9659 9.80713L15.4921 14.2411Z" stroke="url(#paint0_linear_4689_5245)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.63843 5.30713V8.30713" stroke="url(#paint1_linear_4689_5245)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.61792 5.30713H10.6592" stroke="url(#paint2_linear_4689_5245)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.9658 9.80713L21.2313 10.5571" stroke="url(#paint3_linear_4689_5245)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.4969 18.9011C23.4969 19.4729 23.2682 20.0213 22.8611 20.4257C22.454 20.83 21.9019 21.0571 21.3261 21.0571C20.7504 21.0571 20.1982 20.83 19.7911 20.4257C19.384 20.0213 19.1553 19.4729 19.1553 18.9011C19.1553 17.7071 20.6022 15.3071 21.3261 15.3071C22.0501 15.3071 23.4969 17.7071 23.4969 18.9011Z" stroke="url(#paint4_linear_4689_5245)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear_4689_5245" x1="6.46854" y1="16.3737" x2="10.504" y2="8.45128" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4689_5245" x1="7.9189" y1="8.24042" x2="8.94735" y2="7.92074" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4689_5245" x1="6.31231" y1="6.28489" x2="6.52044" y2="5.11239" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4689_5245" x1="19.6012" y1="10.5405" x2="19.8872" y2="9.7349" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4689_5245" x1="20.373" y1="20.9293" x2="23.6466" y2="18.6243" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <clipPath id="clip0_4689_5245">
                <rect width="24.1651" height="24" fill="white" transform="translate(0.590332 0.307129)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 6,
      name: 'Energy OPEX',
      url: '',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M12.4361 6.30713V7.80713" stroke="url(#paint0_linear_4758_661)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.8439 5.94317C22.3828 9.45789 22.3828 15.1564 18.8439 18.6711C15.305 22.1858 9.56733 22.1858 6.02845 18.6711C2.48954 15.1563 2.48954 9.45787 6.02845 5.94317C9.56735 2.42845 15.305 2.42845 18.8439 5.94317" stroke="url(#paint1_linear_4758_661)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.4361 18.3071V16.8071" stroke="url(#paint2_linear_4758_661)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.41553 14.5621V14.5621C9.41553 15.8051 10.4295 16.8121 11.681 16.8121H13.3353C14.5063 16.8121 15.4568 15.8691 15.4568 14.7051V14.7051C15.4568 13.7391 14.7953 12.8971 13.8518 12.6621L11.0205 11.9571C10.077 11.7221 9.41553 10.8801 9.41553 9.91413V9.91413C9.41553 8.75013 10.365 7.80713 11.537 7.80713H13.1913C14.4429 7.80713 15.4568 8.81413 15.4568 10.0571V10.0571" stroke="url(#paint3_linear_4758_661)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
              <linearGradient id="paint0_linear_4758_661" x1="12.215" y1="7.77378" x2="13.0309" y2="7.26306" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4758_661" x1="8.45744" y1="20.9069" x2="19.3209" y2="10.7069" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4758_661" x1="12.215" y1="18.2738" x2="13.0309" y2="17.7631" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4758_661" x1="11.1099" y1="16.6119" x2="16.0069" y2="13.5484" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
            </defs>
          </svg>`
    },
    {
      id: 7,
      name: 'Solar Production',
      url: '',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g clipPath="url(#clip0_4689_5278)">
              <path d="M18.8779 17.1938H5.38956C5.28958 17.1939 5.19106 17.1699 5.10228 17.1239C5.0135 17.078 4.93707 17.0113 4.87941 16.9297C4.82175 16.848 4.78455 16.7537 4.77094 16.6546C4.75734 16.5556 4.76772 16.4547 4.80123 16.3605L7.47956 8.86051C7.52248 8.73874 7.60212 8.63328 7.70749 8.55865C7.81286 8.48403 7.93878 8.44392 8.06789 8.44385H15.6629C15.7852 8.44383 15.9049 8.47976 16.0069 8.54717C16.109 8.61458 16.189 8.7105 16.2371 8.82301L19.4521 16.323C19.4927 16.418 19.5092 16.5217 19.4999 16.6246C19.4907 16.7275 19.4561 16.8266 19.3992 16.9129C19.3423 16.9992 19.2649 17.07 19.174 17.119C19.083 17.1681 18.9812 17.1938 18.8779 17.1938V17.1938Z" stroke="url(#paint0_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.1279 17.1938V19.6938" stroke="url(#paint1_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.8779 17.1938V19.6938" stroke="url(#paint2_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.21142 9.69448C3.85947 9.30365 3.59366 8.84315 3.43127 8.3429C3.26888 7.84265 3.21353 7.31383 3.26883 6.7908C3.32413 6.26776 3.48883 5.76221 3.75223 5.30698C4.01564 4.85174 4.37186 4.457 4.79776 4.14841C5.22366 3.83981 5.70972 3.62425 6.22435 3.51574C6.73898 3.40723 7.27069 3.40818 7.78493 3.51855C8.29917 3.62891 8.78444 3.84621 9.20923 4.15633C9.63402 4.46646 9.98882 4.86247 10.2506 5.31865" stroke="url(#paint3_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.00293 1.56885V0.943848" stroke="url(#paint4_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.3779 3.44385L12.0029 2.81885" stroke="url(#paint5_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.62793 3.44385L2.00293 2.81885" stroke="url(#paint6_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.37793 7.19385H0.75293" stroke="url(#paint7_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.62793 10.9438L2.00293 11.5688" stroke="url(#paint8_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0029 8.44385V17.1938" stroke="url(#paint9_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.28857 12.1938H17.6861" stroke="url(#paint10_linear_4689_5278)" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear_4689_5278" x1="8.89849" y1="16.9993" x2="13.6929" y2="9.46924" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4689_5278" x1="10.4084" y1="19.6383" x2="11.3985" y2="19.269" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4689_5278" x1="14.1584" y1="19.6383" x2="15.1485" y2="19.269" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4689_5278" x1="5.21204" y1="9.55531" x2="8.99389" y2="5.61003" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4689_5278" x1="7.2834" y1="1.55495" x2="7.63299" y2="1.03335" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint5_linear_4689_5278" x1="11.5532" y1="3.42995" x2="11.9303" y2="3.07837" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint6_linear_4689_5278" x1="2.17822" y1="3.42995" x2="2.55525" y2="3.07837" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint7_linear_4689_5278" x1="0.928223" y1="8.17161" x2="1.45439" y2="7.86496" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint8_linear_4689_5278" x1="2.17822" y1="11.555" x2="2.55525" y2="11.2034" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint9_linear_4689_5278" x1="12.2834" y1="16.9993" x2="13.3986" y2="16.8804" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint10_linear_4689_5278" x1="9.48522" y1="13.1716" x2="9.59801" y2="11.9728" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <clipPath id="clip0_4689_5278">
                <rect width="20" height="20" fill="white" transform="translate(0.12793 0.318848)"/>
              </clipPath>
            </defs>
          </svg>`
    },
    {
      id: 8,
      name: 'Battery Backup',
      url: '/LiveView',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g clipPath="url(#clip0_4689_5291)">
              <path d="M19.4323 4.69385H2.34896C1.88872 4.69385 1.51562 5.06694 1.51562 5.52718V16.3605C1.51562 16.8208 1.88872 17.1938 2.34896 17.1938H19.4323C19.8925 17.1938 20.2656 16.8208 20.2656 16.3605V5.52718C20.2656 5.06694 19.8925 4.69385 19.4323 4.69385Z" stroke="url(#paint0_linear_4689_5291)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.84896 2.19385H6.93229C7.15331 2.19385 7.36527 2.28164 7.52155 2.43793C7.67783 2.59421 7.76562 2.80617 7.76562 3.02718V4.69385H4.01562V3.02718C4.01562 2.80617 4.10342 2.59421 4.2597 2.43793C4.41598 2.28164 4.62794 2.19385 4.84896 2.19385Z" stroke="url(#paint1_linear_4689_5291)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.7656 4.69385L14.0156 4.69385V3.02718C14.0156 2.80617 14.1034 2.59421 14.2597 2.43793C14.416 2.28165 14.6279 2.19385 14.849 2.19385L16.9323 2.19385C17.1533 2.19385 17.3653 2.28165 17.5215 2.43793C17.6778 2.59421 17.7656 2.80617 17.7656 3.02718V4.69385Z" stroke="url(#paint2_linear_4689_5291)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.01562 10.9438H6.51562" stroke="url(#paint3_linear_4689_5291)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.2656 10.9438H17.7656" stroke="url(#paint4_linear_4689_5291)" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.5156 9.69385V12.1938" stroke="url(#paint5_linear_4689_5291)" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear_4689_5291" x1="6.77442" y1="16.9159" x2="13.9269" y2="6.91125" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint1_linear_4689_5291" x1="5.06738" y1="4.63826" x2="6.49788" y2="2.63733" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint2_linear_4689_5291" x1="16.7139" y1="2.24943" x2="15.2834" y2="4.25037" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint3_linear_4689_5291" x1="4.7168" y1="11.9216" x2="5.15497" y2="10.9001" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint4_linear_4689_5291" x1="15.9668" y1="11.9216" x2="16.405" y2="10.9001" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <linearGradient id="paint5_linear_4689_5291" x1="16.7961" y1="12.1383" x2="17.7862" y2="11.769" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF7F02"/>
                <stop offset="1" stop-color="#FFC400"/>
              </linearGradient>
              <clipPath id="clip0_4689_5291">
                <rect width="20" height="20" fill="white" transform="translate(0.890625 0.318848)"/>
              </clipPath>
            </defs>
          </svg>`
    }
  ]

  return (
    <div className="welcome_main">
      <div className="header_main welcome_header">
        <div className="contentWrap">
          <div className="headerMain_inner">
            <div className="headerMain_logo">
              <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
            </div>
            <div className="headerMain_title"><span>Thunder Energy Analytics Platform</span></div>

            <div className="headerMain_right">
              <div className="wc_user_info">
                <Link to={'/'}><strong>Ahmed Ali</strong></Link>
                <small>Admin User</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="welcome_wrap">
        <div className="welcome_content">
          <div className="welcome_left_nav">
            <div className="wc_left_inner">
              <ul className="wc_left_list">
                {
                  welcomeMenu.map((item) => {
                    return (
                      <li key={item.id} className={`${item.enabled ? '' : 'disabled'}`}>
                        <Link className="wc_nav_link" to={item.url}>
                          <i className='wc_nav_link_icon' key={item.id} dangerouslySetInnerHTML={{ __html: item.icon }} ></i>
                          <strong>{item.name}</strong>
                          <span>{item.description}</span>
                        </Link>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
          </div>

          <div className="welcome_right">
            <div className="welcome_right_content">
              <div className="wc_time_header">
                <div className="wc_time_header_left">
                  {/* <h2>
                                        Thunder <br></br> Energy Analytics Platform
                                    </h2> */}
                  <ul>
                    <li>Monitoring</li>
                    <li>Prediction</li>
                    <li>Actionable Intelligence</li>
                  </ul>
                </div>
                <div className="wc_time_header_right">
                  <p className="wc_time_title">
                    Islamabad  -  34°C  -  Friday  -  Aug11, 2023  -  18:55
                  </p>
                  <div className="wc_time_srch">
                    <form>
                      <div className="wc_search_field">
                        <input
                          type="text"
                          placeholder=""
                          id="wc_time_srch_input"
                        ></input>
                        <label className="floating_label" htmlFor="wc_time_srch_input">Search or type a command</label>
                        <span className="wc_time_srch_command">⌘ F</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="wc_quick_links">
              <h3>Quick Links</h3>
              <ul className="welcome_list">
                {
                  quickLinks.map((item) => {
                    return (
                      <li key={item.id}>
                        <Link className="welcome_link" to={item.url}>
                          <i key={item.id} dangerouslySetInnerHTML={{ __html: item.icon }} ></i>
                          <span>{item.name}</span>
                        </Link>
                      </li>
                    );
                  })
                }
              </ul>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}
export default Welcome