
import React from "react";
import SpHeader from "../../../../../Components/mainHeader/SpHeader"; 
import MyAllDownloadInfo from "../../../../../Components/ytdToFrom/MyAllDownloadInfo";
import ReactECharts from 'echarts-for-react';
import CommercialSavingPagination from "../CommercialSavingPagination";

function CsGridPeakHrsBeforePolicy() {

    const breadcrumbs = [
        { label: 'Data Stories', link: '/DataStories' },
        { label: 'Commercial Savings', link: '/DsCommercialSavingsLinks' },
        { label: 'Grid Peak Hours Battery Charging', link: '' },
    ];

    //  
    const GridPeakHrsBeforePolicy = {
        title: {
            text: 'Infrastructure Wide (Hours/Day)',
            show: false,
            textStyle: {
                fontSize: 18,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '4px',
            show: true,
            data: ['Grid Utilization', 'Battery Charging',],
            fill: 'orange', 
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            right: '20px',
            bottom: '3%',
            top: '15px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ], 

        series: [
            {
              name: 'Grid Utilization',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              color: [
                '#00B2FF',
             ],
              data: [50, 52, 58, 50, 55, 50, 55, 60, 50, 45, 55, 50]
            },
            {
              name: 'Battery Charging',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              color: [
                '#C93898',
             ],
              data: [40, 42, 48, 50, 45, 50, 45, 35, 40, 35, 45, 35]
            },  
          ] 
    };




    return (
        <div className="gridPeakHrs_main">
            <div className="cs_gp_header">
                <SpHeader breadcrumbs={breadcrumbs} />
            </div>
            <div className="cs_gp_hrs_inner">
                <div className="contentWrap">
                    <div className="cs_gp_heading">
                        <h2> Grid Peak Hours Battery Charging </h2>
                        <div className="ampButtonsGroup">
                            <ul className="ampButtonsBox">
                                <li><span className="ampButton ">Total</span></li>
                                <li><span className="ampButton active">Average</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="cs_gp_hrs_content">
                        <div className="box_card">
                            <div className="row cs_gp_hrs_content_inner">
                                <div className="col-12 col-lg-7">
                                    <div className="cs_gp_hrs_content_info">
                                        <h4><span className="color_purple_dark">Grid</span> utilization and <span className="color_blue_navy">Battery</span> charging before policy is applied</h4>
                                        <p>Battery charging accounts for <strong>23%</strong> of Grid utilization.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="cs_gp_hrs_statusRow">
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Responded Sites</small>
                                                <h2>1308</h2>
                                            </div>
                                        </div>
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Grid Utilization</small>
                                                <h2 className="color_purple_dark">
                                                    <strong>46.0</strong>
                                                    <sub>kWh</sub>
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Battery Charging</small>
                                                <h2 className="color_deep_skyBlue">
                                                    <strong>10.4</strong>
                                                    <sub>kWh</sub>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cs_gp_hrs_sRow_btn_grp">
                                        {/* <Link className="cs_sRow_btn">Grid Utilization</Link>
                                        <Link className="cs_sRow_btn bg_deep_skyBlue">Battery Charging</Link> */}
                                        <MyAllDownloadInfo />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <ReactECharts option={GridPeakHrsBeforePolicy} opts={{ renderer: 'svg' }} style={{ height: 351 }} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <CommercialSavingPagination isNext='/CsGridPeakHrsDuringPolicy' isPrev='/CsGridPeakHrsPolicy' currentId='2'/>

                </div>
            </div>
        </div>
    )
}

export default CsGridPeakHrsBeforePolicy