import React, { useState, useEffect } from 'react';
import './SobTopTabsHeader.css';
import { Link } from 'react-router-dom';

function SobTabsHeader(props) {
    const links = props?.sob_moremenu;
    const [active, setActive] = useState(props.pageNo);
 
    useEffect(() => {
        // Handle class addition on page render
        links.forEach((link, index) => {
            const li = document.querySelector(`.sob_topTabs_ul li[data-id="${link.id}"]`);
            if (li) {
                if (index < active) {
                    li.classList.add('previous-active');
                } else {
                    li.classList.remove('previous-active');
                }
            }
        });
    }, [active, links]);

    const HandleClick = (index) => {
        setActive(index);
        
      
    }
    return (
        <div className='sob_topTabs_main'>
            <div className='contentWrap'>
                <div className='sob_topTabs_row'>
                    <ul className='sob_topTabs_ul'>
                        {links.map((link, index) => {
                            return link.dataType === 'normal' ? <li data-id={link.id} key={link.id} className={`sob_topTabs_li ${active === index ? 'active' : ''}`} onClick={() => link?.hhandleClick ? '' : HandleClick(index)}>
                                <Link className='sob_topTabs_box' onClick={link?.hhandleClick}
                                    // to={`${link.path ?  '/'+link.path: null}`}
                                    to={link.path ? '/' + link.path : ''}
                                >
                                    <div className='sobTopTabs_value'>
                                        <span>{link.value}</span>
                                    </div>
                                    <p>{link.title}</p>

                                </Link>
                            </li> : null;
                        }
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )

}



export default SobTabsHeader;
