import React from "react";
 
import ReactECharts from 'echarts-for-react';
import TotalAverageRadio from "../../Components/TotalAverageRadio/TotalAverageRadio";
import DbDailyFuelTable from "../../screensUi/DbDailyFuelTable";
import ApplyFilterHeader from "../../Components/ApplyFilterHeader";
import AmTabsHeader from "../../Components/TopTabsHeader/AmTabsHeader";
import SpHeader from "../../Components/mainHeader/SpHeader";
import MyAllDownloadInfo from "../../Components/ytdToFrom/MyAllDownloadInfo";
import AddFuelDetails from "../../Modals/AddFuelDetails";



function SfDashboard() {

    const [modalShow, setModalShow] = React.useState(false);
    const breadcrumbs = [
        { label: 'Fueling', link: '' },

    ];
    const moremenu = [
        {
            "id": 1,
            "title": "Fuel Pouring",
            "path": 'SiteFueling',
            "value": "",
            "valueType": "",
            "dataType": "normal",
            "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M14.9018 13.934C14.337 14.6551 13.6137 15.2387 12.7873 15.64C11.9609 16.0414 11.0531 16.25 10.1332 16.25H2.51716C2.1166 16.25 1.73244 16.092 1.4492 15.8107C1.16596 15.5294 1.00684 15.1478 1.00684 14.75V9.5C1.00684 9.10218 1.16596 8.72064 1.4492 8.43934C1.73244 8.15804 2.1166 8 2.51716 8H8.78398C9.12696 8.0001 9.45968 8.11613 9.72743 8.329L12.4853 10.516C12.6735 10.6655 12.895 10.7682 13.1313 10.8153C13.3676 10.8624 13.6118 10.8527 13.8436 10.787L18.3755 9.5L14.9018 13.934Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.04785 5V8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.02734 5H10.0686" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18.376 9.5L20.6415 10.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.9061 18.594C22.9061 19.1658 22.6774 19.7142 22.2703 20.1185C21.8632 20.5229 21.311 20.75 20.7353 20.75C20.1595 20.75 19.6074 20.5229 19.2003 20.1185C18.7932 19.7142 18.5645 19.1658 18.5645 18.594C18.5645 17.4 20.0113 15 20.7353 15C21.4592 15 22.9061 17.4 22.9061 18.594Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
        },
        {
            "id": 2,
            "title": "Dashboard",
            "path": 'SfDashboard',
            "value": "",
            "valueType": "",
            "dataType": "normal",
            "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clip-path="url(#clip0_203_2818)">
              <path d="M9.48524 11.3158C9.48572 11.55 9.39322 11.7747 9.22808 11.9407C9.06293 12.1067 8.83864 12.2004 8.60449 12.2011H1.55849C1.44251 12.2009 1.32772 12.1778 1.22068 12.1331C1.11363 12.0885 1.01643 12.0232 0.934635 11.941C0.852838 11.8588 0.788048 11.7613 0.743967 11.654C0.699887 11.5467 0.677382 11.4318 0.677739 11.3158V1.56886C0.677501 1.45284 0.700117 1.33791 0.744296 1.23064C0.788475 1.12336 0.853351 1.02583 0.93522 0.94363C1.01709 0.861426 1.11435 0.796151 1.22144 0.751534C1.32854 0.706917 1.44337 0.683831 1.55939 0.683594L8.60539 0.695337C8.83939 0.696294 9.06344 0.790042 9.2284 0.95601C9.39335 1.12198 9.48572 1.34661 9.48524 1.5806V11.3158Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.9989 5.91211C20.9972 6.14411 20.9035 6.36595 20.7384 6.5289C20.5732 6.69184 20.3502 6.78256 20.1182 6.78112H13.0722C12.9572 6.78195 12.8433 6.76013 12.7368 6.71689C12.6304 6.67366 12.5335 6.60987 12.4517 6.52916C12.3699 6.44845 12.3048 6.35242 12.2601 6.24654C12.2155 6.14066 12.1921 6.02702 12.1914 5.91211V1.56437C12.1928 1.33261 12.2862 1.1109 12.451 0.947957C12.6158 0.785012 12.8386 0.694159 13.0703 0.69536L20.1163 0.683617C20.3486 0.681933 20.572 0.772516 20.7375 0.935462C20.903 1.09841 20.997 1.32039 20.9989 1.55262V5.91211Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.48523 20.1379C9.48452 20.2531 9.46113 20.367 9.4164 20.4732C9.37166 20.5793 9.30645 20.6756 9.2245 20.7566C9.14255 20.8375 9.04545 20.9015 8.93876 20.9449C8.83207 20.9884 8.71787 21.0103 8.60268 21.0096L1.55668 20.9979C1.32468 20.9988 1.10179 20.9077 0.936984 20.7444C0.772175 20.5811 0.678929 20.3591 0.677734 20.1271V15.7829C0.678326 15.6679 0.701583 15.5541 0.746176 15.4481C0.79077 15.3421 0.855825 15.2459 0.937622 15.165C1.01942 15.0841 1.11636 15.0202 1.22289 14.9768C1.32942 14.9334 1.44346 14.9114 1.55848 14.9121H8.60448C8.83672 14.9109 9.05994 15.002 9.22508 15.1653C9.39023 15.3285 9.4838 15.5507 9.48523 15.7829V20.1379Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.0731 21.0077C12.957 21.0075 12.8421 20.9844 12.7349 20.9397C12.6278 20.895 12.5305 20.8297 12.4486 20.7474C12.3667 20.6651 12.3018 20.5674 12.2577 20.4601C12.2136 20.3527 12.1911 20.2377 12.1914 20.1216V10.3764C12.1911 10.2604 12.2136 10.1454 12.2576 10.0381C12.3017 9.93079 12.3665 9.8332 12.4483 9.75091C12.53 9.66862 12.6272 9.60325 12.7343 9.55852C12.8413 9.51379 12.9561 9.49059 13.0722 9.49023H20.1182C20.2342 9.49059 20.349 9.51379 20.456 9.55852C20.5631 9.60325 20.6603 9.66862 20.7421 9.75091C20.8239 9.8332 20.8886 9.93079 20.9327 10.0381C20.9768 10.1454 20.9993 10.2604 20.9989 10.3764V20.1098C20.9994 20.3439 20.9071 20.5686 20.7421 20.7347C20.5772 20.9008 20.3531 20.9948 20.1191 20.996L13.0731 21.0077Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_203_2818">
                <rect width="21.68" height="21.68" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
        },
        {
            "id": 3,
            "title": "Add Fuel Details",
            // "path": '',
            // "hhandleClick" : setModalShow(true),
            hhandleClick: () => {
                setModalShow(true)
                console.log('Item 2 was clicked');
            },
            "value": "",
            "valueType": "",
            "dataType": "normal",
            "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_206_5384)">
              <path d="M0.75 12H23.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 0.75V23.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_206_5384">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
        },
    ]
    const pageNo = 1;



    //   Daily Fuel Pouring Trend (Avg / Site / Day) infrastructure table
    const df_pouringTrend_infra = {
        title: {
            text: '',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            top: 0,
            left: '0',
            //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 8,
            selectedMode: true,
            icon: "circle"
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '30px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [

            {
                type: 'category',
                boundaryGap: true,
                //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                type: 'pie',
                radius: '52%',
                center: ['50%', '50%'],
                selectedMode: 'single',
                color: [
                    '#A6CEE3',
                    '#1F78B4',
                    '#B2DF8A',
                    '#33A02C',
                    '#FB9A99',
                    '#E31A1C',
                    '#FDBF6F',
                    '#FF7F00',
                    '#CAB2D6',


                ],
                data: [
                    {
                        value: 16,
                        name: 'Fiber Node -ID',
                        label: {
                            formatter: ['16 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 15,
                        name: 'Fiber Node -OD',
                        label: {
                            formatter: ['15 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 15,
                        name: 'Host Sharing',
                        label: {
                            formatter: ['15 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 14,
                        name: 'Host/Solar Site',
                        label: {
                            formatter: ['14 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 14,
                        name: 'Host/Solar Site/Outdoor Fiber',
                        label: {
                            formatter: ['14 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 14,
                        name: 'Standalone - Solar',
                        label: {
                            formatter: ['14 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 13,
                        name: 'Standalone',
                        label: {
                            formatter: ['13 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 13,
                        name: 'TP Guest - OMO',
                        label: {
                            formatter: ['13 Ltrs'].join('\n'),
                        }
                    },
                    {
                        value: 12,
                        name: 'TP Guest - TowerCo',
                        label: {
                            formatter: ['12 Ltrs'].join('\n'),
                        }
                    },


                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    //   Daily Fuel Pouring Trend (Avg / Site / Day) First row chart
    const DailyFuelingCurrentLastMonth = {
        title: {
            text: 'Sites',
            show: false,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },
       
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Fueling Sites', 'Pouring Current Month', 'Pouring Last Month'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '400'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'Sites',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
            {
                type: 'value',
                name: 'Ltrs',
                min: 0,
                max: 25,
                interval: 5,
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],



        series: [

            {
                name: 'Fueling Sites',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#0058FF',
                ],

                data: [300, 150, 400, 400, 150, 300, 300, 300, 100, 300, 200, 200, 400, 100, 300, 100, 100, 100, 300, 300, 200, 200, 100, 300, 100, 200, 300, 200,200],

                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },

            {
                name: 'Pouring Current Month',
                type: 'line',
                stack: 'Hours',
                color: ['#B35A3E'],
                fill: '#B35A3E',
                data: [50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 60, 60, 60, 60, 50, 50, 50, 50, 60, 60, 50, 50, 50, 50,]
            },
            {
                name: 'Pouring Last Month',
                type: 'line',
                stack: 'Hours',
                color: ['#B35A3E'],
                fill: '#B35A3E',
                data: [350, 350, 350, 350, 350, 350, 350, 350, 350, 350, 350, 350, 350, 350, 100, 150, 300, 150, 300, 300, 250, 150, 200, 300, 200, 300, 150, 300, 300,]
            },

        ],

    };

    // Regional Fuel Pouring Trend (Avg / Site / Day) 
    const RegionalFuelPouringConsumption = {
        title: {
            text: 'Sites',
            show: false,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        // tooltip: {
        //     trigger: 'axis'
        // },
        tooltip: {
            trigger: "axis",
            formatter: function (params) {
                let tooltip = `<strong className='weight7 mb-1 d-block'>${params[0].name}</strong> `;
                params.forEach((param) => {
                    if (param.seriesName === "Transformer" && param.value === 0) {
                        //  tooltip += `${param.marker} Custom Value<br />`;
                    } else if (param.seriesName === "Rectifier" && param.value === 0) {
                        // tooltip += `${param.marker} Custom Value<br />`;
                    } else {
                        tooltip += `${param.marker} ${param.seriesName}:    <strong className='weight7 ps-2'>${param.value}</strong> <br />`;
                    }
                });
                return tooltip;
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Target Fuel Pouring', 'Actual Fuel Pouring', 'Actual Fuel Consumption'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '400'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text',],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: '',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        series: [

            {
                name: 'Target Fuel Pouring',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#0058FF',
                ],

                data: [2, 4, 1, 2, 2, 2, 4, 2, 2, 4, 3, 3, 3, 3, 2, 2, 2, 2, 3, 2, 1, 3, 3, 2, 1, 3, 3, 2, 3, 2, 2, 1, 3, 2],

                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },

            {
                name: 'Actual Fuel Pouring',
                type: 'bar',
                // stack: 'Hours',
                color: ['#A6CEE3'],
                data: [4, 2, 2, 4, 3, 3, 2, 2, 2, 3, 4, 1, 3, 3, 4, 1, 3, 2, 3, 2, 3, 2, 1, 2, 3, 2, 2, 4, 2, 3, 1, 4, 2, 4],
            },
            {
                name: 'Actual Fuel Consumption',
                type: 'bar',
                // stack: 'Hours',
                color: ['#B2DF8A'],
                data: [3, 4, 3, 2, 1, 3, 1, 4, 2, 4, 4, 3, 2, 1, 4, 4, 4, 2, 4, 3, 1, 1, 1, 1, 1, 1, 3, 4, 3, 4, 3, 3, 3, 1],
            },

        ],

    };


    // Daily Fuel Pouring Trend (Avg / Site / Day)
    const DailyFuelPouringTrend = {
        title: {
            text: 'Sites',
            show: false,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },
      
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Total', 'Fiber Node -ID', 'Fiber Node -OD', 'Host Sharing', 'Host/Solar Site', 'Host/Solar Site/Outdoor Fiber', 'Standalone - Solar', 'Standalone', 'TP Guest - OMO', 'TP Guest - TowerCo'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '400'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'Count',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
            {
                type: 'value',
                name: 'Ltrs',
                min: 0,
                max: 25,
                interval: 5,
                axisLabel: {
                    formatter: '{value}'
                }
            }
        ],

        series: [

            {
                name: 'Total',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#0058FF',
                ],

                data: [4.5, 2.57, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5],

                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },

            {
                name: 'Fiber Node -ID',
                type: 'bar',
                stack: 'Hours',
                color: ['#A6CEE3'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'Fiber Node -OD',
                type: 'bar',
                stack: 'Hours',
                color: ['#1F78B4'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'Host Sharing',
                type: 'bar',
                stack: 'Hours',
                color: ['#B2DF8A'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'Host/Solar Site',
                type: 'bar',
                stack: 'Hours',
                color: ['#33A02C'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'Host/Solar Site/Outdoor Fiber',
                type: 'bar',
                stack: 'Hours',
                color: ['#FB9A99'],
                data: [0.50,0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'Standalone - Solar',
                type: 'bar',
                stack: 'Hours',
                color: ['#E31A1C'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'Standalone',
                type: 'bar',
                stack: 'Hours',
                color: ['#FDBF6F'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'TP Guest - OMO',
                type: 'bar',
                stack: 'Hours',
                color: ['#FF7F00'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },
            {
                name: 'TP Guest - TowerCo',
                type: 'bar',
                stack: 'Hours',
                color: ['#CAB2D6'],
                data: [0.50, 0.28, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50, 0.50],
            },

        ],

    };


    return (
        <>
            <div className='pg_siteFueling_wraper pb-2'> 
                <SpHeader breadcrumbs={breadcrumbs} />

                <div className='row align-items-center pe-4 pb-2'>
                    <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div>
                    <div className='col-12 col-lg-3'>
                        <MyAllDownloadInfo />
                    </div>
                </div>

                <div className='stf_header pt-2 pb-1'>
                    <AmTabsHeader pageNo={pageNo} moremenu={moremenu} />
                </div>

                <div className="dashboard_main_content">
                    <div className="totalAverage_header">
                        <div className="contentWrap">
                            <div className="totalAverage_header_row">
                                <TotalAverageRadio />
                            </div>
                        </div>
                    </div>
                    <div className="contentWrap">
                        <div className="row pb-3">
                            <div className="col-12">
                                <div className="box_card">
                                    <div className="dashB_content_heading">
                                        <h4 className="label16 weight5">Daily Fuel Pouring Trend (Avg / Site / Day)</h4>
                                    </div>
                                    {/* chart here */}
                                    <ReactECharts option={DailyFuelingCurrentLastMonth} opts={{ renderer: 'svg' }} style={{ height: 330 }} />
                                </div>
                            </div>
                        </div>

                        <div className="db_dFuel_Infra_chartTable pb-3">
                            <div className="box_card">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="dashB_content_heading">
                                            <h4 className="label16 weight5">Daily Fuel Pouring Trend (Avg / Site / Day)</h4>
                                        </div>

                                        <ReactECharts option={df_pouringTrend_infra} opts={{ renderer: 'svg' }} style={{ height: 410 }} />

                                    </div>
                                    <div className="col-12 col-lg-8">
                                        <div className="db_dailyFuel_table">
                                            <DbDailyFuelTable />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className="col-12">
                                <div className="box_card">
                                    <div className="dashB_content_heading">
                                        <h4 className="label16 weight5">Daily Fuel Pouring Trend (Avg / Site / Day)</h4>
                                    </div>
                                    {/* chart here */}
                                    <ReactECharts option={DailyFuelPouringTrend} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                </div>
                            </div>
                        </div>

                        <div className="row pb-3">
                            <div className="col-12">
                                <div className="box_card">
                                    <div className="dashB_content_heading">
                                        <h4 className="label16 weight5">Regional Fuel Pouring Trend (Avg / Site / Day)</h4>
                                    </div>

                                    <ReactECharts option={RegionalFuelPouringConsumption} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                </div>
                            </div>
                        </div>
                        <div className="db_fuelingNAR_tables pb-3">

                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="box_card">
                                        <div className="dashB_content_heading pb-3">
                                            <h4 className="label16 weight5">Fueling Distribution (Avg/Site/Day)</h4>
                                        </div>
                                        <DbDailyFuelTable />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="box_card">
                                        <div className="db_dailyFuel_table">
                                            <div className="dashB_content_heading pb-3">
                                                <h4 className="label16 weight5">NAR Distribution (%age)</h4>
                                            </div>
                                            <DbDailyFuelTable />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                   <AddFuelDetails
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />

            </div>
        </>
    );
}

export default SfDashboard;