
import React from "react";
import ReactECharts from 'echarts-for-react';
function SpSolarPowerContributionBarsChart(props) {


    const columnchartOptions = {
        title: {
            text: '',
            show: false,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 12,
                fontWeight: '500',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: false
            }
        },
        legend: {
            data: ['Site Load', 'Battery Charging', 'Un-accounted', 'Target'],
            right: '0px',
            top: '0px',
            show: true,
            type: 'scroll',
            orient: 'horizontal',
            itemWidth: 24,
            selectedMode: true,
            icon: "circle",
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },
        toolbox: {
            // feature: {
            //     dataZoom: {
            //         yAxisIndex: 'none'
            //     },
            //     restore: {},
            //     saveAsImage: {}
            // }
        },
        axisPointer: {
            link: [
                {
                    xAxisIndex: 'all'
                }
            ]
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 0,
                end: 100,
                xAxisIndex: [0, 1, 2]
            },
            {
                type: 'inside',
                realtime: true,
                start: 0,
                end: 100,
                xAxisIndex: [0, 1, 2]
            }
        ],
        grid: [
            {
                left: 40,
                right: 10,
                height: '22%',
                containLabel: true
            },
            {
                left: 40,
                right: 10,
                height: '22%',
                top: '38%',
                containLabel: true
            },
            {
                left: 40,
                right: 10,
                top: '62%',
                height: '22%',
                bottom: '40px',
                containLabel: true
            }
        ],
        xAxis: [
            {
                type: 'category',
                show: false,
                boundaryGap: false,
                axisLine: { onZero: true },
                data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
            {
                gridIndex: 1,
                type: 'category',
                boundaryGap: false,
                show: false,
                axisLine: { onZero: true },
                //   data: timeData,
                data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
                position: 'bottom',
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0',
                    }
                },
            },
            {
                gridIndex: 2,
                type: 'category',
                boundaryGap: false,
                axisLine: { onZero: false },
                //   data: timeData,
                data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
                //  position: 'top',
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0',
                    }
                },
            }
        ],
        yAxis: [
            {
                name: 'Site Load',
                type: 'value',
                nameRotate: 90,
                nameLocation: 'middle',
                nameGap: 46,
                nameTextStyle: {
                    color: '#333',
                    fontWeight: 'bold',  
                    fontSize:'10px'
                  }
            },
            {
                gridIndex: 1,
                name: 'Battery Charging',
                type: 'value',
                inverse: false,
                nameRotate: 90,
                nameLocation: 'middle',
                nameGap: 40,
                nameTextStyle: {
                    color: '#333',  
                    fontWeight: 'bold',  
                    fontSize:'10px'
                  }
            },
            {
                gridIndex: 2,
                name: 'Un-accounted',
                type: 'value',
                inverse: false,
                nameRotate: 90,
                nameLocation: 'middle',
                nameGap: 40,
                nameTextStyle: {
                    color: '#333',
                    fontWeight: 'bold',  
                    fontSize:'10px'
                  }
            }
        ],
        series: [
            {
                name: 'Site Load',
                type: 'line',
                symbolSize: 8,
                color: '#7243D0',
                showSymbol: false,

                data: [
                    180.97, 120.96, 30.96, 180.95, 250.95, 210.94, 160.94, 230.94, 240.94, 200.94
                ]
            },
            {
                name: 'Battery Charging',
                type: 'line',
                symbolSize: 8,
                color: '#0058FF',
                showSymbol: false,
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: [
                    20, 0, 5, 3, 5, 2, 12, 18, 4, 8
                ]
            },
            {
                name: 'Target',
                type: 'line',
                showSymbol: false,
                xAxisIndex: 1,
                yAxisIndex: 1,
                symbolSize: 8,
                color: '#B35A3E',
                lineStyle: {
                    type: 'dashed',
                },
                // prettier-ignore
                data: [
                    20, 6, 7, 2, 19, 18, 4, 5, 20, 2
                ]
            },


            {
                name: 'Un-accounted',
                type: 'line',
                symbolSize: 8,
                color: '#32323E',
                showSymbol: false,
                xAxisIndex: 2,
                yAxisIndex: 2,

                data: [
                    20, 6, 18, 4, 5, 20, 7, 2, 19, 2
                ]
            },


            {
                name: 'Target',
                type: 'line',
                showSymbol: false,
                xAxisIndex: 0,
                yAxisIndex: 0,
                symbolSize: 8,
                color: '#B35A3E',
                lineStyle: {
                    type: 'dashed',
                },
                data: [
                    180.97, 220.96, 230.96, 180.95, 230.94, 240.94, 200.94, 250.95, 210.94, 160.94
                ]
            }
        ]
    };

    return (
        <>
            <ReactECharts option={columnchartOptions} opts={{ renderer: 'svg' }} style={{ height: 400 }} />
        </>
    )
}

export default SpSolarPowerContributionBarsChart;