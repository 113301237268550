import React from "react";

function SizedTable(props) {
    return (
        <div className='am_top_size'>
            <ul className="d-flex align-items-center flex-wrap">
                {
                    props.data.map((item) => (
                        <li key={item.id}>
                            <div className="am_top_size_box">
                                <h4>{item.name}</h4>
                                <h3>{item.value}</h3>
                                <small>{item.sites}</small>
                                <i className="am_color_bar"  style={{backgroundColor:item.color}}></i>
                            </div>
                        </li>
                    ))
                }
 
            </ul>
        </div>
    )
}
export default SizedTable;