import React from 'react';

const sob_moremenu = [
    {
      "id": 1,
      "title": "General Information",
      "path": 'SobGeneralInfo',
      "value": "1", 
      "dataType": "normal",
    },
    {
      "id": 2,
      "title": "Site Infra",
      "path": 'SobSitesInfra',
      "value": "2", 
      "dataType": "normal", 
    },
    
    {
      "id": 3,
      "title": "Tech Parameters",
      "path": 'SobTechParameters',
      "value": "3", 
      "dataType": "normal", 
    }, 
    {
      "id": 4,
      "title": "Site Load",
      "path": 'SobSiteLoad',
      "value": "4", 
      "dataType": "normal", 
    },
    {
      "id": 5,
      "title": "Solar",
      "path": 'SobSolar',
      "value": "5", 
      "dataType": "normal", 
    },
    {
      "id": 6,
      "title": "Grid",
      "path": 'SobGrid',
      "value": "6", 
      "dataType": "normal", 
    },
    {
      "id": 7,
      "title": "Genset",
      "path": 'SobGenset',
      "value": "7", 
      "dataType": "normal", 
    },
    {
      "id": 8,
      "title": "Rectifier",
      "path": 'SobRectifier',
      "value": "8", 
      "dataType": "normal", 
    },
    {
      "id": 9,
      "title": "Battery",
      "path": 'SobBattery',
      "value": "9", 
      "dataType": "normal", 
    },
    {
      "id": 10,
      "title": "gateway Configs",
      "path": 'SobGatewayConfigs',
      "value": "10", 
      "dataType": "normal", 
    },
  ];
function SobMoreMenuLinks() {
    return(
        <></>
    )
}

export { sob_moremenu, SobMoreMenuLinks }; 