import React from 'react';
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SiteHeaderDropdown from "../SiteHeaderDropdown";
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import { performanceMenu } from "./SpPerformanceMenu";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
import SitePerformanceSolarPowerGenerationChart from '../../../screensUi/SitePerformanceSolarPowerGenerationChart';
import SitePerformanceSolarPowerContribution from '../../../screensUi/SitePerformanceSolarPowerContribution';
import SpSolarPowerContributionBarsChart from '../../../screensUi/SpSolarPowerContributionBarsChart';

function SitesPerformanceSolar() {
    const pageNo = 1;
    const breadcrumbs = [
        { label: 'Sites', link: '' },
        { label: 'Performance', link: '/SitesPerformance' },
        { label: 'Solar', link: '/SitesPerformanceSolar' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SitesPerformanceSolar',
        otherUrl: '/SingleSiteSummary',
    }


    return (
        <div className="sitePerformance_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb='false' isSummaryButton isSiteDropdown={<SiteHeaderDropdown parentPath='/SitesPerformance' />} />
            <div className="pb-2">
                <ApplyFilterHeader />
            </div>
            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'>
                    <AmTabsHeader pageNo={pageNo} moremenu={performanceMenu} />
                </div>
            </div>
            <div className="sitePerformance_solar contentWrap">

                <div className="row">
                    <div className="col-12 col-lg-4 col-xxl-3 sp_left pb-3">
                        <div className="box_card h-100">
                            <div className="inf_summary pb-3">
                                <div className="pt-2">
                                    <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-2">
                                        <span className="px-2 bg_white">Generation</span>
                                    </h4>
                                    <div className=" text-center">
                                        <p className="label20 weight5 d-block text-center pt-2">
                                            <span>Actual</span>
                                        </p>
                                        <h2 className=" ">
                                            <span className="weight9 label60 color_red2 pe-2 d-inline-flex justify-content-center align-items-baseline"><i><svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18" fill="none">
                                                <path d="M13.0674 16.9412C12.418 17.8849 11.0245 17.8849 10.3751 16.9412L0.734229 2.93078C-0.0117851 1.84665 0.764342 0.370446 2.08035 0.370445L21.3622 0.370444C22.6782 0.370444 23.4543 1.84665 22.7083 2.93078L13.0674 16.9412Z" fill="#DD0000" />
                                            </svg></i> 224</span>
                                            <strong className="label40 weight9 d-inline-block" style={{ lineHeight: "1em", verticalAlign: "middle" }}>kWh
                                                <small className="label18 weight4 color_red2 text-end d-block" style={{ lineHeight: "1em" }}>-10%</small>
                                            </strong>
                                        </h2>
                                    </div>
                                </div>
                                <div className="comp_list pt-3 pb-2">
                                    <ul className="d-flex align-items-start justify-content-center flex-wrap">
                                        <li className="pe-3">
                                            <div className="comp_list_data">
                                                <p className="label20 weight5 d-block text-center pt-2">
                                                    <span>Target</span>
                                                </p>
                                                <h2 className=" ">
                                                    <span className="weight5 label30 pe-2 d-inline-flex justify-content-center align-items-baseline"> 250</span>
                                                    <strong className="label30 weight5 d-inline-block" style={{ lineHeight: "1em", verticalAlign: "middle" }}>kWh
                                                        <small className="label18 weight4 text-end d-block" style={{ lineHeight: "1em" }}></small>
                                                    </strong>
                                                </h2>
                                            </div>
                                        </li>
                                        <li className="ps-3">
                                            <div className="comp_list_data">
                                                <p className="label20 weight5 d-block text-center pt-2">
                                                    <span>Projected</span>
                                                </p>
                                                <h2 className="d-flex align-item-start flex-wrap">
                                                    <span className="weight5 label30 color_green_pigment  pe-2 d-inline-flex justify-content-center align-items-baseline align-self-start"><i className="pe-1"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
                                                        <path d="M6.44736 1.20036C6.85748 0.604366 7.73762 0.604365 8.14773 1.20036L14.2367 10.0491C14.7079 10.7338 14.2177 11.6661 13.3865 11.6661L1.20855 11.6661C0.377385 11.6661 -0.112803 10.7338 0.358364 10.0491L6.44736 1.20036Z" fill="#21AB4A" />
                                                    </svg></i> 260</span>
                                                    <strong className="label30 weight5 d-inline-block">kWh
                                                        <small className="label18 weight4 color_green_pigment text-end d-block"  >+1%</small>
                                                    </strong>
                                                </h2>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="pt-3">
                                    <h4 className="inf_heading label20 weight5 d-block heading_border_right text-center pb-2">
                                        <span className="px-2 bg_white">Consumption</span>
                                    </h4>
                                    <div className="comp_list pt-3">
                                        <ul className="d-flex align-items-center flex-wrap">
                                            <li className="width30 pe-3">
                                                <div className="comp_list_data">
                                                    <small className="label14 weight5 d-block text-center">Site Load</small>
                                                    <div className="d-flex justify-content-center align-items-baseline">

                                                        <strong className="label25 weight5 text-center">200kWh</strong>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="width40">
                                                <div className="comp_list_data">
                                                    <small className="label14 weight5 d-block text-center">Battery Charging</small>
                                                    <div className="d-flex justify-content-center align-items-baseline">

                                                        <strong className="label25 weight5 text-center">20kWh</strong>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="width30 ps-3">
                                                <div className="comp_list_data">
                                                    <small className="label14 weight5 d-block text-center">Un-accounted</small>
                                                    <div className="d-flex justify-content-center align-items-baseline">
                                                        <strong className="label25 weight5 text-center">4kWh</strong>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 col-xxl-9 pb-3">
                        <div className="box_card h-100">
                            <h3 className="chartTitleHeading ">Solar Power Generation (kWh)</h3>
                            <div className="sites_solarPowerGeneration_chart">
                                <div className="chart_range_tabs">
                                    <ul>
                                        <li>
                                            <span className="chartRangeBtn">5Y</span>
                                        </li>
                                        <li>
                                            <span className="chartRangeBtn">3Y</span>
                                        </li>
                                        <li>
                                            <span className="chartRangeBtn">1Y</span>
                                        </li>
                                        <li>
                                            <span className="chartRangeBtn active">All</span>
                                        </li>
                                    </ul>
                                </div>
                                <SitePerformanceSolarPowerGenerationChart />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-4 col-xxl-3 sp_left pb-3">
                        <div className="box_card h-100">
                           <SitePerformanceSolarPowerContribution/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 col-xxl-9 pb-3">
                        <div className="box_card h-100">
                            <h3 className="chartTitleHeading ">Solar Power Generation (kWh)</h3>
                            <div className="sites_solarPowerGeneration_chart">
                                <div className="chart_range_tabs">
                                    <ul>
                                        <li>
                                            <span className="chartRangeBtn">5Y</span>
                                        </li>
                                        <li>
                                            <span className="chartRangeBtn">3Y</span>
                                        </li>
                                        <li>
                                            <span className="chartRangeBtn">1Y</span>
                                        </li>
                                        <li>
                                            <span className="chartRangeBtn active">All</span>
                                        </li>
                                    </ul>
                                </div>
                                 <SpSolarPowerContributionBarsChart/>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
export default SitesPerformanceSolar;