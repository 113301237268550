import React, { useState } from 'react';
import QueryBuilder from "../Modals/QueryBuilder";
import AmDbAlldownloadInfo from "./AmDbAlldownloadInfo";
import FilterSelectColumnToDisplay from '../Modals/FilterSelectColumnToDisplay';

function ApplyFiltersSteps() {
    const [currentStep, setCurrentStep] = useState(1);

    const nextStep = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const backStep = () => {
        setCurrentStep(1);
    };

    const resetSteps = () => {
        setCurrentStep(1);
    };

    return (
        <>
            <div className='table_filter_main'>

                {
                    currentStep === 3 ?
                        <div className="am_tableFilter_top_btns">
                            <AmDbAlldownloadInfo />
                            <button onClick={resetSteps} className="btn btn-dark float-end me-3">Reset</button>
                        </div>

                        : ''
                }

                <div className='table_filter_auto'>
                    <div className='table_filter_inner'>
                        <div className='table_filter_progress'>
                            <ul>
                                <li className={currentStep === 1 || currentStep === 2 || currentStep === 3 ? 'active' : ''}>
                                    <div className='tf_progress'>
                                        <span>1</span>
                                        <strong className='ps-1'>Apply Filters</strong>
                                    </div>
                                </li>
                                <li className={currentStep === 2 || currentStep === 3 ? 'active' : ''}>
                                    <div className='tf_progress'>
                                        <span>2</span>
                                        <strong className='ps-1'>Select Colum to Display</strong>
                                    </div>
                                </li>
                                <li className={currentStep === 3 ? 'active' : ''}>
                                    <div className='tf_progress'>
                                        <span>3</span>
                                        <strong className='ps-1'>Output</strong>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* <QueryBuilder/>  */}

                        {/* below commented is for Select Colum to display */}
                        {/* <FilterSelectColumnToDisplay/> */}

                        {/* below function in which used QueryBuilder and FilterSelectColumnToDisplay components */}

                        <div>
                            {
                                currentStep === 1 ? <QueryBuilder /> : ''
                            }
                            {
                                currentStep === 2 ? <FilterSelectColumnToDisplay /> : ''
                            }
                            {
                                currentStep === 3 ? '' : ''
                            }
                        </div>

                        {
                            currentStep === 3 ? '' :
                                <div className='tf_next_reset_btn pt-3'>
                                    <button className='btn btn-secondary me-2' onClick={backStep}>Back</button>
                                    <button className='btn btn-dark' onClick={nextStep}>Next</button>
                                </div>

                        }

                    </div>
                </div>
            </div>
        </>
    );
}

export default ApplyFiltersSteps;