
import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';

const FuelRCASummaryHeatMap = () => {
    const options = {
        chart: {
          type: 'heatmap',
          toolbar: {
            show: false,
          },
          fill: {
            colors: ['#FBBCAF']
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000']
          }
        },
        colors: ['#F66343'],
        xaxis: {
          categories: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3','N3', 'S2', 'S3', 'N2'],
        },
        stroke: {
            width: 0,
          },
      };
      // Define your chart data
      const series = [
        {
            name: 'Data Anomaly',
            data: [90, 43, 5, 47, 17, 90,43, 5, 47, 17],
          
        },
        {
          name: 'Bad',
          data: [23, 75, 250, 80, 30, 23,75, 250, 80, 30],
        }, 
        {
          name: 'Good',
          data: [234, 250, 280, 120, 50, 234,250, 280, 120, 50],
        },
      ];
      
    return (
      <div className='heatmap_out'> 
          <ApexCharts options={options} series={series} type="heatmap" height={260} />
      </div>
    );
  };
  
  export default FuelRCASummaryHeatMap;
  