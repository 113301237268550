import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';
const SiteReachabilityAnmpHeatMap = () => {
    const options = {
        chart: {
          type: 'heatmap',
          toolbar: {
            show: false,
          },
          fill: {
            colors: ['#32323E']
          },
         
        },
        title: {
          text: 'ANMP',
          align: 'left',
          top:'10px',
          style: {
            fontSize: '14px', // You can adjust the font size
            color: '#000',
            fontWeight: '400', // You can adjust the color
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#343434']
          }
        },
        colors: ['#E5292B'],
        xaxis: {
          categories: ['10%', '20%', '30%', '40%', '50%', '60%','70%', '80%', '90%', '100% Ping'],
        },
        stroke: {
            width: 0,
          },
      };
      // Define your chart data
      const series = [
        {
            name: '10%',
            data: [90, 43, 5, 47, 17, 90,43, 5, 47, 17],
          
        },
        {
          name: '20%',
          data: [23, 75, 250, 80, 30, 23,75, 250, 80, 30],
        },
        {
          name: '30%',
          data: [ 234, 250, 280, 120, 50, 234,250, 280, 120, 50],
        },
        {
          name: '40%',
          data: [120, 100, 110, 98, 240, 120,100, 110, 98, 240],
        },
        {
            name: '50%',
            data: [23, 75, 250, 80, 30, 23,75, 250, 80, 30],
        },
        {
            name: '60%',
            data: [ 234, 250, 280, 120, 50, 234,250, 280, 120, 50],
        },
        {
            name: '70%',
            data: [120, 100, 110, 98, 240, 120,100, 110, 98, 240],
        },
        {
            name: '80%',
            data: [90, 43, 5, 47, 17, 90,43, 5, 47, 17],
          
        },
        {
            name: '90%',
            data: [23, 75, 250, 80, 30, 23,75, 250, 80, 30],
        },
        {
            name: '100%',
            data: [ 234, 250, 280, 120, 50, 234,250, 280, 120, 50],
        },
      ];
      
    return (
      <div className='heatmap_out'>
          <h3 className='chartTitleHeading'>Site Reachability</h3>
          <ApexCharts options={options} series={series} type="heatmap" height={450} />
      </div>
    );
  };
  
  export default SiteReachabilityAnmpHeatMap;
  