import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import "./HeaderMain"
import { Link } from "react-router-dom";
import styles from './HeaderMain.module.css';
import SiteSummaryBtn from "../SiteSummaryBtn";




function SpHeader(props) {

    const [isSummary, setSummary] = useState(props.exdb);
    const handleClickFilterButton = event => {
        setSummary(current => !current);
        localStorage.setItem('summaryParentUrl', summryData.minUrl);

    };
    const summryData = props.summaryObj;
    const breadcrumbs = props?.breadcrumbs;



    return (
        <div className="header_main exdb_summary_header">
            <div className="contentWrap">
                <div className="headerMain_inner">
                    <div className="headerMain_logo">
                        <Link to={'/Welcome'}><img src={Logo} alt="#" /></Link>
                    </div>
                    <div className={styles.spHeader_breadCrumb}>
                        <ul>
                            {breadcrumbs.map((breadcrumb, index) => (
                                <li key={index}>
                                    <Link to={breadcrumb?.link}>{breadcrumb?.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="headerMain_right">
                        {
                            props.isSiteDropdown ? props.isSiteDropdown : ''
                        }

                        {
                            props.isSummaryButton ? <SiteSummaryBtn icon={summryData.icon} href={isSummary ? summryData.otherUrl : summryData.minUrl} className={isSummary ? 'btn_minimize' : ''} onClick={handleClickFilterButton}> {isSummary ? summryData.otherName : summryData.minimize}  </SiteSummaryBtn>
                                :
                                ''
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
export default SpHeader;