import React from 'react';
import ReactECharts from 'echarts-for-react';
const AmDonutChart = (props) => {
    const height = props.height;
    return (
        <>
            <ReactECharts option={props.options} opts={{ renderer: 'svg' }} style={{ height: height }} />
        </>
    );
};
export default AmDonutChart;