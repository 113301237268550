import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import GensetOverrunsColChartGroup from "../../../../Components/GensetOverrunsColChartGroup";
import GensetOverrunsRegionWiseChart from "../../../../Components/GensetOverrunsRegionWiseChart";
import GridOverconsumptionKwhCol from "../../../../Components/GridOverconsumptionKwhCol";
import SolarPotentialLosskWhCol from "../../../../Components/SolarPotentialLosskWhCol";
import TotalAverageRadio from "../../../../Components/TotalAverageRadio/TotalAverageRadio";
import SpHeader from "../../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function PowerSourcesAnomalies() {
    const pageNo = 3;
    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Power Sources Anomalies', link: '/PowerSourcesAnomalies' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/PowerSourcesAnomalies',
        otherUrl: '/TeamsViewSummary',
    }
    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />




            <div className="totalAverage_header">
                <div className="contentWrap">
                    <div className="totalAverage_header_row">
                        <TotalAverageRadio />
                    </div>
                </div>
            </div>


            <div className="contentWrap">
                <div className="chart_containter pt-0">
                    <div className="row pb-3">
                        <div className="col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <GensetOverrunsColChartGroup />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <GensetOverrunsRegionWiseChart />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <GridOverconsumptionKwhCol />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SolarPotentialLosskWhCol />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default PowerSourcesAnomalies; 