import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const siteDropdownArry = [
    { id: 1, label: 'Infrastructure', url: '/AmTransformer' },
    { id: 2, label: ' Infra Health Card', url: '/AmInfraHealthCard' },
    { id: 2, label: 'Energy Planning', url: '/AmEnergyPlanning' },
    { id: 3, label: 'Database', url: '/AmDatabase' },
    { id: 4, label: 'Capacity Planning', url: '/AmCapacityPlanning' },
    { id: 5, label: 'Playground', url: '/AmPlayground' },
   
];

const siteDropdownDataArry = siteDropdownArry;
function AmHeaderDropdown(props) {

     
    const navigate = useNavigate();
    const location = useLocation();
    const [defaultValue, setDefaultValue] = useState(null);

    if(props?.parentPath){
        location.pathname = props.parentPath;
    }
    useEffect(() => {
       
        const currentPath = location.pathname;
        const matchingOption = siteDropdownDataArry.find((option) => option.url === currentPath);
        if (matchingOption) {
            setDefaultValue(matchingOption);
        }
    }, [location.pathname]);


    const handleOptionSelect = (event, selectedOption) => {
        if (selectedOption) {
            const selectedUrl = selectedOption.url;
            navigate(selectedUrl);
        }
    };

    return (
        <div className="sitesHeader_dropdown">
            <Autocomplete
                disablePortal
                options={siteDropdownDataArry}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} placeholder="Sites select" />}
                onChange={handleOptionSelect}
                value={defaultValue}
                isOptionEqualToValue={(option, value) => option.url === value.url}
            />
        </div>
    )
}

 
export default AmHeaderDropdown;