import React from "react";
function BackupStretch() {
    const backupStretchData = [
        {
            id: 0,
            average: "Above 10 Hrs",
            value: "750",
            area: 'Sites',
            color: "#6DE43A"
        },
        {
            id: 1,
            average: "Up to 10 Hrs",
            value: "1978",
            area: 'Sites',
            color: "#FAA640"
        },
        {
            id: 2,
            average: "Up to 8 Hrs",
            value: "260",
            area: 'Sites',
            color: "#187BF5"
        },
        {
            id: 3,
            average: "Up to 5 Hrs",
            value: "219",
            area: 'Sites',
            color: "#88745B"
        },
        {
            id: 4,
            average: "Up to 2 Hrs",
            value: "1237",
            area: 'Sites',
            color: "#56D5DC"
        },
    ]
    return (
        <div className='am_backup_st'>
            <div className='am_backup_st_left'>
                <div className='am_backup_st_badges_main'>
                    <ul>
                        {
                            backupStretchData.map((items) => (
                                <li key={items.id}>
                                    <div className='am_backup_st_badges' style={{ background: items.color }}></div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className='am_backup_st_hrs'>
                    <ul>
                        {
                            backupStretchData.map((items) => (
                                <li key={items.id} style={{ color: items.color }}>{items.average}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className='am_backup_st_right'>
                <ul>
                    {
                        backupStretchData.map((items) => (
                            <li key={items.id}>
                                <div className='am_backup_st_sites' >
                                    <i className="am_legend_dot"  style={{ background: items.color }}></i>
                                    <span>{items.value}<em>{items.area}</em></span>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}
export default BackupStretch;