import React from "react";
import "./ActualYtdHrsBox.css";

function ActualYtdHrsBox(props){
   return(
    <div className="actualYtdHrsBox">
        <p>{props.titleText}</p>
        <strong>{props.hours}</strong>
        <span>{props.percent}</span>
    </div>
   );
}
export default ActualYtdHrsBox;