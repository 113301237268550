import React from "react";
import ReactECharts from 'echarts-for-react';

function PowerSourceDutyCycle() {

    const batteryChargingContribution = {
        title: {
            text: 'Battery charging contribution',
            show: false,
            top: '0px',
            left: 'center',
            textStyle: {
                fontSize: 14,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },

        legend: {
            top: '25px',
            left: 'center',
            show: true,
            data: ['Mains', 'Solar', 'Battery', 'LS Hrs', 'TCH', 'Fuel'],
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
              },
        },

        grid: {
            top: '80px',
            left: '60px',
            right: '60px',
            bottom: '20px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                // prettier-ignore
                data: ['Aug 29', 'Aug 30', 'Aug 31', 'Sep 2', 'Sep 3', 'Sep 4']
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'LS Hours',
                position: 'right',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#666666'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            // left Kwh
            {
                type: 'value',
                name: 'KWh',
                position: 'left',
                alignTicks: true,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#666666',
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            // left Fuel
            {
                type: 'value',
                name: 'Fuel',
                position: 'left',
                alignTicks: true,
                offset: 50,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#f66c4f'
                    }
                },
                axisLabel: {
                    formatter: '{value}'
                }
            },
            // right TCH
            {
                type: 'value',
                name: 'TCH',
                position: 'right',
                alignTicks: true,
                offset: 50,

                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#788eae'
                    }
                },
                axisLabel: {
                    formatter: '{value} %'
                }
            },
        ],


        series: [
            {
                name: 'Mains',
                type: 'bar',
                stack: 'Hours',
                color: ['#7243d0'],
                data: [
                    0, 0, 0, 0, 0, 0
                ]
            },
            {
                name: 'Solar',
                type: 'bar',
                stack: 'Hours',
                color: ['#f1b400'],
                yAxisIndex: 1,
                data: [
                    15, 20, 15, 4, 20, 12
                ]
            },
            {
                name: 'Battery',
                type: 'bar',
                stack: 'Hours',
                color: ['#32864b'],
                yAxisIndex: 1,
                data: [
                    10, 15, 20, 2, 15, 15
                ]
            },
            {
                name: 'LS Hrs',
                type: 'line',
                stack: 'Hours',
                color: ['#8498d7'],
                yAxisIndex: 2,
                data: [0, 12, 9, 2, 10, 12]
            },
            {
                name: 'TCH',
                type: 'line',
                color: ['#91cc75'],
                yAxisIndex: 3,
                data: [0, 0, 0, 0, 0, 0,]
            },
            {
                name: 'Fuel',
                type: 'line',
                color: ['#fac858'],
                yAxisIndex: 0,
                data: [0, 3, 0, 0, 0, 3,]
            },

        ]
    };

    return (
        <> 
            <div className="psu_headlines_row">
                <h3 className="chartTitleHeading pb-0">Power Source Duty Cycle</h3>
                <div className="psu_headlines_right d-flex align-items-center">
                    <div className="psu_selectbox">
                        <select>
                            <option value='1'>7 days</option>
                            <option value='2'>Last week</option>
                            <option value='3'>Last Mont</option>
                        </select>
                    </div>
                    <div className="ampButtonsGroup ps-2">
                        <ul className="ampButtonsBox">
                            <li><span className="ampButton active">KWh</span></li>
                            <li><span className="ampButton">Hours</span></li>
                        </ul>
                    </div>
                </div>
            </div>

            <ReactECharts option={batteryChargingContribution} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
        </>
    );
}

export default PowerSourceDutyCycle;

