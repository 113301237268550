
import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';
const NetworkTypeHeatMap = () => {
    const options = {
        chart: {
          
          type: 'heatmap',
          toolbar: {
            show: false,
          },
          fill: {
            colors: ['#32323E']
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#343434']
          }
        },
        
        colors: ['#19AAF8'],
        xaxis: {
          categories: ['2G', '3G', '4G', 'FN'],
        },
        grid: {
          padding: {
            left: 30,
            right: 0,
            top: -48,
            bottom: 0,
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '16px',  
              colors: ['#000'],  
            },
          },
        },
    
        stroke: {
            width: 0,
          },
      };
      // Define your chart data
      const series = [
        {
            name: 'Off',
            data: [90, 43, 5, 47 ],
          
        },
        {
          name: 'Bad',
          data: [23, 75, 250, 80 ],
        },
        {
          name: 'Average',
          data: [ 234, 250, 280, 120 ],
        },
        {
          name: 'Good',
          data: [120, 100, 110, 98 ],
        },
      ];
      
    return (
      <div className='heatmap_out'>
       
          <ApexCharts options={options} series={series} type="heatmap" height={400} />
      </div>
    );
  };
  
  export default NetworkTypeHeatMap;
  