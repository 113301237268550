import React from "react";
import { Link } from "react-router-dom";

function ReportsTempLists() {
    return (
        <div className='management_reports_detail pt-3'>
            <div className='row'>
                <div className='col-12 col-lg-4 pb-3'>
                    <div className='box_card'>
                        <div className='mr_detail_content'>
                            <div className='mr_detail_content_heading'>
                                <h4>Energy Performance <br /> & Consumption</h4>
                                <span>10 Reports</span>
                            </div>
                            <div className='mr_detail_content_reports'>
                                <ul>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 1</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 2</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 3</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 4</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 5</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                </ul>

                                <div className="r_pagination">
                                    <div className="r_pagination_ul">
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15.16 7.41L10.58 12L15.16 16.59L13.75 18L7.75 12L13.75 6L15.16 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button className="active"> 1 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button> 2 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8.84 7.41L13.42 12L8.84 16.59L10.25 18L16.25 12L10.25 6L8.84 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 pb-3'>
                    <div className='box_card'>
                        <div className='mr_detail_content'>
                            <div className='mr_detail_content_heading'>
                                <h4>Loadsheddding</h4>
                                <span>5 Reports</span>
                            </div>
                            <div className='mr_detail_content_reports'>
                                <ul>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 1</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 2</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 3</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 4</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 5</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                </ul>
                                <div className="r_pagination">
                                    <div className="r_pagination_ul">
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15.16 7.41L10.58 12L15.16 16.59L13.75 18L7.75 12L13.75 6L15.16 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button className="active"> 1 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button> 2 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8.84 7.41L13.42 12L8.84 16.59L10.25 18L16.25 12L10.25 6L8.84 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 pb-3'>
                    <div className='box_card'>
                        <div className='mr_detail_content'>
                            <div className='mr_detail_content_heading'>
                                <h4>Grid Consumption</h4>
                                <span>10 Reports</span>
                            </div>
                            <div className='mr_detail_content_reports'>
                                <ul>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 1</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 2</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 3</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 4</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 5</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                </ul>
                                <div className="r_pagination">
                                    <div className="r_pagination_ul">
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15.16 7.41L10.58 12L15.16 16.59L13.75 18L7.75 12L13.75 6L15.16 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button className="active"> 1 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button> 2 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8.84 7.41L13.42 12L8.84 16.59L10.25 18L16.25 12L10.25 6L8.84 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-4 pb-3'>
                    <div className='box_card'>
                        <div className='mr_detail_content'>
                            <div className='mr_detail_content_heading'>
                                <h4>Fuel Consumption</h4>
                                <span>10 Reports</span>
                            </div>
                            <div className='mr_detail_content_reports'>
                                <ul>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 1</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 2</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 3</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 4</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 5</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                </ul>
                                <div className="r_pagination">
                                    <div className="r_pagination_ul">
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15.16 7.41L10.58 12L15.16 16.59L13.75 18L7.75 12L13.75 6L15.16 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button className="active"> 1 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button> 2 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8.84 7.41L13.42 12L8.84 16.59L10.25 18L16.25 12L10.25 6L8.84 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 pb-3'>
                    <div className='box_card'>
                        <div className='mr_detail_content'>
                            <div className='mr_detail_content_heading'>
                                <h4>CO <sub>2</sub> Emissions</h4>
                                <span>5 Reports</span>
                            </div>
                            <div className='mr_detail_content_reports'>
                                <ul>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 1</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 2</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 3</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 4</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 5</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                </ul>
                                <div className="r_pagination">
                                    <div className="r_pagination_ul">
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15.16 7.41L10.58 12L15.16 16.59L13.75 18L7.75 12L13.75 6L15.16 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button className="active"> 1 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button> 2 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8.84 7.41L13.42 12L8.84 16.59L10.25 18L16.25 12L10.25 6L8.84 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-4 pb-3'>
                    <div className='box_card'>
                        <div className='mr_detail_content'>
                            <div className='mr_detail_content_heading'>
                                <h4>Savings</h4>
                                <span>10 Reports</span>
                            </div>
                            <div className='mr_detail_content_reports'>
                                <ul>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 1</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 2</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 3</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 4</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='mr_detail_cr_data'>
                                            <span>Report 5</span>
                                            <Link to={'/ReportsEnergyPerfCons'} className='viewBtn'>View</Link>
                                        </div>
                                    </li>
                                </ul>
                                <div className="r_pagination">
                                    <div className="r_pagination_ul">
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M15.16 7.41L10.58 12L15.16 16.59L13.75 18L7.75 12L13.75 6L15.16 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button className="active"> 1 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button> 2 </button>
                                        </div>
                                        <div className="r_pagination_li">
                                            <button>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M8.84 7.41L13.42 12L8.84 16.59L10.25 18L16.25 12L10.25 6L8.84 7.41Z" fill="#C4CDD5" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default ReportsTempLists;