import React from "react";
import { useState } from "react";

function CustomSelect(props) {
  const [data] = useState(props.data);
  let options = data.map((item, index) => (
    <option key={index} value={item}>{item}</option>
  ));
  const disabled = props?.disabled;
 
  return (
    <>
      <select
        name="customSearch"
        disabled={disabled} 
        onChange={(event) => props?.onSelectChange(event)}>
        <option value="">{props.title}</option>
        {options}
      </select>
    </>
  );
}

export default CustomSelect;