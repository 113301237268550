import React from 'react';

const moremenu = [
    {
      "id": 1,
      "title": "Transformer",
      "path": 'AmTransformer',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clipPath="url(#clip0_27_919)">
              <path d="M12.0826 10.5C14.863 10.5 17.117 8.26142 17.117 5.5C17.117 2.73858 14.863 0.5 12.0826 0.5C9.30214 0.5 7.04816 2.73858 7.04816 5.5C7.04816 8.26142 9.30214 10.5 12.0826 10.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.0963 10.083V10.5C14.0963 11.0304 13.8841 11.5391 13.5065 11.9142C13.1288 12.2893 12.6166 12.5 12.0825 12.5C11.5485 12.5 11.0363 12.2893 10.6586 11.9142C10.281 11.5391 10.0688 11.0304 10.0688 10.5V10.083" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.5585 22H7.04818C6.91466 22 6.78661 21.9473 6.69219 21.8536C6.59778 21.7598 6.54474 21.6326 6.54474 21.5V20.5C6.54474 20.3674 6.59778 20.2402 6.69219 20.1464C6.78661 20.0527 6.91466 20 7.04818 20H8.5585" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.54471 11H4.53094" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M0.503418 19V21H2.51718" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M0.503418 15V17" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.51718 11H0.503418V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.6204 11H19.6342" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.6617 19V21H21.6479" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.6617 15V17" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M21.6479 11H23.6617V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.02753 16H8.05505" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.04126 14V18" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.1101 15.5H20.1376" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M17.117 18.5H9.06191C8.78387 18.5 8.55847 18.7239 8.55847 19V23C8.55847 23.2761 8.78387 23.5 9.06191 23.5H17.117C17.395 23.5 17.6204 23.2761 17.6204 23V19C17.6204 18.7239 17.395 18.5 17.117 18.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.0757 10.4V6C11.0757 5.80222 11.0167 5.60888 10.906 5.44443C10.7954 5.27998 10.6381 5.15181 10.4541 5.07612C10.2702 5.00043 10.0677 4.98063 9.8724 5.01922C9.67708 5.0578 9.49767 5.15304 9.35686 5.29289C9.21605 5.43275 9.12015 5.61093 9.0813 5.80491C9.04245 5.99889 9.06239 6.19996 9.1386 6.38268C9.2148 6.56541 9.34386 6.72159 9.50944 6.83147C9.67502 6.94135 9.86969 7 10.0688 7H12.0826" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.0895 10.4V6C13.0895 5.80222 13.1485 5.60888 13.2592 5.44443C13.3698 5.27998 13.527 5.15181 13.711 5.07612C13.895 5.00043 14.0975 4.98063 14.2928 5.01922C14.4881 5.0578 14.6675 5.15304 14.8083 5.29289C14.9491 5.43275 15.045 5.61093 15.0839 5.80491C15.1227 5.99889 15.1028 6.19996 15.0266 6.38268C14.9504 6.56541 14.8213 6.72159 14.6557 6.83147C14.4902 6.94135 14.2955 7 14.0963 7H12.0826" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0826 12.5V14" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_27_919">
                <rect width="24.1651" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
    },
    {
      "id": 2,
      "title": "Smart Meter",
      "path": 'AmSmartMeter',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_27_925)">
              <path d="M23.0255 10.126H16.9842C16.4281 10.126 15.9773 10.5737 15.9773 11.126V23.126C15.9773 23.6783 16.4281 24.126 16.9842 24.126H23.0255C23.5815 24.126 24.0323 23.6783 24.0323 23.126V11.126C24.0323 10.5737 23.5815 10.126 23.0255 10.126Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9773 12.126H24.0323" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9773 22.126H24.0323" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.89478 8.62598V17.626C3.89478 17.7586 3.94782 17.8858 4.04223 17.9795C4.13664 18.0733 4.2647 18.126 4.39822 18.126H13.9636" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M20.0048 8.12596L11.25 1.71896C11.0102 1.5595 10.7281 1.47437 10.4395 1.47437C10.1508 1.47437 9.86874 1.5595 9.62891 1.71896L0.874084 8.12596" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.4395 7.12598V10.126" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.9315 8.58899C13.4243 9.07849 13.7599 9.70213 13.8959 10.3811C14.0318 11.06 13.962 11.7637 13.6953 12.4032C13.4285 13.0427 12.9769 13.5893 12.3973 13.9739C11.8178 14.3585 11.1365 14.5637 10.4395 14.5637C9.74251 14.5637 9.06118 14.3585 8.48165 13.9739C7.90213 13.5893 7.45044 13.0427 7.1837 12.4032C6.91696 11.7637 6.84715 11.06 6.98309 10.3811C7.11904 9.70213 7.45464 9.07849 7.94746 8.58899" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_27_925">
                <rect width="24.1651" height="24" fill="white" transform="translate(0.370667 0.625977)"/>
              </clipPath>
            </defs>
          </svg>  `,
    },

    {
      "id": 3,
      "title": "Auto transformer Switch",
      "path": 'AmAutotransformerSwitch',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_27_931)">
              <path d="M21.0117 15.123H3.89474C3.33866 15.123 2.88786 15.5707 2.88786 16.123V19.123C2.88786 19.6753 3.33866 20.123 3.89474 20.123H21.0117C21.5678 20.123 22.0186 19.6753 22.0186 19.123V16.123C22.0186 15.5707 21.5678 15.123 21.0117 15.123Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.91539 11.126H17.9911C18.2581 11.126 18.5142 11.2313 18.7031 11.4189C18.8919 11.6064 18.998 11.8608 18.998 12.126V15.126H5.90851V12.126C5.90851 11.8608 6.01459 11.6064 6.20342 11.4189C6.39224 11.2313 6.64835 11.126 6.91539 11.126Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.90851 20.123V24.123" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.4532 20.123V24.123" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.998 20.123V24.123" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.80211 11.126L7.56482 3.57699C7.49003 3.32875 7.46683 3.068 7.49661 2.81058C7.5264 2.55315 7.60856 2.30443 7.73811 2.07951C7.86766 1.85459 8.04188 1.65817 8.25021 1.50218C8.45853 1.34619 8.69661 1.23388 8.94997 1.17207C9.20334 1.11026 9.46672 1.10025 9.7241 1.14264C9.98149 1.18503 10.2275 1.27893 10.4472 1.41865C10.667 1.55836 10.8558 1.74098 11.0023 1.9554C11.1488 2.16983 11.2498 2.41158 11.2993 2.66599L12.9849 11.126" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_27_931">
                <rect width="24.1651" height="24" fill="white" transform="translate(0.370667 0.625977)"/>
              </clipPath>
            </defs>
          </svg> `,
    },
    {
      "id": 4,
      "title": "Battery",
      "path": 'AmBattery',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g clipPath="url(#clip0_28_231)">
              <path d="M13.1436 14.3878H15.0178C15.3493 14.3878 15.6672 14.2561 15.9017 14.0216C16.1361 13.7872 16.2678 13.4693 16.2678 13.1378V11.8878H17.5178C17.8493 11.8878 18.1672 11.7561 18.4017 11.5216C18.6361 11.2872 18.7678 10.9693 18.7678 10.6378V9.38776C18.7678 9.05624 18.6361 8.73829 18.4017 8.50387C18.1672 8.26945 17.8493 8.13776 17.5178 8.13776H16.2678V6.88776C16.2678 6.55624 16.1361 6.23829 15.9017 6.00387C15.6672 5.76945 15.3493 5.63776 15.0178 5.63776H13.1428" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.01778 5.63776H2.51778C2.18626 5.63776 1.86832 5.76945 1.6339 6.00387C1.39948 6.23829 1.26778 6.55624 1.26778 6.88776V13.1378C1.26778 13.4693 1.39948 13.7872 1.6339 14.0216C1.86832 14.2561 2.18626 14.3878 2.51778 14.3878H5.01778" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.0186 5.01276L5.01862 10.6378H7.51862V15.0128L12.5186 9.38776H10.0186V5.01276Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_28_231">
                <rect width="20" height="20" fill="white" transform="translate(0.0177841 0.0119019)"/>
              </clipPath>
            </defs>
          </svg>`,
    },

    {
      "id": 5,
      "title": "Rectifier",
      "path": 'AmRectifier',
      "value": "011,161",
      "valueType": "Sites",
      "dataType": "normal",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g clipPath="url(#clip0_27_943)">
              <path d="M12.4532 14.624C13.5654 14.624 14.467 13.7285 14.467 12.624C14.467 11.5194 13.5654 10.624 12.4532 10.624C11.3411 10.624 10.4395 11.5194 10.4395 12.624C10.4395 13.7285 11.3411 14.624 12.4532 14.624Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.5014 8.12598L23.3205 11.919C23.5093 12.1065 23.6153 12.3608 23.6153 12.626C23.6153 12.8911 23.5093 13.1454 23.3205 13.333L19.5014 17.126" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.40507 17.126L1.58597 13.331C1.39721 13.1434 1.29117 12.8891 1.29117 12.624C1.29117 12.3588 1.39721 12.1045 1.58597 11.917L5.40507 8.12598" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.92227 5.62595L11.7414 1.83295C11.9302 1.64548 12.1862 1.54016 12.4532 1.54016C12.7202 1.54016 12.9763 1.64548 13.1651 1.83295L16.9842 5.62595" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.92227 19.626L11.7414 23.419C11.9302 23.6064 12.1862 23.7118 12.4532 23.7118C12.7202 23.7118 12.9763 23.6064 13.1651 23.419L16.9842 19.626" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_27_943">
                <rect width="24.1651" height="24" fill="white" transform="translate(0.370667 0.625977)"/>
              </clipPath>
            </defs>
          </svg>`,
    },
    {
      "id": 6,
      "title": "Generator",
      "path": 'AmGenerator',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "dropdown",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M16.3992 13.6001H7.5992C7.37842 13.6001 7.19922 13.7793 7.19922 14.0001C7.19922 14.2209 7.37842 14.4001 7.5992 14.4001H16.3992C16.62 14.4001 16.7992 14.2209 16.7992 14.0001C16.7992 13.7793 16.62 13.6001 16.3992 13.6001Z" fill="black"/>
            <path d="M16.3992 12H7.5992C7.37842 12 7.19922 12.1792 7.19922 12.4C7.19922 12.6208 7.37842 12.8 7.5992 12.8H16.3992C16.62 12.8 16.7992 12.6208 16.7992 12.4C16.7992 12.1792 16.62 12 16.3992 12Z" fill="black"/>
            <path d="M19.1992 4H17.9992C17.7784 4 17.5992 4.1792 17.5992 4.39998V17.2C17.5992 17.4208 17.7784 17.6 17.9992 17.6C18.22 17.6 18.3992 17.4208 18.3992 17.2V4.80002H19.1992C21.4048 4.80002 23.1992 6.59444 23.1992 8.8V14.4C23.1992 16.6056 21.4048 18.4 19.1992 18.4H7.5992C7.37842 18.4 7.19922 18.5792 7.19922 18.8C7.19922 19.0208 7.37842 19.2 7.5992 19.2H19.1992C21.846 19.2 23.9992 17.0468 23.9992 14.4V8.8C23.9992 6.1532 21.846 4 19.1992 4Z" fill="black"/>
            <path d="M8.40008 3.20005C8.62086 3.20005 8.80006 3.02085 8.80006 2.80006C8.80006 2.13847 9.33847 1.60006 10.0001 1.60006H13.2001C13.8617 1.60006 14.4001 2.13847 14.4001 2.80006C14.4001 3.02085 14.5793 3.20005 14.8001 3.20005C15.0208 3.20005 15.2 3.02085 15.2 2.80006C15.2 1.69728 14.3029 0.800049 13.2 0.800049H10C8.89723 0.800049 8 1.69724 8 2.80006C8.00009 3.02085 8.1793 3.20005 8.40008 3.20005Z" fill="black"/>
            <path d="M16.3992 15.2H7.5992C7.37842 15.2 7.19922 15.3792 7.19922 15.5999C7.19922 15.8207 7.37842 15.9999 7.5992 15.9999H16.3992C16.62 15.9999 16.7992 15.8207 16.7992 15.5999C16.7992 15.3792 16.62 15.2 16.3992 15.2Z" fill="black"/>
            <path d="M16.3992 10.4001H7.5992C7.37842 10.4001 7.19922 10.5793 7.19922 10.8001C7.19922 11.0209 7.37842 11.2001 7.5992 11.2001H16.3992C16.62 11.2001 16.7992 11.0209 16.7992 10.8001C16.7992 10.5793 16.62 10.4001 16.3992 10.4001Z" fill="black"/>
            <path d="M6 5.59998C5.77922 5.59998 5.60002 5.77919 5.60002 5.99997V18.4H4.8C2.59439 18.4 0.800016 16.6055 0.800016 14.4V8.79995C0.800016 6.59434 2.59444 4.79997 4.8 4.79997H16.4C16.6208 4.79997 16.8 4.62077 16.8 4.39998C16.8 4.1792 16.6208 4 16.4 4H4.8C2.1532 3.99995 0 6.15316 0 8.79995V14.4C0 17.0468 2.1532 19.2 4.8 19.2H6C6.22078 19.2 6.39998 19.0208 6.39998 18.8V5.99997C6.39998 5.77919 6.22078 5.59998 6 5.59998Z" fill="black"/>
            <path d="M16.3992 16.8H7.5992C7.37842 16.8 7.19922 16.9793 7.19922 17.2C7.19922 17.4208 7.37842 17.6 7.5992 17.6H16.3992C16.62 17.6 16.7992 17.4208 16.7992 17.2C16.7992 16.9793 16.62 16.8 16.3992 16.8Z" fill="black"/>
            <path d="M9.61253 20.4C9.61253 20.1792 9.43375 20 9.21255 20H9.20856C8.98778 20 8.81055 20.1792 8.81055 20.4C8.81055 20.6208 8.99177 20.8 9.21255 20.8C9.43375 20.8 9.61253 20.6208 9.61253 20.4Z" fill="black"/>
            <path d="M16.3992 20C16.1784 20 15.9992 20.1792 15.9992 20.4V21.2C15.9992 21.8616 15.4608 22.4 14.7992 22.4H9.19919C8.53759 22.4 7.99919 21.8616 7.99919 21.2V20.4C7.99919 20.1792 7.81998 20 7.5992 20C7.37842 20 7.19922 20.1792 7.19922 20.4V21.2C7.19922 22.3028 8.09641 23.2 9.19923 23.2H14.7993C15.902 23.2 16.7993 22.3028 16.7993 21.2V20.4C16.7992 20.1792 16.62 20 16.3992 20Z" fill="black"/>
            <path d="M16.3992 8.80005H7.5992C7.37842 8.80005 7.19922 8.97925 7.19922 9.20003C7.19922 9.42081 7.37842 9.60002 7.5992 9.60002H16.3992C16.62 9.60002 16.7992 9.42081 16.7992 9.20003C16.7992 8.97925 16.62 8.80005 16.3992 8.80005Z" fill="black"/>
            <path d="M15.2121 20.4C15.2121 20.1792 15.0334 20 14.8122 20H14.8082C14.5874 20 14.4102 20.1792 14.4102 20.4C14.4102 20.6208 14.5914 20.8 14.8122 20.8C15.0333 20.8 15.2121 20.6208 15.2121 20.4Z" fill="black"/>
            <path d="M16.3992 5.6001H7.5992C7.37842 5.6001 7.19922 5.7793 7.19922 6.00008C7.19922 6.22086 7.37842 6.40007 7.5992 6.40007H16.3992C16.62 6.40007 16.7992 6.22086 16.7992 6.00008C16.7992 5.7793 16.62 5.6001 16.3992 5.6001Z" fill="black"/>
            <path d="M16.3992 7.19995H7.5992C7.37842 7.19995 7.19922 7.37915 7.19922 7.59994C7.19922 7.82072 7.37842 7.99992 7.5992 7.99992H16.3992C16.62 7.99992 16.7992 7.82072 16.7992 7.59994C16.7992 7.37915 16.62 7.19995 16.3992 7.19995Z" fill="black"/>
          </svg>`,
    },
    {
      "id": 7,
      "title": "Solar",
      "path": 'AmSolar',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "dropdown",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_1151_1475)">
              <path d="M3 13.5H1.5C1.23478 13.5 0.98043 13.6054 0.792893 13.7929C0.605357 13.9804 0.5 14.2348 0.5 14.5V22.5C0.5 22.7652 0.605357 23.0196 0.792893 23.2071C0.98043 23.3946 1.23478 23.5 1.5 23.5H2.691C2.78378 23.5 2.87473 23.4742 2.95367 23.4254C3.03261 23.3767 3.09644 23.3069 3.138 23.224L3.362 22.776C3.40356 22.6931 3.46739 22.6233 3.54633 22.5746C3.62527 22.5258 3.71622 22.5 3.809 22.5H10.191C10.2838 22.5 10.3747 22.5258 10.4537 22.5746C10.5326 22.6233 10.5964 22.6931 10.638 22.776L10.862 23.224C10.9036 23.3069 10.9674 23.3767 11.0463 23.4254C11.1253 23.4742 11.2162 23.5 11.309 23.5H12.5C12.7652 23.5 13.0196 23.3946 13.2071 23.2071C13.3946 23.0196 13.5 22.7652 13.5 22.5V14.5C13.5 14.2348 13.3946 13.9804 13.2071 13.7929C13.0196 13.6054 12.7652 13.5 12.5 13.5H3Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.5 21.5H17.5C18.0304 21.5 18.5391 21.2893 18.9142 20.9142C19.2893 20.5391 19.5 20.0304 19.5 19.5V15.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 13.5L3.862 15.224C3.90356 15.3069 3.96739 15.3767 4.04633 15.4254C4.12527 15.4742 4.21622 15.5 4.309 15.5H9.691C9.78378 15.5 9.87473 15.4742 9.95367 15.4254C10.0326 15.3767 10.0964 15.3069 10.138 15.224L11 13.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 17.5H4C3.72386 17.5 3.5 17.7239 3.5 18V20C3.5 20.2761 3.72386 20.5 4 20.5H10C10.2761 20.5 10.5 20.2761 10.5 20V18C10.5 17.7239 10.2761 17.5 10 17.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.5 0.5H16.5C15.9477 0.5 15.5 0.947715 15.5 1.5V14.5C15.5 15.0523 15.9477 15.5 16.5 15.5H22.5C23.0523 15.5 23.5 15.0523 23.5 14.5V1.5C23.5 0.947715 23.0523 0.5 22.5 0.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M23.5 11.5H15.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.5 13.25C19.5663 13.25 19.6299 13.2763 19.6768 13.3232C19.7237 13.3701 19.75 13.4337 19.75 13.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.25 13.5C19.25 13.4337 19.2763 13.3701 19.3232 13.3232C19.3701 13.2763 19.4337 13.25 19.5 13.25" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.5 13.75C19.4337 13.75 19.3701 13.7237 19.3232 13.6768C19.2763 13.6299 19.25 13.5663 19.25 13.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.75 13.5C19.75 13.5663 19.7237 13.6299 19.6768 13.6768C19.6299 13.7237 19.5663 13.75 19.5 13.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_1151_1475">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>`,
    },
    {
      "id": 8,
      "title": "RMS & CSU",
      "path": 'AmRMSandCSU',
      "value": "10,408",
      "valueType": "Sites",
      "dataType": "dropdown",
      "icon": `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g clipPath="url(#clip0_1151_1465)">
              <path d="M6.88672 20.1067C10.3385 20.1067 13.1367 17.3085 13.1367 13.8567C13.1367 10.4049 10.3385 7.60669 6.88672 7.60669C3.43494 7.60669 0.636719 10.4049 0.636719 13.8567C0.636719 17.3085 3.43494 20.1067 6.88672 20.1067Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.13672 6.35669V2.60669C3.13672 2.27517 3.26841 1.95723 3.50284 1.72281C3.73726 1.48839 4.0552 1.35669 4.38672 1.35669H13.2442C13.5755 1.35676 13.8932 1.48834 14.1276 1.72252L19.0209 6.61586C19.2551 6.85019 19.3866 7.1679 19.3867 7.49919V18.8567C19.3867 19.1882 19.255 19.5062 19.0206 19.7406C18.7862 19.975 18.4682 20.1067 18.1367 20.1067H14.3867" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19.3867 7.60669H14.3867C14.0552 7.60669 13.7373 7.47499 13.5028 7.24057C13.2684 7.00615 13.1367 6.68821 13.1367 6.35669V1.35669" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.13594 11.3567H6.44177C6.18213 11.3569 5.93064 11.4474 5.73045 11.6127C5.53025 11.778 5.39381 12.0079 5.34454 12.2628C5.29527 12.5177 5.33624 12.7819 5.46041 13.0099C5.58459 13.2379 5.78423 13.4156 6.02511 13.5125L7.74511 14.2009C7.98598 14.2978 8.18563 14.4755 8.3098 14.7035C8.43398 14.9315 8.47494 15.1957 8.42567 15.4506C8.3764 15.7055 8.23997 15.9353 8.03977 16.1007C7.83957 16.266 7.58808 16.3565 7.32844 16.3567H5.63594" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.88672 11.3567V10.7317" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.88672 16.9817V16.3567" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.1426 16.7991L11.4601 18.1166" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.62695 9.28418L3.80112 10.4583" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_1151_1465">
                <rect width="20" height="20" fill="white" transform="translate(0.0117188 0.731689)"/>
              </clipPath>
            </defs>
          </svg>  `,
    },
  ];
function MoreMenuLinks() {
    return(
        <></>
    )
}

export { moremenu, MoreMenuLinks }; 