
import React from "react";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
import ReactECharts from 'echarts-for-react';
import SpHeader from "../../../Components/mainHeader/SpHeader";  

function TeamsViewSummary() {
    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Summary', link: '/TeamsViewSummary' },
    ];
    
    const getUrlFromLocalStorage = localStorage.getItem('summaryParentUrl');
    let parenturl = '/GridUnavailability';

    if (getUrlFromLocalStorage !== '' && getUrlFromLocalStorage !== undefined) {
        parenturl = getUrlFromLocalStorage;
    }

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: parenturl,
        otherUrl: '/TeamsViewSummary',
    }

    // Sites Onboarding Timeline chart 
    const SitesOnboardingTimeline = {
        title: {
            text: 'Sites Onboarding Timeline',
            show: true,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '250px',
            top: '0px',
            show: true,
            data: ['Sites'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '0px',
            top: '70px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Jan 1', 'Jan 2', 'Jan 3', 'Jan 4', 'Jan 5', 'Jan 6', 'Jan 7', 'Jan 8 ', 'Jan 9', 'Jan 10', 'Jan 11', 'Jan 12', 'Jan 13', 'Jan 14', 'Jan 15', 'Jan 16', 'Jan 17', 'Jan 18', 'Jan 19', 'Jan 20', 'Jan 21', 'Jan 22', 'Jan 23', 'Jan 24', 'Jan 25', 'Jan 26', 'Jan 27', 'Jan 28', 'Jan 29', 'Jan 30'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Sites',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#32864B',
                ],

                data: [3, 2, 1, 9, 3, 3, 8, 5, 2, 1, 6, 8, 4, 5, 4, 7, 5, 4, 1, 5, 7, 7, 3, 6, 9, 4, 5, 2, 4, 5],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    // formatter: function (params) {
                    //     return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    // },
                },
                barGap: '0%',

            },

        ],

    };


    //   Power Sources Utilization
    const PowerSourcesUtilization = {
        title: {
            text: 'Power Sources Utilization',
            show: true,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },


        legend: {
            left: '250',
            top: '0px',
            show: true,
            data: ['Grid', 'Genset', 'Solar', 'Site Load', 'LS', 'NAR', 'PAR'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '50px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Jan 1', 'Jan 2', 'Jan 3', 'Jan 4', 'Jan 5', 'Jan 6', 'Jan 7', 'Jan 8 ', 'Jan 9', 'Jan 10', 'Jan 11', 'Jan 12', 'Jan 13', 'Jan 14', 'Jan 15', 'Jan 16', 'Jan 17', 'Jan 18', 'Jan 19', 'Jan 20', 'Jan 21', 'Jan 22', 'Jan 23', 'Jan 24', 'Jan 25', 'Jan 26', 'Jan 27', 'Jan 28', 'Jan 29', 'Jan 30'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'kWh',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
            {
                type: 'value',
                name: 'Hrs',
                min: 0,
                max: 25,
                interval: 5,
                axisLabel: {
                    formatter: '{value}'
                }
            },
            {
                type: 'value',
                name: 'PAR | NAR %',
                position: 'right',
                alignTicks: true,
                offset: 50,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000'
                    }
                },
                axisLabel: {
                    formatter: '{value} '
                }
            },
        ],

        series: [
            {
                name: 'Grid',
                type: 'bar',
                stack: 'Hours',
                color: ['#7243D0'],
                data: [7, 6, 2, 4, 5, 7, 6, 2, 4, 5, 7, 6, 2, 4, 5, 7, 6, 2, 4, 5, 6, 2, 4, 5, 9, 7, 6, 2, 4, 6],
            },
            {
                name: 'Genset',
                type: 'bar',
                stack: 'Hours',
                color: ['#32864B'],
                data: [5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 6, 5, 4, 4, 7, 6, 5, 4, 4, 4,],
            },
            {
                name: 'Solar',
                type: 'bar',
                stack: 'Hours',
                color: ['#F1B400'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 5, 2, 5, 4, 4, 7, 6, 5, 4, 7],
            },


            {
                name: 'Site Load',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#D00',
                ],
                data: [14, 9, 14, 5, 10, 5, 4, 4, 7, 6, 14, 9, 4, 5, 10, 5, 4, 4, 7, 6, 4, 5, 10, 5, 4, 19, 7, 12, 14, 10],

            },
            {
                name: 'LS',
                type: 'line',
                stack: 'Hours',
                smooth: true,
                color: ['#00B9EB'],
                yAxisIndex: 2,
                data: [0, 8, 9, 2, 10, 8, 0, 6, 0, 10, 12, 8, 12, 9, 10, 7, 10, 9, 0, 12, 0, 10, 10, 12, 10, 12, 19, 0, 10, 9,]
            },
            {
                name: 'NAR',
                type: 'line',
                stack: 'Hours',
                smooth: true,
                color: ['#B35A3E'],
                yAxisIndex: 2,
                data: [20, 18, 19, 22, 24, 18, 16, 18, 19, 20, 19, 16, 17, 18, 19, 12, 20, 22, 20, 25, 19, 22, 18, 22, 20, 22, 19, 16, 20, 19,]
            },
            {
                name: 'PAR',
                type: 'line',
                stack: 'Hours',
                smooth: true,
                color: ['#A472B4'],
                yAxisIndex: 2,
                data: [20, 12, 19, 12, 9, 12, 10, 8, 14, 12, 18, 12, 10, 12, 29, 12, 10, 12, 18, 12, 19, 12, 10, 12, 14, 12, 19, 12, 10, 12,]
            },

        ],
    };



    // Performing & Non-performing Sites
    const PerformingNonPerformingSites = {
        title: {
            text: 'Performing & Non-performing Sites',
            show: true,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },


        legend: {
            left: '250px',
            top: '0px',
            show: true,
            data: ['NAR', 'Fuel', 'PAR', 'Grid', 'OPEX', 'CO2', 'Energy Consumption'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'kWh',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },

        ],

        series: [
            {
                name: 'NAR',
                type: 'bar',
                stack: 'stack1',
                color: ['#B35A3E'],
                data: [5, 4, 2, 4, 5, 6, 7, 3, 4, 6, 4, 6],
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(23, 167, 0, 0.10)'
                }
            },
            {
                name: 'Fuel',
                type: 'bar',
                stack: 'stack1',
                color: ['#F55836'],
                data: [5, 3, 4, 5, 3, 5, 4, 2, 5, 6, 4, 3],

            },


            {
                name: 'PAR',
                type: 'bar',
                stack: 'stack1',
                color: ['#A472B4'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 4],
            },
            {
                name: 'Grid',
                type: 'bar',
                stack: 'stack1',
                color: ['#7243D0'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 4],
            },
            {
                name: 'OPEX',
                type: 'bar',
                stack: 'stack1',
                color: ['#41C776'],
                data: [5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 5, 4],
            },
            {
                name: 'CO2',
                type: 'bar',
                stack: 'stack1',
                color: ['#5D5B57'],
                data: [5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 5, 4],
            },

            {
                name: 'Energy Consumption',
                type: 'bar',
                stack: 'stack1',
                color: ['#32864B'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 4],
            },

            // 2nd cols arrays

            {
                name: 'NAR',
                type: 'bar',
                stack: 'stack2',
                color: ['#B35A3E'],
                data: [5, 4, 2, 4, 5, 6, 7, 3, 4, 6, 4, 6],
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(221, 0, 0, 0.10)'
                }
            },
            {
                name: 'Fuel',
                type: 'bar',
                stack: 'stack2',
                color: ['#F55836'],
                data: [5, 3, 4, 5, 3, 5, 4, 2, 5, 6, 4, 3],

            },


            {
                name: 'PAR',
                type: 'bar',
                stack: 'stack2',
                color: ['#A472B4'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 4],
            },
            {
                name: 'Grid',
                type: 'bar',
                stack: 'stack2',
                color: ['#7243D0'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 4],
            },
            {
                name: 'OPEX',
                type: 'bar',
                stack: 'stack2',
                color: ['#41C776'],
                data: [5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 5, 4],
            },
            {
                name: 'CO2',
                type: 'bar',
                stack: 'stack2',
                color: ['#5D5B57'],
                data: [5, 4, 4, 7, 6, 5, 4, 4, 7, 6, 5, 4],
            },

            {
                name: 'Energy Consumption',
                type: 'bar',
                stack: 'stack2',
                color: ['#32864B'],
                data: [2, 1, 2, 5, 2, 5, 4, 4, 7, 6, 2, 4],
            },
        ],
    };

    return (
        <div className="wrapper">
            {/* <HeaderMain pageTitle='Teams View - Grid Unavailability'/> */}
            {/* <SpHeader breadcrumbs={breadcrumbs} /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} isSummaryButton />
            <div className="tmp_totalSitesRow">
                <div className="tmp_totalSitesBox">
                    <strong>Total Sites:</strong>
                    <h3>12500</h3>
                </div>
                <div className="tmp_totalSitesFilters">
                    <ApplyFilterHeader notYTDBtns notDatepicker />
                </div>
            </div>

            <div className="contentWrap">
                <div className="sitesInsights_solar pt-3">
                    <div className="row pb-3">
                        <div className="col-12 col-lg-12">
                            <div className='box_card'>
                                <div className="border-bottom-0 pb-0">
                                    <div className="amp_btn_margin float-end">
                                        <div className="ampButtonsGroup">
                                            <ul className="ampButtonsBox">
                                                <li><span className="ampButton active">Infra</span></li>
                                                <li><span className="ampButton ">Region</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ReactECharts option={SitesOnboardingTimeline} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                            </div>
                        </div>
                    </div>

                    {/*  //   Power Sources Utilization */}
                    <div className="row pb-3">
                        <div className="col-12 col-lg-12">
                            <div className='box_card'>
                                <div className=" border-bottom-0 pb-0">

                                    <div className="amp_btn_margin float-end">
                                        <div className="ampButtonsGroup">
                                            <ul className="ampButtonsBox me-2">
                                                <li><span className="ampButton active">kWh</span></li>
                                                <li><span className="ampButton ">Hrs</span></li>
                                            </ul>
                                            <ul className="ampButtonsBox me-2">
                                                <li><span className="ampButton">Total</span></li>
                                                <li><span className="ampButton active">Average</span></li>
                                            </ul>
                                            <ul className="ampButtonsBox">
                                                <li><span className="ampButton active">Region</span></li>
                                                <li><span className="ampButton ">Infra</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ReactECharts option={PowerSourcesUtilization} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                            </div>
                        </div>
                    </div>
                    {/* Performing & Non-performing Sites */}
                    <div className="row pb-3">
                        <div className="col-12 col-lg-12">
                            <div className='box_card'>
                                <div className=" border-bottom-0 pb-0">
                                    <div className="amp_btn_margin float-end">
                                        <div className="ampButtonsGroup">
                                            <ul className="ampButtonsBox">
                                                <li><span className="ampButton">Region</span></li>
                                                <li><span className="ampButton active">Infra</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <ReactECharts option={PerformingNonPerformingSites} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>  
        </div>
    );
};

export default TeamsViewSummary; 