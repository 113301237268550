import React from "react";
import ApplyFilterHeader from "../../Components/ApplyFilterHeader";
import OnOffGridSitesHeader from "../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import SimpleMapPage from "../../Components/Map/MapChart";
import { Link } from "react-router-dom";
import SpHeader from "../../Components/mainHeader/SpHeader";

function MapView() {
    const breadcrumbs = [
        { label: 'Single Site View', link: '/MapView' },
    ];
    return (
        <div className="MapView_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} />
            
            <ApplyFilterHeader />
            <OnOffGridSitesHeader />
            <div className="contentWrap">
                <div className="row pt-3">
                    <div className="col-12 col-xl-4">
                        <div className="box_card mvc_card">
                            <div className="box_card_inner">
                                <div className="mvc_card_header">
                                    <div className="mvc_card_detail_row d-flex justify-content-between align-items-center">
                                        <div className="mvc_card_detail_cell">
                                        <div className="raised_text text-start"> 
                                            <i className="raised_circle bg-blue2"></i>
                                             AMKM - 03</div>
                                        </div>
                                        <div className="mvc_card_detail_cell">
                                            <strong>AMKM - 03</strong>
                                        </div>
                                        <div className="mvc_card_detail_cell">
                                           <span className="label_btn">Good Site</span>
                                        </div>
                                    </div>
                                    <div className="mvc_latlong_row d-flex justify-content-between align-items-center">
                                        <div className="mvc_latlong_cell">
                                              <span>Lat:</span>
                                              <strong>34.2756</strong>
                                        </div>
                                        <div className="mvc_latlong_cell">
                                              <span>Lat:</span>
                                              <strong>34.2756</strong>
                                        </div>
                                        <div className="mvc_latlong_cell">
                                            <span>Status:</span>
                                             <ul className="d-flex justify-content-between align-items-center">
                                                <li>
                                                    <div className="mvc_status mvc_status_green"> </div>
                                                </li>
                                                <li>
                                                    <div className="mvc_status mvc_status_dark"> </div>
                                                </li>
                                                <li>
                                                    <div className="mvc_status mvc_status_blue"> </div>
                                                </li>
                                             </ul>
                                        </div>
                                    </div>

                                    <div className="mvc_performance">
                                        <strong>Performance</strong>
                                        <ul>
                                            <li>
                                                <div className="raised_text text-start"><i className="raised_circle bg-green"></i>Energy</div>
                                            </li>
                                            <li>
                                                <div className="raised_text text-start"><i className="raised_circle bg-green"></i>CO<sub>2</sub></div>
                                            </li>
                                            <li>
                                                <div className="raised_text text-start"><i className="raised_circle bg-green"></i>NAR</div>
                                            </li>
                                            <li>
                                                <div className="raised_text text-start"><i className="raised_circle bg-green"></i>GLO</div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                {/* content */}
                                <div className="mvc_site_list">
                                    <ul>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span>Site ID</span>
                                                <b>A</b>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mvc_visit_btn">
                                    <Link to={'/SitesSnapshotView'}>
                                    <button className="applyFilter_button" type="submit">Visit Site</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-8">
                        <div className="box_card mvc_map_view">
                            <div className="box_card_inner infrastructureChartBox">
                                <SimpleMapPage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MapView;