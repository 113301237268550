import React, { useState } from 'react';
import RegionWiseColumnChart from "./Charts/RegionWiseColumnChart";
import AmpButtonsGroup from './AmpButtonsGroup/AmpButtonsGroup';

const GridOverconsumptionKwhCol = () => {
    const dataInfra = [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13, 11.5, 6,];
    const dataRegion = [4, 14, 14, 13, 11.5, 6, 14, 14, 18, 13, 13, 13.5];
 
    const [chartData, setChartData] = useState(dataInfra);
    const colsData_gensetOverruns = {
        title: {
            text: 'Grid Overconsumption (kWh)',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
                width:'120px'
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        grid: {
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: '(KWh)',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#7243D0',
                ],
                // areaStyle: {normal: {}},
                data: chartData
            },

        ]
    };

    
    const btnArray = [
        { id: 1, name: 'Infra', active: true },
        { id: 2, name: 'Region', active: false },
    ];
    const [array, setArray] = useState(btnArray);
    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
        console.log('array', id)
        if (id === 1) {
            setChartData(dataInfra);
        }
        if (id === 2) {
            setChartData(dataRegion);
        }
        
    };

    return (
        <>
            <div className="amp_btn_margin">
                <AmpButtonsGroup array={array} handleItemClick={handleItemClick} />
            </div>
            <RegionWiseColumnChart options={colsData_gensetOverruns} />
        </>
    )
}

export default GridOverconsumptionKwhCol;