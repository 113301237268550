
import React from "react";
import SpHeader from "../../../../../Components/mainHeader/SpHeader"; 
import MyAllDownloadInfo from "../../../../../Components/ytdToFrom/MyAllDownloadInfo";
import ReactECharts from 'echarts-for-react';
import CommercialSavingPagination from "../CommercialSavingPagination";

function DsGensetRunHours() {
    const breadcrumbs = [
        { label: 'Data Stories', link: '/DataStories' },
        { label: 'Commercial Savings', link: '/DsCommercialSavingsLinks' },
        { label: 'Grid Peak Hours Battery Charging', link: '' },
    ];

    // 
    const GensetRunHours = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '0px',
            show: true,
            data: ['Grid Utilization', 'Grid Utilization With Policy', 'Grid Utilization With Policy (Peak Hours)', 'Grid Utilization (Peak Hours)', 'Battery Charging', 'Battery Charging (Peak Hours)', 'Battery Charging With Policy', 'Battery Charging With Policy (Peak Hours)'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            top: '30px',
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'Grid Utilization',
                type: 'bar',
                stack: 'Battery Ageing',
                smooth: true,
                color: [
                    '#C93898',
                ],
                // areaStyle: {normal: {}},
                data: [15, 18, 10, 15, 18, 15, 10, 20, 18, 15, 16, 15]
            },
            
            {
                name: 'Grid Utilization With Policy',
                type: 'bar',
                stack: 'Battery Ageing',
                smooth: true,
                color: [
                    '#5D5B57',
                ],
                // areaStyle: {normal: {}},
                data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8]
            }, 
            {
                name: 'Grid Utilization With Policy (Peak Hours)',
                type: 'bar',
                stack: 'Battery Ageing',
                smooth: true,
                color: [
                    '#7243D0',
                ],
                // areaStyle: {normal: {}},
                data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8]
            },
            {
                name: 'Grid Utilization (Peak Hours)',
                type: 'bar',
                stack: 'Battery Ageing',
                smooth: true,
                color: [
                    '#A472B4',
                ],
                // areaStyle: {normal: {}},
                data: [40, 30, 50, 40, 30, 40, 30, 25, 30, 40, 45, 40]
            },

            {
                name: 'Battery Charging',
                type: 'line',
                stack: 'Battery Charging',
                smooth: true,
                color: [
                    '#00B2FF',
                ],
                // areaStyle: {normal: {}},
                data: [15, 12, 9, 14, 11, 15, 14, 10, 14, 10, 15, 11]
            },
            {
                name: 'Battery Charging (Peak Hours)',
                type: 'line',
                stack: 'Battery Charging (Peak Hours)',
                smooth: true,
                color: [
                    '#FF842C',
                ],
                // areaStyle: {normal: {}},
                data: [5, 4, 3, 4, 6, 5, 4, 3, 4, 6, 5, 4]
            },
            {
                name: 'Battery Charging With Policy',
                type: 'line',
                stack: 'Battery Charging With Policy',
                smooth: true,
                color: [
                    '#D6DA1A',
                ],
                // areaStyle: {normal: {}},
                data: [18, 16, 17, 14, 11, 15, 9, 15, 11, 10, 18, 11]
            },
            {
                name: 'Battery Charging With Policy (Peak Hours)',
                type: 'line',
                stack: 'Battery Charging With Policy (Peak Hours)',
                smooth: true,
                color: [
                    '#09CB85',
                ],
                // areaStyle: {normal: {}},
                data: [60, 55, 50, 40, 60, 50, 40, 40, 60, 50, 40, 50]
            },
            
        ]
    };

    return (
        <div className="gridPeakHrs_main">
            <div className="cs_gp_header">
                <SpHeader breadcrumbs={breadcrumbs} />
            </div>
            <div className="cs_gp_hrs_inner">
                <div className="contentWrap">
                    <div className="cs_gp_heading">
                        <h2> Grid Peak Hours Battery Charging </h2>
                        <div className="ampButtonsGroup">
                            <ul className="ampButtonsBox">
                                <li><span className="ampButton ">Total</span></li>
                                <li><span className="ampButton active">Average</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="cs_gp_hrs_content">
                        <div className="box_card">
                            <div className="row cs_gp_hrs_content_inner">
                                <div className="col-12 col-lg-7">
                                    <div className="cs_gp_hrs_content_info">
                                        <h4>
                                            <span className="color_purple_dark">Grid</span> utilization and <span className="color_blue_navy">Battery</span> charging <span className="color_green_pigment">After</span> policy is applied
                                        </h4>
                                        <p>After the policy is applied the battery charging accounts for <strong>20.2%</strong> of Grid utilization i.e. an effective <strong className="color_green_pigment">2.8%</strong> reduction in grid utilization.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5">
                                    <div className="cs_gp_hrs_statusRow">
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Responded Sites</small>
                                                <h2>1279</h2>
                                            </div>
                                        </div>
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Grid Utilization</small>
                                                <h2 className="color_purple_dark">
                                                    <strong>44.9</strong>
                                                    <sub>kWh</sub>
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="cs_gp_hrs_status_cell">
                                            <div className="cs_gp_hrs_status_text">
                                                <small>Battery Charging</small>
                                                <h2 className="color_deep_skyBlue">
                                                    <strong>9.07</strong>
                                                    <sub>kWh</sub>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="cs_gp_hrs_sRow_btn_grp">
                                        {/* <Link className="cs_sRow_btn">Grid Utilization</Link>
                                        <Link className="cs_sRow_btn bg_deep_skyBlue">Battery Charging</Link> */}
                                        <MyAllDownloadInfo />
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">
                                    <ReactECharts option={GensetRunHours} opts={{ renderer: 'svg' }} style={{ height: 351 }} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <CommercialSavingPagination isNext='/DsSolarPotentialMaximization' isPrev='/CsGridPeakHrsAfterPolicy' currentId='5'/>

                </div>
            </div>
        </div>
    )
}

export default DsGensetRunHours