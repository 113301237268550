
import React from 'react';
import ExecutiveDashboardHeader from "../../../Components/mainHeader/ExecutiveDashboardHeader";
import YtdToFromBox from '../../../Components/ytdToFrom/YtdToFromBox';
import ReactECharts from 'echarts-for-react';
import SummaryRadiosTabs from '../../../Components/SummaryRadiosTabs';

import icon1 from "../../../assets/images/opex_reduction_icon1.svg";
import icon2 from "../../../assets/images/opex_diesel_icon.svg";
import icon3 from "../../../assets/images/opex_carbon_icon.svg";
import tree_img from "../../../assets/images/tree_img.svg";



function Savings() {
    let is_main = true;
    let activeIndex = 6;



    //
    // 
    const thunderSavingMpkrData = {
        title: {
            text: 'Thunderbolt Savings - MPKR',
            show: true,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {

            left: 'center',
            top: '30px',
            show: true,
            data: ['TB 3.0', 'TB 2.0', 'TB 1.0', 'V1'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '160px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2021 Actual', '2022 YTD', '2022 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'V1',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    // backgroundColor: "#FDBF6F",
                    // padding: 4,
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#FDBF6F',
                ],
                data: [130, 137, 193],
            },

            {
                name: 'TB 1.0',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#FDFF85',
                ],

                data: [414, 464, 439]

            },
            {
                name: 'TB 2.0',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#88F580',
                ],

                data: [189, 292, 243]

            },

            {
                name: 'TB 3.0',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#4299FF',
                ],
                // areaStyle: {normal: {}},
                data: [18, 138, 143],
                markPoint: {
                    symbol: 'circle',
                    symbolSize: 0.001,
                    symbolOffset: [0, -15],
                    itemStyle: {
                        color: '#fff' // Set the symbol color here
                    },
                    data: [
                        {
                            name: '603',
                            coord: [0, 1018]
                        },
                        {
                            name: '1,031',
                            coord: [1, 1018]
                        },
                        {
                            name: '1,018',
                            coord: [2, 1018]
                        },
                        // Repeat for other columns
                    ],
                    label: {
                        show: true,
                        position: 'inside',
                        offset: [0, -10],
                        formatter: (params) => {
                            return params.data.name;
                        },
                        textStyle: {
                            color: '#000',
                            fontSize: 12
                        },
                        emphasis: {
                            label: {
                                color: "#000",
                            },
                        },
                    }
                }

            },
        ],
        graphic: [
            {
                type: 'group',
                left: '18%',
                top: '70px',
                width: '100%',

                children: [
                    //top main line
                    {
                        type: 'rect',
                        z: 90,
                        left: 'center',
                        top: 'middle',
                        shape: {
                            width: 198,
                            height: 1,
                        },
                        style: {
                            fill: '#000',
                            //  stroke: '#000',
                            lineWidth: 1,
                        },

                    },

                    //first box
                    {
                        type: 'rect',
                        z: 100,
                        left: '25%',
                        top: 'middle',
                        shape: {
                            width: 56,
                            height: 36,
                        },
                        style: {
                            fill: '#fff',
                            stroke: '#000',
                            lineWidth: 1,
                        },
                    },

                    // text left
                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: ' +428\n(+71%)',
                            fill: '#000',
                            fontSize: 10,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: 'center',
                            top: '110px',
                            textAlign: 'left',
                            textVerticalAlign: 'middle',
                            padding: [0, 100, 0, 90]
                        },
                    },
                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '17%',
                        bottom: "-50px",
                        shape: {
                            width: 1,
                            height: 50,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //middle horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '50%',
                        bottom: "-50px",
                        shape: {
                            width: 1,
                            height: 50,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '49%',
                        bottom: -52,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        right: '17%',
                        bottom: "-50px",
                        shape: {
                            width: 1,
                            height: 50,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },

                    {
                        type: 'rect',
                        z: 100,
                        left: '58%',
                        top: 'middle',
                        shape: {
                            width: 56,
                            height: 36,
                        },
                        style: {
                            fill: '#FDBF6F',
                            stroke: '#000',
                            lineWidth: 1,
                        },

                    },
                    // text right
                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '    +13\n(+1.29%)',
                            fill: '#000',
                            fontSize: 10,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: 'center',
                            top: '110px',
                            textAlign: 'left',
                            textVerticalAlign: 'middle',
                            padding: [0, 100, 0, 184]
                        },
                    },

                ],
            },
        ],

    };
  

    //
    // 
    const averageTchAgData = {
        title: {
            text: 'Avg. TCH %age',
            show: true,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {

            left: 'center',
            top: '30px',
            show: true,
            data: ['TB 3.0', 'TB 2.0', 'TB 1.0'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '-30px',
            top: '80px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2021 Actual', '2022 YTD', '2022 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [


            {
                name: 'TB 1.0',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#FDFF85',
                ],

                data: [97, 94, 99]

            },
            {
                name: 'TB 2.0',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#88F580',
                ],

                data: [98, 96, 99]

            },

            {
                name: 'TB 3.0',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#4299FF',
                ],
                // areaStyle: {normal: {}},
                data: [93, 92, 99],

            },
        ],

    };

    //
    // tree chart 

    const yAxisValues = ['1.7', '2.6', '3.5', '4.5', '6'];
    const treeChartData = {
        legend: {

            left: 'center',
            top: '30px',
            show: true,
            data: ['TB 3.0', 'TB 2.0', 'TB 1.0'],
            itemHeight: 10,
            itemWidth: 10

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            top: '80px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['2019', '2020', '2021', '2022', '2023'],
            boundaryGap: true,
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#F0F0F0'
                }
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} Mil',
            },
            //if values is in millions
            //   axisLabel: {
            //     formatter: (value) => `${value / 1000000}M`,
            //   },
        },

        series: [
            {
                type: 'custom',
                renderItem: (params, api) => {
                    const categoryIndex = api.value(0);

                    const imageWidth = 80;
                    const imageHeight = api.value(1) * 39; // Adjust the height based on the data value
                    const x = api.coord([categoryIndex, 0])[0] - imageWidth / 2;
                    const y = api.coord([categoryIndex, 0])[1] - imageHeight; // Adjust the y position based on the image height
                    const imageStyle = {
                        image: tree_img,
                        x: x,
                        y: y,
                        width: imageWidth,
                        height: imageHeight,
                    };
                    return {
                        type: 'image',
                        style: imageStyle,
                    };
                },
                encode: {
                    x: 0,
                },
                data: [
                    [0, 1.7], // Use indices to represent each category and provide the height value
                    [1, 2.6],
                    [2, 3.5],
                    [3, 4.5],
                    [4, 6],
                ],
                markPoint: {
                    symbol: 'square',
                    symbolSize: [80, 36],
                    itemStyle: {
                        color: '#006256',
                        padding: [8, 8, 8, 8], // Add padding for the mark point
                        backgroundColor: '#006256', // Set a background color for the mark point
                        textShadowBlur: 0, // Remove text shadow for the mark point label
                    },
                    label: {
                        formatter: (params) => {
                            //  const dataIndex = params.dataIndex;
                            const value = params.value;
                            // const customValue = yAxisValues[dataIndex];
                            return `${value} Million\n Trees Saved`;
                        },
                        textStyle: {
                            textShadowColor: 'transparent',
                        },
                    },
                    data: [
                        { type: 'max', name: 'Max Value', symbolOffset: [0, '-70%'] },
                        { type: 'min', name: 'Min Value', symbolOffset: [20, '-54%'] },
                    ],
                },

            },
        ],

        // tooltip: {
        //     trigger: 'axis', // Display tooltip when hovering over the axis
        //   },
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                const dataIndex = params[0].dataIndex;
                //  const value = params[0].value;
                const tooltipContent = `${yAxisValues[dataIndex]} Million<br>Trees Saved `;
                return tooltipContent;
            },
        },


    };

    //
    // 
    const ecGWHColData = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: '20px',
            top: '4px',
            show: true,
            data: ['Forecast', 'Actual'],
            fill: 'orange',
            itemHeight: 10,
            itemWidth: 10
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '80px',
            right: '0px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Forecast',
                type: 'bar',
                stack: 'Forecast',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#C3EBFF',
                ],

                data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,]

            },
            {
                name: 'Actual',
                type: 'bar',
                stack: 'Actual',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#19AAF8',
                ],
                data: [9.8, 9.8, 10, 9.8, 9.8, 10, 9.8, 10, 9.8, 10, 9.8, 10]
            },
            
        ],
        // graphic: [
        //     {
        //         type: 'group',
        //         left: '76%',
        //         top: '0%',
        //         children: [
        //             {
        //                 type: 'rect',
        //                 z: 100,
        //                 left: 'center',
        //                 top: 'middle',
        //                 shape: {
        //                     width: 100,
        //                     height: 50,
        //                 },
        //                 style: {
        //                     fill: '#19AAF8',
        //                     stroke: '#19AAF8',
        //                     lineWidth: 1,
        //                 },

        //             },
        //             //left horizantle line
        //             {
        //                 type: 'rect',
        //                 z: 101,
        //                 left: '-28px',
        //                 bottom: "-40px",
        //                 shape: {
        //                     width: 1,
        //                     height: 15,
        //                 },
        //                 style: {
        //                     fill: '#333333',
        //                 },
        //             },
        //             //right horizantle line
        //             {
        //                 type: 'rect',
        //                 z: 101,
        //                 left: '28px',
        //                 bottom: "-39px",
        //                 shape: {
        //                     width: 1,
        //                     height: 13,
        //                 },
        //                 style: {
        //                     fill: '#333333',
        //                 },
        //             },
        //             //pointer arrow
        //             {
        //                 type: 'polygon',
        //                 z: 101,
        //                 left: '24px',
        //                 bottom: -40,
        //                 shape: {
        //                     points: [[-4, 0], [4, 0], [0, 4]],
        //                 },
        //                 style: {
        //                     fill: '#333333',
        //                     stroke: '#333333',
        //                     lineWidth: 1,
        //                 },
        //             },

        //             {
        //                 type: 'text',
        //                 z: 101,
        //                 style: {
        //                     text: '+3.60\n(+45%)',
        //                     fill: '#fff',
        //                     fontSize: 14,
        //                     fontWeight: '400',
        //                     fontFamily: 'Roboto, sans-serif',
        //                     left: '0%', // Adjust the horizontal position
        //                     top: '0%', // Adjust the vertical position
        //                     textAlign: 'center',
        //                     textVerticalAlign: 'middle',
        //                 },

        //             },
        //         ],
        //     },
        // ],
    };


    return (
        <div className="exdb_summary_main">
            <ExecutiveDashboardHeader pageTitle='Executive Dashboard' exdb={is_main} />
            <div className='contentWrap'>
                <div className="exe_summary_box">
                    <div className="exe_summary_header">
                        <SummaryRadiosTabs activeIndex={activeIndex} />
                    </div>
                    <div className='exe_smry_data_header d-flex align-items-center justify-content-between pb-3'>
                        <div>
                            <button className='exe_editData_btn'>
                                Edit Data
                            </button></div>
                        <div> <YtdToFromBox /></div>
                    </div>


                    <div className="saving_reduction_heading">
                        <ul>
                            <li>
                                <i className="ep_opex_icon"> <img src={icon1} alt="#"></img></i>
                                <div className="reduction_heading">
                                    <h2>OPEX Reduction </h2>
                                    <p>Upto 60% Annually</p>
                                </div>
                            </li>
                            <li>
                                <i className="ep_opex_icon"><img src={icon3} alt="#"></img></i>
                                <div className="reduction_heading">
                                    <h2>Carbon Reduction</h2>
                                    <p>1.6K tCO2 Annually Per 100 Sites</p>
                                </div>
                            </li>
                            <li>
                                <i className="ep_opex_icon"> <img src={icon2} alt="#"></img></i>
                                <div className="reduction_heading">
                                    <h2>Diesel Avoidance </h2>
                                    <p>0.6Mn Liters Annually Per 100 Sites</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className='exe_saving_charts_row'>
                        <div className='exe_saving_cell exe_saving_col1'>
                            <div className='exe_smry_consumption_chart'>
                                <ReactECharts option={thunderSavingMpkrData} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
                            </div>
                        </div>
                        <div className='exe_saving_cell exe_saving_col2'>
                            <div className='exe_smry_consumption_chart'>
                                <ReactECharts option={averageTchAgData} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
                            </div>
                        </div>
                        <div className='exe_saving_cell exe_saving_col3'>
                            <div className='exe_smry_consumption_chart'>
                                <ReactECharts option={treeChartData} opts={{ renderer: 'svg' }} style={{ height: 350 }} />
                            </div>
                        </div>
                    </div>

                    <div className='exe_ecp_chart_parent pt-4'>
                        <ReactECharts option={ecGWHColData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                    </div>


                </div>
            </div>
        </div>
    )
}


export default Savings;