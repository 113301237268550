
import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SiteHeaderDropdown from "../SiteHeaderDropdown";
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import { insightsMenu } from "./InsightsMenu";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
function SitesInsightsGenset() {
    const pageNo = 3;
    const breadcrumbs = [
        { label: 'Sites', link: '' },
        { label: 'Insights', link: '/SitesInsights' }, 
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SitesInsightsGenset',
        otherUrl: '/SingleSiteSummary',
         
    }
    return (
        <>
           <div className="sitePerformance_wrapper">
           <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb='false' isSummaryButton isSiteDropdown={<SiteHeaderDropdown parentPath='/SitesInsights' />} />
           <div className="pb-2">
                <ApplyFilterHeader />
            </div>

            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'>
                <AmTabsHeader pageNo={pageNo} moremenu={insightsMenu} />    
                </div>
                
            </div>
            <div className="sitesInsights_solar contentWrap">
                 Insights genset content goes here...
            </div>
            </div>
        </>
    );
}

export default SitesInsightsGenset;