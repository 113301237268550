import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import InfrastructureWideLineChart from "../../../../Components/Charts/InfrastructureWideLineChart";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import RegionWiseColumnChart from "../../../../Components/Charts/RegionWiseColumnChart";
import ReliabilityGradingRegionWiseHeatMap from "../../../../Components/Charts/ReliabilityGradingRegionWiseHeatMap";
import SpHeader from "../../../../Components/mainHeader/SpHeader";
import TeamsViewHeaderDropdown from "../../TeamsViewHeaderDropdown";

function GensetRunningHours() {
    const pageNo = 9;
    const lineoption = {
        title: {
            text: 'Infrastructure Wide (Hours/Day)',
            textStyle: {
                fontSize: 18,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0px',
            top: '4px',
            show: true,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: '2022',
                type: 'line',
                color: [
                    '#D6E7DB',
                ],
                stack: 'Actual',
                smooth: true,
                // areaStyle: {normal: {}},
                data: [13, 12, 12, 12.2, 11, 11.5, 13, 13, 13, 13, 13, 13]
            },
            {
                name: '2023',
                type: 'line',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#32864B',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 14, 13, 13, 13.5, 0, 0, 0, 0, 0, 0]
            },

        ]
    };


    // 
    const regionWiseColData = {
        title: {
            text: 'Region Wise (Hours/Day)',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: '(Hours/Day)',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                color: [
                    '#32864B',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13]
            },

        ]
    };

    // 
    const gensetMaxStretchData = {
        title: {
            text: 'Genset Max Stretch (Sites)',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                axisLabel: {
                    rotate: 60
                },
                type: 'category',
                boundaryGap: true,
                data: ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8', '8-9', '9-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-24'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: '(Hours/Day)',
                type: 'bar',
                stack: 'YTD',
                // smooth: true,
                color: [
                    '#32864B',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13, 14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13, 4, 14, 14, 13]
            },

        ]
    };

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Genset Running Hours', link: '/GensetRunningHours' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/GensetRunningHours',
        otherUrl: '/TeamsViewSummary',
    }

    return (
        <div className="wrapper">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/GridUnavailability' />} />

            <ApplyFilterHeader />
            <div className='pt-1'>
                <TopTabsHeaders pageNo={pageNo} />
            </div>
            <OnOffGridSitesHeader />


            <div className="contentWrap">
                <div className="chart_containter">
                    <div className="row pb-3">
                        <div className="col-5">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <InfrastructureWideLineChart options={lineoption} />
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <RegionWiseColumnChart options={regionWiseColData} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">

                        <div className="col-6">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <RegionWiseColumnChart options={gensetMaxStretchData} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <ReliabilityGradingRegionWiseHeatMap />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GensetRunningHours; 