import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import styles from '../pages/SitePerformance/SitePerformance.module.css';
import Datepicker from "react-tailwindcss-datepicker";
import ReactECharts from 'echarts-for-react';
import SssConsumption from "./SssConsumption";


function SssSiteLoadAndConsumption() {
    const [activeButton, setActiveButton] = useState('');



    const [value, setValue] = useState({
        startDate: null,
        endDate: null
    });
    const [value2, setValue2] = useState({
        startDate: null,
        endDate: null
    });

    const handleValueChange = (newValue) => {
        // console.log("newValue:", newValue);
        setValue(newValue);
    }
    const handleValueChange2 = (newValue) => {
        // console.log("newValue:", newValue);
        setValue2(newValue);
    }

    const currentLoadChartData = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            top: '-50px',
            left: '0',
            show: false,
            data: ['Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                // data: chartData.categories,

                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        color: ['#01A340', '#01A340', '#D9D9D9', '#D9D9D9'],
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['65%', '100%'],
                center: ['50%', '60%'],
                // adjust the start angle
                startAngle: 180,
                label: {
                    show: false,
                },
                itemStyle: {
                    borderRadius: 6,
                    borderColor: '#fff',
                    borderWidth: 8
                },
                data: [
                    { value: 100, name: 'Search Engine' },
                    { value: 100, name: 'Direct' },
                    { value: 100, name: 'Email' },
                    { value: 100, name: 'Union Ads' },

                    {
                        value: 100 + 100 + 100 + 100,
                        itemStyle: {
                            color: 'none',
                            decal: {
                                symbol: 'none'
                            }
                        },
                        label: {
                            show: false
                        }
                    }
                ]
            }
        ]
    };


    //
    // 
    const slc_mainBatteryDG_data = {
        title: {
            text: 'kWh',
            show: true,
            left: '3',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 12,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {

            left: 'center',
            top: '0px',
            show: true,
            data: ['Mains', 'Battery', 'DG'],
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Target', 'Actual'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Mains',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                // label: {
                //     show: false,
                //     color: "#000",

                //     emphasis: {
                //         label: {
                //             color: "#000",
                //         },
                //     },
                // },
                color: [
                    '#B798F5',
                ],

                data: [97, 94]

            },
            {
                name: 'Battery',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                // label: {
                //     show: false,
                //     color: "#000",

                //     emphasis: {
                //         label: {
                //             color: "#000",
                //         },
                //     },
                // },
                color: [
                    '#86B0FF',
                ],

                data: [98, 96]

            },
            {
                name: 'DG',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                // label: {
                //     show: false,
                //     color: "#000",

                //     emphasis: {
                //         label: {
                //             color: "#000",
                //         },
                //     },
                // },
                color: [
                    '#FEDE81',
                ],
                data: [98, 96]
            },

        ],

    };

    //
    // 
    const energySrcUsageData = {
        title: {
            text: 'kWh',
            show: false,
            left: '3',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 12,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {

            left: 'center',
            top: '0px',
            show: true,
            data: ['Mains', 'Battery', 'DG', 'Solar'],
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },

        },
        grid: {
            left: '10px',
            top: '50px',
            right: '20px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'value',
                boundaryGap: '0%',
                // data: [0, 5, 10, 15, 20, 24],
                axisLabel: {
                    formatter: function (value) {
                        // Format the labels here
                        return value + ' Hrs';
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'category',
                data: ['Actual', 'Target'],
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Mains',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                // label: {
                //     show: false,
                //     color: "#000",

                //     emphasis: {
                //         label: {
                //             color: "#000",
                //         },
                //     },
                // },
                color: [
                    '#B798F5',
                ],

                data: [4, 2.5]

            },
            {
                name: 'Battery',
                type: 'bar',
                stack: 'YTD',
                smooth: true,

                color: [
                    '#86B0FF',
                ],

                data: [8, 5]

            },
            {
                name: 'DG',
                type: 'bar',
                stack: 'YTD',
                smooth: true,

                color: [
                    '#FEDE81',
                ],
                data: [5, 16.5]
            },
            {
                name: 'DG',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                // label: {
                //     show: false,
                //     color: "#000",

                //     emphasis: {
                //         label: {
                //             color: "#000",
                //         },
                //     },
                // },
                color: [
                    '#FEDE81',
                ],
                data: [2, 0]
            },
            {
                name: 'Solar',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                // label: {
                //     show: false,
                //     color: "#000",

                //     emphasis: {
                //         label: {
                //             color: "#000",
                //         },
                //     },
                // },
                color: [
                    '#86C493',
                ],
                data: [1, 0]
            },

        ],

    };


    //
    // 
    const showTopChart_data = {
        title: {
            text: 'kva',
            show: true,
            left: '3',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 12,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {

            left: 'center',
            top: '0px',
            show: true,
            // data: ['Mains', 'Battery', 'DG'],
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: [
                    '00:00',
                    '01:00',
                    '02:00',
                    '03:00',
                    '04:00',
                    '05:00',
                    '06:00',
                    '07:00',
                    '08:00',
                    '09:00',
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                    '18:00',
                    '19:00',
                    '20:00',
                    '21:00',
                    '22:00',
                    '23:00',
                    '24:00',


                ],
                // splitLine: {
                //     show: false,
                //     lineStyle: {
                //         color: '#F0F0F0'
                //     }
                // },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                axisLabel: {
                    formatter: '{value} A'
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        visualMap: {
            show: false,
            dimension: 0,
            pieces: [
                {
                    lte: 6,
                    color: 'green'
                },
                {
                    gt: 6,
                    lte: 8,
                    color: 'red'
                },
                {
                    gt: 8,
                    lte: 17,
                    color: 'green'
                },
                {
                    gt: 17,
                    lte: 21,
                    color: 'red'
                },
                {
                    gt: 21,
                    color: 'green'
                }
            ]
        },
        series: [
            {
                name: 'Electricity',
                type: 'line',
                smooth: true,
                // prettier-ignore
                data: [300, 280, 250, 260, 270, 300, 550, 500, 400, 390, 380, 390, 400, 500, 600, 750, 800, 700, 600, 400],
                markArea: {
                    itemStyle: {
                        color: 'rgba(245, 88, 54, 0.2)'
                    },
                    data: [
                        [
                            {
                                name: '',
                                xAxis: '06:00'
                            },
                            {
                                xAxis: '08:00'
                            }
                        ],
                        [
                            {
                                name: '',
                                xAxis: '17:00'
                            },
                            {
                                xAxis: '21:00'
                            }
                        ]
                    ]
                },
                markLine: {
                    //symbol: 'none', // No symbol at the start and end of the line
                    data: [
                        {
                            type: 'average',
                            name: 'Average', // Name for the average line
                        },
                    ],
                    lineStyle: {
                        color: 'rgba(0, 89, 255, 1)', // Color of the average line
                    },
                },
            }
        ]
    };


    // user reference to scroll into view after show more click
    const divRefs = {
        show_consumption: useRef(null),
        show_energySrc: useRef(null),
    };
    // this is show more handle click
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        const targetRef = divRefs[buttonName];
        console.log(targetRef)
        setTimeout(() => {
            if (targetRef && targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 400);
    };


    const energySourcesUsageShowData = {
        title: {
            text: 'kWh',
            show: true,
            left: '3',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 12,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {

            left: 'center',
            top: '0px',
            show: true,
            data: ['Mains', 'Solar', 'Genset', 'Battery', 'Main->Site', 'Main->Batt', 'Solar->Site', 'Solar->Batt', 'DG->Site', 'DG->Batt'],
            itemHeight: 10,
            itemWidth: 10,
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            top: '50px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['Power Source'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                // show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Mains',
                type: 'bar',
                stack: 'Mains',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#B798F5',
                ],
                data: [2500.43]
            },
            {
                name: 'Solar',
                type: 'bar',
                stack: 'Mains',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#FEDE81',
                ],
                data: [0]
            },
            {
                name: 'Genset',
                type: 'bar',
                stack: 'Mains',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#86C493',
                ],
                data: [0]
            },
            {
                name: 'Battery',
                type: 'bar',
                stack: 'Mains',
                smooth: true,
                label: {
                    show: false,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#86B0FF',
                ],

                data: [105]

            },

            {
                name: 'Main->Site',
                type: 'bar',
                stack: 'Inside',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#B798F5',
                ],
                data: [2386]
            },
            {
                name: 'Main->Batt',
                type: 'bar',
                stack: 'Inside',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#F2E4A6',
                ],
                data: [125]
            },
            {
                name: 'Solar->Site',
                type: 'bar',
                stack: 'Inside',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#FEDE81',
                ],
                data: [0]
            },
            {
                name: 'Solar->Batt',
                type: 'bar',
                stack: 'Inside',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#F1B400',
                ],
                data: [0]
            },
            {
                name: 'DG->Site',
                type: 'bar',
                stack: 'Inside',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#86C493',
                ],
                data: [0]
            },
            {
                name: 'DG->Batt',
                type: 'bar',
                stack: 'Inside',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#32864B',
                ],
                data: [0]
            },

        ],

    };




    return (
        <>
            <div className="SssSiteLoadAndCon_sec">
                <div className="row">
                    <div className={`col-lg-6 ${activeButton === 'show_consumption' ? '' : 'pb-4'}`}>

                        <div className="box_card has_info_icon h-100">
                            <div className="box_info_flexible">
                                <h3 className='chartTitleHeading  pb-0 pt-0'>
                                    Site Load and Consumption
                                </h3>
                                <div className="ms-auto pe-3">
                                    <div className="t_picker">
                                        <Datepicker
                                            inputClassName='t_datepicker'
                                            toggleClassName="d-none"
                                            // containerClassName="relative" 
                                            value={value2}
                                            placeholder={"Select date range"}
                                            onChange={handleValueChange2}
                                            showShortcuts={true}
                                            useRange={true}
                                            asSingle={false}
                                            separator={"-"}
                                        />
                                    </div>
                                </div>
                                <div className='box_info'>
                                    <i className='box_info_icon'></i>
                                </div>
                            </div>
                            <div className="sts_slcLoad_data">

                                <div className="row pb-3">
                                    <div className="col-lg-5 sts_slc_chartBox">
                                        <ReactECharts option={currentLoadChartData} opts={{ renderer: 'svg' }} style={{ height: 180 }} />
                                        <div className={styles.sts_slc_chrtData}>
                                            <h3>4 <sub>kW</sub></h3>
                                            <p>Current Load  <strong>79A</strong></p>
                                            <p>Demand Load <strong>14 kW</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <ReactECharts option={slc_mainBatteryDG_data} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
                                    </div>
                                </div>
                                {
                                    activeButton === 'show_consumption' ? '' :

                                        <div className="d-flex justify-content-between pt-2">
                                            <p className={styles.slc_updatedDate}>Last Updated: October 05,2020</p>
                                            <div className="d-flex justify-content-end  pt-2">
                                                <Link onClick={() => handleButtonClick('show_consumption')} className={styles.moreBtn}>View more <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                    <path d="M12.6654 8.01497H3.33203" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.33203 11.349L12.6654 8.01562" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.33203 4.68164L12.6654 8.01497" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg></i></Link>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`col-lg-6 ${activeButton === 'show_energySrc' ? '' : 'pb-4'}`}>
                        <div className="box_card has_info_icon h-100">
                            <div className="box_info_flexible">
                                <h3 className='chartTitleHeading  pb-0 pt-0'>
                                    Energy Sources Usage (Hrs)
                                </h3>
                                <div className="ms-auto pe-3">
                                    <div className="t_picker">
                                        <Datepicker
                                            inputClassName='t_datepicker'
                                            toggleClassName="d-none"
                                            // containerClassName="relative" 
                                            value={value}
                                            placeholder={"Select date range"}
                                            onChange={handleValueChange}
                                            showShortcuts={true}
                                            useRange={true}
                                            asSingle={false}
                                            separator={"-"}
                                        />
                                    </div>
                                </div>
                                <div className='box_info'>
                                    <i className='box_info_icon'></i>
                                </div>
                            </div>
                            <div className="sts_slcLoad_data">
                                <div className="row pb-3">
                                    <div className="col-lg-12">
                                        <ReactECharts option={energySrcUsageData} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
                                    </div>
                                </div>

                                {
                                    activeButton === 'show_energySrc' ? '' : <div className="d-flex justify-content-between pt-2">
                                        <p className={styles.slc_updatedDate}>Last Updated: October 05,2020</p>
                                        <div className="d-flex justify-content-end  pt-2">
                                            <Link onClick={() => handleButtonClick('show_energySrc')} className={styles.moreBtn}>View more <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                                <path d="M12.6654 8.01497H3.33203" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.33203 11.349L12.6654 8.01562" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.33203 4.68164L12.6654 8.01497" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg></i></Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* more data show1 */}
            {
                activeButton === 'show_consumption' ?
                    <div ref={divRefs.show_consumption} className="slc_moreShow_content">
                        <div className="box_card mb-4 has_info_icon">
                            <div className="box_info_flexible">
                                <h3 className='chartTitleHeading  pb-0 pt-0'>
                                    Site Load
                                </h3>
                                <div className="ms-auto pe-3">
                                    <div className="t_picker">
                                        <Datepicker
                                            inputClassName='t_datepicker'
                                            toggleClassName="d-none"
                                            // containerClassName="relative" 
                                            value={value2}
                                            placeholder={"Select date range"}
                                            onChange={handleValueChange2}
                                            showShortcuts={true}
                                            useRange={true}
                                            asSingle={false}
                                            separator={"-"}
                                        />
                                    </div>
                                </div>
                                <div className={styles.viewCloseBox}>
                                    <div onClick={() => handleButtonClick('')} className={styles.closeBoxBtn}></div>
                                </div>
                            </div>
                            <div className="sts_slcLoad_data">
                                <div className="row pb-4">
                                    <div className="col-lg-12">
                                        <ReactECharts option={showTopChart_data} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
                                    </div>
                                </div>

                                <div className="row pt-4 pb-3">
                                    <div className="col-lg-12">
                                        <SssConsumption />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
            }

            {/* Energy Sources Usage (Hrs) show data */}
            {
                activeButton === 'show_energySrc' ?
                    <div ref={divRefs.show_energySrc} className="slc_moreShow_content">
                        <div className="box_card mb-4 has_info_icon">
                            <div className="box_info_flexible">
                                <h3 className='chartTitleHeading  pb-0 pt-0'>  Energy Sources Usage </h3>
                                <div className="ms-auto pe-3">
                                    <div className="t_picker">
                                        <Datepicker
                                            inputClassName='t_datepicker'
                                            toggleClassName="d-none"
                                            // containerClassName="relative" 
                                            value={value2}
                                            placeholder={"Select date range"}
                                            onChange={handleValueChange2}
                                            showShortcuts={true}
                                            useRange={true}
                                            asSingle={false}
                                            separator={"-"}
                                        />
                                    </div>
                                </div>
                                <div className={styles.viewCloseBox}>
                                    <div onClick={() => handleButtonClick('')} className={styles.closeBoxBtn}></div>
                                </div>
                            </div>
                            <div className="sts_slcLoad_data">
                                <div className="row pb-4">
                                    <div className="col-lg-12">
                                        <ReactECharts option={energySourcesUsageShowData} opts={{ renderer: 'svg' }} style={{ height: 250 }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    ''
            }

        </>
    )
}

export default SssSiteLoadAndConsumption;