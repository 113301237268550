import React from "react";
import "./ProductionSummaryList.css";
function ProductionSummaryList(props) {
    return (
        <div className="productionSummaryList">
            <h3 className="chartTitleHeading">{props.title}</h3>
            <ul>
                {
                    props.data.map((item) => {
                        return (
                            <li key={item.id}>
                                <p>{item.title}</p>
                                <h3><span>{item.value}</span> <sub>{item.type}</sub></h3>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}
export default ProductionSummaryList;
