import React from "react";
import OnOffGridSitesHeader from "../../../../Components/onOffGridSitesHeader/OnOffGridSitesHeader";
import ApplyFilterHeader from "../../../../Components/ApplyFilterHeader";
import TopTabsHeaders from "../../../../Components/TopTabsHeader/TopTabsHeader";
import SimpleMapPage from "../../../../Components/Map/MapChart";
import TotalAverageRadio from "../../../../Components/TotalAverageRadio/TotalAverageRadio";
import RegionWiseColumnChart from "../../../../Components/Charts/RegionWiseColumnChart";
import ReliabilityGradingChartPie from "../../../../Components/Charts/ReliabilityGradingChartPie";
import FaultySitesFaultRatecol from "../../../../Components/Charts/FaultySitesFaultRatecol";
import WarrantyClaimsPie from "../../../../Components/Charts/WarrantyClaimsPie";
import SpHeader from "../../../../Components/mainHeader/SpHeader";

function BatteryFaultySites() {
    const pageNo = 13;

      // 
   const backupTimeData = {
    title: { 
       text: 'Backup Time',
       textStyle: {
          fontSize: 16,
          fontWeight: '500',
          fontFamily: 'Roboto, sans-serif',
       },
    },
    tooltip: {
       trigger: 'axis'
    },
    legend: {
       right: '0',
       top: '4px',
       show: false,
       data: ['2022', '2023'],
       fill: 'orange',
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
       left: '0px',
       right: '10px',
       bottom: '3%',
       containLabel: true
    },
    xAxis: [
       {
          type: 'category',
          boundaryGap: true,
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          splitLine: {
             show: true,
             lineStyle: {
                color: '#F0F0F0'
             }
          },
       }
    ],
    yAxis: [
       {
          type: 'value',
          splitLine: {
             show: true,
             lineStyle: {
                color: '#F0F0F0'
             }
          },
       }
    ],
    series: [
       {
          name: 'Backup Time',
          type: 'bar',
          stack: 'Backup Time',
          smooth: true,
          color: [
             '#0058FF',
          ],
          // areaStyle: {normal: {}},
          data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13,4, 14]
       },

    ]
 };
    // 
    const backupTimeRegionWiseData = {
        title: {
           text: 'Backup Time Region Wise',
           textStyle: {
              fontSize: 16,
              fontWeight: '500',
              fontFamily: 'Roboto, sans-serif',
           },
        },
        tooltip: {
           trigger: 'axis'
        },
        legend: {
           right: '0',
           top: '4px',
           show: false,
           data: ['2022', '2023'],
           fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
           left: '0px',
           right: '10px',
           bottom: '3%',
           containLabel: true
        },
        xAxis: [
           {
              type: 'category',
              boundaryGap: true,
              data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
              splitLine: {
                 show: true,
                 lineStyle: {
                    color: '#F0F0F0'
                 }
              },
           }
        ],
        yAxis: [
           {
              type: 'value',
              splitLine: {
                 show: true,
                 lineStyle: {
                    color: '#F0F0F0'
                 }
              },
           }
        ],
        series: [
           {
              name: 'Region Wise',
              type: 'bar',
              stack: 'Backup Time',
              smooth: true,
              color: [
                 '#0058FF',
              ],
              // areaStyle: {normal: {}},
              data: [14, 14, 18, 13, 13, 13.5, 4, 14, 14, 13]
           },
    
        ]
     };

     //

        // start  Genset Run Hours
 // start  Genset Run Hours
 const piechartOptions = {
    title: {
        text: 'Reliability Grading',
        textStyle: {
            fontSize: 16,
            fontWeight: '500',
            fontFamily: 'Roboto, sans-serif',
        },
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        bottom: 10,
        left: 'center',
        //   data: ['Good 0 - 8 Hours', 'CityB', 'CityD', 'CityC', 'CityE'],
        type: 'scroll',
        orient: 'horizontal',
        itemWidth: 8,
        selectedMode: true,
        icon: "circle"
    },

    grid: {
        left: '30px',
        right: '10px',
        bottom: '0%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: true,
            //data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
            show: false,
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#F0F0F0'
                }
            },
        }
    ],
    yAxis: [
        {
            type: 'value',
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#F0F0F0'
                }
            },
        }
    ],

    series: [
        {
            type: 'pie',
            radius: '60%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            color: [
                '#99B3AB',
                '#41BA70',
                '#FAA640',
                '#5967FC',
                '#F56867',
            ],
            data: [
                {
                    value: 9,
                    name: '0 - 2 Hours',
                    label: {
                        formatter: ['9'].join('\n'),
                    }
                },
                {
                    value: 13,
                    name: '2 - 5 Hours',
                    label: {
                        formatter: ['13'].join('\n'),
                    }
                },
                {
                    value: 15,
                    name: '5 - 8 Hours',
                    label: {
                        formatter: ['15'].join('\n'),
                    }
                },
                {
                    value: 73,
                    name: '8 - 10 Hours',
                    label: {
                        formatter: ['73'].join('\n'),
                    }
                },
                {
                    value: 227,
                    name: '10+ Hours',
                    label: {
                        formatter: ['227'].join('\n'),
                    }
                },

            ],
            emphasis: {

                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};
    //  

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Battery Faulty Sites', link: '/BatteryFaultySites' },
    ];


    return (
        <div className="wrapper">
           <SpHeader breadcrumbs={breadcrumbs} />
            <TopTabsHeaders pageNo={pageNo} />
            <ApplyFilterHeader />
            <OnOffGridSitesHeader />
            <div className="totalAverage_header">
                <div className="contentWrap">
                    <div className="totalAverage_header_row">
                        <TotalAverageRadio />
                    </div>
                </div>
            </div>
            <div className="contentWrap">
                <div className="chart_containter pt-0">
                    <div className="row">
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                   <RegionWiseColumnChart options={backupTimeData} />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                     <RegionWiseColumnChart options={backupTimeRegionWiseData} />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2nd row */}
                    <div className="row">
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                <ReliabilityGradingChartPie options={piechartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner">
                                    <FaultySitesFaultRatecol />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3 col-12 col-lg-4">
                            <div className="box_card">
                                <div className="box_card_inner infrastructureChartBox">
                                    <WarrantyClaimsPie />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default BatteryFaultySites; 