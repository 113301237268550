
import React from 'react';
// import "../../AssetManagement/AssetManagement.css";

import "./TnpNetworkInsights.css"
import SpHeader from '../../../Components/mainHeader/SpHeader';
import TeamsViewHeaderDropdown from '../TeamsViewHeaderDropdown';
import ApplyFilterHeader from '../../../Components/ApplyFilterHeader';
import TnpTabsHeader from '../../../Components/TopTabsHeader/TnpTabsHeader';

import ReactECharts from 'echarts-for-react';
import { moremenu } from './MoreMenuLinks';
import SolarRCASummaryHeatMap from '../../../Components/Charts/SolarRCASummaryHeatMap';
import { Link } from 'react-router-dom';



function TnpSolarRCA() {

    const pageNo = 3;

    const breadcrumbs = [
        { label: 'Teams View', link: '' },
        { label: 'Network Insights', link: '/TnpNetworkInsights' },
        { label: 'Summary', link: '' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/TnpNetworkInsights',
        otherUrl: '/TeamsViewSummary',
    }


    // chart


    const siteLoadIncrease = {
        title: {
            text: 'Region Wise Breakdown',
            show: false,
            subtext: 'Sites',
            left: '-5px',
            width: "100%",
            color: '#000',
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
            subtextStyle: {
                color: '#000',
                fontSize: 12,
                fontWeight: '500',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '50px',
            show: false,
            data: ['Previous', 'Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 12,
                color: '#000',
                fontWeight: '700'
            },

        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '6px',
            top: '10px',
            right: '6px',
            bottom: '2%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                axisLabel: {
                    textStyle: {
                        fontSize: 11,
                        color: '#000',
                    },
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: true,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Sites',
                type: 'bar',
                smooth: true,
                label: {
                    show: false,
                    position: 'outside',
                    color: "#000",
                    offset: [0, -2],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                showBackground: false,
                backgroundStyle: {
                    color: 'rgba(216, 224, 228, 1)'
                },
                color: '#D00',
                data: [30, 10, 15, 20, 40, 15, 40, 13, 15, 13]
            },
        ],

    };



    return (
        <div className="wrapper tniNetwork_main">
            <SpHeader breadcrumbs={breadcrumbs} exdb='false' summaryObj={summaryObj} isSummaryButton isSiteDropdown={<TeamsViewHeaderDropdown parentPath='/TnpNetworkInsights' />} />
            <div className="tmp_totalSitesFilters">
                <ApplyFilterHeader notYTDBtns notDatepicker />
            </div>

            <div className='tnpNetworkTabs'>
                <div className='stf_header pt-2 pb-1'>
                    <TnpTabsHeader pageNo={pageNo} moremenu={moremenu} />
                    <div className="ampButtonsGroup ps-2">
                        <ul className="ampButtonsBox">
                            <li>
                                <Link to={"/TnpSolarRCA"} className="ampButton active">Data</Link>
                            </li>
                            <li>
                                <Link to={"/TniSolarRCATable"} className="ampButton">Table</Link >
                            </li>
                            <li>
                                <Link to={"/TnpSolarRCAMap"} className="ampButton">Map</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='tniTopBtns_ltrSites pb-2'>
                <div className='contentWrap'>
                    <div className="ampButtonsGroup ps-2">
                        <ul className="ampButtonsBox">
                            <li>
                                <span className="ampButton active">Ltrs</span>
                            </li>
                            <li>
                                <span className="ampButton">Sites</span>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div className='tnpGridRCA'>
                <div className='contentWrap'>
                    <div className='row'>
                        <div className='col-12 col-lg-4'>
                            <div className='box_card p-0'>
                                <div className='tnpNetIn_summary'>
                                    <h4 className='label20 color_white bg_yellow tnpNetIn_heading'>
                                        Solar RCA Summary
                                    </h4>
                                    <div className='tnpNetIn_inner'>
                                        <div className='tnpNetIn_inner_heading'>
                                            <span className='label20 weight5'>Total Sites</span>
                                            <h2>12056</h2>
                                        </div>
                                        <div className='tnpNetIn_content'>
                                            <div className='tnpNetIn_infraLevel pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-4">
                                                    <span className="px-2 bg_white">Infrastructure Level</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Good Sites</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Bad Sites</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Data Anomaly</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_ctgDist pb-4'>
                                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-4">
                                                    <span className="px-2 bg_white">Category Distribution</span>
                                                </h4>

                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="label30 weight5 color_green4">10000</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="label30 weight5 color_red2">2556</h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field</small>
                                                        <h2 className="label30 weight5 color_dark_black">556</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='tnpNetIn_uses'>
                                                <div className="tnpNetIn_uses_heading">
                                                    <small className="label22 weight5">Potential Loss</small>
                                                    <h2>
                                                        <span>130</span>
                                                        <strong>kWh</strong>
                                                    </h2>
                                                </div>
                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">External</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">15</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Infra</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">40</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <small className="label18 weight5">Field</small>
                                                        <h2 className="text-center">
                                                            <span className="weight9 label38 color_red2 pe-2">35</span>
                                                            <strong className="label20 weight9">kWh</strong>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='tnpNetIn_chart'>
                                            <div className='tnpNetIn_chart_heading'>
                                                <h2>Region Wise Breakdown</h2>
                                                <div className="ampButtonsGroup ps-2">
                                                    <ul className="ampButtonsBox">
                                                        <li>
                                                            <span className="ampButton active">Infrastructure</span>
                                                        </li>
                                                        <li>
                                                            <span className="ampButton">Category</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <SolarRCASummaryHeatMap />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-lg-8'>
                            <div className='row pb-3'>
                                <div className='col-12 col-lg-12'>
                                    <div className='box_card p-0'>
                                        <div className='tnpGRInfra_Issues'>
                                            <h4 className='label20 color_white bg_yellow tnpNetIn_heading'>
                                                Infrastructure Issues
                                            </h4>
                                            <div className='tnpGRInfra_Issues_content'>
                                                <ul>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Excessive Battery Usage</small>
                                                                <h2>
                                                                    <span>300</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Excessive Grid Usage </small>
                                                                <h2>
                                                                    <span>120</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Excessive DG Run </small>
                                                                <h2>
                                                                    <span>30</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12 col-lg-12'>
                                    <div className='box_card p-0'>
                                        <div className='tnpGRInfra_Issues'>
                                            <h4 className='label20 color_white bg_yellow tnpNetIn_heading'>
                                                Field Challenges
                                            </h4>
                                            <div className='tnpGRInfra_Issues_content'>
                                                <ul>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Faulty Solar</small>
                                                                <h2>
                                                                    <span>300</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Solar Missing</small>
                                                                <h2>
                                                                    <span>120</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Partial Panels Connected</small>
                                                                <h2>
                                                                    <span>30</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Shadows</small>
                                                                <h2>
                                                                    <span>300</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Dusty Panels</small>
                                                                <h2>
                                                                    <span>120</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Un-accounted For</small>
                                                                <h2>
                                                                    <span>30</span>
                                                                    <strong>kWh</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-3'>
                                <div className='col-12 col-lg-12'>
                                    <div className='box_card p-0'>
                                        <div className='tnpGRInfra_Issues'>
                                            <h4 className='label20 color_white bg_yellow tnpNetIn_heading'>
                                                External Issues
                                            </h4>
                                            <div className='tnpGRInfra_Issues_content'>
                                                <ul>
                                                    <li>
                                                        <div className='tnpGRInfra_Issues_info'>
                                                            <div className="tnpNetIn_uses_heading">
                                                                <small className="label22 weight5">Extended LS</small>
                                                                <h2>
                                                                    <span>300</span>
                                                                    <strong>Ltrs</strong>
                                                                </h2>
                                                            </div>

                                                            <div className='tnpGRInfra_Issues_chart'>
                                                                <ReactECharts option={siteLoadIncrease} opts={{ renderer: 'svg' }} style={{ height: 140 }} />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}
export default TnpSolarRCA;