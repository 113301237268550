import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function SummaryRadiosTabs(props) {
    let activeIndex = props.activeIndex;

    const [array, setArray] = useState([
        { id: 1, name: 'Energy Performance and Consumption', url: "/Summary", active: false },
        { id: 2, name: 'Loadsheddding', url: "/Loadsheddding", active: false },
        { id: 3, name: 'Grid Consumption', url: "/GridConsumption", active: false },
        { id: 4, name: 'Fuel Consumption', url: "/SummaryFuelConsumption", active: false },
        { id: 5, name: 'CO₂ Emissions', url: "/CO2Emissions", active: false },
        { id: 6, name: 'Savings', url: "/Savings", active: false },
    ]);

    useEffect(() => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === activeIndex) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
    }, [activeIndex]);

    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
    };

    return (
        <div className="exe_top_radios_row">
            <ul>
                {array.map((item) => (
                    <li key={item.id}>
                        <Link to={item.url}
                            className={`exe_summary_radio ${item.active ? 'active' : ''}`}
                            onClick={() => handleItemClick(item.id)}
                        >
                            <span>{item.name}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}


export default SummaryRadiosTabs