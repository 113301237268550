
import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SobTabsHeader from "../SobTabsHeader/SobTabsHeader";
import { sob_moremenu } from "../SobTabsHeader/SobMoreMenuLinks";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Link } from "react-router-dom";




function SobSitesInfra() {
    const pageNo = 1;
    const breadcrumbs = [
        { label: 'Site On Boarding', link: '' },
        { label: 'Add Existing Site', link: '/SitesOnboarding' },
        { label: 'AMKM - 03', link: '' },
    ];

    // const summaryObj = {
    //     minimize: 'Minimise',
    //     otherName: "Summary",
    //     minUrl: '/SitePulse',
    //     otherUrl: '/SingleSiteSummary',
    // }



    return (
        <div className="sobSteps_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} />

            <SobTabsHeader pageNo={pageNo} sob_moremenu={sob_moremenu} />

            <div className="sobSteps_content_main">

                <div className="sobGeneralInfo pb-1">
                    <div className="sobSteps_content contentWrap">
                        <div className="row pt-3">
                            <div className="col-12 col-lg-8 box_card">
                                <div className="sobGeneralInfo_inner">
                                    <div className="sob_heading">
                                        <h2> Site Infra</h2>
                                    </div>
                                    <div className="row">
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Priority Group<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Sub Priority<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select label="Select an option">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Commercial + B2B<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>NAR Priority (Sept'22)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>ON-Air Status<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>2G Integration DateGeo Tag<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Dismantle Scope<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Dismantle Status<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>USF Project<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Green Field/Roof Top<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Tower Type<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Site Status Indoor/Outdoor<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Site Ownership Status (updated)Type<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>TP As Guest (OMO)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>OMO 1<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>OMO1 On ground Power<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>TP AS Guest  (ON Ground Power)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>TP Guest MORAN Sharing USF Project<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>On Ground Power To Guest<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Guest Count<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="row ps-1">
                                    <div className="col-12 col-lg-12">
                                        <div className="sobGeneralInfo_inner bg_gray_dark5">
                                            <div className="sob_heading">
                                                <h2>Site Infra</h2>
                                            </div>
                                            <div className="sob_summary_data">
                                                <table className="table st_summary_list mb-2">
                                                    <tbody>
                                                        <tr>
                                                            <th>Priority Group</th>
                                                            <th>GST</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Sub Priority</td>
                                                            <td>Deposit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Commercial + B2B</td>
                                                            <td>Ballon / RV</td>
                                                        </tr>
                                                        <tr>
                                                            <td> NAR Priority (Sept'22)</td>
                                                            <td>Term</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ON-Air Status</td>
                                                            <td>Payment Structure</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2G Integration Date</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dismantle Scope</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dismantle Status</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dismantle Date</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>USF Project</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Green Field /  Roof TOP</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tower Type</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Site Status Indoor / Outdoor</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Site Ownership Status (updated)Type</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Site Ownership Sub Category</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf_next_reset_btn pt-3 contentWrap">
                        <Link to={'/OnboardingSearch'} className="btn btn-secondary me-2">Cancel</Link>
                        <Link to={'/SobGeneralInfo'} className="btn btn-secondary me-2">Back</Link>
                        <Link to={'/SobTechParameters'} className="btn btn-dark">Next</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SobSitesInfra;