import React from "react";

function ExeParTable() {

    const data = [
        {
            id: 1,
            title: 'PAR',
            value: 82,
            valueType: '%'
        },
        {
            id: 2,
            title: 'NAR',
            value: 95.5,
            valueType: '%'
        },
        {
            id: 3,
            title: 'TCH',
            value: 92,
            valueType: '%'
        },
        {
            id: 4,
            title: 'Energy Consumption',
            value: 68,
            valueType: 'GWH'
        },
        {
            id: 5,
            title: 'CO2 Emission',
            value: 20,
            valueType: 'ktCO2'
        },
        {
            id: 6,
            title: 'OPEX (PKR)',
            value: 1996.8,
            valueType: 'Mn'
        }
    ]

    return (
        <div className="ts_ext_table">
            <table>
                <tbody>
                    {
                        data.map((data) => {
                            return (
                                <tr key={data.id}>
                                    <td>
                                        <p>{data.title}</p>
                                    </td>
                                    <td><strong>{data.value}<sub>{data.valueType}</sub></strong></td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    )
}

export default ExeParTable;