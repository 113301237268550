import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';
const LoadDistributionRegionWiseHeatMap = () => {
    const options = {
        chart: {
          type: 'heatmap',
          toolbar: {
            show: false,
          },
          fill: {
            colors: ['#32323E']
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#343434']
          }
        },
        colors: ['#41A63A'],
        xaxis: {
          categories: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3','N3', 'S2', 'S3', 'N2'],
        },
        stroke: {
            width: 0,
          },
      };
      // Define your chart data
      const series = [
      
        {
          name: '5 - 10 kW',
          data: [23, 75, 250, 80, 30, 23,75, 250, 80, 30],
        },
        {
          name: '10 - 250 kW',
          data: [ 234, 250, 280, 120, 50, 234,250, 280, 120, 50],
        },
        {
          name: '250+ kW',
          data: [120, 100, 110, 98, 240, 120,100, 110, 98, 240],
        },
      ];
      
    return (
      <div className='heatmap_out'>
          <h3 className='chartTitleHeading'>Load Distribution Region Wise </h3>
          <ApexCharts options={options} series={series} type="heatmap" height={260} />
      </div>
    );
  };
  
  export default LoadDistributionRegionWiseHeatMap;
  