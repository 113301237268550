
import './App.css';
import TeamViewRoute from './pages/PagesRoutes/PagesRoutes';

function App() {
  return (
    <div className="App">
        <TeamViewRoute/>
    </div>
  );
}

export default App;
