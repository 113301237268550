import { useState } from "react";
import { Link } from "react-router-dom";
import styles from '../../SitePerformance/SitePerformance.module.css';

import icon1 from '../../../assets/images/sts_mains.svg';
import icon2 from '../../../assets/images/sts_outage.svg';
import icon3 from '../../../assets/images/sts_genset.svg';
import icon4 from '../../../assets/images/sts_fuel.svg';
import icon5 from '../../../assets/images/sts_battery.svg';
import icon6 from '../../../assets/images/sts_solar.svg';
import ReportsTempLists from "./ReportsTempLists";



const show_data =
{
    mains: {
        actual: [14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 13, 13],
        color: '#7243D0',
        actualColor: '#B798F5',
        targetlColor: '#326657',
        target: [10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 8, 8],
        categories: ['Jan, 25', 'Jan, 26', 'Jan, 27', 'Jan, 28', 'Jan, 29', 'Jan, 30', 'Jan, 31', 'Feb, 01', 'Feb, 02', 'Feb, 03', 'Feb, 04', 'Feb, 05', 'Feb, 06', 'Feb, 07', 'Feb, 08', 'Feb, 09', 'Feb, 10', 'Feb, 11', 'Feb, 12', 'Feb, 13', 'Feb, 14', 'Feb, 15', 'Feb, 16', 'Feb, 17', 'Feb, 18', 'Feb, 18', 'Feb, 19', 'Feb, 21', 'Feb, 22', 'Feb, 23', 'Feb, 24']
    },
    loadShedding
        : {
        actual: [14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 13, 13],
        color: '#464E5C',
        actualColor: '#BDC4D1',
        targetlColor: '#326657',
        target: [10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 8, 8],
        categories: ['Jan, 25', 'Jan, 26', 'Jan, 27', 'Jan, 28', 'Jan, 29', 'Jan, 30', 'Jan, 31', 'Feb, 01', 'Feb, 02', 'Feb, 03', 'Feb, 04', 'Feb, 05', 'Feb, 06', 'Feb, 07', 'Feb, 08', 'Feb, 09', 'Feb, 10', 'Feb, 11', 'Feb, 12', 'Feb, 13', 'Feb, 14', 'Feb, 15', 'Feb, 16', 'Feb, 17', 'Feb, 18', 'Feb, 18', 'Feb, 19', 'Feb, 21', 'Feb, 22', 'Feb, 23', 'Feb, 24']
    },
    genset
        : {
        actual: [14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 13, 13],
        color: '#32864B',
        actualColor: '#86C493',
        targetlColor: '#326657',
        target: [10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 8, 8],
        categories: ['Jan, 25', 'Jan, 26', 'Jan, 27', 'Jan, 28', 'Jan, 29', 'Jan, 30', 'Jan, 31', 'Feb, 01', 'Feb, 02', 'Feb, 03', 'Feb, 04', 'Feb, 05', 'Feb, 06', 'Feb, 07', 'Feb, 08', 'Feb, 09', 'Feb, 10', 'Feb, 11', 'Feb, 12', 'Feb, 13', 'Feb, 14', 'Feb, 15', 'Feb, 16', 'Feb, 17', 'Feb, 18', 'Feb, 18', 'Feb, 19', 'Feb, 21', 'Feb, 22', 'Feb, 23', 'Feb, 24']
    },
    fuel
        : {
        actual: [14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 13, 13],
        color: '#F55836',
        actualColor: '#FF9882',
        targetlColor: '#326657',
        target: [10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 8, 8],
        categories: ['Jan, 25', 'Jan, 26', 'Jan, 27', 'Jan, 28', 'Jan, 29', 'Jan, 30', 'Jan, 31', 'Feb, 01', 'Feb, 02', 'Feb, 03', 'Feb, 04', 'Feb, 05', 'Feb, 06', 'Feb, 07', 'Feb, 08', 'Feb, 09', 'Feb, 10', 'Feb, 11', 'Feb, 12', 'Feb, 13', 'Feb, 14', 'Feb, 15', 'Feb, 16', 'Feb, 17', 'Feb, 18', 'Feb, 18', 'Feb, 19', 'Feb, 21', 'Feb, 22', 'Feb, 23', 'Feb, 24']
    },
    battery
        : {
        actual: [14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 13, 13],
        color: '#0058FF',
        actualColor: '#86B0FF',
        targetlColor: '#326657',
        target: [10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 8, 8],
        categories: ['Jan, 25', 'Jan, 26', 'Jan, 27', 'Jan, 28', 'Jan, 29', 'Jan, 30', 'Jan, 31', 'Feb, 01', 'Feb, 02', 'Feb, 03', 'Feb, 04', 'Feb, 05', 'Feb, 06', 'Feb, 07', 'Feb, 08', 'Feb, 09', 'Feb, 10', 'Feb, 11', 'Feb, 12', 'Feb, 13', 'Feb, 14', 'Feb, 15', 'Feb, 16', 'Feb, 17', 'Feb, 18', 'Feb, 18', 'Feb, 19', 'Feb, 21', 'Feb, 22', 'Feb, 23', 'Feb, 24']
    },
    solar
        : {
        actual: [14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 14, 14, 18, 13, 13, 13, 13, 13],
        color: '#F1B400',
        actualColor: '#FEDE81',
        targetlColor: '#326657',
        target: [10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 10, 10, 10, 10, 10, 8, 8, 8],
        categories: ['Jan, 25', 'Jan, 26', 'Jan, 27', 'Jan, 28', 'Jan, 29', 'Jan, 30', 'Jan, 31', 'Feb, 01', 'Feb, 02', 'Feb, 03', 'Feb, 04', 'Feb, 05', 'Feb, 06', 'Feb, 07', 'Feb, 08', 'Feb, 09', 'Feb, 10', 'Feb, 11', 'Feb, 12', 'Feb, 13', 'Feb, 14', 'Feb, 15', 'Feb, 16', 'Feb, 17', 'Feb, 18', 'Feb, 18', 'Feb, 19', 'Feb, 21', 'Feb, 22', 'Feb, 23', 'Feb, 24']
    }

}

function ReportsTempHeader() {
    // activeButton is used in handleButtonClick function
    const [activeButton, setActiveButton] = useState('show_Mains');
    const [chartData, setChartData] = useState(show_data.mains);
    const [value, setValue] = useState({
        startDate: null,
        endDate: null
    });


    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        // Update chart data based on the button clicked
        if (buttonName === "show_Mains") {
            setChartData(show_data.mains);
        } else if (buttonName === 'show_LoadShedding') {
            setChartData(show_data.loadShedding);
        }
        if (buttonName === 'show_Genset') {
            setChartData(show_data.genset);
        }
        if (buttonName === 'show_Fuel') {
            setChartData(show_data.fuel);
        }
        if (buttonName === 'show_Battery') {
            setChartData(show_data.battery);
        }
        if (buttonName === 'show_Solar') {
            setChartData(show_data.solar);
        }

    };





    // chart data1
    // 
    const backupTimeData = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            top: '-50px',
            left: '0',
            show: true,
            data: ['Actual', 'Target'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 16,
                color: '#000',
                fontWeight: '700'
            },
        },

        grid: {
            top: '10px',
            left: '0px',
            right: '10px',
            bottom: '30px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: chartData.categories,

                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'Actual',
                type: 'bar',
                stack: 'Genset Count',
                smooth: true,
                color: [
                    chartData.actualColor,
                ],
                // areaStyle: {normal: {}},
                data: chartData.actual
            },

            {
                name: 'Target',
                type: 'line',
                stack: 'Fuel Consumption',
                //smooth: true,
                color: [
                    chartData.targetlColor,
                ],
                // areaStyle: {normal: {}},
                data: chartData.target
            },

        ]
    };

    return (
        <>
            <div className={`row sss_targets_boxes_row pt-1  ${styles.reportsTempTabs_main}`}>
                <div className="col-12 col-md-6 col-lg-3 col-xl-2 pb-3">
                    <div className={`box_card h-100 bg_blue cursor-pointer ${styles.ss_targetsBox} ${activeButton === 'show_Mains' ? styles.active : ''}`} onClick={() => handleButtonClick('show_Mains')}>
                        <div className={`bg_blue ${styles.targets_headlines}`}>
                            <strong>
                                <i className="targets_icon"><img src={icon1} alt="#"></img></i>
                                Management Reports
                            </strong>
                         </div>
                        <div className={styles.targetsBox_data}>
                            <div className={activeButton === 'show_Mains' ? 'color_blue' : ''}>
                                <h2>50</h2>
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 col-xl-2 pb-3">
                    <div className={`box_card h-100 bg_grey_tuna cursor-pointer ${styles.ss_targetsBox} ${activeButton === 'show_LoadShedding' ? styles.active : ''}`} onClick={() => handleButtonClick('show_LoadShedding')}>
                        <div className={`bg_grey_tuna ${styles.targets_headlines}`}>
                            <strong>
                                <i className="targets_icon"><img src={icon2} alt="#"></img></i>
                                Teams View Reports
                            </strong> 
                        </div>
                        <div className={styles.targetsBox_data}>
                            <div className={activeButton === 'show_LoadShedding' ? 'color_grey_tuna' : ''}>
                                <h2>250</h2>
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 col-xl-2 pb-3">
                    <div className={`box_card h-100 bg_green cursor-pointer ${styles.ss_targetsBox} ${activeButton === 'show_Genset' ? styles.active : ''}`} onClick={() => handleButtonClick('show_Genset')}>
                        <div className={`bg_green ${styles.targets_headlines}`}>
                            <strong>
                                <i className="targets_icon"><img src={icon3} alt="#"></img></i>
                                Asset Management & Planning Reports
                            </strong> 
                        </div>
                        <div className={styles.targetsBox_data}>
                            <div className={activeButton === 'show_Genset' ? 'color_green' : ''}>
                                <h2>10</h2>
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 col-xl-2 pb-3">
                    <div className={`box_card h-100 bg_orange cursor-pointer ${styles.ss_targetsBox} ${activeButton === 'show_Fuel' ? styles.active : ''}`} onClick={() => handleButtonClick('show_Fuel')}>
                        <div className={`bg_orange ${styles.targets_headlines}`}>
                            <strong>
                                <i className="targets_icon"><img src={icon4} alt="#"></img></i>
                                Site Reports
                            </strong> 
                        </div>
                        <div className={styles.targetsBox_data}>
                            <div className={activeButton === 'show_Fuel' ? 'color_orange' : ''}>
                                <h2>550</h2>
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 col-xl-2 pb-3">
                    <div className={`box_card h-100 bg_blue_navy cursor-pointer ${styles.ss_targetsBox} ${activeButton === 'show_Battery' ? styles.active : ''}`} onClick={() => handleButtonClick('show_Battery')} >
                        <div className={`bg_blue_navy ${styles.targets_headlines}`}>
                            <strong>
                                <i className="targets_icon"><img src={icon5} alt="#"></img></i>
                                Fueling Reports
                            </strong> 
                        </div>
                        <div className={styles.targetsBox_data}>
                            <div className={activeButton === 'show_Battery' ? 'color_blue_navy' : ''}>
                                <h2>125</h2>
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 col-xl-2 pb-3">
                    <div className={`box_card h-100 bg_yellow cursor-pointer ${styles.ss_targetsBox} ${activeButton === 'show_Solar' ? styles.active : ''}`} onClick={() => handleButtonClick('show_Solar')}>
                        <div className={`bg_yellow ${styles.targets_headlines}`}>
                            <strong>
                                <i className="targets_icon"><img src={icon6} alt="#"></img></i>
                                Alarms Reports
                            </strong> 
                        </div>
                        <div className={styles.targetsBox_data}>
                            <div className={activeButton === 'show_Solar' ? 'color_yellow' : ''}>
                                <h2>500</h2>
                                <p>Reports</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                activeButton !== '' ?
                    <ReportsTempLists />
                    :
                    ''
            }


        </>
    )
}
export default ReportsTempHeader;


