import React from "react";
import styles from '../pages/SitePerformance/SitePerformance.module.css';


function SssAlaramsShow() {
    return (
        <>
            <div className="sss_targets_rowShow ">
                <div className={`box_card ${styles.viewTrend_showBox}`}>
                    <div className="row">
                        <div className="faulty_transformers col-lg-12">
                            <div className="feeders_status_row raised_circle_small">
                                <div className="feeders_status_cell">
                                    <div className="raised_text text-start">
                                        <small>Total</small>
                                        <h2>20</h2>
                                    </div>
                                </div>
                                <div className="feeders_status_cell">
                                    <div className="raised_text text-start">
                                        <i className="raised_circle bg_red"> </i>
                                        <small>Critical</small>
                                        <div className="feeders_status_row">
                                            <div className="feeders_status_cell text-start ps-0">
                                                <h5 className="text-start">5 
                                                   <i className="arrowColorText arrowUpRed">25%</i>
                                                 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feeders_status_cell">
                                    <div className="raised_text text-start">
                                        <i className="raised_circle bg_green"> </i>
                                        <small>Major</small>
                                        <div className="feeders_status_row">
                                            <div className="feeders_status_cell text-start ps-0">
                                                <h5 className="text-start">3 
                                                   <i className="arrowColorText arrowUpRed">15%</i>
                                                 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feeders_status_cell">
                                    <div className="raised_text text-start">
                                        <i className="raised_circle bg_blue_navy"> </i>
                                        <small>Minor</small>
                                        <div className="feeders_status_row">
                                            <div className="feeders_status_cell text-start ps-0">
                                                <h5 className="text-start">10 
                                                   <i className="arrowColorText arrowUpGreen">50%</i>
                                                 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="feeders_status_cell">
                                    <div className="raised_text text-start">
                                        <i className="raised_circle bg_yellow"> </i>
                                        <small>Warning</small>
                                        <div className="feeders_status_row">
                                            <div className="feeders_status_cell text-start ps-0">
                                                <h5 className="text-start">2 
                                                   <i className="arrowColorText arrowUpGreen">10%</i>
                                                 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SssAlaramsShow;


