import React from 'react';
import ExecutiveDashboardHeader from "../../../Components/mainHeader/ExecutiveDashboardHeader";
import YtdToFromBox from '../../../Components/ytdToFrom/YtdToFromBox';
import ReactECharts from 'echarts-for-react';
import SummaryRadiosTabs from '../../../Components/SummaryRadiosTabs';


function Loadsheddding() {
  let is_main = true;
  let activeIndex = 2;



  //
  // 
  const ecGWHData = {
    title: {
      text: 'ss',
      show: false,
      textStyle: {
        fontSize: 16,
        fontWeight: '500',
        fontFamily: 'Roboto, sans-serif',
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      right: '0',
      top: '4px',
      show: false,
      data: ['2022', '2023'],
      fill: 'orange',
      itemHeight: 10,
      itemWidth: 10
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: '10px',
      top: '76px',
      right: '50px',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['2022 Avg.'],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        show: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],

    series: [

      {
        name: '2022 YTD',
        type: 'bar',
        stack: 'YTD',
        smooth: true,
        label: {
          show: true
        },
        color: [
          '#19AAF8',
        ],
        // areaStyle: {normal: {}},
        data: [10.7]
      },

      {
        name: 'Target',
        type: 'bar',
        stack: '1',
        smooth: true,
        label: {
          show: true
        },
        color: [
          '#C3EBFF',
        ],

        data: [8],

      },

    ],
    graphic: [
      {
        type: 'group',
        left: 'center',
        top: '0%',
        children: [
          {
            type: 'rect',
            z: 100,
            left: 'center',
            top: 'middle',
            shape: {
              width: 100,
              height: 50,
            },
            style: {
              fill: '#19AAF8',
              stroke: '#19AAF8',
              lineWidth: 1,
            },

          },
          //left horizantle line
          {
            type: 'rect',
            z: 101,
            left: '-28px',
            bottom: "-40px",
            shape: {
              width: 1,
              height: 15,
            },
            style: {
              fill: '#333333',
            },
          },
          //right horizantle line
          {
            type: 'rect',
            z: 101,
            left: '28px',
            bottom: "-39px",
            shape: {
              width: 1,
              height: 13,
            },
            style: {
              fill: '#333333',
            },
          },
          //pointer arrow
          {
            type: 'polygon',
            z: 101,
            left: '24px',
            bottom: -40,
            shape: {
              points: [[-4, 0], [4, 0], [0, 4]],
            },
            style: {
              fill: '#333333',
              stroke: '#333333',
              lineWidth: 1,
            },
          },

          {
            type: 'text',
            z: 101,
            style: {
              text: '+2.70\n(+34%)',
              fill: '#fff',
              fontSize: 14,
              fontWeight: '400',
              fontFamily: 'Roboto, sans-serif',
              left: '0%', // Adjust the horizontal position
              top: '0%', // Adjust the vertical position
              textAlign: 'center',
              textVerticalAlign: 'middle',
            },

          },
        ],
      },
    ],
  };


  //
  // 
  const ecGWHColData = {
    title: {
      text: '',
      show: false,
      textStyle: {
        fontSize: 16,
        fontWeight: '500',
        color: '#000',
        fontFamily: 'Roboto, sans-serif',
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      left: '20px',
      top: '4px',
      show: true,
      data: ['Forecast', 'Actual', 'Grid Off', 'Unreliable Grid'],
      fill: 'orange',
      itemHeight: 14,
      itemWidth: 14,
      textStyle: {
        fontSize: 16,
        color: '#000',
        fontWeight: '700'
      },
    },
    // toolbox: {
    //   feature: {
    //     saveAsImage: {}
    //   }
    // },
    grid: {
      left: '0px',
      top: '106px',
      right: '0px',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: true,
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        splitLine: {
          show: false,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        show: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#F0F0F0'
          }
        },
      }
    ],

    series: [
      {
        name: 'Forecast',
        type: 'bar',
        stack: 'Forecast',
        smooth: true,
        label: {
          show: true,
          position: 'outside',
          color: "#000",
          emphasis: {
            label: {
              color: "#000",
            },
          },
        },
        color: [
          '#C3EBFF',
        ],

        data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8,]

      },
      {
        name: 'Actual',
        type: 'bar',
        stack: 'Actual',
        smooth: true,
        label: {
          show: true,
          position: 'outside',
          color: "#000",
          offset: [5, -18],
          emphasis: {
            label: {
              color: "#000",
            },
          },
        },
        color: [
          '#19AAF8',
        ],
        data: [9.8, 9.8, 10, 9.8, 9.8, 10, 9.8, 10, 9.8, 10, 9.8, 10]
      },
      {
        name: 'Grid Off',
        type: 'bar',
        barWidth: 5,
        stack: 'YTD',
        smooth: true,
        label: {
          show: false
        },
        color: [
          '#464E5C',
        ],
        // areaStyle: {normal: {}},
        data: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6]
      },
      {
        name: 'Unreliable Grid',
        type: 'bar',
        barWidth: 5,
        stack: 'YTD',
        smooth: true,
        label: {
          show: false
        },
        color: [
          '#5967FC',
        ],
        // areaStyle: {normal: {}},
        data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4]
      },

    ],
    graphic: [
      {
        type: 'group',
        left: '76%',
        top: '0%',
        children: [
          {
            type: 'rect',
            z: 100,
            left: 'center',
            top: 'middle',
            shape: {
              width: 100,
              height: 50,
            },
            style: {
              fill: '#19AAF8',
              stroke: '#19AAF8',
              lineWidth: 1,
            },

          },
          //left horizantle line
          {
            type: 'rect',
            z: 101,
            left: '-28px',
            bottom: "-40px",
            shape: {
              width: 1,
              height: 15,
            },
            style: {
              fill: '#333333',
            },
          },
          //right horizantle line
          {
            type: 'rect',
            z: 101,
            left: '28px',
            bottom: "-39px",
            shape: {
              width: 1,
              height: 13,
            },
            style: {
              fill: '#333333',
            },
          },
          //pointer arrow
          {
            type: 'polygon',
            z: 101,
            left: '24px',
            bottom: -40,
            shape: {
              points: [[-4, 0], [4, 0], [0, 4]],
            },
            style: {
              fill: '#333333',
              stroke: '#333333',
              lineWidth: 1,
            },
          },

          {
            type: 'text',
            z: 101,
            style: {
              text: '+3.60\n(+45%)',
              fill: '#fff',
              fontSize: 14,
              fontWeight: '400',
              fontFamily: 'Roboto, sans-serif',
              left: '0%', // Adjust the horizontal position
              top: '0%', // Adjust the vertical position
              textAlign: 'center',
              textVerticalAlign: 'middle',
            },

          },
        ],
      },
    ],
  };



  return (
    <div className="exdb_summary_main">
      <ExecutiveDashboardHeader pageTitle='Executive Dashboard' exdb={is_main} />
      <div className='contentWrap'>
        <div className="exe_summary_box">
          <div className="exe_summary_header">
            <SummaryRadiosTabs activeIndex={activeIndex} />
          </div>
          <div className='exe_smry_data_header d-flex align-items-center justify-content-between pb-3'>
            <div>
              <button className='exe_editData_btn'>
                Edit Data
              </button></div>
            <div> <YtdToFromBox /></div>
          </div>

          <div className='exe_smry_charts_content exe_border_none'>
            <div className='exe_smry_charts_left'>
              <div className='exe_energy_box'>
                <div className="ep_emissions_headlines">
                  <h2>
                    <b>+2.7 <small>+34%</small></b>
                  </h2>
                  <strong>Loadshedding Hrs/day</strong>
                  <p>Network experienced 3.6 hours of grid outages beyond forecast in September.</p>
                </div>
                <div className='ep_emissions_chart_out'>
                  <ReactECharts option={ecGWHData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                </div>
              </div>


            </div>

            <div className='exe_smry_charts_right'>


            </div>
          </div>
          <div className='exe_ecp_chart_parent pt-4'>
            <ReactECharts option={ecGWHColData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
          </div>


        </div>
      </div>


    </div>
  )
}


export default Loadsheddding;