
import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SiteHeaderDropdown from "../SiteHeaderDropdown";
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import { insightsMenu } from "./InsightsMenu";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
function SitesInsightsSolar() {
    const pageNo = 1;
    const breadcrumbs = [
        { label: 'Sites', link: '' },
        { label: 'Insights', link: '/SitesInsights' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SitesInsightsSolar',
        otherUrl: '/SingleSiteSummary',
         
    }
    return (
        <>
           <div className="sitePerformance_wrapper">
           <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb='false' isSummaryButton isSiteDropdown={<SiteHeaderDropdown parentPath='/SitesInsights' />} />
           <div className="pb-2">
                <ApplyFilterHeader />
            </div>
            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'>
                <AmTabsHeader pageNo={pageNo} moremenu={insightsMenu} />    
                </div>
                 
            </div>
            <div className="sitesInsights_solar contentWrap">
                <div className="row">
                    <div className="col-12 col-lg-4 insi_solar_left">
                        <div className="box_card insi_left_heading">
                            <h2 className="weight9 color_white">Right Sized</h2>
                        </div> 
                        <div className="insi_solar_power pt-3">
                            <div className="box_card py-4">
                                <h4 className="inf_heading label20 weight5 d-block text-center heading_border_right pb-4">
                                    <span className="px-2 bg_white">Power Loss</span>
                                </h4>
                                <h2 className="text-center">
                                    <span className="weight9 color_red2 pe-2">90</span>
                                    <strong className="label30 weight9 d-inline-block" style={{ lineHeight: "1em", verticalAlign: "middle" }}>kWh
                                        <small className="label18 weight4 color_red2 text-end d-block" style={{ lineHeight: "1em" }}>10%</small>
                                    </strong>
                                </h2>

                                <div className="comp_list">
                                    <h4 className="inf_heading label20 weight5 d-block heading_border_right text-center pb-3">
                                        <span className="px-2 bg_white">Comparison</span>
                                    </h4>
                                    <ul className="d-flex align-items-center flex-wrap py-2">
                                        <li className="width30 pe-3">
                                            <div className="comp_list_data">
                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                <div className="d-flex justify-content-center align-items-baseline">
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                        </svg>
                                                    </i>
                                                    <strong className="label22 weight5 color_red2 text-center">10% </strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="width40">
                                            <div className="comp_list_data">
                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                <div className="d-flex justify-content-center align-items-baseline">
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                        </svg>
                                                    </i>
                                                    <strong className="label22 weight5 color_red2 text-center">15% </strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="width30 ps-3">
                                            <div className="comp_list_data">
                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                <div className="d-flex justify-content-center align-items-baseline">
                                                    <i>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                        </svg>
                                                    </i>
                                                    <strong className="label22 weight5 color_red2 text-center">15% </strong>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="insi_solar_left_inner pt-3 pb-4">
                            <div className="box_card p-0">
                                <div className="insi_solar_heading st_tInputs_heading color_blue6">
                                    <h4 className="label20 weight5">
                                        <span className="color_white">External</span>
                                    </h4>

                                    <h2 className="weight9 label30 color_white">
                                        15
                                        <strong className="weight9 label22 color_white ps-2">kWh
                                            <small className="label18 weight5 color_white ps-2">(32.7%)</small>
                                        </strong>
                                    </h2>
                                </div>
                                <div className="insiSolar_cloudyData">
                                    <div className="extCloudy_heading">
                                        <small className="label22 weight5 text-center d-block">Cloudy Weather</small>
                                        <h2 className="text-center">
                                            <span className="weight9 color_red2 pe-2">15</span>
                                            <strong className="label30 weight9 d-inline-block">kWh
                                            </strong>
                                        </h2>
                                    </div>
                                    <div className="comp_list sitesInfra_rSolarIns py-2">
                                        <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                            <li>
                                                <div className="comp_list_data">
                                                    <small className="label11 weight5 d-block text-center">Project</small>
                                                    <div className="d-flex justify-content-center align-items-baseline">
                                                        <i>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                            </svg>
                                                        </i>
                                                        <strong className="label22 weight5 color_red2 text-center">7.5% </strong>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="comp_list_data">
                                                    <small className="label11 weight5 d-block text-center">Belt</small>
                                                    <div className="d-flex justify-content-center align-items-baseline">
                                                        <i>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                            </svg>
                                                        </i>
                                                        <strong className="label22 weight5 color_red2 text-center">7% </strong>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="comp_list_data">
                                                    <small className="label11 weight5 d-block text-center">Region</small>
                                                    <div className="d-flex justify-content-center align-items-baseline">
                                                        <i>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                            </svg>
                                                        </i>
                                                        <strong className="label22 weight5 color_red2 text-center">3% </strong>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 insi_solar_right">
                        <div className="row">
                            <div className="col-12">
                                <div className="insi_solar_right_inner">
                                    <div className="box_card p-0">
                                        <div className="insi_solar_heading st_tInputs_heading bg_gray_dark4">
                                            <h4 className="label20 weight5">
                                                <span className="color_white">Configuration Issues</span>
                                            </h4>
                                            <h2 className="weight9 label30 color_white">
                                                40
                                                <strong className="weight9 label22 color_white ps-2">kWh
                                                    <small className="label18 weight5 color_white ps-2">(44.4%)</small>
                                                </strong>
                                            </h2>
                                        </div>
                                        <div className="insiSolar_data_main">
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Excessive Battery Usage</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_red2 pe-2">30</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">7.5% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">7% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">3% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Excessive Grid Usage</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_gray_light3 pe-2">0</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Excessive DG Run</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_red2 pe-2">10</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">5% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">9%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">2%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="row pt-3">
                            <div className="col-12">
                                <div className="insi_solar_right_inner">
                                    <div className="box_card p-0">
                                        <div className="insi_solar_heading st_tInputs_heading bg_gray_dark4">
                                            <h4 className="label20 weight5">
                                                <span className="color_white">Field Challenges</span>
                                            </h4>
                                            <h2 className="weight9 label30 color_white">
                                                35
                                                <strong className="weight9 label22 color_white ps-2">kWh
                                                    <small className="label18 weight5 color_white ps-2">(38.9%)</small>
                                                </strong>
                                            </h2>
                                        </div>
                                        <div className="insiSolar_data_main">
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Faulty Solar</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_gray_light3 pe-2">0</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Solar Missing</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_gray_light3 pe-2">0</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Partial Panels Connected</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_gray_light3 pe-2">0</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0% </strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <strong className="label22 weight5 color_gray_light3 text-center">0%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="insiSolar_data_main">
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Shadow</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_red2 pe-2">5</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">2%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">10%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">8%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Dusty Panels</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_red2 pe-2">10</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">1%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">2%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">11%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="insiSolar_data">
                                                <div className="insiSolar_dataTop">
                                                    <small className="label22 weight5 text-center d-block">Un-accounted For</small>
                                                    <h2 className="text-center">
                                                        <span className="weight9 color_red2 pe-2">20</span>
                                                        <strong className="label30 weight9 d-inline-block">kWh
                                                        </strong>
                                                    </h2>
                                                </div>
                                                <div className="comp_list sitesInfra_rSolarIns">
                                                    <ul className="d-flex align-items-center justify-content-center flex-wrap">
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Project</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">9%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Belt</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">5%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="comp_list_data">
                                                                <small className="label11 weight5 d-block text-center">Region</small>
                                                                <div className="d-flex justify-content-center align-items-baseline">
                                                                    <i>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                            <path d="M8.31206 9.15434C7.65874 10.0344 6.34126 10.0345 5.68794 9.15434L1.1417 3.02986C0.341335 1.95165 1.11095 0.421874 2.45376 0.421874L11.5462 0.421873C12.889 0.421873 13.6587 1.95165 12.8583 3.02986L8.31206 9.15434Z" fill="#DD0000" />
                                                                        </svg>
                                                                    </i>
                                                                    <strong className="label22 weight5 color_red2 text-center">1%</strong>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default SitesInsightsSolar;