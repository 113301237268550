import React from "react";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styles from '../pages/SitePerformance/SitePerformance.module.css';

function CirclePrograssChart() {
    return (
        <>
            <div className="psu_headlines_row">
                <h3 className="chartTitleHeading pb-0">Sites Availabality</h3>
                <div className="psu_headlines_right">
                    <div className="psu_selectbox">
                        <select>
                            <option value='1'>7 days</option>
                            <option value='2'>Last week</option>
                            <option value='3'>Last Mont</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='ssView_circleChart'>
                <div className='ssView_circleChart_cell'>
                    <CircularProgressbarWithChildren value={52.2}>
                        <div style={{ fontSize: 12, marginTop: -5 }}>
                            <strong className='label16 weight6'>52.2%</strong>
                        </div>
                        <span className='color_gray_dark3 label14 weight4'>PAR</span>
                    </CircularProgressbarWithChildren>
                </div>
                <div className='ssView_circleChart_cell'>
                    <CircularProgressbarWithChildren value={10}>
                        <div style={{ fontSize: 12, marginTop: -5 }}>
                            <strong className='label16 weight6'>10%</strong>
                        </div>
                        <span className='color_gray_dark3 label14 weight4'>NAR</span>
                    </CircularProgressbarWithChildren>
                </div>
                <div className='ssView_circleChart_cell'>
                    <CircularProgressbarWithChildren value={0}>
                        <div style={{ fontSize: 12, marginTop: -5 }}>
                            <strong className='label16 weight6'>0%</strong>
                        </div>
                        <span className='color_gray_dark3 label14 weight4'>TCH</span>
                    </CircularProgressbarWithChildren>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <small className={styles.moreBtn}>View more <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M12.6654 8.01497H3.33203" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.33203 11.349L12.6654 8.01562" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.33203 4.68164L12.6654 8.01497" stroke="#FF7F02" stroke-linecap="round" stroke-linejoin="round" />
                </svg></i></small>
            </div>
        </>
    );
}

export default CirclePrograssChart;