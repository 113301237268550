import React from 'react';
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import SimpleMapPage from "../../../Components/Map/MapChart";
import "../AssetManagement.css";
import ReactECharts from 'echarts-for-react';

import { moremenu } from '../MoreMenuLinks';
import SpHeader from '../../../Components/mainHeader/SpHeader';
import AmHeaderDropdown from '../AmHeaderDropdown';
import MyAllDownloadInfo from '../../../Components/ytdToFrom/MyAllDownloadInfo';

function AmAutotransformerSwitch() {
    const pageNo = 2;

    // 
    const backupTimeData = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            top: '10px',
            left: '0px',
            right: '10px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['1 Year', '2 Year', '3 Year', '4 Year', '5 Year'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        series: [
            {
                name: 'Battery Ageing',
                type: 'bar',
                stack: 'Battery Ageing',
                smooth: true,
                color: [
                    '#187CF5',
                ],
                // areaStyle: {normal: {}},
                data: [14, 14, 18, 13, 13]
            },

        ]
    };

    const breadcrumbs = [
        { label: 'Auto Transformer Switch', link: '' },
    ];
    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/AmAutotransformerSwitch',
        otherUrl: '/AmSummary',
       // icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">  <g clip-path="url(#clip0_4924_1097)"><path d="M22.9037 13.7827H1.77701C1.22155 13.7827 0.771271 14.233 0.771271 14.7885V23.5079C0.771271 24.0634 1.22155 24.5137 1.77701 24.5137H22.9037C23.4591 24.5137 23.9094 24.0634 23.9094 23.5079V14.7885C23.9094 14.233 23.4591 13.7827 22.9037 13.7827Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2.31381 13.7826V7.78011C2.31544 7.51236 2.42323 7.2562 2.61352 7.06784C2.80381 6.87948 3.06106 6.7743 3.3288 6.7754H11.9547C12.1119 6.77516 12.2671 6.81145 12.4079 6.8814C12.5487 6.95136 12.6713 7.05307 12.7661 7.17852L13.9857 9.08715H20.5816C20.8495 9.08633 21.1068 9.19191 21.297 9.38069C21.4871 9.56946 21.5945 9.826 21.5956 10.0939V13.7826" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9.32202 16.8003H15.3585" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  <path d="M20.2967 6.00214V4.6951C20.2951 4.42735 20.1873 4.17119 19.997 3.98283C19.8067 3.79447 19.5495 3.68929 19.2817 3.69039H13.9857L12.766 1.77764C12.6712 1.65219 12.5486 1.55048 12.4078 1.48053C12.267 1.41057 12.1119 1.37428 11.9546 1.37452H4.62757C4.49482 1.37398 4.36326 1.39959 4.24041 1.44989C4.11756 1.50019 4.00582 1.5742 3.91156 1.66769C3.81731 1.76118 3.7424 1.87231 3.6911 1.99475C3.6398 2.11719 3.61312 2.24853 3.61258 2.38129V3.45387" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  </g>  <defs> <clipPath id="clip0_4924_1097"> <rect width="24.6807" height="24.6807" fill="white" transform="translate(0 0.605469)"/> </clipPath>  </defs> svg>`
     }

    return (
        <div className="assetManagement_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<AmHeaderDropdown parentPath='/AmTransformer'/>} />
            
            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div>
                <div className='col-12 col-lg-3'>
                    <MyAllDownloadInfo/>
                </div>
            </div>

            <AmTabsHeader pageNo={pageNo} moremenu={moremenu} />

            <div className="contentWrap assetManagement_content">
                <div className="chart_containter pb-0">
                    <div className="row">
                        <div className="col-12 col-xl-8">
                            <div className="row pb-3">
                                <div className="col-12">
                                    <div className="box_card mb-3">
                                        <div className="row">
                                            <div className="faulty_transformers col-lg-10">

                                                <div className="feeders_status_row raised_circle_small">
                                                    <div className="feeders_status_cell">
                                                        <div className='raised_text text-start'>
                                                            <i className='raised_circle bg-green'></i>
                                                            <small>Automatic ATS</small>
                                                            <h2>3,500</h2>

                                                        </div>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <div className='raised_text text-start'>
                                                            <i className='raised_circle bg-blue'></i>
                                                            <small>Manual Switches</small>
                                                            <h2>1,800</h2>

                                                        </div>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <div className='raised_text text-start'>
                                                            <i className='raised_circle bg-red'></i>
                                                            <small>Faulty Switches</small>
                                                            <div className='feeders_status_row'>
                                                                <div className="feeders_status_cell text-start ps-0">
                                                                    <h5 className='text-start'>185</h5>
                                                                    <small className="color-green">Auto ATS</small>
                                                                </div>
                                                                <div className="feeders_status_cell text-start">
                                                                    <h5 className='text-start'>157</h5>
                                                                    <small className="color-blue">Manual Switches</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className='col-12'>
                                    <div className="box_card has_info_icon">
                                        <div className="box_card_inner">
                                            <div className='box_info'>
                                                <i className='box_info_icon'></i>
                                            </div>
                                            <h3 className="chartTitleHeading pb-3">Ageing</h3>
                                            <ReactECharts option={backupTimeData} opts={{ renderer: 'svg' }} style={{ height: 262 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4">
                            <div className="box_card am_gen_map  mb-3">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AmAutotransformerSwitch;