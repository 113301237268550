import React, { useState } from 'react';
import "./TotalAverageRadio.css";
const TotalAverageRadio = () => {

  const [array, setArray] = useState([
    { id: 1, name: 'Total', active: true },
    { id: 2, name: 'Average', active: false },
  ]);

  
  // Function to handle item click and update active state
  const handleItemClick = (id) => {
    setArray((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      })
    );
  };

  return (
    <div className='totalAverageRadio_box'>
      <ul>
        {array.map((item) => (
          <li  key={item.id}>
            <label
              className={`totalAverageRadio ${item.active ? 'active' : ''}`}
              onClick={() => handleItemClick(item.id)}
            > 
              {item.name}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TotalAverageRadio;
