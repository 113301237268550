import React, { useState } from 'react';
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";
import AmTabsHeader from "../../../Components/TopTabsHeader/AmTabsHeader";
import SimpleMapPage from "../../../Components/Map/MapChart";
import "../AssetManagement.css";
import AmDonutChart from "../../../Components/Charts/AmDonutChart";
import AmpButtonsGroup from '../../../Components/AmpButtonsGroup/AmpButtonsGroup';
import SizedTable from '../../../Components/SizedTable';
import { moremenu } from '../MoreMenuLinks';
import AmHeaderDropdown from '../AmHeaderDropdown';
import SpHeader from '../../../Components/mainHeader/SpHeader';

import MyAllDownloadInfo from '../../../Components/ytdToFrom/MyAllDownloadInfo';

function AmTransformer() {
    const pageNo = 0;
    // sized data arry
    const sizedTableData = [
        {
            id: 0,
            name: "Over Sized",
            value: "1,825",
            sites: "Sites",
            color: "#F13534"
        },
        {
            id: 1,
            name: "Right Sized",
            value: "8,443",
            sites: "Sites",
            color: "#01A340"
        },
        {
            id: 2,
            name: "Under Sized",
            value: "5,515",
            sites: "Sites",
            color: "#F88800"
        },
        {
            id: 3,
            name: "Asset Conflict",
            value: "796",
            sites: "Sites",
            color: "#5967FC"
        },
        {
            id: 4,
            name: "Off Grid",
            value: "7,856",
            sites: "Sites",
            color: "#32323E"
        },
        {
            id: 5,
            name: "Off Load",
            value: "122",
            sites: "Sites",
            color: "#5D5B57"
        }
    ]


    // Distribution Companies data array
    const distributionCompanies_arr = [
        {
            id: 0,
            name: "Fesco",
            value: "9,000",
            sites: "Faisalabad",
            color: "#5B525E"
        },
        {
            id: 1,
            name: "Sepco",
            value: "9,000",
            sites: "Sukkur",
            color: "#1B97D8"
        },
        {
            id: 2,
            name: "Mepco",
            value: "9,000",
            sites: "Multan",
            color: "#CB7B3A"
        },
        {
            id: 3,
            name: "Hesco",
            value: "9,000",
            sites: "Hyderabad",
            color: "#D12E7E"
        },
        {
            id: 4,
            name: "K-Electric",
            value: "9,000",
            sites: "Karachi",
            color: "#86B0FF"
        },
        {
            id: 5,
            name: "Qesco",
            value: "9,000",
            sites: "Quetta",
            color: "#5F4F82"
        },
        {
            id: 6,
            name: "Lesco",
            value: "9,000",
            sites: "Lahore",
            color: "#E5CF1A"
        },
        {
            id: 7,
            name: "Gepco",
            value: "9,000",
            sites: "Gujranwala",
            color: "#137C71"
        },
        {
            id: 8,
            name: "Tesco",
            value: "9,000",
            sites: "Tribal Areas",
            color: "#8A5363"
        },
        {
            id: 9,
            name: "Pesco",
            value: "9,000",
            sites: "Peshawar",
            color: "#AF1A23"
        },
        {
            id: 10,
            name: "Iesco",
            value: "9,000",
            sites: "Islamabad",
            color: "#8C6843"
        },
        {
            id: 11,
            name: "Barkiyat",
            value: "9,000",
            sites: "AJK",
            color: "#80D1A0"
        }
    ]



    // Category Distribution chart js start
    const donutHeight = 280;
    const dataRaised = [
        {
            value: 600,
            name: 'Sharing',
        },
        {
            value: 250,
            name: 'COLO',
        },
        {
            value: 250,
            name: 'OMO',
        },
        {
            value: 250,
            name: 'B2S',
        },
        {
            value: 250,
            name: 'TP Host',
        },
        {
            value: 250,
            name: 'TP Built',
        },
        {
            value: 250,
            name: 'Telenor - NA',
        },
    ];
    const dataAging = [
        {
            value: 500,
            name: 'Sharing',
            label: {
                show: false,
                formatter: ['250'].join('\n'),
            }
        },
        {
            value: 150,
            name: 'COLO',
        },
        {
            value: 350,
            name: 'OMO',
        },
        {
            value: 650,
            name: 'B2S',
        },
        {
            value: 550,
            name: 'TP Host',
        },
        {
            value: 450,
            name: 'TP Built',
        },
        {
            value: 350,
            name: 'Telenor - NA',
        },
    ];
    const [chartData, setChartData] = useState(dataRaised);
    const piechartOptions = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            backgroundColor: '#F0F0F0',
            top: 10,
            right: 0,
            data: ['Sharing', 'COLO', 'OMO', 'B2S', 'TP Host', 'TP Built', 'Telenor - NA'],
            type: 'scroll',
            orient: 'vertical',
            itemWidth: 24,
            itemHeight: 12,
            selectedMode: true,
            padding: [10, 10, 10, 10],
            borderRadius: 8,
            itemGap: 26,
            icon: 'roundRect',

        },

        grid: {
            left: '30px',
            right: '10px',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                //data: ['Sharing', 'COLO', 'OMO', 'B2S', 'TP Host', 'TP Built', 'Telenor - NA'],
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['32%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 0,
                    borderColor: '#fff',
                    borderWidth: 0
                },
                label: {
                    show: true,
                    position: 'center',
                    formatter: ' Total \n 11,125',
                    fontSize: 16,
                    fontWeight: 'bold'
                },

                labelLine: {
                    show: false
                },
                center: ['26%', '50%'],
                color: [
                    '#EF444D',
                    '#187BF5',
                    '#56D5DC',
                    '#8BC486',
                    '#FAA640',
                    '#88745B',
                    '#8172B3',

                ],
                data: chartData,
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 16,
                        fontWeight: 'bold'
                    },
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };
    const btnArray = [
        { id: 1, name: 'Consumption', active: true },
        { id: 2, name: 'Run Hours', active: false },
    ];
    const [array, setArray] = useState(btnArray);
    const handleItemClick = (id) => {
        setArray((prevState) =>
            prevState.map((item) => {
                if (item.id === id) {
                    return { ...item, active: true };
                } else {
                    return { ...item, active: false };
                }
            })
        );
        console.log('array', id)
        if (id === 1) {
            setChartData(dataRaised);
        }
        if (id === 2) {
            setChartData(dataAging);
        }

    };
    // Category Distribution chart js end


    const breadcrumbs = [
        { label: 'Transformer', link: '' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/AmTransformer',
        otherUrl: '/AmSummary',
        // icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">  <g clip-path="url(#clip0_4924_1097)"><path d="M22.9037 13.7827H1.77701C1.22155 13.7827 0.771271 14.233 0.771271 14.7885V23.5079C0.771271 24.0634 1.22155 24.5137 1.77701 24.5137H22.9037C23.4591 24.5137 23.9094 24.0634 23.9094 23.5079V14.7885C23.9094 14.233 23.4591 13.7827 22.9037 13.7827Z" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M2.31381 13.7826V7.78011C2.31544 7.51236 2.42323 7.2562 2.61352 7.06784C2.80381 6.87948 3.06106 6.7743 3.3288 6.7754H11.9547C12.1119 6.77516 12.2671 6.81145 12.4079 6.8814C12.5487 6.95136 12.6713 7.05307 12.7661 7.17852L13.9857 9.08715H20.5816C20.8495 9.08633 21.1068 9.19191 21.297 9.38069C21.4871 9.56946 21.5945 9.826 21.5956 10.0939V13.7826" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/> <path d="M9.32202 16.8003H15.3585" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  <path d="M20.2967 6.00214V4.6951C20.2951 4.42735 20.1873 4.17119 19.997 3.98283C19.8067 3.79447 19.5495 3.68929 19.2817 3.69039H13.9857L12.766 1.77764C12.6712 1.65219 12.5486 1.55048 12.4078 1.48053C12.267 1.41057 12.1119 1.37428 11.9546 1.37452H4.62757C4.49482 1.37398 4.36326 1.39959 4.24041 1.44989C4.11756 1.50019 4.00582 1.5742 3.91156 1.66769C3.81731 1.76118 3.7424 1.87231 3.6911 1.99475C3.6398 2.11719 3.61312 2.24853 3.61258 2.38129V3.45387" stroke="black" stroke-width="1.54254" stroke-linecap="round" stroke-linejoin="round"/>  </g>  <defs> <clipPath id="clip0_4924_1097"> <rect width="24.6807" height="24.6807" fill="white" transform="translate(0 0.605469)"/> </clipPath>  </defs> svg>`
    }

    return (
        <div className="assetManagement_wrapper">
            {/* <HeaderMain pageTitle='Asset Management' /> */}
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb={true} isSummaryButton isSiteDropdown={<AmHeaderDropdown parentPath='/AmTransformer' />} />

            <div className='row align-items-center pe-4 pb-2'>
                <div className='col-12 col-lg-9'><ApplyFilterHeader isNotYtd /></div>
                <div className='col-12 col-lg-3'>
                    <MyAllDownloadInfo/>
                </div>
            </div>

            <AmTabsHeader pageNo={pageNo} moremenu={moremenu} />

            <div className="contentWrap assetManagement_content">
                <div className="chart_containter">
                    <div className="row pb-3">
                        <div className="col-12 col-xl-8">
                            <div className="row pb-3">
                                <div className="col-12">
                                    <div className="box_card p-0">
                                        <SizedTable data={sizedTableData} />
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-3 ">
                                <div className="col-12 col-xl-5">
                                    <div className="box_card mb-3">
                                        <div className="row">
                                            <div className="faulty_transformers col-lg-6">
                                                <div className="am_faulty_transformers">
                                                    <span>Faulty Transformers</span>
                                                    <h2>7</h2>
                                                </div>
                                            </div>
                                            <div className="faulty_transformers col-lg-6">
                                                <div className="am_faulty_transformers">
                                                    <span>Connected Feeders</span>
                                                    <h2>50</h2>
                                                </div>
                                                <div className="feeders_status_row">
                                                    <div className="feeders_status_cell">
                                                        <h5>45</h5>
                                                        <small className="color-green">Online</small>
                                                    </div>
                                                    <div className="feeders_status_cell">
                                                        <h5>5</h5>
                                                        <small className="color-red">Offline</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box_card has_info_icon">
                                        <div className='box_info'>
                                            <i className='box_info_icon'></i>
                                        </div>
                                        <h3 className='chartTitleHeading text-center pb-3'>
                                            Category Distribution
                                        </h3>
                                        <div className="am_donut_chart_main">
                                            <div className="">
                                                <AmpButtonsGroup array={array} handleItemClick={handleItemClick} />
                                            </div>
                                            <AmDonutChart height={donutHeight} options={piechartOptions} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-7">
                                    <div className="box_card">
                                        <h3 className="chartTitleHeading text-center pb-3">Distribution Companies</h3>
                                        <div className="am_campaign_list row">
                                            {distributionCompanies_arr.map((item) => (
                                                <div key={item.id} className="col-lg-6 pb-2">
                                                    <div className="am_campaign_box">
                                                        <i className="am_vertical_bar" style={{ backgroundColor: item.color }}></i>
                                                        <div className="am_campaign_cell">
                                                            <h3>{item.name}</h3>
                                                            <small>{item.sites}</small>
                                                        </div>
                                                        <div className="am_campaign_cell">
                                                            <strong>{item.value}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4">
                            <div className="box_card am_transformer_map_h">
                                <div className="box_card_inner infrastructureChartBox">
                                    <SimpleMapPage />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}
export default AmTransformer;