
import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SiteHeaderDropdown from "../SiteHeaderDropdown";

import ReactECharts from 'echarts-for-react';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import SiteReachabilityAnmpHeatMap from "../../../Components/Charts/SiteReachabilityAnmpHeatMap";
import ApplyFilterHeader from "../../../Components/ApplyFilterHeader";



function SitePulse() {
    const breadcrumbs = [
        { label: 'Sites', link: '' },
        { label: 'Site Pulse', link: '/SitePulse' },
    ];

    const summaryObj = {
        minimize: 'Minimise',
        otherName: "Summary",
        minUrl: '/SitePulse',
        otherUrl: '/SingleSiteSummary',
    }


    const site_reachability = {
        title: {
            text: 'Sites',
            show: false,
            left: '3',
            top: '30px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '400',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        // tooltip: {
        //     trigger: 'axis'
        // },
        tooltip: {
            trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        legend: {
            left: '0',
            top: '0px',
            show: true,
            data: ['Ping', 'SNMP'],
            itemHeight: 10,
            itemWidth: 10,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 13,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '70px',
            right: '0px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['00.00', '01.00', '02.00', '03.00', '04.00', '05.00', '07.00', '08.00', '09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00', '20.00', '21.00', '22.00', '23.00'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'Ping',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#0059FF',
                ],

                data: [5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000],

                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',
            },
            {
                name: 'SNMP',
                type: 'bar',
                stack: '1',
                smooth: true,
                color: [
                    '#32864B',
                ],

                data: [5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000],
                label: {
                    show: false,
                    color: "#FFF",
                    emphasis: {
                        label: {
                            color: "#FFF",
                        },
                    },
                    formatter: function (params) {
                        return params.value === 0 ? "" : params.value; // Hide labels with value 0
                    },
                },
                barGap: '0%',

            },

        ],

    };

 
    const TimeToOutAgeHrsChart = {
        
        series: [
            {
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                center: ['50%', '84%'],
                radius: '100%',
                min: 0,
                max: 1,
                splitNumber: 8,
                axisLine: {
                    lineStyle: {
                        width: 6,
                        color: [
                            [0.25, '#FF6E76'],
                            [0.5, '#FDDD60'],
                            [0.75, '#58D9F9'],
                            [1, '#7CFFB2']
                        ]
                    }
                },
                pointer: {
                    icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                    length: '12%',
                    width: 20,
                    offsetCenter: [0, '-65%'],
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    length: 12,
                    lineStyle: {
                        color: 'auto',
                        width: 2
                    }
                },
                splitLine: {
                    length: 20,
                    lineStyle: {
                        color: 'auto',
                        width: 5
                    }
                },
                axisLabel: {
                    color: '#464646',
                    fontSize: 18,
                    fontWeight: "700",
                    distance: -60,
                    rotate: 'tangential',
                   
                    formatter: function (value) {
                        if (value === 0.875) {
                            return '6+ hrs';
                        } else if (value === 0.625) {
                            return '4-6 hrs';
                        } else if (value === 0.375) {
                            return '1-4 hrs';
                        } else if (value === 0.125) {
                            return '< 1 hr';
                        }
                        return '';
                    }
                },
                title: {
                    offsetCenter: [0, '-10%'],
                    fontSize: 20
                },
                detail: {
                    fontSize: 34,
                    offsetCenter: [0, '-6%'],
                    valueAnimation: true,
                    formatter: function (value) {
                       // return Math.round(value * 100) + 'hrs';
                       return '7.49 hrs';
                    },
                    color: 'inherit'
                },
                data: [
                    {
                        value: 0.75,
                      //  name: '7.49 hrs',
                        // label: {
                        //     textStyle: {
                        //         color: 'blue', // Set the color for both name and value
                        //         fontSize: 16, // Set the font size for both name and value
                        //     },
                        // },
                    },

                ]
            }
        ]
    };



    return (
        <div className="sitePerformance_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} summaryObj={summaryObj} exdb='false' isSummaryButton isSiteDropdown={<SiteHeaderDropdown />} />
            <div className="pb-2">
                <ApplyFilterHeader />
            </div>
            <div className="contentWrap inf_contentMain">
                <div className="site_reachability">
                    <div className="row pb-3">
                        <div className="col-12 col-lg-8">
                            <div className='box_card'>
                                <div className="box_info_flexible border-bottom-0 pb-0">
                                    <h3 className='chartTitleHeading pb-0 pt-0'>
                                        Site Reachability
                                    </h3>
                                </div>
                                <ReactECharts option={site_reachability} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="box_card h-100">
                                <div className="box_info_flexible border-bottom-0 pb-0">
                                    <h3 className='chartTitleHeading pb-0 pt-0'>
                                        Time to Outage (hrs)
                                    </h3>
                                </div> 
                                <ReactECharts option={TimeToOutAgeHrsChart} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                            </div>
                        </div>
                    </div>

                    <div className="row box_card">
                        <div className="col-12 col-lg-10">
                            <SiteReachabilityAnmpHeatMap />
                        </div>
                        <div className="col-12 col-lg-2">
                            <div className='ssView_circleChart sp_circleChart'>
                                <div className='ssView_circleChart_cell strokeClr_blue'>
                                    <CircularProgressbarWithChildren value={100} strokeWidth='12'>
                                        <div className="ssView_circleChart_value">
                                            <strong className='label20 weight6'>100%</strong>
                                        </div>
                                        <span className='color_gray_dark3 label16 weight4'>Ping</span>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className='ssView_circleChart_cell strokeClr_green'>
                                    <CircularProgressbarWithChildren value={100} strokeWidth='12'>
                                        <div className="ssView_circleChart_value">
                                            <strong className='label20 weight6'>100%</strong>
                                        </div>
                                        <span className='color_gray_dark3 label16 weight4'>SNMP</span>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className='ssView_circleChart_cell strokeClr_lBlue'>
                                    <CircularProgressbarWithChildren value={98} strokeWidth='12'>
                                        <div className="ssView_circleChart_value">
                                            <strong className='label20 weight6'>98%</strong>
                                        </div>
                                        <span className='color_gray_dark3 label16 weight4'>PAR</span>
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default SitePulse;