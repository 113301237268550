import React from "react";
import ReactECharts from 'echarts-for-react';

import SimpleMapPage from "../../../Components/Map/MapChart";

function TnpNarParMapView() {

    //   Region Wise Breakdown chart
    const RegionWiseBreakdown = {
        title: {
            text: 'Region Wise Breakdown',
            show: true,
            left: '3',
            top: '2px',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },

        tooltip: {
            trigger: 'axis'
        },

        legend: {
            left: '0px',
            top: '24px',
            show: true,
            data: ['Good Sites', 'Bad Sites'],
            itemHeight: 14,
            itemWidth: 14,
            type: 'scroll',
            orient: 'horizontal',
            textStyle: {
                fontSize: 14,
                color: '#000',
                fontWeight: '600'
            },
        },

        grid: {
            left: '10px',
            top: '90px',
            right: '10px',
            bottom: '0px',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: '0%',
                data: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'N2', 'S3'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                name: 'Sites',
                // show: true, 
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],
        series: [
            {
                name: 'Good Sites',
                type: 'bar',
                stack: 'Hours',
                color: ['#21AB4A'],
                data: [13, 12, 16, 14, 15, 17, 16, 12, 14, 15,],
            },
            {
                name: 'Bad Sites',
                type: 'bar',
                // stack: '1',
                smooth: true,
                color: [
                    '#D00',
                ],
                data: [14, 19, 14, 11, 10, 15, 12, 9, 10, 12],
            },
        ],
    };

    return (
        <div className='tnpEnergyCons'>
            <div className='row pb-3'>
                <div className='col-12 col-lg-4'>
                    <div className='tnpSiteBox pb-3'>
                        <div className="box_card faulty_transformers bg_light_blue2">
                            <div className="am_faulty_transformers">
                                <span>Good Sites</span>
                                <h2>10000</h2>
                            </div>
                            <div className="feeders_status_row">
                                <div className="feeders_status_cell narParSites">
                                    <small>Online Sites</small>
                                    <div className='feaders_value_Box'>
                                        <ul>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>NAR</span>
                                                    <h4 className='color-green'>3000</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>PAR</span>
                                                    <h4 className='color-green'>5000</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>TCH</span>
                                                    <h4 className='color-green'>2000</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="feeders_status_cell narParSites">
                                    <small>Offline Sites</small>
                                    <div className='feaders_value_Box'>
                                        <ul>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>NAR</span>
                                                    <h4 className='color-red'>1250</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>PAR</span>
                                                    <h4 className='color-red'>500</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>TCH</span>
                                                    <h4 className='color-red'>806</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tnpSiteBox pb-3'>
                        <div className="box_card faulty_transformers bg_light_pink">
                            <div className="am_faulty_transformers">
                                <span>Bad Sites</span>
                                <h2>2500</h2>
                            </div>
                            <div className="feeders_status_row">
                                <div className="feeders_status_cell narParSites">
                                    <small>Online Sites</small>
                                    <div className='feaders_value_Box'>
                                        <ul>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>NAR</span>
                                                    <h4 className='color-green'>1000</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>PAR</span>
                                                    <h4 className='color-green'>800</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>TCH</span>
                                                    <h4 className='color-green'>200</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="feeders_status_cell narParSites">
                                    <small>Offline Sites</small>
                                    <div className='feaders_value_Box'>
                                        <ul>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>NAR</span>
                                                    <h4 className='color-red'>50</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>PAR</span>
                                                    <h4 className='color-red'>700</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='feaders_value_Box_inner'>
                                                    <span>TCH</span>
                                                    <h4 className='color-red'>30</h4>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className='grid_siteBoxChart p-0'>
                        <div className="box_card">
                            <div className="amp_btn_margin float-end">
                                <div className="ampButtonsGroup">
                                    <ul className="ampButtonsBox">
                                        <li><span className="ampButton active">NAR</span></li>
                                        <li><span className="ampButton ">PAR</span></li>
                                        <li><span className="ampButton ">TCH</span></li>
                                    </ul>
                                </div>
                            </div>
                            <ReactECharts option={RegionWiseBreakdown} opts={{ renderer: 'svg' }} style={{ height: 270 }} />
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-8 tnpNarParMapChart'>
                    <div className='box_card'>
                        <SimpleMapPage />
                    </div>
                </div>
            </div>
        </div >
    )
}
export default TnpNarParMapView;