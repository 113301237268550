
import React from "react";
import ReactECharts from 'echarts-for-react';
function SsViewBatteryProfileChart(props) {


    const columnchartOptions = {
        title: {
            text: 'Battery profile over a day',
            show: true,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 12,
                fontWeight: '500',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: false
            }
        },
        legend: {
            data: ['Battery', 'Batter Volt', 'Main', 'DG', 'Solar'],
            left: 10
        },
        toolbox: {
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                restore: {},
                saveAsImage: {}
            }
        },
        axisPointer: {
            link: [
                {
                    xAxisIndex: 'all'
                }
            ]
        },
        dataZoom: [
            {
                show: true,
                realtime: true,
                start: 0,
                end: 100,
                xAxisIndex: [0, 1]
            },
            {
                type: 'inside',
                realtime: true,
                start: 0,
                end: 100,
                xAxisIndex: [0, 1]
            }
        ],
        grid: [
            {
                left: 60,
                right: 10,
                height: '42%',
                containLabel: true
            },
            {
                left: 60,
                right: 10,
                top: '60%',
                height: '35%',
                bottom: '80px',
                containLabel: true
            }
        ],
        xAxis: [
            {
                type: 'category',
                show: false,
                boundaryGap: false,
                axisLine: { onZero: true },
                data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
                splitLine: {
                    show: false,
                    lineStyle: {
                      color: '#F0F0F0'
                    }
                  },
            },
            {
                gridIndex: 1,
                type: 'category',
                boundaryGap: false,
                axisLine: { onZero: true },
                //   data: timeData,
                data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00'],
                position: 'bottom',
                splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#F0F0F0',
                    }
                  },
            }
        ],
        yAxis: [
            {
                name: 'Batt (V)',
                type: 'value',
                max: 300
            },
            {
                gridIndex: 1,
                name: '',
                type: 'value',
                inverse: true
            }
        ],
        series: [
            {
                name: 'Battery',
                type: 'line',
                symbolSize: 8,
                color: 'rgba(0, 89, 255, 1)',
                areaStyle: {
                    color: 'rgba(0, 89, 255, 0.5)',
                },
                data: [
                    100.97, 100.96, 100.96, 100.95, 100.95, 100.94, 100.94, 100.94, 100.94, 100.94
                ]
            },
            {
                name: 'Main',
                type: 'line',
                symbolSize: 8,
                color: 'rgba(114, 67, 208, 1)',
                areaStyle: {
                    color: 'rgba(114, 67, 208, 0.5)',
                },
                data: [
                    200.97, 220.96, 230.96, 180.95, 200.95, 200.94, 200.94, 200.94, 200.94, 200.94
                ]
            },

            {
                name: 'DG',
                type: 'line',
                symbolSize: 8,
                color: 'rgba(50, 50, 62, 1)',
                areaStyle: {
                    color: 'rgba(50, 50, 62, 0.5)',
                },
                data: [
                    200.97, 220.96, 230.96, 180.95, 200.95, 200.94, 200.94, 200.94, 200.94, 200.94
                ]
            },

            {
                name: 'Solar',
                type: 'line',
                symbolSize: 8,
                color: 'rgba(1, 163, 64, 1)',
                areaStyle: {
                    color: 'rgba(1, 163, 64, 0.5)',
                },
                data: [
                    200.97, 220.96, -30.96, -80.95, -90.95, 200.94, 200.94, 200.94, 200.94, 200.94
                ]
            },


            
            {
                name: 'Batter Volt',
                type: 'line',
                xAxisIndex: 1,
                yAxisIndex: 1,
                symbolSize: 8,
                color: 'rgba(240, 180, 29, 1)',
                areaStyle: {
                    color: 'rgba(240, 180, 29, 0.5)',
                },
                // prettier-ignore
                data: [
                    -10, -9, -10.1, -10, -10, -10, -10.1, -10,-10, -11,
                ]
            }
        ]
    };

    return (
        <>
            <ReactECharts option={columnchartOptions} opts={{ renderer: 'svg' }} style={{ height: 400 }} />
        </>
    )
}

export default SsViewBatteryProfileChart;