
import React from "react";
import SpHeader from "../../../Components/mainHeader/SpHeader";
import SobTabsHeader from "../SobTabsHeader/SobTabsHeader";
import { sob_moremenu } from "../SobTabsHeader/SobMoreMenuLinks";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Link } from "react-router-dom";


function SobBattery() {
    const pageNo = 8;
    const breadcrumbs = [
        { label: 'Site On Boarding', link: '' },
        { label: 'Add Existing Site', link: '/SitesOnboarding' },
        { label: 'AMKM - 03', link: '' },
    ];

    // const summaryObj = {
    //     minimize: 'Minimise',
    //     otherName: "Summary",
    //     minUrl: '/SitePulse',
    //     otherUrl: '/SingleSiteSummary',
    // }


    return (
        <div className="sobSteps_wrapper">
            <SpHeader breadcrumbs={breadcrumbs} />

            <SobTabsHeader pageNo={pageNo} sob_moremenu={sob_moremenu} />

            <div className="sobSteps_content_main">
                <div className="sobGeneralInfo pb-1">
                    <div className="sobSteps_content contentWrap">
                        <div className="row pt-3">
                            <div className="col-12 col-lg-8 box_card">
                                <div className="sobGeneralInfo_inner">
                                    <div className="sob_heading">
                                        <h2>Battery</h2>
                                    </div>
                                    <div className="row">
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Old Battery Project<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select label="Select an option">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Implemented 'Key Battery Project<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Install Date<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Movement status<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>PTN Battery Bank<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Cabinet Type<span>*</span></h4>
                                                <OutlinedInput />
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Brand<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>BTS Battery Rating<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Rating (Ah)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Technology<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>EOL / Non-EOL<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>EOL Date<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Aging Till Applicable Month<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Backup Time<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>GAP From LS<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Required DC Cable Size (mm)<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Battery Replacement Required<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-md-6 col-lg-4 col-xl-3  pb-4'>
                                            <div className="sobInfo_box">
                                                <h4>Warranty Status<span>*</span></h4>
                                                <div className="applyFilter_box">
                                                    <FormControl className='m-0'>
                                                        <Select defaultValue="" label="Grouping">
                                                            <MenuItem value={1} className='label16 weight6'>Select 1</MenuItem>
                                                            <MenuItem value={2} className='label16 weight6'>Select 2</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="row ps-1">
                                    <div className="col-12 col-lg-12">
                                        <div className="sobGeneralInfo_inner bg_gray_dark5">
                                            <div className="sob_heading">
                                                <h2>Battery</h2>
                                            </div>
                                            <div className="sob_summary_data">
                                                <table className="table st_summary_list mb-2">
                                                    <tbody>
                                                        <tr>
                                                            <th>Old Battery Project</th>
                                                            <th>GST</th>
                                                        </tr>
                                                        <tr>
                                                            <td>Implemented 'Key Battery Project</td>
                                                            <td>Deposit</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Install Date</td>
                                                            <td>Ballon / RV</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Movement status</td>
                                                            <td>Term</td>
                                                        </tr>
                                                        <tr>
                                                            <td>PTN Battery Bank</td>
                                                            <td>Payment Structure</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cabinet Type</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Brand</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>BTS Battery Rating</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Rating (Ah)</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Technology</td>
                                                            <td>Financed Amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>EOL / Non-EOL</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>EOL Date</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Aging Till Applicable Month</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Battery Backup Time</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                        <tr>
                                                            <td>GAP From LS</td>
                                                            <td>Blended Rate</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf_next_reset_btn pt-3 contentWrap">
                        <Link to={'/OnboardingSearch'} className="btn btn-secondary me-2">Cancel</Link>
                        <Link to={'/SobRectifier'} className="btn btn-secondary me-2">Back</Link>
                        <Link to={'/SobGatewayConfigs'} className="btn btn-dark">Next</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SobBattery;