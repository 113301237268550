import React from 'react';
import ExecutiveDashboardHeader from "../../../Components/mainHeader/ExecutiveDashboardHeader";
import YtdToFromBox from '../../../Components/ytdToFrom/YtdToFromBox';
import ReactECharts from 'echarts-for-react';
import SummaryRadiosTabs from '../../../Components/SummaryRadiosTabs';

function SummaryFuelConsumption() {
    let is_main = true;
    let activeIndex = 4;

    //
    // 
    const ecGWHData = {
        title: {
            text: 'ss',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            right: '0',
            top: '4px',
            show: false,
            data: ['2022', '2023'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 16,  
                color: '#000',
                fontWeight:'700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '10px',
            top: '76px',
            right: '50px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Target - 2022 YTD'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: '2022 YTD',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true
                },
                color: [
                    '#19AAF8',
                ],
                // areaStyle: {normal: {}},
                data: [19.37]
            },
        

            {
                name: 'Target',
                type: 'bar',
                stack: '1',
                smooth: true,
                label: {
                    show: true
                },
                color: [
                    '#C3EBFF',
                ],

                data: [17.63],
            },

        ],
        graphic: [
            {
                type: 'group',
                left: 'center',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: 'center',
                        top: 'middle',
                        shape: {
                            width: 100,
                            height: 50,
                        },
                        style: {
                            fill: '#19AAF8',
                            stroke: '#19AAF8',
                            lineWidth: 1,
                        },

                    },
                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '-28px',
                        bottom: "-40px",
                        shape: {
                            width: 1,
                            height: 15,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '28px',
                        bottom: "-39px",
                        shape: {
                            width: 1,
                            height: 13,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '24px',
                        bottom: -40,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '+1.74\n(+10%)',
                            fill: '#fff',
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: '0%', // Adjust the horizontal position
                            top: '0%', // Adjust the vertical position
                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },

                    },
                ],
            },
        ],
    };


    //
    // 
    const ecGWHColData = {
        title: {
            text: '',
            show: false,
            textStyle: {
                fontSize: 16,
                fontWeight: '500',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: '0',
            top: '12px',
            show: true,
            data: ['Forecast', 'Actual', 'Unaccounted', 'Battery Charging', 'Site Load'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 14,  
                color: '#000',
                fontWeight:'700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '106px',
            right: '0px',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],
        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [
            {
                name: 'Forecast',
                type: 'bar',
                stack: 'Forecast',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#C3EBFF',
                ],

                data: [49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37]

            },
            {
                name: 'Actual',
                type: 'bar',
                stack: 'Actual',
                smooth: true,
                label: {
                    show: true,
                    position: 'outside',
                    color: "#000",
                    offset: [5, -18],
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#19AAF8',
                ],

                data: [49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37, 49.37]

            },
            {
                name: 'Unaccounted',
                type: 'bar',
                barWidth: 5,
                stack: 'YTD',
                smooth: true,
                label: {
                    show: false
                },
                color: [
                    '#32323E',
                ],
                // areaStyle: {normal: {}},
                data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10]
            },
            {
                name: 'Battery Charging',
                type: 'bar',
                barWidth: 5,
                stack: 'YTD',
                smooth: true,
                label: {
                    show: false
                },
                color: [
                    '#0058FF',
                ],
                // areaStyle: {normal: {}},
                data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20]
            },
            {
                name: 'Site Load',
                type: 'bar',
                barWidth: 5,
                stack: 'YTD',
                smooth: true,
                label: {
                    show: false,
                    position: 'outside',
                },
                color: [
                    '#7243D0',
                ],

                data: [16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,]
            },


        ],
        graphic: [
            {
                type: 'group',
                left: '76%',
                top: '0%',
                children: [
                    {
                        type: 'rect',
                        z: 100,
                        left: 'center',
                        top: 'middle',
                        shape: {
                            width: 100,
                            height: 50,
                        },
                        style: {
                            fill: '#19AAF8',
                            stroke: '#19AAF8',
                            lineWidth: 1,
                        },

                    },
                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '-28px',
                        bottom: "-40px",
                        shape: {
                            width: 1,
                            height: 15,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '28px',
                        bottom: "-39px",
                        shape: {
                            width: 1,
                            height: 13,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '24px',
                        bottom: -40,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '+0.21\n(+16%)',
                            fill: '#fff',
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: '0%', // Adjust the horizontal position
                            top: '0%', // Adjust the vertical position
                            textAlign: 'center',
                            textVerticalAlign: 'middle',
                        },

                    },
                ],
            },
        ],
    };


    //
    // 
    const ecConsumptionData = {
        title: {
            text: '2022 Fuel Consumption (Mn Litres)',
            show: true,
            left: 'center',
            width: "100%",
            textStyle: {
                textAlign: 'center',
                fontSize: 16,
                fontWeight: '600',
                color: '#000',
                fontFamily: 'Roboto, sans-serif',
            },
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            left: 'center',
            top: '30px',
            show: true,
            data: ['OMO Share \n at Host Sites', 'TP Share \n at Guest Sites', 'TP Owned'],
            fill: 'orange',
            itemHeight: 14,
            itemWidth: 14,
            textStyle: {
                fontSize: 16,  
                color: '#000',
                fontWeight:'700'
            },
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
            left: '0px',
            top: '200px',
            right: '0px',
            bottom: '5%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: ['2021 Actual', '2022 YTD', '2022 Target'],
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            },
        ],

        yAxis: [
            {
                type: 'value',
                show: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#F0F0F0'
                    }
                },
            }
        ],

        series: [

            {
                name: 'OMO Share \n at Host Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    backgroundColor: "#FDF192",
                    padding: 4,
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },

                },


                color: [
                    '#FDF192',
                ],
                data: [-2.67, -2.83, -2.78],

            },

            {
                name: 'TP Share \n at Guest Sites',
                type: 'bar',
                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",

                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#FFB4B4',
                ],

                data: [6.18, 6.55, 6.62]

            },
            {
                name: 'TP Owned',
                type: 'bar',

                stack: 'YTD',
                smooth: true,
                label: {
                    show: true,
                    color: "#000",
                    emphasis: {
                        label: {
                            color: "#000",
                        },
                    },
                },
                color: [
                    '#19AAF8',
                ],
                // areaStyle: {normal: {}},
                data: [16.50, 19.37, 17.39],
                markPoint: {
                    symbol: 'circle',
                    symbolSize: 0.001,
                    symbolOffset: [0, -15],
                    itemStyle: {
                        color: '#fff' // Set the symbol color here
                    },
                    data: [
                        {
                            name: '20.01',
                            coord: [0, 25.01]
                        },
                        {
                            name: '23.09',
                            coord: [1, 25.09]
                        },
                        {
                            name: '21.23',
                            coord: [2, 25.23]
                        },
                        // Repeat for other columns
                    ],
                    label: {
                        show: true,
                        position: 'inside',
                        offset: [0, -10],
                        formatter: (params) => {
                            return params.data.name;
                        },
                        textStyle: {
                            color: '#000',
                            fontSize: 12
                        },
                        emphasis: {
                            label: {
                                color: "#000",
                            },
                        },
                    }
                }

            },
        ],
        graphic: [
            {
                type: 'group',
                left: '18%',
                top: '100px',
                width: '100%',

                children: [
                    //top main line
                    {
                        type: 'rect',
                        z: 90,
                        left: 'center',
                        top: 'middle',
                        shape: {
                            width: 354,
                            height: 1,
                        },
                        style: {
                            fill: '#000',
                            //  stroke: '#000',
                            lineWidth: 1,
                        },

                    },

                    //first box
                    {
                        type: 'rect',
                        z: 100,
                        left: '25%',
                        top: 'middle',
                        shape: {
                            width: 80,
                            height: 50,
                        },
                        style: {
                            fill: '#fff',
                            stroke: '#000',
                            lineWidth: 1,
                        },
                    },

                    // text left
                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '+3.08\n(+15%)',
                            fill: '#000',
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: 'center',
                            top: '110px',
                            textAlign: 'left',
                            textVerticalAlign: 'middle',
                            padding: [0, 100, 0, 156]
                        },
                    },
                    //left horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '17%',
                        bottom: "-50px",
                        shape: {
                            width: 1,
                            height: 50,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //middle horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        left: '49.8%',
                        bottom: "-50px",
                        shape: {
                            width: 1,
                            height: 50,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },
                    //pointer arrow
                    {
                        type: 'polygon',
                        z: 101,
                        left: '49%',
                        bottom: -52,
                        shape: {
                            points: [[-4, 0], [4, 0], [0, 4]],
                        },
                        style: {
                            fill: '#333333',
                            stroke: '#333333',
                            lineWidth: 1,
                        },
                    },

                    //right horizantle line
                    {
                        type: 'rect',
                        z: 101,
                        right: '17%',
                        bottom: "-50px",
                        shape: {
                            width: 1,
                            height: 50,
                        },
                        style: {
                            fill: '#333333',
                        },
                    },

                    {
                        type: 'rect',
                        z: 100,
                        left: '58%',
                        top: 'middle',
                        shape: {
                            width: 80,
                            height: 50,
                        },
                        style: {
                            fill: '#FDBF6F',
                            stroke: '#000',
                            lineWidth: 1,
                        },

                    },
                    // text right
                    {
                        type: 'text',
                        z: 101,
                        style: {
                            text: '+1.86\n(+8.8%)',
                            fill: '#000',
                            fontSize: 14,
                            fontWeight: '400',
                            fontFamily: 'Roboto, sans-serif',
                            left: 'center',
                            top: '110px',
                            textAlign: 'left',
                            textVerticalAlign: 'middle',
                            padding: [0, 100, 0, 332]
                        },
                    },

                ],
            },
        ],

    };


    return (
        <div className="exdb_summary_main">
            <ExecutiveDashboardHeader pageTitle='Executive Dashboard' exdb={is_main} />
            <div className='contentWrap'>
                <div className="exe_summary_box">
                    <div className="exe_summary_header">
                       <SummaryRadiosTabs activeIndex={activeIndex}/>
                    </div>
                    <div className='exe_smry_data_header d-flex align-items-center justify-content-between pb-3'>
                        <div>
                            <button className='exe_editData_btn'>
                                Edit Data
                            </button></div>
                        <div> <YtdToFromBox /></div>
                    </div>

                    <div className='exe_smry_charts_content'>
                        <div className='exe_smry_charts_left'>
                            <div className='exe_energy_box'>
                                <div className="ep_emissions_headlines">
                                    <h2>
                                        <b>+1.74  <small>+10%</small></b>
                                    </h2>
                                    <strong>Fuel Consumption Mn Litres</strong>
                                    <p>Driven by increase in fuel & grid consumption, network energy consumption increased by 21.5 GWh (3.83%)  at owned sites.</p>
                                    <strong>Fuel Million Litres</strong>
                                    <p>Excess fuel consumption of 210k litres on owned sites.</p>
                                </div>
                                <div className='ep_emissions_chart_out'>
                                    <ReactECharts option={ecGWHData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                                </div>
                            </div>

                            <div className='exe_ecp_chart_parent pt-4'>
                                <ReactECharts option={ecGWHColData} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
                            </div>
                        </div>

                        <div className='exe_smry_charts_right'>
                            <div className='exe_smry_consumption_chart'>
                                <ReactECharts option={ecConsumptionData} opts={{ renderer: 'svg' }} style={{ height: 540 }} />
                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </div>
    )
}


export default SummaryFuelConsumption;