
import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'react-apexcharts';
const RegionWiseNetworkReachability = () => {
    const options = {
        chart: {
            type: 'heatmap',
            toolbar: {
                show: false,
            },
            fill: {
                colors: ['#32323E']
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#343434']
            }
        },
        colors: ['#E5292B'],
        xaxis: {
            categories: ['C4', 'N1', 'C2', 'S1', 'C1', 'C3', 'N3', 'S2', 'S3', 'N2 Region'],
        },
        stroke: {
            width: 0,
        },
    };
    // Define your chart data
    const series = [
        {
            name: '10%',
            data: [240, 240, 890, 190, 240, 196, 240, 27, 345, 190],

        },
        {
            name: '20%',
            data: [240, 67, 34, 240, 178, 240, 156, 240, 99, 240],
        },
        {
            name: '30%',
            data: [240, 240, 96, 758, 25, 240, 58, 240, 240, 240],
        },
        {
            name: '40%',
            data: [240, 240, 240, 145, 240, 135, 365, 240, 189, 174],
        },
        {
            name: '50%',
            data: [45, 258, 240, 112, 21, 240, 189, 240, 240, 240],
        },
        {
            name: '60%',
            data: [240, 240, 240, 140, 240, 240, 240, 240, 180, 240],
        },
        {
            name: '70%',
            data: [67, 95, 135, 240, 85, 240, 240, 56, 75, 96],
        },
        {
            name: '80%',
            data: [240, 346, 240, 78, 11, 240, 88, 189, 90, 240],
        },
        {
            name: '90%',
            data: [12, 256, 56, 240, 89, 240, 98, 240, 89, 254],
        },
        {
            name: '100%',
            data: [120, 100, 110, 98, 240, 120, 100, 110, 98, 240],
        },
    ];


    return (
        <div className='heatmap_out'> 
            <div className='d-flex justify-content-between align-items-center'>
            <h3 className='chartTitleHeading'>Region Wise Network Reachability</h3>
                <div className="ampButtonsGroup ps-2 pb-0">
                    <ul className="ampButtonsBox">
                        <li>
                            <span className="ampButton active">SNMP</span>
                        </li>
                        <li>
                            <span className="ampButton">Ping</span>
                        </li>
                    </ul>
                </div>
            </div>

            <ApexCharts options={options} series={series} type="heatmap" height={350} />
        </div>
    );
};

export default RegionWiseNetworkReachability;
