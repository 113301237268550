import React, { useState } from "react";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Popover, 
    List,
    ListItem,
    Checkbox,
} from '@mui/material';
import { Button, Form } from 'react-bootstrap';

function DbDailyFuelTable() {
    // table
    const [currentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(20); // Ini

    const columns = [
        { field: 'InfrastructureAUG', headerName: 'Infrastructure (AUG)', flex: 1 }, 
        { field: 'InfrastructureAug1', headerName: '01', flex: 1 },
        { field: 'InfrastructureAug2', headerName: '02', flex: 1 },
        { field: 'InfrastructureAug3', headerName: '03', flex: 1 },
        { field: 'InfrastructureAug4', headerName: '04', flex: 1 },
        { field: 'InfrastructureAug5', headerName: '05', flex: 1 },
        { field: 'InfrastructureAug6', headerName: '06', flex: 1 },
        { field: 'InfrastructureAug7', headerName: '07', flex: 1 },
        { field: 'InfrastructureAug8', headerName: '08', flex: 1 },
        { field: 'InfrastructureAug9', headerName: '09', flex: 1 },
        { field: 'InfrastructureAug10', headerName: '10', flex: 1 },
        { field: 'InfrastructureAug11', headerName: '11', flex: 1 },
    ];

    const [rows, setRows] = useState([
        { id: 1, InfrastructureAUG: 'Fiber Node - ID', InfrastructureAug1: '256', InfrastructureAug2: '256', InfrastructureAug3: '256', InfrastructureAug4: '256', InfrastructureAug5: '256', InfrastructureAug6: '256', InfrastructureAug7: '256', InfrastructureAug8: '256', InfrastructureAug9: '256', InfrastructureAug10: '256', InfrastructureAug11: '256' },
        { id: 2, InfrastructureAUG: 'Fiber Node - OD', InfrastructureAug1: '232', InfrastructureAug2: '232', InfrastructureAug3: '232', InfrastructureAug4: '232', InfrastructureAug5: '232', InfrastructureAug6: '232', InfrastructureAug7: '232', InfrastructureAug8: '232', InfrastructureAug9: '232', InfrastructureAug10: '232', InfrastructureAug11: '232' },
        { id: 3, InfrastructureAUG: 'Host Sharing', InfrastructureAug1: '12', InfrastructureAug2: '12', InfrastructureAug3: '12', InfrastructureAug4: '12', InfrastructureAug5: '12', InfrastructureAug6: '12', InfrastructureAug7: '12', InfrastructureAug8: '12', InfrastructureAug9: '12', InfrastructureAug10: '12', InfrastructureAug11: '12' },
        { id: 4, InfrastructureAUG: 'Host / Solar Site / Outdoor Fiber', InfrastructureAug1: '123', InfrastructureAug2: '123', InfrastructureAug3: '123', InfrastructureAug4: '123', InfrastructureAug5: '123', InfrastructureAug6: '123', InfrastructureAug7: '123', InfrastructureAug8: '123', InfrastructureAug9: '123', InfrastructureAug10: '123', InfrastructureAug11: '123' },
        { id: 5, InfrastructureAUG: 'Standalone', InfrastructureAug1: '123', InfrastructureAug2: '123', InfrastructureAug3: '123', InfrastructureAug4: '123', InfrastructureAug5: '123', InfrastructureAug6: '123', InfrastructureAug7: '123', InfrastructureAug8: '123', InfrastructureAug9: '123', InfrastructureAug10: '123', InfrastructureAug11: '123' },
        { id: 6, InfrastructureAUG: 'Standalone - Solar', InfrastructureAug1: '233', InfrastructureAug2: '233', InfrastructureAug3: '233', InfrastructureAug4: '233', InfrastructureAug5: '233', InfrastructureAug6: '233', InfrastructureAug7: '233', InfrastructureAug8: '233', InfrastructureAug9: '233', InfrastructureAug10: '233', InfrastructureAug11: '233' },
        { id: 7, InfrastructureAUG: 'TP Guest - OMO', InfrastructureAug1: '233', InfrastructureAug2: '233', InfrastructureAug3: '233', InfrastructureAug4: '233', InfrastructureAug5: '233', InfrastructureAug6: '233', InfrastructureAug7: '233', InfrastructureAug8: '233', InfrastructureAug9: '233', InfrastructureAug10: '233', InfrastructureAug11: '233' },
        { id: 8, InfrastructureAUG: 'TP Guest - TowerCo', InfrastructureAug1: '222', InfrastructureAug2: '222', InfrastructureAug3: '222', InfrastructureAug4: '222', InfrastructureAug5: '222', InfrastructureAug6: '222', InfrastructureAug7: '222', InfrastructureAug8: '222', InfrastructureAug9: '222', InfrastructureAug10: '222', InfrastructureAug11: '222' },

    ]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleFilterIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setFilterOpen(!filterOpen);
    };



    const handleSort = (property) => {
        const isAscending = orderBy === property && order === 'asc';
        const newOrder = isAscending ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);

        const sortedRows = [...rows].sort((a, b) => {
            const aValue = a[property];
            const bValue = b[property];
            if (newOrder === 'asc') {
                return aValue < bValue ? -1 : 1;
            } else {
                return bValue < aValue ? -1 : 1;
            }
        });

        setRows(sortedRows);
    };


    const handleFilterChange = (event) => {
        const value = event.target.value;

        if (selectedFilters.includes(value)) {
            setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
        } else {
            setSelectedFilters([...selectedFilters, value]);
        }
    };

    const applyFilters = () => {
        // Apply filters based on selectedFilters
        // You can implement your custom filtering logic here

        // For demonstration purposes, we'll just log the selected filters
        console.log('Selected Filters:', selectedFilters);

        // Close the filter dropdown
        setFilterOpen(false);
    };


    // const handlePageChange = (event, newPage) => {
    //     setCurrentPage(newPage);
    //     console.log("currentPage:", newPage);
    // };

    // pagination js

    // Calculate the total number of pages

    // const totalPages = Math.ceil(rows.length / rowsPerPage);

    // Slice the data based on the current page and rows per page

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const slicedData = rows.slice(startIndex, endIndex);

    console.log("setRowsPerPage:", setRowsPerPage);
    console.log("endIndex:", endIndex);
    console.log("slicedData---:", slicedData);

    return (
        <>
            <div className='stf_tableWrap amDb_table_hAuto'>
                <TableContainer>
                    <Table aria-label="Basic table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.field}>
                                        <div className='customTbl_headCell'>
                                            <TableSortLabel
                                                active={orderBy === column.field}
                                                direction={orderBy === column.field ? order : 'asc'}
                                                onClick={() => handleSort(column.field)}
                                            >
                                                {column.headerName}
                                            </TableSortLabel>
                                            <Tooltip title="Filter">
                                                <label className='filter_header_btn' onClick={handleFilterIconClick}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="11" viewBox="0 0 9 11" fill="none">
                                                        <path d="M7.66615 1.88513C7.70623 1.83087 7.73063 1.76635 7.73663 1.6988C7.74263 1.63125 7.72999 1.56333 7.70012 1.50267C7.67024 1.442 7.62433 1.39098 7.56751 1.35533C7.51069 1.31967 7.44522 1.30078 7.37843 1.30078H0.897744C0.830952 1.30078 0.765478 1.31967 0.708661 1.35533C0.651843 1.39098 0.605927 1.442 0.576056 1.50267C0.546186 1.56333 0.533541 1.63125 0.539539 1.6988C0.545538 1.76635 0.569942 1.83087 0.610018 1.88513L3.41823 5.71662V9.33556C3.41823 9.40339 3.43683 9.46987 3.47194 9.52757C3.50706 9.58527 3.5573 9.63189 3.61704 9.66222C3.67679 9.69256 3.74366 9.7054 3.81019 9.69931C3.87671 9.69321 3.94025 9.66843 3.99368 9.62774L4.713 9.07991C4.75767 9.04589 4.79392 9.00178 4.81889 8.95107C4.84386 8.90036 4.85686 8.84444 4.85686 8.78774V5.71626L7.66615 1.88513Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </label>
                                            </Tooltip>

                                            <Popover className='stf_filter_dropdown' open={filterOpen} anchorEl={anchorEl} onClose={() => setFilterOpen(false)}>
                                                <div className='stf_filter_header'>
                                                    <h3 className='stf_filter_title'>Filter By Value <span>T<small>T</small></span></h3>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Filter Values"
                                                        aria-describedby="passwordHelpBlock"
                                                    />
                                                </div>
                                                <List>
                                                    <ListItem>
                                                        <label>
                                                            <Checkbox
                                                                value="FilterValue1"
                                                                checked={selectedFilters.includes('FilterValue1')}
                                                                onChange={handleFilterChange}
                                                            />
                                                            Filter Option 1
                                                        </label>
                                                    </ListItem>
                                                    <ListItem>
                                                        <label>
                                                            <Checkbox
                                                                value="FilterValue2"
                                                                checked={selectedFilters.includes('FilterValue2')}
                                                                onChange={handleFilterChange}
                                                            />
                                                            Filter Option 2
                                                        </label>
                                                    </ListItem>
                                                    {/* Add more filter options as needed */}
                                                </List>
                                                <div className='stf_filter_footer'>
                                                    <Button variant="outline-secondary" onClick={applyFilters}>Cancel</Button>
                                                    <Button variant="dark" onClick={applyFilters}>Ok</Button>
                                                </div>
                                            </Popover>
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {slicedData.map((row) => (
                                <TableRow key={row.id}>
                                    {columns.map((column) => (
                                        <TableCell key={column.field}>{row[column.field]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> 
            </div>
        </>
    );
}

export default DbDailyFuelTable;