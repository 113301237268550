import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./PowerFlow.css";
import battery_img from "../../assets/images/powerflow/battery_img.svg";
import pf_grid_img from "../../assets/images/powerflow/pf_grid_img.svg";
import pf_tower_img from "../../assets/images/powerflow/pf_tower_img.svg";
import pf_generator_img from "../../assets/images/powerflow/pf_generator_img.svg";
import pf_solar_img from "../../assets/images/powerflow/pf_solar_img.svg";
import pf_rectifier_img from "../../assets/images/powerflow/pf_rectifier_img.svg";

function PowerFlow() {
    const [wfData, setData] = React.useState([]);
    const [isResponse, setIsResponse] = useState(false);

    // useEffect(() => {
    //     fetch("https://api.thunder.softoo.co/vis/api/dashboard/ssu/powersource?siteId=ZLD019")
    //         .then((response) => response.json())
    //         .then((data) => {
    //             return (
    //                 console.log(data),
    //                 setData(data)
    //             )
    //         }
    //         );

    // }, []);
    useEffect(() => {
        fetchData();
       
        console.log('fetchDatassssss');
    }, []);


    const fetchData = async () => {
        try {
            const response = await fetch('https://api.thunder.softoo.co/vis/api/dashboard/ssu/powersource?siteId=ITN011');
            const jsonData = await response.json();
            // Process the fetched data

            // Call the jQuery function in the API success response
            console.log('------sss', jsonData)
          //  setData(jsonData)
               setData(jsonData)
               myFunction(jsonData);
               batteryCharged(jsonData);
               setIsResponse(true);
           
        } catch (error) {
            console.error('Error:', error); 
        }
    };

   



    return (
        <div className="powerFlow_graph_main">
            <h3 className="pf_heading">Power Flow</h3>
            <div className="powerFlow_graph">

                <div className="pf_graph">
                    <svg className="pf_main_svg" width="511" height="391" viewBox="0 0 511 391" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="pf_gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stop-color="rgba(255, 196, 0, 0.1)" />
                                <stop offset="100%" stop-color="rgba(255, 196, 0, 1)" />
                            </linearGradient>
                        </defs>
                        {/* <!-- solar to ractifier start--> */}
                        <path
                            d="M282 112.59L151.733 184.216C141.102 190.061 141.448 205.454 152.331 210.816L194.5 231.59"
                            stroke="#EDEDED" strokeWidth="3" />
                        <path className="pf_path pf_solor_to_recti"
                            d="M282 112.59L151.733 184.216C141.102 190.061 141.448 205.454 152.331 210.816L194.5 231.59"
                            stroke="red" strokeWidth="3" />
                        {/* <!-- solar to ractifier end --> */}

                        {/* <!--  ractifier to battery start --> */}
                        <path
                            d="M406.5 181.09L288.85 260.583C281.508 265.544 272.089 266.248 264.092 262.434L212 237.59"
                            stroke="#EDEDED" strokeWidth="3" />
                        <path className="pf_path pf_recti_to_battery"
                            d="M406.5 181.09L288.85 260.583C281.508 265.544 272.089 266.248 264.092 262.434L212 237.59"
                            stroke="red" strokeWidth="3" />
                        {/* <!--  ractifier to battery start --> */}

                        {/* <!-- gen to rectifier start--> */}
                        <path d="M67 321.09L192.5 241.09" stroke="#EDEDED" strokeWidth="3" />
                        <path className="pf_path pf_gen_to_recti" d="M67 321.09L192.5 241.09" stroke="red"
                            strokeWidth="3" />
                        {/* <!-- gen to rectifier end--> */}


                        {/* <!-- rectifier to tower start--> */}
                        <path d="M49 176L182 237" stroke="#EDEDED" strokeWidth="3" />
                        <path className="pf_path pf_recti_to_tower" d="M49 176L182 237" stroke="red"
                            strokeWidth="3" />
                        {/* <!-- rectifier to tower end--> */}

                        {/* <!-- grid to rectifier start--> */}
                        <path d="M223 255L409 343" stroke="#EDEDED" strokeWidth="3" />
                        <path className="pf_path pf_grid_to_recti" d="M223 255L409 343" stroke="red"
                            strokeWidth="3" />
                        {/* <!-- grid to rectifier end--> */}

                        <path className="pf_circle pf_tower_circle_grey"
                            d="M42.9938 187.29C57.9021 187.29 69.9876 180.517 69.9876 172.162C69.9876 163.807 57.9021 157.034 42.9938 157.034C28.0855 157.034 16 163.807 16 172.162C16 180.517 28.0855 187.29 42.9938 187.29Z"
                            fill="white" stroke="#50D9FF" strokeWidth="3" strokeMiterlimit="10" />
                        <path className="pf_circle pf_tower_circle"
                            d="M42.9938 187.29C57.9021 187.29 69.9876 180.517 69.9876 172.162C69.9876 163.807 57.9021 157.034 42.9938 157.034C28.0855 157.034 16 163.807 16 172.162C16 180.517 28.0855 187.29 42.9938 187.29Z"
                            fill="white" stroke="#03B9EC" strokeWidth="3" strokeMiterlimit="10" />

                        <path className="pf_circle pf_solar_circle_grey"
                            d="M298.524 128.04C329.741 128.04 355.048 112.913 355.048 94.2532C355.048 75.5931 329.741 60.4661 298.524 60.4661C267.307 60.4661 242 75.5931 242 94.2532C242 112.913 267.307 128.04 298.524 128.04Z"
                            fill="white" stroke="#FFC73C" strokeWidth="3" strokeMiterlimit="10" />
                        <path className="pf_circle pf_solar_circle"
                            d="M298.524 128.04C329.741 128.04 355.048 112.913 355.048 94.2532C355.048 75.5931 329.741 60.4661 298.524 60.4661C267.307 60.4661 242 75.5931 242 94.2532C242 112.913 267.307 128.04 298.524 128.04Z"
                            fill="white" stroke="#EDEDED" strokeWidth="3" strokeMiterlimit="10" />

                        <path className="pf_circle pf_battery_circle_grey"
                            d="M431.967 190.057C457.353 190.057 477.933 178.525 477.933 164.3C477.933 150.075 457.353 138.543 431.967 138.543C406.58 138.543 386 150.075 386 164.3C386 178.525 406.58 190.057 431.967 190.057Z"
                            fill="white" stroke="#5588FF" strokeWidth="3" strokeMiterlimit="10" />
                        <path className="pf_circle pf_battery_circle"
                            d="M431.967 190.057C457.353 190.057 477.933 178.525 477.933 164.3C477.933 150.075 457.353 138.543 431.967 138.543C406.58 138.543 386 150.075 386 164.3C386 178.525 406.58 190.057 431.967 190.057Z"
                            fill="white" stroke="#EDEDED" strokeWidth="3" strokeMiterlimit="10" />

                        <path className="pf_circle pf_grid_circle_grey"
                            d="M451.596 389.877C483.363 389.877 509.115 375.448 509.115 357.65C509.115 339.851 483.363 325.422 451.596 325.422C419.829 325.422 394.077 339.851 394.077 357.65C394.077 375.448 419.829 389.877 451.596 389.877Z"
                            fill="white" stroke="#9A76FA" strokeWidth="3" strokeMiterlimit="10" />
                        <path className="pf_circle pf_grid_circle"
                            d="M451.596 389.877C483.363 389.877 509.115 375.448 509.115 357.65C509.115 339.851 483.363 325.422 451.596 325.422C419.829 325.422 394.077 339.851 394.077 357.65C394.077 375.448 419.829 389.877 451.596 389.877Z"
                            fill="white" stroke="#EDEDED" strokeWidth="3" strokeMiterlimit="10" />

                        <path className="pf_circle pf_gen_circle_grey"
                            d="M55.8263 360.539C85.5537 360.539 109.653 347.037 109.653 330.382C109.653 313.726 85.5537 300.224 55.8263 300.224C26.0989 300.224 2 313.726 2 330.382C2 347.037 26.0989 360.539 55.8263 360.539Z"
                            fill="white" stroke="#32864B" strokeWidth="3" strokeMiterlimit="10" />



                        <path className="pf_circle pf_gen_circle"
                            d="M55.8263 360.539C85.5537 360.539 109.653 347.037 109.653 330.382C109.653 313.726 85.5537 300.224 55.8263 300.224C26.0989 300.224 2 313.726 2 330.382C2 347.037 26.0989 360.539 55.8263 360.539Z"
                            fill="white" stroke="#EDEDED" strokeWidth="3" strokeMiterlimit="10" />

                        <path className="pf_circle pf_rectifier_circle_grey"
                            d="M199.894 261.013C218.614 261.013 233.789 252.51 233.789 242.022C233.789 231.534 218.614 223.031 199.894 223.031C181.175 223.031 166 231.534 166 242.022C166 252.51 181.175 261.013 199.894 261.013Z"
                            fill="white" stroke="#CAAA82" strokeWidth="3" strokeMiterlimit="10" />
                        <path className="pf_circle pf_rectifier_circle"
                            d="M199.894 261.013C218.614 261.013 233.789 252.51 233.789 242.022C233.789 231.534 218.614 223.031 199.894 223.031C181.175 223.031 166 231.534 166 242.022C166 252.51 181.175 261.013 199.894 261.013Z"
                            fill="white" stroke="#88745B" strokeWidth="3" strokeMiterlimit="10" />
                    </svg>
                </div>
                {/* <!-- tower image and info data --> */}
                <div className="pf_graph_pos pf_pos_tower">
                    <div className="pf_graph_pos_img">
                        <img src={pf_tower_img} alt="#"></img>
                    </div>
                    <div className="pf_graph_data">
                        <ul>
                            <li>
                                <span>
                                    <strong> {isResponse? wfData.data.raw_values.load_curr.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label>Load</label>
                            </li>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.raw_values.load_total_pow.toFixed(1) : '0'}</strong> <small>kW</small> 

                                </span>
                                <label>Average</label>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- generator image and info data --> */}
                <div className="pf_graph_pos pf_pos_generator">
                    <div className="pf_graph_pos_img">
                        <img src={pf_generator_img} alt="#"></img>
                    </div>
                    <div className="pf_graph_data">
                        <ul>
                            <li>
                                <span>
                                    <strong> {isResponse? wfData.data.power_values.DG.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label>Load</label>
                            </li>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.avg_values.dg_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label>Average</label>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- solar image and info data --> */}
                <div className="pf_graph_pos pf_pos_solar">
                    <div className="pf_graph_pos_img">
                        <img src={pf_solar_img} alt="#"></img>
                    </div>
                    <div className="pf_graph_data pf_graph_bottom">
                        <ul>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.power_values.Solar.toFixed(1) : ''}</strong> <small>kW</small>
                                </span>
                                <label>Load</label>
                            </li>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.avg_values.solar_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>  
                                </span>
                                <label>Average</label>
                            </li>
                        </ul> 
                    </div>
                </div>
                {/* <!-- solar image and info data --> */}
                <div className="pf_graph_pos pf_pos_grid">
                    <div className="pf_graph_pos_img">
                        <img src={pf_grid_img} alt="#"></img>
                    </div>
                    <div className="pf_graph_data pf_graph_bottom">
                        <ul>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.power_values.Main.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label>Load</label>
                            </li>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.avg_values.main_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label>Average</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="pf_graph_pos pf_pos_battery">
                    <div className="pf_graph_pos_img">
                        <img src={battery_img} alt="#"></img>

                        <div className="pf_battery_pointer">
                            <svg width="10" height="21" viewBox="0 0 10 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.01082 0.398805C8.91224 0.0851315 8.73299 0.00448257 8.49998 0.156838C8.20423 0.354004 7.96224 0.712486 7.72922 1.07993C7.55894 1.35776 7.4066 1.6535 7.23632 1.93133L7.15566 2.0568C6.96746 2.19123 6.7703 2.3167 6.57313 2.44217C6.83303 1.94926 7.11084 1.49219 7.4514 1.15163L7.44246 1.09786C7.31699 1.15163 7.1915 1.19644 7.06604 1.28606C6.65378 1.59077 6.30427 2.09264 5.98163 2.63933C5.78446 2.96196 0.00390625 12.4439 0.00390625 12.4439L3.27506 11.1444L0.0307914 20.3484L8.52686 7.45198L6.13397 8.40196C6.84198 6.44822 8.22215 2.63934 8.49998 2.07472C8.54479 1.97614 8.59855 1.87755 8.66129 1.78793C8.84949 1.50114 8.94806 1.48323 9.10042 1.7252C9.12731 1.23229 9.12733 0.784174 9.01082 0.398805Z"
                                    fill="#A9A9A9" />
                                <defs>
                                    <linearGradient id="pf_battery_active_gradient" x1="7.78775"
                                        y1="1.04435" x2="1.6057" y2="16.7113"
                                        gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#F57F20" />
                                        <stop offset="1" stop-color="#FFC50F" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>

                        <div className="pf_battery_bar">
                            <ul>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 20 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 19 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 18 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 17 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 16 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 15 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 14 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 13 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 12 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 11 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 10 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 9 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 8 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 7 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 6 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 5 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 4 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 3 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 2 }}></li>
                                <li className="pf_battery_cell batteryCell_disabled" style={{ zIndex: 1 }}></li>
                            </ul>
                        </div>
                    </div>
                    <div className="pf_graph_data pf_graph_bottom">
                        <ul>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.power_values.Battery.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label className="pf_battery_dischargin">Load</label>
                            </li>
                            <li>
                                <span>
                                    <strong>{isResponse? wfData.data.avg_values.battery_avg_kw.toFixed(1) : '0'}</strong> <small>kW</small>
                                </span>
                                <label>Average</label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="pf_graph_pos pf_pos_rectifier">
                    <div className="pf_graph_pos_img">
                        <img src={pf_rectifier_img} alt="#"></img>
                    </div>
                </div> 
            </div>
        </div>
    )
}


function myFunction(wfData) {
   

    let data = wfData.data;
 
    // colors variables
    let grey_color = '#EDEDED';
    let tower_color = '#03B9EC';
    let rectifier_color = '#88745B';
    let grid_color = '#6A54A3';
    let solar_color = '#F0B41D';
    let battery_color = '#4162AE';
    // let generator_color = '#32864B';

    // things are connected

    let connected_tower = false; 

    let connected_rectifier = false;

    let connected_solar = false;

    let connected_grid = false;

    let connected_battery = false;

    let connected_generator = false;

    // progress variables  
    let generator_on = false;
    let generator_power = false;

    let solar_power = false;

    let grid_power = false;
  
   
    let battery_charging = false;
    let battery_disCharging = false;

    if(data.power_values.DG > 0){
        generator_on = true;
        generator_power = true;
    }

    if(data.power_values.Battery > 0){
        connected_battery = true;
        connected_rectifier = true;
        connected_tower = true; 
    
    }
    
    if(data.power_values.Main > 0){
        connected_grid = true;
        grid_power = true;
        connected_rectifier = true;
        connected_tower = true; 
    }
    
    if(data.power_values.Solar > 0){
        connected_solar = true;
        solar_power = true;
        connected_rectifier = true;
        connected_tower = true; 
    }

    if(data.charging >= 0){
        battery_charging = true;
    }
    if(data.charging < 0){
        battery_disCharging = true;
    }
     



    if (connected_tower === true) {
        $('.pf_tower_circle').css({ stroke: tower_color });
    } else {
        $('.pf_tower_circle').css({ stroke: grey_color });
    }
    if (connected_rectifier === true) {
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });
    } else {
        $('.pf_rectifier_circle').css({ stroke: grey_color });
    }

    if (connected_solar === true) {
        $('.pf_solar_circle').css({ stroke: solar_color });
    } else {
        $('.pf_solar_circle').css({ stroke: grey_color });
    }
    if (connected_grid === true) {
        $('.pf_grid_circle').css({ stroke: grid_color });

    } else {
        $('.pf_grid_circle').css({ stroke: grey_color });
    }
    if (connected_battery === true) {
        $('.pf_battery_circle').css({ stroke: battery_color });
        $('.pf_pos_battery_progress').removeClass("pf_hide");
    } else {
        $('.pf_battery_circle').css({ stroke: grey_color });
        $('.pf_pos_battery_progress').addClass("pf_hide");
    }
    if (connected_generator === true) {
        $('.pf_gen_circle').css({ stroke: '#6BC283' });
    } else {
        $('.pf_gen_circle').css({ stroke: grey_color });
    }

    // progres conditions

    if (generator_on === true) {
        $('.pf_gen_circle').addClass('active');
        $('.pf_gen_circle').css({ stroke: '#6BC283' });
        if (generator_power === true) {
            $('.pf_gen_to_recti, .pf_recti_to_tower').addClass("active");

            $('.pf_tower_circle, .pf_rectifier_circle').addClass('active');
            $('.pf_tower_circle').css({ stroke: tower_color });
            $('.pf_rectifier_circle').css({ stroke: rectifier_color });
        } else {
            $('.pf_gen_to_recti').removeClass("active");
        }
    } else {
        $('.pf_gen_to_recti').removeClass("active");

    }

    // grid power
    if (grid_power === true) {
        $('.pf_grid_to_recti, .pf_recti_to_tower').addClass('active');
        $('.pf_grid_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_grid_circle').css({ stroke: grid_color });

        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });


    } else {
        $('.pf_grid_to_recti').removeClass("active");
    }

    // solar power
    if (solar_power === true) {
        $('.pf_solor_to_recti, .pf_recti_to_tower').addClass('active');
        $('.pf_solar_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_solar_circle').css({ stroke: solar_color });
        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });
    } else {
        $('.pf_solor_to_recti').removeClass("active");
    }

    // battery charging power
    if (battery_charging === true) {
        $('.pf_recti_to_battery, .pf_recti_to_tower, .pf_battery_pointer').addClass('active');
        $('.pf_battery_dischargin').text('Charging');
        $('.pf_battery_dischargin').addClass('charging');
        $('.pf_battery_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_battery_circle').css({ stroke: battery_color });
        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });

        $('.pf_battery_bar').addClass('is_charging');


    } else {
        $('.pf_battery_pointer').removeClass("active");
        $('.pf_battery_dischargin').removeClass('charging');

        $('.pf_battery_bar').removeClass('is_charging');
    }

    // battery charging power
    if (battery_disCharging === true) {
        $('.pf_recti_to_battery, .pf_battery_dischargin').addClass('decharging');
        $('.pf_recti_to_tower').addClass('active');
        $('.pf_battery_dischargin').text('Discharging');
        $('.pf_battery_circle, .pf_tower_circle, .pf_rectifier_circle').addClass('active');
        $('.pf_battery_circle').css({ stroke: battery_color });

        $('.pf_tower_circle').css({ stroke: tower_color });
        $('.pf_rectifier_circle').css({ stroke: rectifier_color });
        $('.pf_battery_bar').addClass('is_discharging');

    } else {
        $('.pf_battery_dischargin').removeClass("decharging");
        $('.pf_battery_bar').removeClass('is_discharging');
    }





}
function batteryCharged(wfData) {
    let data = wfData.data;
    console.log('batteryCharged---', data);

    const positiveNumber = Math.abs(data.charging);

    let battery_life = positiveNumber;

    if (battery_life > 100) {
        battery_life = 100;
    } 
    if (battery_life < 5) {
        battery_life = 5;
    }
    let battery_cells_calc = battery_life / 5;
    let battery_cells = battery_cells_calc.toFixed(0);

    // let parent = $('.pf_battery_bar ul');
    let li = $('.pf_battery_bar ul li');

    var reversedIndex = (li.length - battery_cells);


    $(li).eq(reversedIndex).addClass('current');


    $('.pf_battery_bar ul li.current').prevAll().addClass("batteryCell_disabled");
    $('.pf_battery_bar ul li.current').removeClass("batteryCell_disabled");
    $('.pf_battery_bar ul li.current').nextAll().removeClass("batteryCell_disabled");
    $('.pf_battery_bar').attr("data-percent", battery_life);
    $('.pf_battery_bar').attr("data-cells", battery_cells);



    if (battery_life <= 20) {
        $('.pf_battery_bar').addClass("batteryCell_down");
    } else {
        $('.pf_battery_bar').removeClass("batteryCell_down");
    }
    if (battery_life <= 40 && battery_life > 20) {
        $('.pf_battery_bar').addClass("batteryCell_warning");

    } else {
        $('.pf_battery_bar').removeClass("batteryCell_warning");
    }
    if (battery_life > 40) {

    }


    if ($('.pf_battery_bar').hasClass('is_charging')) {
        $('.pf_battery_bar ul li.current').addClass("blinkAble");

        var len = $('.pf_battery_bar ul li.blinkAble').prevAll().length;
        var oldlen = $('.pf_battery_bar ul li.blinkAble').prevAll().length;


        clearInterval();
        setInterval(function () {
            len = len - 1;
            if (len < 0) {
                len = oldlen;
                $(li).removeClass('blink');
            }
            $(li).eq(len).addClass('blink');
        }, 500)
    }
}


export default PowerFlow;