import React from 'react';
import ReactECharts from 'echarts-for-react';
const ReliabilityGradingChartPie = (props) => {
   
    return (
        <>
            <ReactECharts option={props.options} opts={{ renderer: 'svg' }} style={{ height: 300 }} />
        </>
    );
};
export default ReliabilityGradingChartPie;